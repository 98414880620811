import IbanFormControl from 'components/IbanFormControl';
import { Select } from 'containers';
import PropTypes from 'prop-types';
import React from 'react';
import { FormGroup, FormLabel } from 'react-bootstrap';

const WithoutNextTenant = ({
  t,
  possibleTerminationDates,
  noticeDate,
  handleOnChange,
  iban,
  handleIbanChanged,
}) => (
  <>
    <FormGroup className="mb-3" style={{ width: '100%' }}>
      <FormLabel>{t('terminationDate')}</FormLabel>
      <Select
        className="mb-3"
        options={possibleTerminationDates}
        value={noticeDate}
        onChange={handleOnChange}
        selectProp="_d"
        output={(date) => date.format('DD. MMMM YYYY')}
      />
    </FormGroup>
    <FormGroup className="mb-3" style={{ width: '100%' }}>
      <FormLabel>{t('iban')}</FormLabel>
      <IbanFormControl value={iban} onChange={handleIbanChanged} required />
    </FormGroup>
    <div className="mb-2">{t('notice')}</div>
  </>
);

WithoutNextTenant.propTypes = {
  t: PropTypes.func.isRequired,
  possibleTerminationDates: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  iban: PropTypes.string.isRequired,
  handleIbanChanged: PropTypes.func.isRequired,
};

export default WithoutNextTenant;
