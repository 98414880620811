import React from 'react';
import PropTypes from 'prop-types';
import Content from 'components/Content';
import { HouseGiverList } from 'containers';

const HouseGivers = ({ t }) => (
  <Content>
    <h2>{t('houseGiver.houseGiver')}</h2>
    <HouseGiverList />
  </Content>
);

HouseGivers.propTypes = {
  t: PropTypes.func.isRequired
};

export default HouseGivers;
