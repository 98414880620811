import PropTypes from 'prop-types';
import React from 'react';
import { FormControl } from 'react-bootstrap';

const IbanFormControl = ({ value, onChange, pattern, ...rest }) => {
  return (
    <FormControl
      type="text"
      placeholder="DE00000000000000000000"
      pattern={pattern}
      onChange={onChange}
      value={value}
      {...rest}
    />
  );
};
IbanFormControl.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  pattern: PropTypes.string.isRequired
};

export default IbanFormControl;
