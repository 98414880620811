import { put, takeLatest, call } from 'redux-saga/effects';
import { statisticsApi } from 'utils/api';
import i18n from 'i18next';
import {
  GET_RENTAL_ENTRANCE,
  getRentalEntranceSuccess,
  getRentalEntranceFailure
} from './rentalEntrance.actions';

function* getRentalEntrance() {
  try {
    const { data, errors } = yield call(statisticsApi.rentalEntrance);

    if (!errors) {
      yield put(getRentalEntranceSuccess(data));
    } else {
      yield put(getRentalEntranceFailure(errors));
    }
  } catch (error) {
    yield put(getRentalEntranceFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(GET_RENTAL_ENTRANCE, getRentalEntrance);
}
