import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OverviewTable from 'components/OverviewTable';
import PropTypes from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const HouseList = ({
  t,
  houses,
  columnConfiguration,
  handleNewHouseClick,
  handleHouseRowClick
}) => (
  <OverviewTable
    rows={houses}
    columns={columnConfiguration}
    onRowClick={handleHouseRowClick}
    searchable={{ searchFormatted: true }}
    actionButton={
      <OverlayTrigger
        placement="top"
        delay={{ show: 300, hide: 400 }}
        overlay={<Tooltip>{t('house.addHouse')}</Tooltip>}
      >
        <Button className="float-md-right" onClick={handleNewHouseClick}>
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      </OverlayTrigger>
    }
  />
);

HouseList.propTypes = {
  t: PropTypes.func.isRequired,
  houses: PropTypes.array.isRequired,
  columnConfiguration: PropTypes.array.isRequired,
  handleNewHouseClick: PropTypes.func.isRequired,
  handleHouseRowClick: PropTypes.func.isRequired
};

export default HouseList;
