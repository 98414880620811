import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Map, RoomOverviewBase, SimpleRoomPreview } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { CurrentTenants, Description } from './components';

const Room = ({ t, handleToBookingClick, room, moreRooms }) => {
  const fullAddress = `${room.address.street} ${room.address.houseNumber}, ${room.address.postalCode} ${room.address.location}`;

  return (
    <Container>
      <RoomOverviewBase
        className="my-5"
        t={t}
        handleBookClick={handleToBookingClick}
        room={room}
        canBook
        imageGallery
      />
      <Row>
        <Col className="col-12 col-xl-8 col-lg-8 mb-4">
          <Description
            t={t}
            locationOfRoom={room.locationOfRoom}
            freeAt={room.freeAt}
            symbolRoom={room.symbol}
            symbolFlat={room.flat.symbol}
            descriptonRoom={room.description}
            descriptionApartment={room.flat.description}
          />
          <div className="mt-5">
            <div className="d-flex mb-2">
              <div className="me-2">
                <FontAwesomeIcon icon={faMapMarkerAlt} color="#007BFF" />
              </div>
              <div style={{ color: '#007BFF' }}>{fullAddress}</div>
            </div>
            <div
              className="mb-md-5 mb-2"
              style={{ width: '100%', height: '500px', position: 'relative' }}
            >
              <Map
                latitude={room.address.latitude}
                longitude={room.address.longitude}
              />
            </div>
          </div>
        </Col>
        <Col className="col-12 col-xl-4 col-lg-4">
          <CurrentTenants t={t} currentTenants={room.flat.currentTenants} />
          <div className="mt-4 mb-md-5">
            <div className="mb-3">
              <b>{t('room:moreRooms')}</b>
            </div>
            {moreRooms.length > 0 &&
              moreRooms.map((room) => (
                <SimpleRoomPreview
                  className="mt-3"
                  key={room.id}
                  image={room.images[0] ? room.images[0].imageUrl : null}
                  price={room.price}
                  squareMeters={room.squareMeters}
                  description={room.description}
                  id={room.id}
                />
              ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

Room.propTypes = {
  t: PropTypes.func.isRequired,
  handleToBookingClick: PropTypes.func.isRequired,
  room: PropTypes.object.isRequired,
  moreRooms: PropTypes.array.isRequired,
};

export default Room;
