import useUser from 'hooks/store/useUser';
import i18n from 'i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Form, Navbar, NavDropdown } from 'react-bootstrap';
import { FlagIcon } from 'react-flag-kit';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logout } from 'store/account/logout/logout.actions';
import * as UserRoles from 'utils/constants/userRole.constants';
import { getAccessToken } from 'utils/localStorage.adapter';
import config from '../../../config';

const Header = ({ firstName, lastName }) => {
  const { t } = useTranslation('header');

  const user = useUser();

  const dispatch = useDispatch();
  const history = useHistory();

  const [language, setLanguage] = useState(
    localStorage.getItem('i18nextLng')
      ? localStorage.getItem('i18nextLng')
      : 'de-DE'
  );
  const isTenant =
    user.data && user.data.role && UserRoles.USER === user.data.role;

  const isAdmin =
    user.data && user.data.role && user.data.role === UserRoles.ADMIN;

  const enableDashboard = user.data;

  const handleLoginClick = () => history.push('/login');
  const handleDashboardClick = () => history.push('/dashboard');
  const handleLogoutClick = () => {
    dispatch(logout());
    window.location.reload();
  };
  const handleAccountBalanceClick = () => history.push('/dashboard/balance');
  const handleBrandClick = () => history.push('/');
  const handleHangfireClick = () => {
    window.location = config.HangfireUrl + '?token=' + getAccessToken();
  };

  return (
    <Navbar
      variant="dark"
      bg="dark"
      style={{ justifyContent: 'space-between' }}
      expand="md"
      className="px-3"
    >
      <Navbar.Brand
        style={{ color: 'white', cursor: 'pointer' }}
        onClick={handleBrandClick}
      >
        Student Housing Duisburg
      </Navbar.Brand>
      <Form className="d-inline-flex">
        {!firstName ? (
          <Button onClick={handleLoginClick} className="me-3" variant="light">
            {t('login')}
          </Button>
        ) : (
          <>
            <style type="text/css">
              {`
               .dropdown-toggle {
                    color: white;
                }
               .dropdown-toggle:hover {
                    color: white;
                }

                .no-padding-left .dropdown-toggle {
                  padding-left: 0 !important;
                }
            `}
            </style>
            {isTenant && enableDashboard && (
              <>
                <div className="me-4" style={{ color: 'white' }}>
                  {t('customerId')}
                  <br />
                  {user.data.customerId}
                </div>
                <div
                  className="me-4"
                  style={{ color: 'white', cursor: 'pointer' }}
                  onClick={handleAccountBalanceClick}
                >
                  {t('accountBalance')}
                  <br />
                  <span
                    style={{
                      color:
                        user.data.accountBalance >= 0 ? 'white' : '#DC3545',
                    }}
                  >
                    {`${user.data.accountBalance}€`}
                  </span>
                </div>
                {user.data.booking && (
                  <div className="me-4" style={{ color: 'white' }}>
                    {t('roomSymbol')}
                    <br />
                    {user.data.booking.roomSymbol}
                  </div>
                )}
              </>
            )}
            <Button
              onClick={handleDashboardClick}
              className="me-4"
              variant="light"
              disabled={!enableDashboard}
            >
              {t('dashboard')}
            </Button>
            {isAdmin && (
              <Button
                onClick={handleHangfireClick}
                className="me-4"
                variant="light"
              >
                Hangfire
              </Button>
            )}
            <NavDropdown
              className="no-padding-left"
              title={
                <span className="pl-0" style={{ color: 'white' }}>
                  {`${firstName} ${lastName}`}
                </span>
              }
            >
              {isTenant && (
                <NavDropdown.Item
                  href="/dashboard/user/profile"
                  disabled={!enableDashboard}
                >
                  {t('profile')}
                </NavDropdown.Item>
              )}
              <NavDropdown.Item href="/dashboard" disabled={!enableDashboard}>
                {t('dashboard')}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={handleLogoutClick}>
                {t('logout')}
              </NavDropdown.Item>
            </NavDropdown>
          </>
        )}
        <div className="ms-3">
          {language === 'en-US' ? (
            <FlagIcon
              onClick={() => {
                i18n.changeLanguage('de-DE');
                moment.locale('de');
                setLanguage('de-DE');
              }}
              code={'US'}
              size={24}
              style={{ marginRight: 20, cursor: 'pointer' }}
            />
          ) : (
            <FlagIcon
              onClick={() => {
                i18n.changeLanguage('en-US');
                moment.locale('en');
                setLanguage('en-US');
              }}
              code={'DE'}
              size={24}
              style={{ marginRight: 20, cursor: 'pointer' }}
            />
          )}
        </div>
      </Form>
    </Navbar>
  );
};

Header.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};

export default Header;
