import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleLoadUser } from 'store/account/getUser/user.actions';
import { getAccessToken } from 'utils/localStorage.adapter';

const useUser = (selector = state => state.user) => {
  const dispatch = useDispatch();

  const { success } = useSelector(state => state.user);
  const user = useSelector(selector);

  useEffect(() => {
    const jwt = getAccessToken();
    if (!success) dispatch(handleLoadUser(jwt));
  }, [success]);

  return user;
};

export default useUser;
