import useBookings from 'hooks/store/useBookings';
import useTenants from 'hooks/store/useTenants';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Tenants from './Tenants';
import TenantsModalDetailModal from './TenantsModal';

const TenantsContainer = () => {
  const { t } = useTranslation('tenants');
  const [filter, setFilter] = useState(t('allBalances'));
  const [onlyActiveTenantsFilter, setOnlyActiveTenantsFilter] = useState(true);
  const history = useHistory();

  const handleFilterChange = (e) => setFilter(e.target.value);

  const onActiveTenantsFilterChange = () =>
    setOnlyActiveTenantsFilter(!onlyActiveTenantsFilter);

  const allActiveBookings = useBookings((state) => {
    return state.bookings.filter(
      (x) =>
        x.termination === null ||
        moment(x.termination.effectiveFrom).isSameOrAfter(moment())
    );
  });

  const tenants = useTenants((state) => {
    let filteredTenants = state.tenants;
    if (onlyActiveTenantsFilter) {
      filteredTenants = filteredTenants.filter((y) => {
        return allActiveBookings.find((x) => x.tenant.userId === y.userId);
      });
    }
    switch (filter) {
      case t('positiveBalances'):
        return filteredTenants.filter((tenant) => tenant.accountBalance >= 0);
      case t('negativeBalances'):
        return filteredTenants.filter((tenant) => tenant.accountBalance < 0);
      default:
        return filteredTenants;
    }
  });

  const [selectedTenant, setSelectedTenant] = useState(tenants[0]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const rowEvents = {
    onClick: (_, tenant) => {
      setSelectedTenant(tenant);
      setIsModalOpen(true);
    },
    onMouseEnter: () => {},
  };

  const columns = [
    {
      dataField: 'customerId',
      text: t('customerId'),
      sort: true,
      headerStyle: { width: '10%' },
    },
    {
      dataField: 'firstName',
      text: t('firstName'),
      sort: true,
      headerStyle: { width: '30%' },
    },
    {
      dataField: 'lastName',
      text: t('lastName'),
      sort: true,
      headerStyle: { width: '30%' },
    },
    {
      dataField: 'accountBalance',
      text: t('balance'),
      sort: true,
      headerStyle: { width: '20%' },
      formatter: (b) => `${b}€`,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation();
          if (typeof row.accountBalance === 'number') {
            history.push(`/dashboard/balance/${row.userId}`, {
              tenant: row,
              firstName: row.firstName,
              lastName: row.lastName,
              customerId: row.customerId,
            });
          }
        },
      },
    },
  ];

  const handleOpenRoomClick = (roomId) => history.push(`data/room/${roomId}`);
  const handleNewTenantClick = () =>
    history.push('/dashboard/landlord/createNewTenant');

  return (
    <>
      {selectedTenant && (
        <TenantsModalDetailModal
          tenant={selectedTenant}
          isOpen={isModalOpen}
          onHide={() => setIsModalOpen(false)}
        />
      )}
      <Tenants
        {...{
          handleFilterChange,
          tenants: tenants,
          rowEvents,
          columns,
          t,
          filter,
          selectedTenant,
          isModalOpen,
          setIsModalOpen,
          handleOpenRoomClick,
          onActiveTenantsFilterChange,
          onlyActiveTenantsFilter,
          handleNewTenantClick,
        }}
      />
    </>
  );
};

export default TenantsContainer;
