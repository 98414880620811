import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getClaimReports } from 'store/claimReport/getClaimReports/getClaimReports.actions';

const useClaimReports = (selector = state => state.claimReports) => {
  const dispatch = useDispatch();

  const { success } = useSelector(state => state.request.getClaimReports);
  const claimReports = useSelector(selector);

  useEffect(() => {
    if (!success) dispatch(getClaimReports());
  }, [success]);

  return claimReports;
};

export default useClaimReports;
