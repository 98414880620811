export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const logout = () => ({
  type: LOGOUT
});
export const logoutSuccess = payload => ({
  type: LOGOUT_SUCCESS,
  payload
});
export const logoutFailure = errors => ({
  type: LOGOUT_FAILURE,
  errors
});
