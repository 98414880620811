import React, { useEffect } from 'react';
import EarnMoney from './EarnMoney';
import { useDispatch, useSelector } from 'react-redux';
import { getAllRooms } from 'store/room/getAllRooms/getAllRooms.actions';
import { getCreditSettings } from 'store/settings/credit/getCreditSettings/getCreditSettings.actions';
import { getOfferedCreditNotes } from 'store/creditNotes/getOfferedCreditNotes/getOfferedCreditNotes.actions';
import { getCurrentAdvertiserPosition } from '../../../../store/creditNotes/getCurrentAdvertiserPosition/getCurrentAdvertiserPosition.actions';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { getPayments } from '../../../../store/payment/getPayments/getPayments.actions';

const EarnMoneyContainer = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('earnMoney');

  const roomsLoaded = useSelector(state => state.request.getAllRooms.success);
  const rooms = useSelector(state => state.rooms);
  const bookableRooms = rooms.filter(
    x =>
      x.bookable &&
      moment(x.freeAt)
        .startOf('day')
        .isSameOrBefore(moment().startOf('day'))
  );

  const { success: credentialSettingsLoaded } = useSelector(
    state => state.request.getCreditSettings
  );
  const { data: offeredCreditNotes } = useSelector(
    state => state.request.getOfferedCreditNotes
  );
  const credentialSettings = useSelector(state => state.settings.credit);
  const user = useSelector(state => state.user.data);

  useEffect(() => {
    if (!roomsLoaded) dispatch(getAllRooms());
  }, [roomsLoaded]);

  useEffect(() => {
    if (!credentialSettingsLoaded) dispatch(getCreditSettings());
  }, [credentialSettingsLoaded]);

  useEffect(() => {
    dispatch(getPayments());
  }, []);

  useEffect(() => {
    dispatch(getOfferedCreditNotes());
  }, []);

  const payments = useSelector(state => state.payments);

  const calculateTotalEarnableCredit = () => {
    if (!credentialSettings.creditAdvertiser) return 0;
    if (!roomsLoaded) return 0;

    return (bookableRooms.length * credentialSettings.creditAdvertiser).toFixed(
      2
    );
  };

  const calculateTotalEarnedCredit = () => {
    if (!offeredCreditNotes) return 0;
    if (offeredCreditNotes.length === 0) return 0;

    let sum = 0;
    payments
      .filter(x => x.paymentMethod === 4)
      .forEach(x => {
        sum += x.amount;
      });

    return sum.toFixed(2);
  };

  const hasCreditNotesThisMonth = () => {
    if (!payments) return false;
    if (payments.length === 0) return false;

    const startOfMonth = moment()
      .clone()
      .startOf('month')
      .format('YYYY-MM-DD hh:mm');

    const endOfMonth = moment()
      .clone()
      .endOf('month')
      .format('YYYY-MM-DD hh:mm');

    return payments.some(
      x =>
        moment(x.bookingDate).isBetween(startOfMonth, endOfMonth) &&
        x.paymentMethod === 4
    );
  };

  const calculateAdvertisedTenants = () => {
    if (!offeredCreditNotes) return 0;
    return offeredCreditNotes.length;
  };

  const calculateAmountPerRoom = () => {
    if (!credentialSettings.creditAdvertiser) return 0;

    return credentialSettings.creditAdvertiser.toFixed(2);
  };

  const calculateBonusFirstPlace = () => {
    if (!credentialSettings.bonus) return 0;

    return credentialSettings.bonus.toFixed(2);
  };

  const {
    success: getCurrentAdvertiserPositionSuccess,
    data: currentPosition
  } = useSelector(state => state.request.getCurrentAdvertiserPosition);

  useEffect(() => {
    if (!getCurrentAdvertiserPositionSuccess)
      dispatch(getCurrentAdvertiserPosition());
  }, [getCurrentAdvertiserPositionSuccess]);

  return (
    <EarnMoney
      {...{
        totalEarnedMoney: calculateTotalEarnedCredit() + '€',
        totalAdvertisedTenants: calculateAdvertisedTenants(),
        totalAvailableMoney: calculateTotalEarnableCredit() + '€',
        bonusPerRoom: calculateAmountPerRoom() + '€',
        promotionCode: user.promotionCode,
        availableRooms: bookableRooms,
        currentPosition,
        calculateBonusFirstPlace: calculateBonusFirstPlace() + '€',
        hasCreditNotesThisMonth: hasCreditNotesThisMonth(),
        t
      }}
    />
  );
};

export default EarnMoneyContainer;
