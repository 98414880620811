import i18n from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';

const EmailFormGroup = ({ value, onChange, ...rest }) => (
  <FormGroup className="mb-3" controlId="formEmail">
    <FormLabel>{i18n.t('forms:user.email')}</FormLabel>
    <FormControl
      value={value}
      onChange={onChange}
      name="email"
      maxLength="50"
      type="email"
      {...rest}
    />
  </FormGroup>
);

EmailFormGroup.defaultProps = {
  value: ''
};

EmailFormGroup.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default EmailFormGroup;
