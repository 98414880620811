import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTenantsForLandlord } from 'store/landlord/getTenantsForLandlord/getTenantsForLandlord.actions';

const useTenants = (selector = state => state.tenants) => {
  const dispatch = useDispatch();

  const { success } = useSelector(state => state.request.getTenantsForLandlord);
  const tenants = useSelector(selector);

  useEffect(() => {
    if (!success) dispatch(getTenantsForLandlord());
  }, [success]);

  return tenants;
};

export default useTenants;
