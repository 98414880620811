import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useInputState from 'hooks/useInputState';
import { registerTenant } from 'store/account/registerTenant/registerTenant.actions';
import TenantRegister from './TenantRegister';
import successToast from 'utils/successToast';

const TenantRegisterContainer = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation('register');
  const [errors, setErrors] = useState([]);
  const [email, setEmail] = useInputState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [showButton, setShowButton] = useState(true);

  const { success, loading, errors: apiErrors } = useSelector(
    state => state.request.registerTenant
  );

  const handleSubmit = (email, password, repeatPassword) => {
    if (password !== repeatPassword) {
      setErrors([t('passwordsDoNotMatch')]);
      setPassword('');
      setRepeatPassword('');
      return;
    }

    dispatch(registerTenant(email, password));
  };

  useEffect(() => {
    if (success) {
      setShowButton(false);
      successToast('confirmationCodeSend');
      localStorage.setItem('email', email);
    }
  }, [success]);

  useEffect(() => {
    if (apiErrors.length > 0) {
      setErrors(apiErrors);
    }
  }, [apiErrors]);

  const handlePasswordChanged = e => setPassword(e.target.value);
  const handleRepeatPasswordChanged = e => setRepeatPassword(e.target.value);

  return (
    <>
      <TenantRegister
        {...{
          t,
          registerUser: handleSubmit,
          email,
          setEmail,
          password,
          setPassword,
          repeatPassword,
          setRepeatPassword,
          errors,
          loading,
          handlePasswordChanged,
          handleRepeatPasswordChanged,
          showButton
        }}
      />
    </>
  );
};

TenantRegisterContainer.propTypes = {};

export default TenantRegisterContainer;
