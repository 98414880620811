export const UPDATE_FLAT = 'UPDATE_FLAT';
export const UPDATE_FLAT_SUCCESS = 'UPDATE_FLAT_SUCCESS';
export const UPDATE_FLAT_FAILURE = 'UPDATE_FLAT_FAILURE';
export const UPDATE_FLAT_CLEAR = 'UPDATE_FLAT_CLEAR';

export const updateFlat = (id, symbol, level, description, squareMeters, houseId) => ({
  type: UPDATE_FLAT,
  symbol,
  id,
  level,
  description,
  squareMeters,
  houseId
});
export const updateFlatSuccess = payload => ({
  type: UPDATE_FLAT_SUCCESS,
  payload
});
export const updateFlatFailure = errors => ({
  type: UPDATE_FLAT_FAILURE,
  errors
});
export const updateFlatClear = () => ({
  type: UPDATE_FLAT_CLEAR
});
