import {
  BirthDateFormControl,
  ErrorAlerts,
  GenderFormControl,
  JobFormControl,
  LoadingButton,
  NationalityFormControl
} from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

const CreateBaseData = ({
  t,
  firstName,
  lastName,
  mobileNumber,
  nationality,
  birthDate,
  gender,
  job,
  errors,
  loading,
  handleMobileNumberChange,
  handleNationalityChange,
  handleBirthDateChange,
  handleGenderChange,
  handleJobChange,
  handleFirstNameChange,
  handleLastNameChange,
  handleSubmit
}) => (
  <Container fluid>
    <Row>
      <Col className="col-12">
        <ErrorAlerts errors={errors} />
        <Form onSubmit={handleSubmit}>
          <FormGroup className="mb-3" controlId="formBasicFirstName">
            <FormLabel>{t('lastName')}</FormLabel>
            <FormControl
              type="text"
              value={lastName}
              onChange={handleLastNameChange}
              required
            />
          </FormGroup>

          <FormGroup className="mb-3" controlId="formBasicLastName">
            <FormLabel>{t('firstName')}</FormLabel>
            <FormControl
              type="text"
              value={firstName}
              onChange={handleFirstNameChange}
              required
            />
          </FormGroup>

          <FormGroup className="mb-3" controlId="formBasicPhoneNumber">
            <FormLabel>{t('phoneNumber')}</FormLabel>
            <FormControl
              value={mobileNumber}
              onChange={handleMobileNumberChange}
              pattern="[0-9 ()+-]+$"
              required
            />
          </FormGroup>

          <FormGroup className="mb-3" controlId="formBasicNationality">
            <FormLabel>{t('nationality')}</FormLabel>
            <NationalityFormControl
              value={nationality}
              onChange={handleNationalityChange}
              required
            />
          </FormGroup>

          <FormGroup className="mb-3" controlId="formBasicBirthday">
            <FormLabel>{t('birthDate')}</FormLabel>
            <BirthDateFormControl
              value={birthDate}
              onChange={handleBirthDateChange}
            />
          </FormGroup>

          <FormGroup className="mb-3" controlId="formBasicGender">
            <FormLabel>{t('gender')}</FormLabel>
            <GenderFormControl
              value={gender}
              onChange={handleGenderChange}
              required
            />
          </FormGroup>

          <FormGroup className="mb-3" controlId="formBasicJob">
            <FormLabel>{t('job')}</FormLabel>
            <JobFormControl value={job} onChange={handleJobChange} />
          </FormGroup>

          <LoadingButton
            className="w-100 my-3"
            type="submit"
            isLoading={loading}
          >
            {t('submit')}
          </LoadingButton>
        </Form>
      </Col>
    </Row>
  </Container>
);

CreateBaseData.propTypes = {
  t: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  mobileNumber: PropTypes.string.isRequired,
  nationality: PropTypes.string.isRequired,
  birthDate: PropTypes.string.isRequired,
  gender: PropTypes.number,
  job: PropTypes.number,
  errors: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  handleMobileNumberChange: PropTypes.func.isRequired,
  handleNationalityChange: PropTypes.func.isRequired,
  handleBirthDateChange: PropTypes.func.isRequired,
  handleGenderChange: PropTypes.func.isRequired,
  handleJobChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleFirstNameChange: PropTypes.func.isRequired,
  handleLastNameChange: PropTypes.func.isRequired
};

export default CreateBaseData;
