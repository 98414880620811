import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { paymentApi } from 'utils/api';
import {
  ADD_PAYMENTS,
  addPaymentsSuccess,
  addPaymentsFailure
} from './addPayments.actions';
import successToast from 'utils/successToast';

function* addPayments({ amount, reference, userId }) {
  try {
    const { data, errors } = yield call(
      paymentApi.addCreatePayment,
      Number(amount),
      reference,
      userId
    );

    if (!errors) {
      yield put(addPaymentsSuccess(data));
      successToast('addPayments');
    } else {
      yield put(addPaymentsFailure(errors));
    }
  } catch (error) {
    yield put(addPaymentsFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(ADD_PAYMENTS, addPayments);
}
