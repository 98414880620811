import useBookings from 'hooks/store/useBookings';
import useTenants from 'hooks/store/useTenants';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toDisplayDate } from 'utils/date.formatter';
import TenantsModalDetailModal from '../tenants/TenantsModal';
import BookingDetailModal from './BookingDetailModal';
import Bookings from './Bookings';

const BookingsContainer = () => {
  const { t } = useTranslation('bookings');

  const bookings = useBookings();
  const tenants = useTenants();

  const [selectedTenant, setSelectedTenant] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState('');
  const [isBookingDetailModalOpen, setIsBookingDetailModalOpen] =
    useState(false);

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      e.stopPropagation();

      setSelectedBooking(row);
      setIsBookingDetailModalOpen(true);
    },
    onMouseEnter: () => {},
  };

  const columns = [
    {
      dataField: 'tenant',
      text: t('tenant'),
      sort: true,
      formatter: (tenant) =>
        tenant ? `${tenant.firstName} ${tenant.lastName}` : '',
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation();
          if (row.tenant) {
            setSelectedTenant(
              tenants.find((x) => x.userId === row.tenant.userId)
            );
            setIsModalOpen(true);
          }
        },
      },
    },
    {
      dataField: 'startDate',
      text: t('startDate'),
      sort: true,
      formatter: (date) => toDisplayDate(date),
    },
    {
      dataField: 'termination.effectiveFrom',
      text: t('endDate'),
      sort: true,
      formatter: (date) => (date ? toDisplayDate(date) : ''),
    },
    {
      dataField: 'room.symbol',
      text: t('room'),
      sort: true,
    },
    {
      dataField: 'deposit',
      text: t('deposit'),
      sort: true,
      formatter: (deposit) => (deposit ? `${deposit}` : ''),
    },
    {
      dataField: 'rentalFee',
      text: t('rentalFee'),
      sort: true,
      formatter: (rentalFee) => (rentalFee ? `${rentalFee}` : ''),
    },
    {
      dataField: 'depositSettlement',
      text: t('depositSettlement'),
      sort: true,
      formatter: (depositSettlement) => {
        if (!depositSettlement) {
          return '';
        }

        return depositSettlement.approved
          ? t('depositSettlementApproved')
          : t('waitingForApproval');
      },
    },
  ];

  return (
    <>
      {selectedTenant && (
        <TenantsModalDetailModal
          tenant={selectedTenant}
          isOpen={isModalOpen}
          onHide={() => setIsModalOpen(false)}
        />
      )}
      <BookingDetailModal
        booking={selectedBooking}
        t={t}
        isOpen={isBookingDetailModalOpen}
        onHide={() => setIsBookingDetailModalOpen(false)}
      />
      <Bookings {...{ t, bookings, rowEvents, columns }} />
    </>
  );
};

export default BookingsContainer;
