import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { getAccessToken } from '../utils/localStorage.adapter';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = !!getAccessToken();

  if (!isLoggedIn) {
    const renderComponent = () => <Redirect to="/login" />;
    return <Route {...rest} component={renderComponent} />;
  } else {
    return <Route {...rest} component={Component} />;
  }
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired
};

export default PrivateRoute;
