import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  UPDATE_IBAN,
  UPDATE_IBAN_SUCCESS,
  UPDATE_IBAN_FAILURE,
  UPDATE_IBAN_CLEAR
} from './updateIban.actions';

export default createReducer(
  {
    [UPDATE_IBAN]: defaultRequest,
    [UPDATE_IBAN_SUCCESS]: defaultSuccess,
    [UPDATE_IBAN_FAILURE]: defaultError,
    [UPDATE_IBAN_CLEAR]: () => defaultInitialState
  },
  defaultInitialState
);
