import React from 'react';
import PropTypes from 'prop-types';
import Content from 'components/Content';
import { HouseOwnerForm } from 'containers';

const AddHouseOwner = ({ onSubmit, t, isLoading }) => {
  return (
    <Content>
      <h2>{t('houseOwner.houseOwner')}</h2>
      <HouseOwnerForm onSubmit={onSubmit} isLoading={isLoading} />
    </Content>
  );
};

AddHouseOwner.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default AddHouseOwner;
