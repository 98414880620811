import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import {
  AddressFormGroup,
  FirstNameFormGroup,
  LastNameFormGroup,
  SubmitButtonFormGroup
} from 'components';

const HouseOwnerForm = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  handleSubmit,
  address,
  setAddress,
  isLoading
}) => (
  <Form onSubmit={handleSubmit}>
    <FirstNameFormGroup value={firstName} onChange={setFirstName} required />
    <LastNameFormGroup value={lastName} onChange={setLastName} required />

    <AddressFormGroup address={address} onChangeAddress={setAddress} />

    <SubmitButtonFormGroup isLoading={isLoading} />
  </Form>
);

HouseOwnerForm.propTypes = {
  firstName: PropTypes.string.isRequired,
  setFirstName: PropTypes.func.isRequired,
  lastName: PropTypes.string.isRequired,
  setLastName: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  address: PropTypes.object,
  setAddress: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default HouseOwnerForm;
