import {
  createReducer,
  updateArray,
  pushToArray,
  updateItemInArray,
  removeItemInArray
} from 'utils/reducer.helper';

import { GET_HOUSES_SUCCESS } from './getHouses/getHouses.actions';
import { GET_HOUSE_SUCCESS } from './getHouse/getHouse.actions';
import { ADD_HOUSE_SUCCESS } from './addHouse/addHouse.actions';
import { UPDATE_HOUSE_SUCCESS } from './updateHouse/updateHouse.actions';
import { DELETE_HOUSE_SUCCESS } from './deleteHouse/deleteHouse.actions';
import { GET_HOUSE_FLATS_SUCCESS } from './getHouseFlats/getHouseFlats.actions';

export default createReducer(
  {
    [GET_HOUSES_SUCCESS]: (state, action) =>
      updateArray(state, { ...action.payload }),
    [GET_HOUSE_SUCCESS]: (state, action) =>
      updateItemInArray(state, action.payload.id, action.payload),
    [ADD_HOUSE_SUCCESS]: (state, action) => pushToArray(state, action.payload),
    [UPDATE_HOUSE_SUCCESS]: (state, action) =>
      updateItemInArray(state, action.payload.id, action.payload),
    [DELETE_HOUSE_SUCCESS]: (state, action) =>
      removeItemInArray(state, action.payload.id),
    [GET_HOUSE_FLATS_SUCCESS]: (state, { payload }) => {
      if (payload.length > 0) {
        const houseId = payload[0].houseId;
        const houseToUpdate = state.find(x => x.id === houseId);
        houseToUpdate.flats = payload;
        return updateArray(state, [houseToUpdate]);
      }
      return state;
    }
  },
  []
);
