import React, {
  useState,
  cloneElement,
  isValidElement,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal, Button, FormLabel, FormControl } from 'react-bootstrap';
import {
  updatePhoneNumber,
  updatePhoneNumberClear
} from 'store/account/updatePhoneNumber/updatePhoneNumber.actions';
import { toast } from 'react-toastify';
import { LoadingButton } from 'components';

const ChangePhoneNumberModal = ({ children }) => {
  const { t } = useTranslation('tenantProfile');
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const isLoading = useSelector(
    state => state.request.updatePhoneNumber.loading
  );

  const handleShowModal = () => setShowModal(true);
  const handleHideModal = () => setShowModal(false);

  const { data: userInfo } = useSelector(state => state.user);
  const { success } = useSelector(state => state.request.updatePhoneNumber);

  useEffect(() => {
    setPhoneNumber(userInfo.phoneNumber);
  }, []);

  useEffect(() => {
    if (success) {
      handleHideModal();
      dispatch(updatePhoneNumberClear());
    }
  }, [success]);

  const handleOnChange = e => setPhoneNumber(e.target.value);

  const handleSubmit = () => {
    if (phoneNumber)
      if (userInfo.phoneNumber !== phoneNumber)
        dispatch(updatePhoneNumber(phoneNumber));
      else toast.warn(t('samePhoneNumer'));
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') handleSubmit();
  };

  const phoneNumberRegex = new RegExp(/^[0-9 ()+-]+$/);
  const isPhoneNumber = phoneNumberRegex.test(phoneNumber);

  return (
    <>
      {isValidElement(children) &&
        cloneElement(children, { onClick: handleShowModal })}
      <Modal
        size="lg"
        show={showModal}
        onHide={handleHideModal}
        aria-labelledby="example-modal-sizes-title-lg"
        centered
        isLoading
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {t('editPhoneNumber')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormLabel>{t('phoneNumber')}</FormLabel>
          <FormControl
            value={phoneNumber}
            onChange={handleOnChange}
            pattern="[0-9 ()+-]+$"
            onKeyDown={handleKeyDown}
          />
          <LoadingButton
            onClick={handleSubmit}
            style={{ marginTop: 10 }}
            disabled={!isPhoneNumber || phoneNumber.length <= 7}
            isLoading={isLoading}
          >
            {t('submit')}
          </LoadingButton>
        </Modal.Body>
      </Modal>
    </>
  );
};

ChangePhoneNumberModal.propTypes = {
  children: PropTypes.node
};

export default ChangePhoneNumberModal;
