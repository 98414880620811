import useUserRole from 'hooks/store/useUserRole';
import useInputState from 'hooks/useInputState';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { login } from 'store/account/login/login.actions';
import {
  LANDLORD,
  SERVICEPROVIDER,
  USER,
} from 'utils/constants/userRole.constants';
import Login from './Login';

const LoginContainer = ({ location }) => {
  const { t } = useTranslation('login');
  const dispatch = useDispatch();
  const history = useHistory();

  const [errors, setErrors] = useState([]);
  const [email, setEmail] = useInputState('');
  const [emailConfimationError, setEmailConfimationError] = useState('');
  const [password, setPassword] = useInputState('');
  const [isFromRegister, setIsFromRegister] = useState(false);
  const [stayLoggedIn, setStayLoggedIn] = useState(true);
  const [redirect, setRedirect] = useState('');

  const { loading, errors: apiErrors } = useSelector(
    (state) => state.request.login
  );

  const userRole = useUserRole();

  const handleSubmit = () => dispatch(login(email, password));
  const handleStayLoggedInChange = (e) =>
    setStayLoggedIn((prevState) => !prevState);

  useEffect(() => {
    if (userRole) {
      const path = redirect ?? '/';
      switch (userRole) {
        case SERVICEPROVIDER:
          history.push('/dashboard/tickets');
          break;
        case USER:
          history.push('/dashboard/user/profile');
          break;
        case LANDLORD:
          history.push('/dashboard/data');
          break;
        default:
          history.push(path);
          break;
      }
    }
  }, [userRole]);

  useEffect(() => {
    if (apiErrors.length > 0) {
      setErrors(apiErrors.filter((x) => x !== t('emailNotConfirmed')));
      setEmailConfimationError(
        apiErrors.some((x) => x === t('emailNotConfirmed'))
      );
    }
  }, [apiErrors]);

  useEffect(() => {
    if (location.state && location.state.fromRegister) {
      setIsFromRegister(true);
    }
    if (location.state && location.state.redirect) {
      setRedirect(location.state.redirect);
    }
  }, [location]);

  return (
    <Login
      {...{
        t,
        errors,
        setErrors,
        email,
        handleEmailChange: setEmail,
        password,
        handlePasswordChange: setPassword,
        handleSubmit,
        loading,
        isFromRegister,
        stayLoggedIn,
        handleStayLoggedInChange,
        emailConfimationError,
      }}
    />
  );
};

LoginContainer.propTypes = {
  location: PropTypes.object.isRequired,
};

export default LoginContainer;
