import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from '../../../utils/reducer.helper';

import { LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAILURE } from './logout.actions';

export default createReducer(
  {
    [LOGOUT]: defaultRequest,
    [LOGOUT_SUCCESS]: defaultSuccess,
    [LOGOUT_FAILURE]: defaultError
  },
  defaultInitialState
);
