import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBook,
  faTint,
  faWifi,
  faUtensils,
  faEuroSign,
  faFileContract,
  faSquare,
  faUsers,
  faSortNumericUpAlt,
  faCalendarAlt,
  faSubway,
  faMapMarkedAlt,
  faBed,
  faPlus,
  faCog,
  faSearch
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faBook,
  faTint,
  faWifi,
  faUtensils,
  faEuroSign,
  faFileContract,
  faSquare,
  faUsers,
  faSortNumericUpAlt,
  faCalendarAlt,
  faSubway,
  faMapMarkedAlt,
  faBed,
  faPlus,
  faCog,
  faSearch
);
