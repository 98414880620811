import PropTypes from 'prop-types';
import React from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';

const FormGroupControl = ({ onChange, value, label, type, ...rest }) => {
  return (
    <FormGroup className="mb-3">
      <label>{label}</label>
      <FormControl
        value={value}
        onChange={onChange}
        {...rest}
        type={type}
      ></FormControl>
    </FormGroup>
  );
};

FormGroupControl.defaultProps = {
  onChange: () => {}
};

FormGroupControl.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string
};

export default FormGroupControl;
