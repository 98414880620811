import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { getRoom } from 'store/room/getRoom/getRoom.actions';
import { toInputValue } from 'utils/date.formatter';
import { Loader } from 'components';
import CreatePayment from './CreatePayment';
import {
  validatePromotionCode,
  validatePromotionCodeReset
} from 'store/promotionCode/validatePromotionCode/validatePromotionCode.actions';
import { getAdvertisedBonusForRoom } from 'store/settings/credit/getAdvertisedBonusForRoom/getAdvertisedBonusForRoom.actions';

const CreatePaymentContainer = ({ roomId, onCreateBooking, newTenant }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('bookingOverview');

  const room = useSelector(state => state.room);
  const { success } = useSelector(state => state.request.getRoom);

  const [startOfRental, setStartOfRental] = useState('');
  const [firstPossibleCheckinDate, setFirstPossibleCheckinDate] = useState('');
  const [rentalDuration, setRentalDuration] = useState(12);
  const [firstCheckBox, setFirstCheckBox] = useState(false);
  const [secondCheckBox, setSecondCheckBox] = useState(false);

  useEffect(() => {
    dispatch(getRoom(roomId));
    dispatch(getAdvertisedBonusForRoom(roomId));
  }, []);

  useEffect(() => {
    if (success) {
      if (moment().isAfter(room.freeAt)) {
        setStartOfRental(
          moment()
            .add(1, 'days')
            .format()
        );
        setFirstPossibleCheckinDate(
          moment()
            .add(1, 'days')
            .format()
        );
        return;
      }

      if (newTenant.moveInDate) {
        setStartOfRental(newTenant.moveInDate);
        setFirstPossibleCheckinDate(newTenant.moveInDate);
        return;
      }

      setStartOfRental(room.freeAt);
      setFirstPossibleCheckinDate(room.freeAt);
    }
  }, [success]);

  const handleFirstCheckBoxChecked = e => setFirstCheckBox(e.target.checked);
  const handleSecondCheckBoxChecked = e => setSecondCheckBox(e.target.checked);
  const handleStartOfRentalChange = e => setStartOfRental(e);
  const handleRentalDuration = e =>
    setRentalDuration(e.target.value.split(' ')[0]);

  let rental = 0;

  switch (rentalDuration) {
    case '6':
      rental = (room.price * 1.1).toFixed(2);
      break;
    case '9':
      rental = (room.price * 1.05).toFixed(2);
      break;
    case '12':
      rental = room.price.toFixed(2);
      break;
    default:
      if (room.price) {
        rental = room.price.toFixed(2);
      }
      break;
  }

  const isSubmitEnabled = () => {
    if (!firstCheckBox) return false;
    if (!secondCheckBox) return false;
    if (
      !moment(startOfRental).isBetween(
        moment(firstPossibleCheckinDate)
          .add(-1, 'days')
          .format('YYYY-MM-DD'),
        moment(firstPossibleCheckinDate)
          .add(14, 'days')
          .format('YYYY-MM-DD')
      )
    )
      return false;
    return true;
  };

  const [promotionCode, setPromotionCode] = useState('');
  const handlePromotionCodeChange = e => setPromotionCode(e.target.value);

  const handleSubmit = () => {
    history.push(
      {
        pathname: `/tenantRegister/${roomId}`,
        search: `?startofrental=${toInputValue(
          startOfRental
        )}&rentalduration=${rentalDuration}&deposit=${
          room.deposit
        }&promotionCode=${promotionCode}`
      },
      { newTenant: { ...newTenant } }
    );
    onCreateBooking();
  };

  const { data: isPromotionCodeValid, loading } = useSelector(
    x => x.request.validatePromotionCode
  );

  const promotionBonus = useSelector(
    x => x.request.getAdvertisedBonusForRoom.data
  );

  const handleValidatePromotionCode = () => {
    dispatch(validatePromotionCodeReset());
    dispatch(validatePromotionCode(promotionCode));
  };

  return (
    <Loader prop={room} spinner>
      <CreatePayment
        {...{
          t,
          room,
          startOfRental,
          handleStartOfRentalChange,
          rentalDuration: rentalDuration + ` ${t('months')}`,
          handleRentalDuration,
          rental,
          handleSubmit,
          isSubmitEnabled: isSubmitEnabled(),
          firstCheckBox,
          handleFirstCheckBoxChecked,
          secondCheckBox,
          handleSecondCheckBoxChecked,
          firstPossibleCheckinDate,
          promotionCode,
          handlePromotionCodeChange,
          handleValidatePromotionCode,
          loading,
          isPromotionCodeValid,
          promotionBonus: promotionBonus ? promotionBonus.value : '',
          newTenant: !!newTenant.moveInDate
        }}
      />
    </Loader>
  );
};

CreatePaymentContainer.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  roomId: PropTypes.string.isRequired,
  onCreateBooking: PropTypes.func.isRequired
};

export default CreatePaymentContainer;
