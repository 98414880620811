import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Content from 'components/Content';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const Flat = ({
  houseGiver,
  handleEdithouseGiverClick,
  handleDeleteHouseGiverClick,
  t
}) => (
  <Content>
    <OverlayTrigger
      placement="top"
      delay={{ show: 300, hide: 400 }}
      overlay={<Tooltip>{t('houseGiver.editHouseGiver')}</Tooltip>}
    >
      <Button
        style={{ float: 'right' }}
        onClick={handleEdithouseGiverClick}
        variant="secondary"
      >
        <FontAwesomeIcon icon={faPen} />
      </Button>
    </OverlayTrigger>{' '}
    <OverlayTrigger
      placement="top"
      delay={{ show: 300, hide: 400 }}
      overlay={<Tooltip>{t('houseGiver.deleteHouseGiver')}</Tooltip>}
    >
      <Button
        style={{ float: 'right', marginRight: 20 }}
        onClick={handleDeleteHouseGiverClick}
        variant="danger"
      >
        <FontAwesomeIcon icon={faTrash} />
      </Button>
    </OverlayTrigger>
    <h5>{t('houseGiver.firstName')}</h5>
    <span>{houseGiver.firstName}</span>
    <h5>{t('houseGiver.lastName')}</h5>
    <span>{houseGiver.lastName}</span>
    <h5>{t('houseOwner.address')}</h5>
    <span>{`${houseGiver.address.street} ${houseGiver.address.houseNumber} ${houseGiver.address.postalCode} ${houseGiver.address.location}`}</span>
  </Content>
);

Flat.propTypes = {
  houseGiver: PropTypes.object.isRequired,
  handleEdithouseGiverClick: PropTypes.func.isRequired,
  handleDeleteHouseGiverClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default Flat;
