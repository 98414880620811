import { DateFormControl } from 'components';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { FormGroup, FormLabel } from 'react-bootstrap';

const FreeAtFormGroup = ({ value, onChange }) => (
  <FormGroup className="mb-3" controlId="formFreeAt">
    <FormLabel>{i18n.t('forms:freeAt')}</FormLabel>
    <DateFormControl value={value} onChange={onChange} />
  </FormGroup>
);

FreeAtFormGroup.defaultProps = {
  value: ''
};

FreeAtFormGroup.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default FreeAtFormGroup;
