import API from './API';

const prefix = 'settings';

export const saveCreditSettings = ({
  creditAdvertiser,
  creditAdvertised,
  bonus
}) =>
  API.post(`${prefix}/landlord/credit`, {
    creditAdvertiser: parseFloat(creditAdvertiser),
    creditAdvertised: parseFloat(creditAdvertised),
    bonus: parseFloat(bonus)
  });

export const getCreditSettings = () => API.get(`${prefix}/credit`);
export const getAdvertisedBonusForRoom = roomId =>
  API.get(`${prefix}/room/${roomId}/bonus`);

export const getNewsletterSubscription = () =>
  API.get(`${prefix}/newsletter_subscription`);

export const setNewsletterSubscription = value =>
  API.post(`${prefix}/newsletter_subscription/${value}`);

export default {
  saveCreditSettings,
  getCreditSettings,
  getAdvertisedBonusForRoom,
  getNewsletterSubscription,
  setNewsletterSubscription
};
