import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup } from 'react-bootstrap';
import i18n from 'i18next';
import { NumericFormControl } from 'components';

const SquareMeterFormControl = ({ value, onChange, ...rest }) => (
  <InputGroup className="mb-2">
    <NumericFormControl value={value} onChange={onChange} {...rest} />
    <InputGroup.Text>{i18n.t('base:sizeSymbol')}</InputGroup.Text>
  </InputGroup>
);

SquareMeterFormControl.defaultProps = {
  value: '0'
};

SquareMeterFormControl.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

export default SquareMeterFormControl;
