import { ErrorAlerts } from 'components';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  InputGroup
} from 'react-bootstrap';

const CreditSettings = ({
  creditAdvertiser,
  onCreditAdvertiserChange,
  creditAdvertised,
  onCreditAdvertisedChange,
  bonus,
  onBonusChange,
  onSubmit,
  t,
  errors,
  submitDisabled
}) => {
  return (
    <>
      <ErrorAlerts errors={errors} />
      <Form onSubmit={onSubmit}>
        <FormGroup className="mb-3">
          <FormLabel>{t('landlord.credit.creditAdvertiser')}</FormLabel>
          <InputGroup>
            <FormControl
              value={creditAdvertiser}
              onChange={onCreditAdvertiserChange}
              type="number"
              min="0.00"
              step="0.01"
            />
            <InputGroup.Text>{i18n.t('base:currencySymbol')}</InputGroup.Text>
          </InputGroup>
        </FormGroup>
        <FormGroup className="mb-3">
          <FormLabel>{t('landlord.credit.creditAdvertised')}</FormLabel>
          <InputGroup>
            <FormControl
              value={creditAdvertised}
              onChange={onCreditAdvertisedChange}
              type="number"
              min="0.00"
              step="0.01"
              pattern="[0-9]*"
            />
            <InputGroup.Text>{i18n.t('base:currencySymbol')}</InputGroup.Text>
          </InputGroup>
        </FormGroup>
        <FormGroup className="mb-3">
          <FormLabel>{t('landlord.credit.bonus')}</FormLabel>
          <InputGroup>
            <FormControl
              value={bonus}
              onChange={onBonusChange}
              type="number"
              min="0.00"
              step="0.01"
            />
            <InputGroup.Text>{i18n.t('base:currencySymbol')}</InputGroup.Text>
          </InputGroup>
        </FormGroup>
        <Button className="mb-3" type="submit" disabled={submitDisabled}>
          {t('landlord.credit.submit')}
        </Button>
      </Form>
    </>
  );
};

export default CreditSettings;

CreditSettings.propTypes = {
  creditAdvertiser: PropTypes.number.isRequired,
  onCreditAdvertiserChange: PropTypes.func.isRequired,
  creditAdvertised: PropTypes.number.isRequired,
  onCreditAdvertisedChange: PropTypes.func.isRequired,
  bonus: PropTypes.number.isRequired,
  onBonusChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  submitDisabled: PropTypes.bool.isRequired
};
