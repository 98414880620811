import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { LoadingButton, FormGroupControl } from 'components';

const RegisterServiceProviderForm = ({
  t,
  handleSubmit,
  password,
  repeatPassword,
  loading,
  handlePasswordChanged,
  handleRepeatPasswordChanged
}) => (
  <div className="my-5 col-12 col-md-6 col-lg-3 mx-auto">
    <Form
      className="mx-3"
      onSubmit={e => {
        e.preventDefault();
        handleSubmit(password, repeatPassword);
      }}
    >
      <h1 className="text-center mb-3">{t('serviceProviderRegistration')}</h1>
      <FormGroupControl
        label={t('password')}
        value={password}
        onChange={handlePasswordChanged}
        required
        type="password"
      />
      <FormGroupControl
        label={t('repeatPassword')}
        value={repeatPassword}
        onChange={handleRepeatPasswordChanged}
        required
        type="password"
      />

      <LoadingButton className="w-100 mt-3" type="submit" isLoading={loading}>
        {t('submit')}
      </LoadingButton>
    </Form>
  </div>
);

RegisterServiceProviderForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  handlePasswordChanged: PropTypes.func.isRequired,
  repeatPassword: PropTypes.string.isRequired,
  handleRepeatPasswordChanged: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default RegisterServiceProviderForm;
