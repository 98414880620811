import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ActivateAccount from './ActivateAccount';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'qs';
import { activateAccount } from 'store/account/activateAccount/activateAccount.actions';
import errorToast from 'utils/errorToast';
import { useHistory } from 'react-router-dom';

const ActivateAccountContainer = ({ location }) => {
  const { t } = useTranslation('activateAccount');
  const dispatch = useDispatch();
  const history = useHistory();

  const { search } = location;
  const queryStrings = qs.parse(search);

  const secret = queryStrings['?secret'];

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatedPassword, setRepeatedPassword] = useState('');

  const handlePasswordChange = e => setPassword(e.target.value);
  const handleRepeatedPasswordChange = e => setRepeatedPassword(e.target.value);
  const handleEmailChange = e => setEmail(e.target.value);

  const { success, loading } = useSelector(
    state => state.request.activateAccount
  );

  const handleSubmit = () => {
    if (password !== repeatedPassword) {
      errorToast(t('passwordsDoNotMatch'));
      setPassword('');
      setRepeatedPassword('');
      return;
    }

    dispatch(activateAccount(secret, email, password));
  };

  useEffect(() => {
    if (success) {
      history.push('/');
    }
  }, [success]);

  return (
    <ActivateAccount
      {...{
        t,
        email,
        handleEmailChange,
        password,
        handlePasswordChange,
        repeatedPassword,
        handleRepeatedPasswordChange,
        handleSubmit,
        loading
      }}
    ></ActivateAccount>
  );
};

ActivateAccountContainer.propTypes = {
  location: PropTypes.object.isRequired
};

export default ActivateAccountContainer;
