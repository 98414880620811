import React from 'react';
import { useTranslation } from 'react-i18next';
import ServiceProviders from './ServiceProviders';

const ServiceProvidersContainer = () => {
  const { t } = useTranslation('data');

  return <ServiceProviders {...{ t }} />;
};

export default ServiceProvidersContainer;
