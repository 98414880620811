import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { bookingApi } from 'utils/api/';
import {
  GET_BOOKINGS,
  getBookingsSuccess,
  getBookingsFailure
} from './getBookings.actions';

export function* getBookings() {
  try {
    const { data, errors } = yield call(bookingApi.getBookings);

    if (!errors) {
      yield put(getBookingsSuccess(data));
    } else {
      yield put(getBookingsFailure(errors));
    }
  } catch (error) {
    yield put(getBookingsFailure([i18n.t('base:generalError')]));
  }
}

export default function*() {
  yield takeLatest(GET_BOOKINGS, getBookings);
}
