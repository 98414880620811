import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InvoiceDamage from './InvoiceDamage';
import { useDispatch, useSelector } from 'react-redux';
import {
  invoiceClaimReport,
  invoiceClaimReportClear
} from 'store/claimReport/invoiceClaimReport/invoiceClaimReport.actions';
import { useHistory } from 'react-router-dom';

const InvoiceDamageContainer = () => {
  const { t } = useTranslation('claimReport');
  const dispatch = useDispatch();
  const history = useHistory();

  const { loading: isLoading, success } = useSelector(
    state => state.request.invoiceClaimReport
  );

  const [damageAmount, setDamageAmount] = useState(0);
  const [description, setDescription] = useState('');
  const [claim, setClaim] = useState('');
  const [tenantId, setTenantId] = useState('');
  const [images, setImages] = useState([]);
  const handleSelectFiles = e => {
    setImages([...e.target.files]);
  };
  const handleSubmitform = e => {
    e.preventDefault();

    dispatch(
      invoiceClaimReport(claim, description, tenantId, damageAmount, images)
    );
  };
  useEffect(() => {
    if (success) {
      dispatch(invoiceClaimReportClear());
      history.push(`/dashboard/claimReports`);
    }
  }, [success]);

  return (
    <InvoiceDamage
      {...{
        description,
        setDescription,
        handleSubmitform,
        t,
        damageAmount,
        setDamageAmount,
        claim,
        setClaim,
        tenantId,
        setTenantId,
        handleSelectFiles,
        isLoading
      }}
    />
  );
};

export default InvoiceDamageContainer;
