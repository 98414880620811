import { FlatSelect } from 'containers';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { FormGroup, FormLabel } from 'react-bootstrap';

const FlatFormGroup = ({ value, onChange, ...rest }) => (
  <FormGroup className="mb-3" controlId="formFlat">
    <FormLabel>{i18n.t('forms:flat')}</FormLabel>
    <FlatSelect
      selectProp="id"
      onChange={onChange}
      value={value}
      name="flat"
      {...rest}
    />
  </FormGroup>
);

FlatFormGroup.defaultProps = {
  value: ''
};

FlatFormGroup.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default FlatFormGroup;
