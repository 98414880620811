import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { flatApi } from 'utils/api';
import {
  GET_FLAT_HOUSE,
  getFlatHouseSuccess,
  getFlatHouseFailure
} from './getFlatHouse.actions';

function* getFlatHouse({ id }) {
  try {
    const { data, errors } = yield call(flatApi.getFlatHouse, id);

    if (!errors) {
      yield put(getFlatHouseSuccess(data));
    } else {
      yield put(getFlatHouseFailure(errors));
    }
  } catch (error) {
    yield put(getFlatHouseFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(GET_FLAT_HOUSE, getFlatHouse);
}
