import { Loader } from 'components';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getRoom } from 'store/room/getRoom/getRoom.actions';
import {
  updateRoom,
  updateRoomClear,
} from 'store/room/updateRoom/updateRoom.actions';
import EditRoom from './EditRoom';

const EditRoomContainer = ({ match }) => {
  const { t } = useTranslation('data');
  const roomId = parseInt(match.params.roomId);
  const dispatch = useDispatch();
  const history = useHistory();

  const room = useSelector((state) => state.room);

  useEffect(() => {
    dispatch(getRoom(roomId));
  }, []);

  const { success, loading: isLoading } = useSelector(
    (state) => state.request.updateRoom
  );

  useEffect(() => {
    if (success) {
      dispatch(updateRoomClear());
      history.push(`/dashboard/data/room/${roomId}`);
    }
  }, [success]);

  const handleSubmit = ({
    symbol,
    squareMeters,
    price,
    deposit,
    description,
    existingImages,
    newImages,
    equipment,
    freeAt,
    locationOfRoom,
  }) => {
    dispatch(
      updateRoom(
        roomId,
        symbol,
        squareMeters,
        price,
        deposit,
        description,
        equipment,
        newImages,
        existingImages,
        freeAt,
        locationOfRoom
      )
    );
  };

  return room !== undefined ? (
    <Loader prop={room}>
      <EditRoom {...{ t, room, handleSubmit, isLoading }} />
    </Loader>
  ) : (
    <div />
  );
};

EditRoomContainer.propTypes = { match: PropTypes.object.isRequired };

export default EditRoomContainer;
