import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceProviderProfile from './ServiceProviderProfile';
import useUser from 'hooks/store/useUser';
import { useDispatch, useSelector } from 'react-redux';
import { updateServiceProviderInfo } from 'store/account/updateServiceProviderInfo/updateServiceProviderInfo.actions';

const ServiceProviderProfileContainer = () => {
  const [address, setAddress] = useState();
  const [email, setEmail] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();

  const { t } = useTranslation('serviceProviderProfile');

  const dispatch = useDispatch();

  const user = useUser();

  useEffect(() => {
    if (user.success) {
      setFirstName(user.data.firstName);
      setLastName(user.data.lastName);
      setEmail(user.data.email);
      setAddress({
        street: user.data.street,
        houseNumber: user.data.houseNumber,
        postalCode: user.data.postalCode,
        location: user.data.location
      });
    }
  }, [user]);

  const handleEmailChange = e => setEmail(e.target.value);
  const handleFirstNameChange = e => setFirstName(e.target.value);
  const handleLastNameChange = e => setLastName(e.target.value);

  const { loading } = useSelector(
    state => state.request.updateServiceProviderInfo
  );

  const handleSave = () => {
    dispatch(
      updateServiceProviderInfo(
        firstName,
        lastName,
        email,
        address.street,
        address.houseNumber,
        address.location,
        address.postalCode
      )
    );
  };

  return (
    <ServiceProviderProfile
      {...{
        address,
        setAddress,
        t,
        email,
        firstName,
        lastName,
        handleEmailChange,
        handleFirstNameChange,
        handleLastNameChange,
        handleSave,
        loading
      }}
    />
  );
};

export default ServiceProviderProfileContainer;
