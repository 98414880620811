export const DELETE_ADDRESS = 'DELETE_ADDRESS';
export const DELETE_ADDRESS_SUCCESS = 'DELETE_ADDRESS_SUCCESS';
export const DELETE_ADDRESS_FAILURE = 'DELETE_ADDRESS_FAILURE';

export const deleteAddress = id => ({
  type: DELETE_ADDRESS,
  id
});
export const deleteAddressSuccess = payload => ({
  type: DELETE_ADDRESS_SUCCESS,
  payload
});
export const deleteAddressFailure = errors => ({
  type: DELETE_ADDRESS_FAILURE,
  errors
});
