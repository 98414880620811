import { createReducer, updateArray, pushToArray } from 'utils/reducer.helper';

import { GET_PAYMENTS_SUCCESS } from './getPayments/getPayments.actions';
import { SAVE_PAYMENT_SUCCESS } from './savePayment/savePayment.actions';
import { ADD_PAYMENTS_SUCCESS } from './addPayments/addPayments.actions';

export default createReducer(
  {
    [GET_PAYMENTS_SUCCESS]: (state, action) =>
      updateArray(state, { ...action.payload }),
    [SAVE_PAYMENT_SUCCESS]: (state, action) =>
      pushToArray(state, action.payload),
    [ADD_PAYMENTS_SUCCESS]: (state, action) =>
      updateArray(state, { ...action.payload })
  },
  []
);
