import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { houseOwnerApi } from 'utils/api';
import {
  GET_HOUSE_OWNER,
  getHouseOwnerSuccess,
  getHouseOwnerFailure
} from './getHouseOwner.actions';

function* getHouseOwnerSaga({ id }) {
  try {
    const { data, errors } = yield call(houseOwnerApi.getHouseOwner, id);

    if (!errors) {
      yield put(getHouseOwnerSuccess(data));
    } else {
      yield put(getHouseOwnerFailure(errors));
    }
  } catch (error) {
    yield put(getHouseOwnerFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(GET_HOUSE_OWNER, getHouseOwnerSaga);
}
