import { Loader, LoadingButton } from 'components';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateIban } from 'store/account/updateIban/updateIban.actions';
import { addInvitedNewTenant } from 'store/termination/addInvitedNewTenant/addInvitedNewTenant.actions';
import { createTermination } from 'store/termination/createTermination/createTermination.actions';
import { deleteTermination } from 'store/termination/deleteTermination/deleteTermination.actions';
import { getTermination } from 'store/termination/getTermination/getTermination.actions';
import { getCreditSettings } from '../../../../store/settings/credit/getCreditSettings/getCreditSettings.actions';
import Termination from './Termination';

const TerminationContainer = () => {
  const { t } = useTranslation('termination');
  const dispatch = useDispatch();
  const [firstPossibleNoticeDate, setFirstPossibleNoticeDate] = useState();
  const [noticeDate, setNoticeDate] = useState();
  const [nextTenantEmail, setNextTenantEmail] = useState([]);
  const [iban, setIban] = useState();
  const [isStudent, setIsStudent] = useState();
  const [ageBetween, setAgeBetween] = useState();

  const [tab, setTabKey] = useState('with');
  const [show, setShow] = useState(false);
  const [possibleTerminationDates, setPossibleTerminationDates] = useState();

  const { success: credentialSettingsLoaded } = useSelector(
    state => state.request.getCreditSettings
  );
  const credentialSettings = useSelector(state => state.settings.credit);

  useEffect(() => {
    if (!credentialSettingsLoaded) dispatch(getCreditSettings());
  }, [credentialSettingsLoaded]);

  const user = useSelector(state => state.user.data);
  const termination = useSelector(state => state.termination);
  const createTerminationLoading = useSelector(
    state => state.request.createTermination.loading
  );
  const deleteTerminationLoading = useSelector(
    state => state.request.deleteTermination.loading
  );

  useEffect(() => {
    dispatch(getTermination());
  }, []);

  useEffect(() => {
    const { booking } = user;
    if (booking) {
      let date = moment(booking.startDateContract).add(
        Number(booking.durationInMonths),
        'months'
      );
      if (date < moment().add(3, 'months')) {
        date = moment().add(3, 'months');
      }
      date.subtract(1, 'day');
      date = date.endOf('month');
      setFirstPossibleNoticeDate(date);
      setNoticeDate(date);

      const dates = [];

      for (let i = 0; i < 120; i++) {
        dates.push(
          date
            .clone()
            .add(i, 'months')
            .endOf('month')
        );
      }

      setPossibleTerminationDates(dates);
    }
  }, [user]);

  const clearMe = () => {
    setNextTenantEmail('');
    setIban('');
    setIsStudent(false);
    setAgeBetween(false);
  };

  const handleSubmit = () => {
    dispatch(updateIban(iban));
    if (tab === 'with') {
      dispatch(createTermination(noticeDate, nextTenantEmail));
      clearMe();
      return;
    }
    dispatch(createTermination(noticeDate));
    clearMe();
  };

  const addNewTenant = () => {
    setNextTenantEmail('');
    dispatch(addInvitedNewTenant(nextTenantEmail));
  };

  const handleOnChange = e => setNoticeDate(e);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCancelTermination = () => {
    handleShow();
  };

  const confirmCancel = () => {
    handleClose();
    dispatch(deleteTermination());
  };

  const handleTabChanged = key => setTabKey(key);
  const handleNextTenantEmailChanged = e => setNextTenantEmail(e.target.value);
  const handleIbanChanged = e => setIban(e.target.value.toUpperCase());
  const handleIsStudentChanged = e => setIsStudent(e.target.checked);
  const handleAgeBetweenChanged = e => setAgeBetween(e.target.checked);

  const alreadyTerminated = Object.keys(termination).length > 0;

  return (
    <Loader prop={user} spinner>
      <Termination
        {...{
          t,
          firstPossibleNoticeDate,
          handleSubmit,
          alreadyTerminated,
          noticeDate,
          handleOnChange,
          termination,
          handleCancelTermination,
          handleNextTenantEmailChanged,
          handleIbanChanged,
          handleIsStudentChanged,
          handleAgeBetweenChanged,
          nextTenantEmail,
          iban,
          isStudent,
          ageBetween,
          tab,
          handleTabChanged,
          user,
          createTerminationLoading,
          credentialSettings,
          promotionCode: user.promotionCode,
          possibleTerminationDates,
          addNewTenant
        }}
      />
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        aria-labelledby="example-modal-sizes-title-lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {t('withdrawtermination')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{t('terminationwithdraw')}</Modal.Body>
        <Modal.Footer>
          <LoadingButton onClick={handleClose} style={{ marginTop: 10 }}>
            {t('no')}
          </LoadingButton>
          <LoadingButton
            onClick={confirmCancel}
            style={{ marginTop: 10 }}
            disabled={!deleteTerminationLoading}
            isLoading={createTerminationLoading}
          >
            {t('yes')}
          </LoadingButton>
        </Modal.Footer>
      </Modal>
    </Loader>
  );
};

export default TerminationContainer;
