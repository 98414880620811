import i18n from 'i18next';
import { call, put, takeLatest } from 'redux-saga/effects';
import { roomApi } from 'utils/api';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';
import {
  updateRoomFailure,
  updateRoomSuccess,
  UPDATE_ROOM,
} from './updateRoom.actions';

function* updateRoom({
  id,
  symbol,
  squareMeters,
  price,
  deposit,
  description,
  equipment,
  images,
  imageIds,
  freeAt,
  locationOfRoom,
}) {
  try {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('symbol', symbol);
    formData.append('squareMeters', parseInt(squareMeters));
    formData.append('price', price);
    formData.append('deposit', deposit);
    formData.append('description', description);
    formData.append('Equipment.Furnished', equipment.furnished);
    formData.append('Equipment.Washer', equipment.washer);
    formData.append('Equipment.Internet', equipment.internet);
    formData.append('Equipment.Kitchen', equipment.kitchen);
    formData.append('Equipment.AllInclusive', equipment.allInclusive);
    formData.append('freeAt', freeAt);
    formData.append('locationOfRoom', locationOfRoom);

    formData.append(
      'equipment.TenancyAgreementInclusive',
      equipment.tenancyAgreementInclusive
    );

    images.forEach((file) => formData.append('images', file));
    imageIds.forEach((id) => formData.append('imageIds', id));

    const { data, errors } = yield call(roomApi.updateRoom, formData);

    if (!errors) {
      yield put(updateRoomSuccess(data));
      successToast('updateRoom');
    } else {
      yield put(updateRoomFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(updateRoomFailure(i18n.t('base:generalError')));
  }
}

export default function* () {
  yield takeLatest(UPDATE_ROOM, updateRoom);
}
