import i18n from 'i18next';
import { call, put, takeLatest } from 'redux-saga/effects';
import { serviceProviderApi } from 'utils/api';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';
import {
  updateServiceProviderFailure,
  updateServiceProviderSuccess,
  UPDATE_SERVICE_PROVIDER
} from './updateServiceProvider.actions';

function* updateServiceProviderSaga({
  id,
  firstName,
  lastName,
  email,
  serviceProviderTypes,
  houseIds
}) {
  try {
    const { data, errors } = yield call(
      serviceProviderApi.updateServiceProvider,
      id,
      firstName,
      lastName,
      email,
      serviceProviderTypes,
      houseIds
    );

    if (!errors) {
      yield put(updateServiceProviderSuccess(data));
      successToast('updateServiceProvider');
    } else {
      yield put(updateServiceProviderFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(updateServiceProviderFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(UPDATE_SERVICE_PROVIDER, updateServiceProviderSaga);
}
