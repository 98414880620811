export const GET_LANDLORDS = 'GET_LANDLORDS';
export const GET_LANDLORDS_SUCCESS = 'GET_LANDLORDS_SUCCESS';
export const GET_LANDLORDS_FAILURE = 'GET_LANDLORDS_FAILURE';
export const GET_LANDLORDS_CLEAR = 'GET_LANDLORDS_CLEAR';

export const getLandlords = () => ({
  type: GET_LANDLORDS
});
export const getLandlordsSuccess = payload => ({
  type: GET_LANDLORDS_SUCCESS,
  payload
});
export const getLandlordsFailure = errors => ({
  type: GET_LANDLORDS_FAILURE,
  errors
});
export const getLandlordsClear = () => ({
  type: GET_LANDLORDS_CLEAR
});
