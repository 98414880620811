import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import EditHouse from './EditHouse';
import { Loader } from 'components';
import { getHouse } from 'store/houses/getHouse/getHouse.actions';
import {
  updateHouse,
  updateHouseClear
} from 'store/houses/updateHouse/updateHouse.actions';

const EditHouseContainer = ({ match }) => {
  const { t } = useTranslation('data');
  const history = useHistory();

  const houseId = parseInt(match.params.houseId);
  const dispatch = useDispatch();

  const houses = useSelector(state => state.houses);
  const house = houses.find(x => x.id === houseId);

  const { success: getHouseSuccess } = useSelector(
    state => state.request.getHouse
  );

  useEffect(() => {
    if (!getHouseSuccess) dispatch(getHouse(houseId));
  }, [getHouseSuccess]);

  const { success, loading: isLoading } = useSelector(
    state => state.request.updateHouse
  );

  useEffect(() => {
    if (success) {
      dispatch(updateHouseClear());
      history.push(`/dashboard/data/house/${house.id}`);
    }
  }, [success]);

  const handleSubmit = ({
    address,
    giverId,
    ownerId,
    serviceProviderIds,
    minutesToTrainStation,
    levels
  }) => {
    dispatch(
      updateHouse(
        houseId,
        levels,
        minutesToTrainStation,
        giverId,
        serviceProviderIds,
        ownerId,
        house.address.id,
        address
      )
    );
  };

  return house !== undefined ? (
    <Loader prop={house}>
      <EditHouse {...{ t, house, handleSubmit, isLoading }} />
    </Loader>
  ) : (
    <div />
  );
};

EditHouseContainer.propTypes = { match: PropTypes.object.isRequired };

export default EditHouseContainer;
