import {
  BirthDateFormControl,
  DateFormControl,
  FirstNameFormGroup,
  JobFormControl,
  LastNameFormGroup,
  LoadingButton,
  NationalityFormControl,
  PhoneNumberFormControl
} from 'components';
import EmailFormGroup from 'components/FormGroups/EmailFormGroup/EmailFormGroup';
import { RoomSelect } from 'containers';
import useBookings from 'hooks/store/useBookings';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  Modal
} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bookAsLandlord } from 'store/booking/bookAsLandlord/bookAsLandlord.actions';
import { updateBooking } from 'store/booking/updateBooking/updateBooking.actions';
import { getTenantForLandlord } from 'store/landlord/getTenantForLandlord/getTenantForLandlord.actions';
import { updateTenantData } from 'store/tenants/updateTenantData/updateTenantData.actions';
import {
  createTermination,
  createTerminationReset
} from 'store/termination/createTermination/createTermination.actions';
import { toDisplayDate } from 'utils/date.formatter';
import KickOutModal from './KickOutModal';

const TenantsModalDetailModal = ({ tenant, isOpen, onHide }) => {
  const [localTenant, setLocalTenant] = useState();
  const [localBooking, setLocalBooking] = useState();
  const [terminateDate, setTerminateDate] = useState(moment());
  const [newBooking, setNewBooking] = useState({ startDate: moment() });

  const { t } = useTranslation('tenants');
  const history = useHistory();

  const { loading, success: createdTerminationSuccess } = useSelector(
    (state) => state.request.createTermination
  );

  const dispatch = useDispatch();

  const booking = useBookings((state) => {
    return state.bookings.find(
      (booking) => {
        const isSelectedUserBooking = booking.tenant.userId === tenant.userId;
        if (booking.termination) {
          return isSelectedUserBooking && moment(booking.termination.effectiveFrom).isSameOrAfter(moment(), "day");
        }
        return isSelectedUserBooking;
      }
    );
  });

  const { loading: bookAsLandlordIsLoading } = useSelector(
    (state) => state.request.bookAsLandlord
  );

  useEffect(() => {
    dispatch(getTenantForLandlord(tenant.userId));
  }, [tenant.userId]);

  const { data } = useSelector((state) => state.request.getTenantForLandlord);

  useEffect(() => {
    setLocalBooking(booking);
  }, [booking]);

  useEffect(() => {
    if (data) {
      setLocalTenant(data);
    }
  }, [data]);

  const [isKickOutModalOpen, setIsKickOutModalOpen] = useState(false);

  const { loading: updateTenantDataIsLoading } = useSelector(
    (state) => state.request.updateTenantData
  );

  const { loading: updateBookingIsLoading } = useSelector(
    (state) => state.request.updateBooking
  );

  const handleKickOut = () =>
    dispatch(createTermination(terminateDate, null, localBooking.id));

  const handleUpdateTenantData = () => {
    dispatch(
      updateTenantData(
        localTenant.userId,
        localTenant.phoneNumber,
        localTenant.nationality,
        localTenant.dateOfBirth,
        localTenant.gender,
        localTenant.job,
        localTenant.firstName,
        localTenant.lastName,
        localTenant.email
      )
    );
  };

  const handleUpdateBookingData = () => {
    dispatch(
      updateBooking(
        localBooking.id,
        localBooking.startDate,
        localBooking.durationInMonths,
        localBooking.rentalFee,
        localBooking.deposit
      )
    );
  };

  const handleBookAsLandlord = () => {
    dispatch(
      bookAsLandlord(
        newBooking.startDate,
        newBooking.durationInMonths,
        newBooking.roomId,
        localTenant.userId,
        newBooking.rentalFee,
        newBooking.deposit
      )
    );
  };

  const handleOpenRoomClick = (roomId) =>
    history.push(`/dashboard/data/room/${roomId}`);

  useEffect(() => {
    if (createdTerminationSuccess) {
      dispatch(createTerminationReset());
      setIsKickOutModalOpen(false);
      onHide();
    }
  }, [createdTerminationSuccess]);

  return (
    <>
      <KickOutModal
        isOpen={isKickOutModalOpen}
        t={t}
        handleClose={() => setIsKickOutModalOpen(false)}
        handleKickOut={handleKickOut}
        isLoading={loading}
        date={terminateDate}
        setDate={setTerminateDate}
      />
      <Modal
        show={isOpen}
        size="lg"
        onHide={() => {
          setNewBooking({ startDate: moment() });
          onHide();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t('tenant')} <span>{tenant.customerId}</span>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body
          style={{ display: 'flex', flexDirection: 'row', gap: '15px' }}
        >
          {localTenant && (
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              <FirstNameFormGroup
                onChange={(e) =>
                  setLocalTenant({ ...localTenant, firstName: e.target.value })
                }
                value={localTenant.firstName}
              />
              <LastNameFormGroup
                onChange={(e) =>
                  setLocalTenant({ ...localTenant, lastName: e.target.value })
                }
                value={localTenant.lastName}
              />
              <EmailFormGroup
                onChange={(e) =>
                  setLocalTenant({ ...localTenant, email: e.target.value })
                }
                value={localTenant.email}
              />
              <FormGroup className="mb-3">
                <FormLabel>{t('mobileNumber')}</FormLabel>
                <PhoneNumberFormControl
                  onChange={(e) =>
                    setLocalTenant({
                      ...localTenant,
                      phoneNumber: e.target.value,
                    })
                  }
                  value={localTenant.phoneNumber}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <FormLabel>{t('nationality')}</FormLabel>
                <NationalityFormControl
                  onChange={(e) =>
                    setLocalTenant({
                      ...localTenant,
                      nationality: e.target.value,
                    })
                  }
                  value={localTenant.nationality}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <FormLabel>{t('dateOfBirth')}</FormLabel>
                <BirthDateFormControl
                  onChange={(e) =>
                    setLocalTenant({
                      ...localTenant,
                      dateOfBirth: e,
                    })
                  }
                  value={localTenant.dateOfBirth}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <FormLabel>{t('job')}</FormLabel>
                <JobFormControl
                  onChange={(e) =>
                    setLocalTenant({ ...localTenant, job: e.target.value })
                  }
                  value={localTenant.job}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <FormLabel>{t('iban')}</FormLabel>
                <FormControl
                  disabled
                  onChange={(e) =>
                    setLocalTenant({ ...localTenant, iban: e.target.value })
                  }
                  value={localTenant.iban}
                />
              </FormGroup>
              <LoadingButton
                className="mb-3"
                variant="primary"
                isLoading={updateTenantDataIsLoading}
                onClick={() => handleUpdateTenantData()}
              >
                {t('saveBaseData')}
              </LoadingButton>
            </div>
          )}
          {localBooking ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                justifyContent: 'space-between',
              }}
            >
              <Form>
                <FormGroup className="mb-3">
                  <FormLabel>{t('room')}</FormLabel>
                  <div>
                    <span>{localBooking.room.symbol}</span>
                    <Button
                      variant="secondary"
                      onClick={() => handleOpenRoomClick(localBooking.room.id)}
                      style={{ marginLeft: '20px' }}
                    >
                      {t('goToRoom')}
                    </Button>
                  </div>
                </FormGroup>
                <FormGroup className="mb-3">
                  <FormLabel>{t('startDate')}</FormLabel>
                  <DateFormControl
                    key="localBooking_startDate"
                    value={localBooking.startDate}
                    onChange={(e) =>
                      setLocalBooking({ ...localBooking, startDate: e })
                    }
                  />
                </FormGroup>
                <FormGroup className="mb-3">
                  <FormLabel>{t('rent')}</FormLabel>
                  <FormControl
                    key="localBooking_rentalFee"
                    value={localBooking.rentalFee}
                    onChange={(e) =>
                      setLocalBooking({
                        ...localBooking,
                        rentalFee: e.target.value,
                      })
                    }
                    type="number"
                  />
                </FormGroup>
                <FormGroup className="mb-3">
                  <FormLabel>{t('deposit')}</FormLabel>
                  <FormControl
                    key="localBooking_deposit"
                    value={localBooking.deposit}
                    onChange={(e) =>
                      setLocalBooking({
                        ...localBooking,
                        deposit: e.target.value,
                      })
                    }
                    type="number"
                  />
                </FormGroup>
                <FormGroup className="mb-3">
                  <FormLabel>{t('minDuration')}</FormLabel>
                  <FormControl
                    key="localBooking_minDuration"
                    value={localBooking.durationInMonths}
                    onChange={(e) =>
                      setLocalBooking({
                        ...localBooking,
                        durationInMonths: e.target.value,
                      })
                    }
                    type="number"
                  />
                </FormGroup>
                <FormGroup className="mb-3">
                  <FormLabel>{t('termination')}</FormLabel>
                  <div>
                    <span>
                      {localBooking.termination
                        ? toDisplayDate(localBooking.termination.effectiveFrom)
                        : '-'}
                    </span>
                    <Button
                      variant="secondary"
                      onClick={() => setIsKickOutModalOpen(true)}
                      style={{ marginLeft: '20px' }}
                    >
                      {t('removeTenant')}
                    </Button>
                  </div>
                </FormGroup>
                {localTenant?.documents?.rentalContract && (
                  <FormGroup className="mb-3">
                    <FormLabel>{t('rentalContract')}</FormLabel>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${localTenant?.documents?.rentalContract}`}
                    >
                      <Button variant="secondary w-100">{t('view')}</Button>
                    </a>
                  </FormGroup>
                )}
              </Form>
              <LoadingButton
                className="mb-3"
                variant="primary"
                onClick={() => handleUpdateBookingData()}
                isLoading={updateBookingIsLoading}
              >
                {t('saveBookingData')}
              </LoadingButton>
            </div>
          ) : (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleBookAsLandlord();
              }}
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                justifyContent: 'space-between',
              }}
            >
              <FormGroup className="mb-3">
                <FormLabel>{t('startDate')}</FormLabel>
                <DateFormControl
                  key="newBooking_startDate"
                  value={newBooking.startDate}
                  onChange={(e) =>
                    setNewBooking((x) => ({ ...x, startDate: e }))
                  }
                  required
                />
              </FormGroup>
              <FormGroup>
                <FormLabel>{t('room')}</FormLabel>
                <RoomSelect
                  onChange={(room) => {
                    setNewBooking((x) => ({
                      ...x,
                      roomId: room?.id,
                      rentalFee: room?.price ?? 0,
                      deposit: room?.deposit ?? 0,
                      room: room,
                    }));
                  }}
                  selectProp={null}
                  value={newBooking.room}
                  selector={(state) => {
                    return state.rooms.filter((x) =>
                      moment(x.freeAt).isSameOrBefore(newBooking.startDate)
                    );
                  }}
                  required
                />
              </FormGroup>

              <FormGroup className="mb-3">
                <FormLabel>{t('rent')}</FormLabel>
                <FormControl
                  key="newBooking_rentalFee"
                  value={newBooking.rentalFee}
                  onChange={(e) => {
                    setNewBooking((x) => ({ ...x, rentalFee: e.target.value }));
                  }}
                  type="number"
                  required
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <FormLabel>{t('deposit')}</FormLabel>
                <FormControl
                  key="newBooking_deposit"
                  value={newBooking.deposit}
                  onChange={(e) =>
                    setNewBooking((x) => ({ ...x, deposit: e.target.value }))
                  }
                  type="number"
                  required
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <FormLabel>{t('minDuration')}</FormLabel>
                <FormControl
                  key="newBooking_minDuration"
                  value={newBooking !== {} ? newBooking.durationInMonths : ''}
                  onChange={(e) =>
                    setNewBooking((x) => ({
                      ...x,
                      durationInMonths: e.target.value,
                    }))
                  }
                  type="number"
                  required
                />
              </FormGroup>
              <LoadingButton
                variant="primary"
                type="submit"
                isLoading={bookAsLandlordIsLoading}
                className="mb-3 w-100 mt-auto"
              >
                {t('bookRoomForTenant')}
              </LoadingButton>
            </Form>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

TenantsModalDetailModal.defaultState = {
  tenant: undefined,
  isOpen: false,
};

TenantsModalDetailModal.propTypes = {
  tenant: PropTypes.object,
  isOpen: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  handleOpenRoomClick: PropTypes.func.isRequired,
};

export default TenantsModalDetailModal;
