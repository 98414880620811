import API from './API';

const prefix = 'address';

export const getAddress = id => API.get(`${prefix}/${id}`);

export const deleteAddress = id => API.delete(`${prefix}/${id}`);

export const getAddresses = () => API.get(`${prefix}`);

export const addAddress = address =>
  API.post(`${prefix}`, {
    ...address
  });

export const updateAddress = (id, address) =>
  API.put(`${prefix}`, {
    id,
    ...address
  });

export default {
  getAddress,
  getAddresses,
  addAddress,
  updateAddress,
  deleteAddress
};
