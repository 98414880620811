import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getTenantBalanceForLandlord } from 'store/landlord/getTenantBalanceForLandlord/getTenantBalanceForLandlord.actions';
import {
  addPayments,
  addPaymentsClear,
} from 'store/payment/addPayments/addPayments.actions';
import { toDisplayDate } from 'utils/date.formatter';
import { v4 as uuidv4 } from 'uuid';
import Balance from './tenantBalance';

const BalanceContainer = ({ match, location }) => {
  const { t } = useTranslation('balance');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTenantModalOpen, setIsTenantModalOpen] = useState(false);

  const [tableData, setTableData] = useState([]);
  const dispatch = useDispatch();

  const [value, setValue] = useState('0');
  const [reference, setReference] = useState('');
  const id = parseInt(match.params.tenantId);
  const tenantBalances = useSelector(
    (state) => state.tenantBalancesForLandlord
  );
  const { loading: addPaymentsLoading, success } = useSelector(
    (state) => state.request.addPayments
  );
  const openModal = () => {
    setIsModalOpen(true);
  };
  const onHide = () => {
    setIsModalOpen(false);
  };
  const onChange = (e) => {
    setValue(e.target.value);
  };
  const onChangeReference = (e) => {
    setReference(e.target.value);
  };

  useEffect(() => {
    if (success) {
      setIsModalOpen(false);
      dispatch(addPaymentsClear());
    }
  }, [success]);

  const submitCreatePayment = () => {
    dispatch(addPayments(value, id, reference));
  };

  const getCorrectDescriptionForDebt = (debt) => {
    if (debt.description === 'rent') {
      return `${t(debt.description)} ${moment(debt.created).format('MMMM')}`;
    }

    return t(debt.description);
  };

  const mapToPaymentDescription = (paymentMehtod) => {
    if (paymentMehtod === 4) {
      return t('creditNote');
    } else {
      return t('inpayment');
    }
  };

  useEffect(() => {
    dispatch(getTenantBalanceForLandlord(id));
  }, []);

  useEffect(() => {
    const currentTableData = [];
    tenantBalances.debts.map((x) =>
      currentTableData.push({
        id: x.id ? x.id : uuidv4(),
        created: x.created,
        remittanceInfo: x.id ? x.description : getCorrectDescriptionForDebt(x),
        amount: -x.amount,
      })
    );

    tenantBalances.payments.map((x) =>
      currentTableData.push({
        id: x.id,
        created: x.bookingDate,
        remittanceInfo: x.info
          ? x.info
          : mapToPaymentDescription(x.paymentMethod),
        amount: x.amount,
      })
    );

    currentTableData.sort(
      (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()
    );
    setTableData(currentTableData);
  }, [tenantBalances]);

  const columns = [
    {
      dataField: 'remittanceInfo',
      text: t('usage'),
      sort: true,
    },
    {
      dataField: 'created',
      text: t('date'),
      sort: true,
      formatter: toDisplayDate,
    },
    {
      dataField: 'amount',
      text: t('amount'),
      sort: true,
      formatter: (x) => `${x}€`,
      style: (x) => (x >= 0 ? { color: '#28A745' } : { color: '#DC3545' }),
    },
  ];

  return (
    <Balance
      {...{
        columns,
        data: tableData,
        t,
        firstName: location.state.firstName,
        lastName: location.state.lastName,
        customerId: location.state.customerId,
        isModalOpen,
        onHide,
        openModal,
        onChange,
        value,
        submitCreatePayment,
        onChangeReference,
        reference,
        isLoading: addPaymentsLoading,
        isTenantModalOpen,
        setIsTenantModalOpen,
        tenant: location.state.tenant,
      }}
    />
  );
};

BalanceContainer.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default BalanceContainer;
