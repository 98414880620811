import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AddHouseOwner from './AddHouseOwner';
import {
  addHouseOwner,
  addHouseOwnerClear
} from 'store/houseOwner/addHouseOwner/addHouseOwner.actions';

const AddHouseOwnerContainer = () => {
  const { t } = useTranslation('data');
  const history = useHistory();
  const dispatch = useDispatch();

  const { data: houseOwner, success, loading } = useSelector(
    state => state.request.addHouseOwner
  );

  useEffect(() => {
    if (success) {
      dispatch(addHouseOwnerClear());
      history.push(`/dashboard/data/houseOwner/${houseOwner.id}`);
    }
  }, [success]);

  const onSubmit = ({ firstName, lastName, address }) =>
    dispatch(addHouseOwner(firstName, lastName, address));

  return <AddHouseOwner isLoading={loading} {...{ onSubmit, t }} />;
};

export default AddHouseOwnerContainer;
