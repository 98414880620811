export const BOOKING_NEXT_TENANT = 'BOOKING_NEXT_TENANT';
export const BOOKING_NEXT_TENANT_SUCCESS = 'BOOKING_NEXT_TENANT_SUCCESS';
export const BOOKING_NEXT_TENANT_FAILURE = 'BOOKING_NEXT_TENANT_FAILURE';

export const bookingNextTenant = (
  terminationDate,
  tenantId,
  roomId,
  orderId,
  startDate,
  durationInMonths,
  secret
) => ({
  type: BOOKING_NEXT_TENANT,
  terminationDate,
  tenantId,
  roomId,
  orderId,
  startDate,
  durationInMonths,
  secret
});

export const bookingNextTenantSuccess = payload => ({
  type: BOOKING_NEXT_TENANT_SUCCESS,
  payload
});

export const bookingNextTenantFailure = errors => ({
  type: BOOKING_NEXT_TENANT_FAILURE,
  errors
});
