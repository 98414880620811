export const GET_TENANTS_FOR_LANDLORD = 'GET_TENANTS_FOR_LANDLORD';
export const GET_TENANTS_FOR_LANDLORD_SUCCESS =
  'GET_TENANTS_FOR_LANDLORD_SUCCESS';
export const GET_TENANTS_FOR_LANDLORD_FAILURE =
  'GET_TENANTS_FOR_LANDLORD_FAILURE';

export const getTenantsForLandlord = () => ({
  type: GET_TENANTS_FOR_LANDLORD
});

export const getTenantsForLandlordSuccess = payload => ({
  type: GET_TENANTS_FOR_LANDLORD_SUCCESS,
  payload
});

export const getTenantsForLandlordFailure = errors => ({
  type: GET_TENANTS_FOR_LANDLORD_FAILURE,
  errors
});
