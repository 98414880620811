import React from 'react';
import PropTypes from 'prop-types';
import { Container, Tabs, Tab } from 'react-bootstrap';
import CreditSettings from './CreditSettings';
import BankingSettings from './BankingSettings';

const Settings = ({
  creditAdvertiser,
  onCreditAdvertiserChange,
  creditAdvertised,
  onCreditAdvertisedChange,
  bonus,
  onBonusChange,
  onSubmit,
  t,
  errors,
  submitDisabled
}) => (
  <Container className="mt-5">
    <Tabs className="mb-4" defaultActiveKey="banking">
      <Tab eventKey="banking" title={t('landlord.banking.banking')}>
        <BankingSettings t={t} />
      </Tab>
      <Tab eventKey="credit" title={t('landlord.credit.credit')}>
        <CreditSettings
          {...{
            creditAdvertiser,
            onCreditAdvertiserChange,
            creditAdvertised,
            onCreditAdvertisedChange,
            bonus,
            onBonusChange,
            onSubmit,
            t,
            errors,
            submitDisabled
          }}
        />
      </Tab>
    </Tabs>
  </Container>
);

Settings.propTypes = {
  creditAdvertiser: PropTypes.number.isRequired,
  onCreditAdvertiserChange: PropTypes.func.isRequired,
  creditAdvertised: PropTypes.number.isRequired,
  onCreditAdvertisedChange: PropTypes.func.isRequired,
  bonus: PropTypes.number.isRequired,
  onBonusChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  submitDisabled: PropTypes.bool.isRequired
};

export default Settings;
