import { put, takeLatest, call } from 'redux-saga/effects';
import { creditNotesApi } from 'utils/api';
import i18n from 'i18next';
import {
  GET_CURRENT_ADVERTISER_POSITION,
  getCurrentAdvertiserPositionSuccess,
  getCurrentAdvertiserPositionFailure
} from './getCurrentAdvertiserPosition.actions';

function* getCurrentAdvertiserPosition() {
  try {
    const { data, errors } = yield call(creditNotesApi.getCurrentAdvertiserPosition);
    if (!errors && data.errors.length === 0) {
      yield put(getCurrentAdvertiserPositionSuccess(data.resultObject));
    } else {
      yield put(getCurrentAdvertiserPositionFailure(errors || data.errors));
    }
  } catch (error) {
    yield put(getCurrentAdvertiserPositionFailure(i18n.t('base:generalError')));
  }
}

export default function* () {
  yield takeLatest(GET_CURRENT_ADVERTISER_POSITION, getCurrentAdvertiserPosition);
}
