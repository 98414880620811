import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AddHouseGiver from './AddHouseGiver';
import {
  addHouseGiverClear,
  addHouseGiver
} from 'store/houseGiver/addHouseGiver/addHouseGiver.actions';

const AddHouseGiverContainer = () => {
  const { t } = useTranslation('data');
  const history = useHistory();
  const dispatch = useDispatch();

  const { data: houseGiver, success, loading } = useSelector(
    state => state.request.addHouseGiver
  );

  useEffect(() => {
    if (success) {
      dispatch(addHouseGiverClear());
      history.push(`/dashboard/data/houseGiver/${houseGiver.id}`);
    }
  }, [success]);

  const onSubmit = ({ firstName, lastName, address }) =>
    dispatch(addHouseGiver(firstName, lastName, address));

  return <AddHouseGiver isLoading={loading} {...{ onSubmit, t }} />;
};

export default AddHouseGiverContainer;
