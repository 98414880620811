import React from 'react';
import { Map as GoogleMap, Marker, GoogleApiWrapper } from 'google-maps-react';
import PropTypes from 'prop-types';

const mapStyles = {
  width: '100%',
  height: '100%'
};

const language = localStorage.getItem('i18nextLng')
  ? localStorage.getItem('i18nextLng')
  : 'de-DE';

export const Map = ({ google, onMarkerClick, latitude, longitude }) => {
  return (
    <>
      <GoogleMap
        google={google}
        style={mapStyles}
        initialCenter={{
          lat: latitude,
          lng: longitude
        }}
        zoom={13}
      >
        <Marker onClick={onMarkerClick} name={'House'} />
      </GoogleMap>
      )
    </>
  );
};

Map.propTypes = {
  google: PropTypes.object.isRequired,
  onMarkerClick: PropTypes.func.isRequired,
  latitude: PropTypes.string.isRequired,
  longitude: PropTypes.string.isRequired
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDXIxTW50JKzjN_i-buNx-jAMJaUHyFkG8',
  language: language
})(Map);
