import i18n from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {
  Search
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import Container from 'react-bootstrap/Container';
import { DateFormControl } from '../../../../components';

const CreditNotes = ({
  creditNotes,
  creditNoteColumns,
  fromDate,
  handleFromDateChange,
  toDate,
  handleToDateChange,
  t,
  advertisedTenants,
  totalAmount,
  displayData
}) => {
  const { SearchBar } = Search;
  return (
    <Container className="mt-5">
      <Row className="mt-5 mb-5">
        <Card className="me-5">
          <Card.Body>
            <Card.Text>{t('advertisedTenants')}</Card.Text>
            <Card.Title style={{ textAlign: 'center' }}>
              {advertisedTenants}
              {/* //TODO: translate */}
            </Card.Title>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Text>{t('totalAmountCredits')}</Card.Text>
            <Card.Title style={{ textAlign: 'center' }}>
              {totalAmount}
              {/* //TODO: translate */}
            </Card.Title>
          </Card.Body>
        </Card>
      </Row>
      <ToolkitProvider
        keyField="id"
        data={displayData}
        columns={creditNoteColumns}
        search={{ searchFormatted: true }}
      >
        {props => (
          <>
            <Row style={{ alignItems: 'center' }} className="mt-3">
              <Col className="col-md-5 col-12 mt-3 mt-md-0">
                <b style={{ fontSize: '20px' }}>{t('creditNotes')}</b>
              </Col>
              <Col className="col-md-1 col-12 mt-3 mt-md-0">
                <span>{t('date')}</span>
              </Col>
              <Col className="col-md-2 col-12 mt-3 mt-md-0">
                <DateFormControl
                  value={fromDate}
                  onChange={handleFromDateChange}
                />
              </Col>
              <Col className="col-md-2 col-12 mt-3 mt-md-0">
                <DateFormControl value={toDate} onChange={handleToDateChange} />
              </Col>
              <Col className="col-md-2 col-12 mt-3 mt-md-0">
                <SearchBar
                  {...props.searchProps}
                  srText={null}
                  placeholder={i18n.t('base:search')}
                />
              </Col>
            </Row>
            <hr />
            <BootstrapTable
              {...props.baseProps}
              hover
              wrapperClasses="table-responsive"
            />
          </>
        )}
      </ToolkitProvider>
    </Container>
  );
};

CreditNotes.propTypes = {
  creditNotes: PropTypes.array.isRequired,
  creditNoteColumns: PropTypes.array.isRequired,
  fromDate: PropTypes.string.isRequired,
  handleFromDateChange: PropTypes.func.isRequired,
  toDate: PropTypes.string.isRequired,
  handleToDateChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  advertisedTenants: PropTypes.number.isRequired,
  totalAmount: PropTypes.string.isRequired,
  displayData: PropTypes.array,
  searchProps: PropTypes.array,
  baseProps: PropTypes.array
};

export default CreditNotes;
