import React from 'react';
import { Route, Switch } from 'react-router';
import PrivateRoute from './components/PrivateRoute';
import LandingPage from './views/landingPage/LandingPage.container';
import Login from 'views/account/login';
import Dashboard from './views/dashboard/';
import Error from './views/error/Error';
import UpdateUserInfo from './views/updateUserInfo';
import RoomOverview_ from './views/roomOverview';
import BookingOverview from './views/bookingOverview';
import BookingPayment from 'views/payment/BookingPayment.container';
import ConfirmEmail from 'views/email/confirmEmail';
import ResendConfirmationEmail from './views/email/resendConfirmationEmail';
import TenantProfile from 'views/tenantProfile';
import RegisterServiceProvider from './views/account/register/serviceProvider/RegisterServiceProvider.container';
import Imprint from './views/imprint/Imprint';
import DataPrivacy from './views/dataPrivacy/DataPrivacy';
import ForgotPassword from './views/account/forgotPassword';
import ResetPassword from 'views/account/resetPassword';
import ActivateAccount from 'views/account/activateAccount';
import RegistrationFlowContainer from './views/account/register/registrationFlow/RegistrationFlow.Container';

const Routes = () => (
  <Switch>
    <Route path="/" component={LandingPage} exact />
    <Route path="/login" component={Login} />
    <Route
      path="/tenantRegister/:roomId"
      component={RegistrationFlowContainer}
    />
    <Route path="/forgotPassword" component={ForgotPassword} />
    <Route path="/resetPassword" component={ResetPassword} />
    <Route path="/activateAccount" component={ActivateAccount} />
    <Route path="/room/:id" component={RoomOverview_} />
    <Route
      path="/resendConfirmationEmail"
      component={ResendConfirmationEmail}
    />
    <Route path="/confirmEmail" component={ConfirmEmail} />
    <Route
      path="/serviceProvider/confirmEmail"
      component={RegisterServiceProvider}
    />
    <Route path="/imprint" component={Imprint} />
    <Route path="/dataPrivacy" component={DataPrivacy} />
    <PrivateRoute path="/booking/room/:roomId" component={BookingOverview} />
    <PrivateRoute path="/payment/room/:roomId" component={BookingPayment} />
    <PrivateRoute path="/user_info" component={UpdateUserInfo} />
    <PrivateRoute path="/user/profile" component={TenantProfile} />
    <PrivateRoute path="/dashboard" component={Dashboard} />
    <Route path="/" component={Error} />
  </Switch>
);

export default Routes;
