export const RESEND_CONFIRMATION_EMAIL = 'RESEND_CONFIRMATION_EMAIL';
export const RESEND_CONFIRMATION_EMAIL_SUCCESS =
  'RESEND_CONFIRMATION_EMAIL_SUCCESS';
export const RESEND_CONFIRMATION_EMAIL_FAILURE =
  'RESEND_CONFIRMATION_EMAIL_FAILURE';

export const resendConfirmationEmail = email => ({
  type: RESEND_CONFIRMATION_EMAIL,
  email
});
export const resendConfirmationEmailSuccess = payload => ({
  type: RESEND_CONFIRMATION_EMAIL_SUCCESS,
  payload
});
export const resendConfirmationEmailFailure = errors => ({
  type: RESEND_CONFIRMATION_EMAIL_FAILURE,
  errors
});
