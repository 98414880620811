import { createReducer, updateObject } from '../../utils/reducer.helper';
import { GET_ROOM_SUCCESS } from './getRoom/getRoom.actions';

export default createReducer(
  {
    [GET_ROOM_SUCCESS]: (state, action) =>
      updateObject(state, { ...action.payload })
  },
  {}
);
