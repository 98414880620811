import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup } from 'react-bootstrap';
import i18n from 'i18next';
import { NumericFormControl } from 'components';

const PriceFormControl = ({ value, onChange, ...rest }) => (
  <InputGroup>
    <NumericFormControl value={value} onChange={onChange} {...rest} />
    <InputGroup.Text>{i18n.t('base:currencySymbol')}</InputGroup.Text>
  </InputGroup>
);

PriceFormControl.defaultProps = {
  value: '0'
};

PriceFormControl.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

export default PriceFormControl;
