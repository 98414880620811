import React from 'react';
import PropTypes from 'prop-types';
import IbanFormControl from './IbanFormControl';

const IbanFormControlContainer = ({ value, onChange, ...rest }) => {
  const ibanRegex = '^[A-Z]{2}[0-9]{2}(?:[ ]?[0-9]{4}){4}(?:[ ]?[0-9]{1,2})?$';
  return (
    <IbanFormControl {...{ value, onChange, pattern: ibanRegex, ...rest }} />
  );
};

IbanFormControlContainer.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default IbanFormControlContainer;
