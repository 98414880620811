import API from './API';

const prefix = 'payment';

export const addPayment = orderId => API.post(`${prefix}`, { orderId });

export const getPayments = () => API.get(`${prefix}`);

export const addCreatePayment = (amount, reference, userId) =>
  API.post(`${prefix}/addBooking`, { amount, reference, userId });

export default {
  addPayment,
  getPayments,
  addCreatePayment
};
