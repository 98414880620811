import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import GenderControl from './GenderFormControl';
import {
  getAllGenders,
  getGenderByKey,
  getGenderByValue
} from '../../utils/genderService/gender.service';

const GenderFormControlContainer = ({ value, onChange, ...rest }) => {
  const { t } = useTranslation('setBaseData');
  const genders = getAllGenders();

  const handleOnChange = e =>
    onChange({
      ...e,
      target: {
        value:
          e.target.value !== t('select')
            ? getGenderByValue(e.target.value).key
            : null
      }
    });

  const gender = value ? getGenderByKey(value).value : t('select');

  return <GenderControl {...{ t, gender, handleOnChange, genders, ...rest }} />;
};

GenderFormControlContainer.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

export default GenderFormControlContainer;
