import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import JobFormControl from './JobFormControl';
import {
  getAllJobs,
  getJobByKey,
  getJobByValue
} from '../../utils/jobService/job.service';

const JobFormControlContainer = ({ value, onChange, ...rest }) => {
  const { t } = useTranslation('setBaseData');
  const jobs = getAllJobs();

  const handleOnChange = e =>
    onChange({
      ...e,
      target: {
        value:
          e.target.value !== t('select')
            ? getJobByValue(e.target.value).key
            : null
      }
    });

  const job = value != null ? getJobByKey(value) : t('select');

  return <JobFormControl {...{ t, job, handleOnChange, jobs, ...rest }} />;
};

JobFormControlContainer.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

export default JobFormControlContainer;
