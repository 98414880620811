import React, {
  useState,
  cloneElement,
  isValidElement,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal, FormLabel, FormControl } from 'react-bootstrap';
import {
  updateEmail,
  updateEmailClear
} from 'store/account/updateEmail/updateEmail.actions';
import { toast } from 'react-toastify';
import { LoadingButton } from 'components';

const ChangePhoneNumberModal = ({ children }) => {
  const { t } = useTranslation('tenantProfile');
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState('');
  const handleShowModal = () => setShowModal(true);
  const handleHideModal = () => setShowModal(false);

  const isLoading = useSelector(state => state.request.updateEmail.loading);

  const { data: userInfo } = useSelector(state => state.user);
  const { success } = useSelector(state => state.request.updateEmail);

  useEffect(() => {
    setEmail(userInfo.email);
  }, []);

  useEffect(() => {
    if (success) {
      setShowModal(false);
      dispatch(updateEmailClear());
    }
  }, [success]);

  const handleOnChange = e => setEmail(e.target.value);

  const handleSubmit = () => {
    if (email) {
      if (userInfo.email !== email) dispatch(updateEmail(email));
      else toast.warn(t('sameEmail'));
    }
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') handleSubmit();
  };
  const emailRegex = new RegExp(
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
  );
  const isValidEmail = emailRegex.test(email);

  return (
    <>
      {isValidElement(children) &&
        cloneElement(children, { onClick: handleShowModal })}
      <Modal
        size="lg"
        show={showModal}
        onHide={handleHideModal}
        aria-labelledby="example-modal-sizes-title-lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {t('editEmail')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormLabel>{t('email')}</FormLabel>
          <FormControl
            type="email"
            value={email}
            onChange={handleOnChange}
            onKeyDown={handleKeyDown}
          />
          <LoadingButton
            onClick={handleSubmit}
            style={{ marginTop: 10 }}
            disabled={!isValidEmail}
            isLoading={isLoading}
          >
            {t('submit')}
          </LoadingButton>
        </Modal.Body>
      </Modal>
    </>
  );
};

ChangePhoneNumberModal.propTypes = {
  children: PropTypes.node
};

export default ChangePhoneNumberModal;
