export const GET_HOUSE = 'GET_HOUSE';
export const GET_HOUSE_SUCCESS = 'GET_HOUSE_SUCCESS';
export const GET_HOUSE_FAILURE = 'GET_HOUSE_FAILURE';

export const getHouse = id => ({
  type: GET_HOUSE,
  id
});
export const getHouseSuccess = payload => ({
  type: GET_HOUSE_SUCCESS,
  payload
});
export const getHouseFailure = errors => ({
  type: GET_HOUSE_FAILURE,
  errors
});
