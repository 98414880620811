import { ErrorAlerts } from 'components';
import LoadingButton from 'components/LoadingButton';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Container,
  Form,
  FormCheck,
  FormGroup,
  FormLabel,
  InputGroup
} from 'react-bootstrap';

const Settings = ({
  subscripteToNewsletter,
  onNewsletterSubscriptionChange,
  onSubmit,
  t,
  errors,
  loading
}) => (
  <Container className="mt-5">
    <div className="mb-2" style={{ fontSize: 20 }}>
      <b>{t('landlord.credit.settings')}</b>
    </div>
    <ErrorAlerts errors={errors} />
    <Form onSubmit={onSubmit}>
      <FormGroup className="mb-3">
        <FormLabel>{t('tenant.newsletter')}</FormLabel>
        <InputGroup>
          <FormCheck
            type="checkbox"
            label={t('tenant.newsletterSubscription')}
            checked={subscripteToNewsletter}
            onChange={onNewsletterSubscriptionChange}
          />
        </InputGroup>
      </FormGroup>
      <LoadingButton className="mb-3" isLoading={loading} type="submit">
        {t('landlord.credit.submit')}
      </LoadingButton>
    </Form>
  </Container>
);

Settings.propTypes = {
  subscripteToNewsletter: PropTypes.bool.isRequired,
  onNewsletterSubscriptionChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  errors: PropTypes.array,
  loading: PropTypes.bool.isRequired
};

export default Settings;
