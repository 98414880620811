import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  DELETE_LANDLORD,
  DELETE_LANDLORD_SUCCESS,
  DELETE_LANDLORD_FAILURE,
  DELETE_LANDLORD_CLEAR
} from './deleteLandlord.actions';

export default createReducer(
  {
    [DELETE_LANDLORD]: defaultRequest,
    [DELETE_LANDLORD_SUCCESS]: defaultSuccess,
    [DELETE_LANDLORD_FAILURE]: defaultError,
    [DELETE_LANDLORD_CLEAR]: () => defaultInitialState
  },
  defaultInitialState
);
