import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { landlordApi } from 'utils/api';
import {
  DELETE_LANDLORD,
  deleteLandlordSuccess,
  deleteLandlordFailure
} from './deleteLandlord.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';

function* deleteLandlordSaga({ id }) {
  try {
    const { data, errors } = yield call(landlordApi.deleteLandlord, id);

    if (!errors) {
      yield put(deleteLandlordSuccess(data));
      successToast('deleteLandlord');
    } else {
      yield put(deleteLandlordFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(deleteLandlordFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(DELETE_LANDLORD, deleteLandlordSaga);
}
