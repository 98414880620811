export const DELETE_LANDLORD = 'DELETE_LANDLORD';
export const DELETE_LANDLORD_SUCCESS = 'DELETE_LANDLORD_SUCCESS';
export const DELETE_LANDLORD_FAILURE = 'DELETE_LANDLORD_FAILURE';
export const DELETE_LANDLORD_CLEAR = 'DELETE_LANDLORD_CLEAR';

export const deleteLandlord = id => ({
  type: DELETE_LANDLORD,
  id
});
export const deleteLandlordSuccess = payload => ({
  type: DELETE_LANDLORD_SUCCESS,
  payload
});
export const deleteLandlordFailure = errors => ({
  type: DELETE_LANDLORD_FAILURE,
  errors
});
export const deleteLandlordClear = () => ({
  type: DELETE_LANDLORD_CLEAR
});
