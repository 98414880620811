import React from 'react';
import PropTypes from 'prop-types';
import Content from 'components/Content';
import { HouseForm } from 'containers';

const AddHouse = ({ t, handleSubmit, isLoading }) => (
  <Content>
    <h2>{t('addHouse.newHouse')}</h2>
    <HouseForm onSubmit={handleSubmit} isLoading={isLoading} />
  </Content>
);

AddHouse.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default AddHouse;
