import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { flatApi } from 'utils/api';
import {
  UPDATE_FLAT,
  updateFlatSuccess,
  updateFlatFailure
} from './updateFlat.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';

function* updateFlat({ id, symbol, level, description, squareMeters, houseId }) {
  try {
    const { data, errors } = yield call(
      flatApi.updateFlat,
      id,
      symbol,
      parseInt(level),
      description,
      parseInt(squareMeters),
      houseId
    );

    if (!errors) {
      yield put(updateFlatSuccess(data));
      successToast('updateFlat');
    } else {
      yield put(updateFlatFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(updateFlatFailure(i18n.t('base:generalError')));
  }
}

export default function* () {
  yield takeLatest(UPDATE_FLAT, updateFlat);
}
