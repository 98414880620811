import useFlats from 'hooks/store/useFlats';
import PropTypes from 'prop-types';
import React from 'react';
import Select from '../../shared/select';

const FlatSelectContainer = ({ onChange, value, selector, ...rest }) => {
  const flats = useFlats(selector);

  return (
    <Select
      className="mb-3"
      value={value}
      onChange={onChange}
      options={flats}
      output={flat =>
        `${flat.symbol}: ${flat.address.street} ${flat.address.houseNumber}, ${flat.level}`
      }
      {...rest}
    />
  );
};

FlatSelectContainer.defaultProps = {
  onChange: () => {},
  selector: state => state.flats
};

FlatSelectContainer.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  selector: PropTypes.func
};

export default FlatSelectContainer;
