import React from 'react';
import PropTypes from 'prop-types';
import Content from 'components/Content';
import { FlatForm } from 'containers';

const AddFlat = ({ onSubmit, t, isLoading }) => {
  return (
    <Content>
      <h2>{t('addFlat.addFlat')}</h2>
      <FlatForm onSubmit={onSubmit} isLoading={isLoading} />
    </Content>
  );
};

AddFlat.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default AddFlat;
