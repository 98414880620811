import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="mt-5 footer">
      <div className="mb-3">
        <b>Student Housing Duisburg</b>
      </div>
      <div className="mb-3">
        <Link
          style={{ color: '#6C757D', textDecoration: 'none' }}
          to="/dataPrivacy"
        >
          {t('landingPage:dataPrivacy')}
        </Link>
      </div>
      <div className="mb-3">
        <Link
          style={{ color: '#6C757D', textDecoration: 'none' }}
          to="/imprint"
        >
          {t('landingPage:imprint')}
        </Link>
      </div>
      <div className="mb-3" style={{ color: '#6C757D' }}>
        © Copyright 2020-2022 Student Housing Duisburg
      </div>
      <div>
        Handcrafted with ❤️ by{' '}
        <a
          style={{
            color: '#FF7700',
            textDecoration: 'none',
            cursor: 'pointer'
          }}
          onClick={() => (window.location.href = 'https://shadet.de')}
        >
          Shadet
        </a>
      </div>
    </div>
  );
};

export default Footer;
