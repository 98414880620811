export const CREATE_CLAIM = 'CREATE_CLAIM';
export const CREATE_CLAIM_SUCCESS = 'CREATE_CLAIM_SUCCESS';
export const CREATE_CLAIM_FAILURE = 'CREATE_CLAIM_FAILURE';
export const CREATE_CLAIM_CLEAR = 'CREATE_CLAIM_CLEAR';

export const createClaim = (
  title,
  description,
  damageAmount,
  ticketId,
  claimReportId,
  image
) => ({
  type: CREATE_CLAIM,
  title,
  description,
  ticketId,
  claimReportId,
  damageAmount,
  image,
});

export const createClaimSuccess = (payload) => ({
  type: CREATE_CLAIM_SUCCESS,
  payload,
});

export const createClaimFailure = (errors) => ({
  type: CREATE_CLAIM_FAILURE,
  errors,
});

export const createClaimClear = () => ({
  type: CREATE_CLAIM_CLEAR,
});
