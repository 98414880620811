export const ADD_INVITED_NEW_TENANT = 'ADD_INVITED_NEW_TENANT';
export const ADD_INVITED_NEW_TENANT_SUCCESS = 'ADD_INVITED_NEW_TENANT_SUCCESS';
export const ADD_INVITED_NEW_TENANT_FAILURE = 'ADD_INVITED_NEW_TENANT_FAILURE';
export const ADD_INVITED_NEW_TENANT_RESET = 'ADD_INVITED_NEW_TENANT_RESET';

export const addInvitedNewTenant = email => ({
  type: ADD_INVITED_NEW_TENANT,
  email
});
export const addInvitedNewTenantSuccess = payload => ({
  type: ADD_INVITED_NEW_TENANT_SUCCESS,
  payload
});
export const addInvitedNewTenantFailure = errors => ({
  type: ADD_INVITED_NEW_TENANT_FAILURE,
  errors
});
export const addInvitedNewTenantReset = () => ({
  type: ADD_INVITED_NEW_TENANT_RESET
});
