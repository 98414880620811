import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  GET_BANKING_STATUS,
  GET_BANKING_STATUS_SUCCESS,
  GET_BANKING_STATUS_FAILURE
} from './getBankingStatus.actions';

export default createReducer(
  {
    [GET_BANKING_STATUS]: defaultRequest,
    [GET_BANKING_STATUS_SUCCESS]: defaultSuccess,
    [GET_BANKING_STATUS_FAILURE]: defaultError
  },
  defaultInitialState
);
