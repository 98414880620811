import React from 'react';
import { useTranslation } from 'react-i18next';
import HouseGivers from './HouseGivers';

const HouseGiversContainer = () => {
  const { t } = useTranslation('data');

  return <HouseGivers {...{ t }} />;
};

export default HouseGiversContainer;
