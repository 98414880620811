import { DateFormControl } from 'components';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

const BirthDateFormControlContainer = ({ value, onChange, ...rest }) => {
  const minAge = new Date(new Date().setFullYear(new Date().getFullYear() - 18))
    .toISOString()
    .split('T')[0];
  const maxAge = new Date(new Date().setFullYear(new Date().getFullYear() - 34))
    .toISOString()
    .split('T')[0];

  value = moment(value).isAfter(moment.utc('0001-01-01')) ? value : minAge;

  return (
    <DateFormControl
      {...{ value, onChange, max: minAge, min: maxAge, ...rest }}
    />
  );
};

BirthDateFormControlContainer.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default BirthDateFormControlContainer;
