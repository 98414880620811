import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { houseOwnerApi, addressApi } from 'utils/api';
import {
  ADD_HOUSE_OWNER,
  addHouseOwnerSuccess,
  addHouseOwnerFailure
} from './addHouseOwner.actions';
import {
  addAddressSuccess,
  addAddressFailure
} from 'store/address/addAddress/addAddress.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';

function* addHouseOwnerSaga({ firstName, lastName, address }) {
  try {
    const { data: addressResult, errors: addressErrors } = yield call(
      addressApi.addAddress,
      address
    );

    if (!addressErrors) {
      yield put(addAddressSuccess(address));

      const { data: houseGiver, errors: houseGiverErrors } = yield call(
        houseOwnerApi.addHouseOwner,
        addressResult.id,
        firstName,
        lastName
      );

      const giverWithAddress = {
        ...houseGiver,
        addressResult
      };

      if (!houseGiverErrors) {
        yield put(addHouseOwnerSuccess(giverWithAddress));
        successToast('addHouseOwner');
      } else {
        yield put(addHouseOwnerFailure(houseGiverErrors));
      }
    } else {
      yield put(addAddressFailure(addressErrors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(addHouseOwnerFailure(i18n.t('base:generalError')));
    yield put(addAddressFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(ADD_HOUSE_OWNER, addHouseOwnerSaga);
}
