import PropTypes from 'prop-types';
import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

const Select = ({
  selectProp,
  options,
  value,
  onChange,
  output,
  multiple,
  ...rest
}) => {
  const selected = multiple
    ? options.filter(x => value?.includes(selectProp ? x[selectProp] : x))
    : options.find(x => (selectProp ? x[selectProp] === value : x === value));
  return (
    options.length > 0 && (
      <Typeahead
        clearButton
        inputProps={{ autoComplete: 'none' }}
        onChange={selected => {
          if (multiple) {
            onChange(selectProp ? selected.map(x => x[selectProp]) : selected);
            return;
          }

          if (!selected[0]) {
            onChange(null);
            return;
          }

          if (!selectProp) {
            onChange(selected[0]);
            return;
          }

          onChange(selected[0][selectProp]);
        }}
        options={options}
        labelKey={x => output(x)}
        multiple={multiple}
        selected={multiple ? selected : selected ? [selected] : []}
        {...rest}
      ></Typeahead>
    )
  );
};

Select.defaultProps = {
  onChange: () => {},
  selector: state => state.rooms,
  options: [],
  value: '',
  multiple: false
};

Select.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.any,
  selector: PropTypes.func,
  output: PropTypes.any,
  selectProp: PropTypes.string,
  multiple: PropTypes.bool
};
export default Select;
