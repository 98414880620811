export const GET_TENANT_FOR_LANDLORD = 'GET_TENANT_FOR_LANDLORD';
export const GET_TENANT_FOR_LANDLORD_SUCCESS =
  'GET_TENANT_FOR_LANDLORD_SUCCESS';
export const GET_TENANT_FOR_LANDLORD_FAILURE =
  'GET_TENANT_FOR_LANDLORD_FAILURE';

export const getTenantForLandlord = (userId) => ({
  userId,
  type: GET_TENANT_FOR_LANDLORD,
});

export const getTenantForLandlordSuccess = (payload) => ({
  type: GET_TENANT_FOR_LANDLORD_SUCCESS,
  payload,
});

export const getTenantForLandlordFailure = (errors) => ({
  type: GET_TENANT_FOR_LANDLORD_FAILURE,
  errors,
});
