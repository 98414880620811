export const CAN_BOOK_ROOM = 'CAN_BOOK_ROOM';
export const CAN_BOOK_ROOM_SUCCESS = 'CAN_BOOK_ROOM_SUCCESS';
export const CAN_BOOK_ROOM_FAILURE = 'CAN_BOOK_ROOM_FAILURE';

export const canBookRoom = (startDate, durationInMonths, roomId) => ({
  type: CAN_BOOK_ROOM,
  startDate,
  durationInMonths,
  roomId
});

export const canBookRoomSuccess = payload => ({
  type: CAN_BOOK_ROOM_SUCCESS,
  payload
});

export const canBookRoomFailure = errors => ({
  type: CAN_BOOK_ROOM_FAILURE,
  errors
});
