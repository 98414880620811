import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import bcategories from '../utils/categories';
import useClaimReportStore from 'hooks/store/useClaimReportStore';
import PropTypes from 'prop-types';
import CreateClaimReportUser from './CreateClaimReport.user';
import errorToast from 'utils/errorToast';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const CreateClaimReportUserContainer = ({ isEntryClaimReport }) => {
  const { t } = useTranslation('claimReport');

  const { sendClaimReport } = useClaimReportStore();
  const { loading: isLoading, success } = useSelector(
    state => state.request.sendClaimReport
  );
  const history = useHistory();

  const firstTierCategories = bcategories();
  const [secondTierCategories, setSecondTierCategories] = useState(
    firstTierCategories[0].subtypes
  );
  const [thirdTierCategories, setThierTierCategories] = useState(
    secondTierCategories[0].subtypes
  );

  const [selectedFirstTierCategory, setSelectedFirstTierCategory] = useState(
    firstTierCategories[0]
  );
  const [selectedSecondTierCategory, setSelectedSecondTierCategory] = useState(
    secondTierCategories[0]
  );
  const [selectedThirdTierCategory, setSelectedThirdTierCategory] = useState(
    thirdTierCategories[0]
  );

  const handleFirstTierCategoryChange = e => {
    const selectedFirstTier = firstTierCategories.find(
      x => x.value === e.target.value
    );
    setSelectedFirstTierCategory(selectedFirstTier);
    if (selectedFirstTier.subtypes) {
      const selectedSecondTier = selectedFirstTier.subtypes[0];
      setSecondTierCategories(selectedFirstTier.subtypes);
      setSelectedSecondTierCategory(selectedSecondTier);

      if (selectedSecondTier.subtypes) {
        setSelectedThirdTierCategory(selectedSecondTier.subtypes[0]);
        setThierTierCategories(selectedSecondTier.subtypes);
      } else setSelectedThirdTierCategory([]);
    } else setSelectedSecondTierCategory([]);
  };

  const handleSecondTierCategoryChange = e => {
    const selectedSecondTier = secondTierCategories.find(
      x => x.value === e.target.value
    );
    setSelectedSecondTierCategory(selectedSecondTier);
    if (selectedSecondTier.subtypes) {
      const selectedThirdTier = selectedSecondTier.subtypes[0];
      setSelectedThirdTierCategory(selectedThirdTier);
      setThierTierCategories(selectedSecondTier.subtypes);
    }
  };

  const handleThirdTierCategoryChange = e =>
    setSelectedThirdTierCategory(
      thirdTierCategories.find(x => x.value === e.target.value)
    );

  const descriptionHeader = () => {
    let header = selectedFirstTierCategory.value;
    if (Object.keys(selectedSecondTierCategory).length)
      header += ' - ' + selectedSecondTierCategory.value;
    if (Object.keys(selectedThirdTierCategory).length)
      header += ' - ' + selectedThirdTierCategory.value;
    return header + '\n';
  };

  const [description, setDescription] = useState('');
  const handleDescriptionChanged = e => {
    setDescription(e.target.value);
  };

  const [subject, setSubject] = useState('');
  const handleSubjectChanged = e => {
    setSubject(e.target.value);
  };

  const [images, setImages] = useState([]);
  const handleSelectFiles = e => {
    setImages([...e.target.files]);
  };

  const handleSubmitform = e => {
    e.preventDefault();
    if (images.length === 0) {
      errorToast('claimReport:noImageError');
      return;
    }
    sendClaimReport(
      isEntryClaimReport ? subject : selectedFirstTierCategory.value,
      isEntryClaimReport ? description : descriptionHeader() + description,
      images,
      isEntryClaimReport
    );
  };
  useEffect(() => {
    if (success) {
      history.push(`/dashboard/claimReports`);
    }
  }, [success]);

  return (
    <CreateClaimReportUser
      {...{
        description,
        handleDescriptionChanged,
        handleSelectFiles,
        handleSubmitform,
        t,
        subject,
        handleSubjectChanged,
        firstTierCategories,
        secondTierCategories,
        thirdTierCategories,
        selectedFirstTierCategory,
        selectedSecondTierCategory,
        selectedThirdTierCategory,
        handleFirstTierCategoryChange,
        handleSecondTierCategoryChange,
        handleThirdTierCategoryChange,
        isEntryClaimReport,
        isLoading
      }}
    />
  );
};

CreateClaimReportUserContainer.defaultProps = {
  isEntryClaimReport: false
};

CreateClaimReportUserContainer.propTypes = {
  isEntryClaimReport: PropTypes.bool
};

export default CreateClaimReportUserContainer;
