import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  GET_CLAIM_REPORT,
  GET_CLAIM_REPORT_SUCCESS,
  GET_CLAIM_REPORT_FAILURE
} from './getClaimReport.actions';

export default createReducer(
  {
    [GET_CLAIM_REPORT]: defaultRequest,
    [GET_CLAIM_REPORT_SUCCESS]: defaultSuccess,
    [GET_CLAIM_REPORT_FAILURE]: defaultError
  },
  defaultInitialState
);
