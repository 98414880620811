import useHouses from 'hooks/store/useHouses';
import PropTypes from 'prop-types';
import React from 'react';
import Select from '../../shared/select';

const HouseSelectContainer = ({ onChange, value, selector, ...rest }) => {
  const houses = useHouses(selector);

  return (
    <Select
      className="mb-3"
      value={value}
      onChange={onChange}
      options={houses}
      output={house =>
        `${house.address?.street} ${house.address?.houseNumber}, ${house.address?.postalCode} ${house.address?.location}`
      }
      {...rest}
    />
  );
};

HouseSelectContainer.defaultProps = {
  onChange: () => {},
  selector: state => state.houses
};

HouseSelectContainer.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  selector: PropTypes.func
};

export default HouseSelectContainer;
