export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_CLEAR = 'LOGIN_CLEAR';

export const login = (email, password) => ({
  type: LOGIN,
  email,
  password
});
export const loginSuccess = payload => ({
  type: LOGIN_SUCCESS,
  payload
});
export const loginFailure = errors => ({
  type: LOGIN_FAILURE,
  errors
});
export const loginClear = () => ({
  type: LOGIN_CLEAR
});
