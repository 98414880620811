export const SAVE_PAYMENT = 'SAVE_PAYMENT';
export const SAVE_PAYMENT_SUCCESS = 'SAVE_PAYMENT_SUCCESS';
export const SAVE_PAYMENT_FAILURE = 'SAVE_PAYMENT_FAILURE';

export const savePayment = orderId => ({
  type: SAVE_PAYMENT,
  orderId
});

export const savePaymentSuccess = payload => ({
  type: SAVE_PAYMENT_SUCCESS,
  payload
});

export const savePaymentFailure = errors => ({
  type: SAVE_PAYMENT_FAILURE,
  errors
});
