import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from '../../../utils/reducer.helper';

import {
  CONFIRM_EMAIL_BY_CODE,
  CONFIRM_EMAIL_BY_CODE_SUCCESS,
  CONFIRM_EMAIL_BY_CODE_FAILURE,
  CONFIRM_EMAIL_BY_CODE_CLEAR
} from './confirmEmailByCode.actions';

export default createReducer(
  {
    [CONFIRM_EMAIL_BY_CODE]: defaultRequest,
    [CONFIRM_EMAIL_BY_CODE_SUCCESS]: defaultSuccess,
    [CONFIRM_EMAIL_BY_CODE_FAILURE]: defaultError,
    [CONFIRM_EMAIL_BY_CODE_CLEAR]: () => defaultInitialState
  },
  defaultInitialState
);
