import { FormGroupControl, LoadingButton } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import { Form, FormCheck } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const LoginForm = ({
  t,
  handleSubmit,
  email,
  password,
  handleEmailChange,
  handlePasswordChange,
  loading,
  stayLoggedIn,
  handleStayLoggedInChange
}) => {
  return (
    <Form
      className="mx-3"
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <FormGroupControl
        label={t('emailAddress')}
        value={email}
        onChange={handleEmailChange}
        required
      />
      <FormGroupControl
        label={t('password')}
        value={password}
        onChange={handlePasswordChange}
        required
        type="password"
      />
      <div
        style={{
          marginTop: -15,
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        {t('forgotPassword')}
        <Link to="/forgotPassword">{t('clickHere')}</Link>
      </div>
      <FormCheck
        checked={stayLoggedIn}
        onChange={handleStayLoggedInChange}
        className="mt-3"
        label={t('stayLoggedin')}
      ></FormCheck>
      <LoadingButton className="w-100 mt-3" type="submit" isLoading={loading}>
        {t('submit')}
      </LoadingButton>
    </Form>
  );
};

LoginForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  handleEmailChange: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  handlePasswordChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  stayLoggedIn: PropTypes.bool.isRequired,
  handleStayLoggedInChange: PropTypes.func.isRequired
};

export default LoginForm;
