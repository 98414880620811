export const GET_BANKING_STATUS = 'GET_BANKING_STATUS';
export const GET_BANKING_STATUS_SUCCESS = 'GET_BANKING_STATUS_SUCCESS';
export const GET_BANKING_STATUS_FAILURE = 'GET_BANKING_STATUS_FAILURE';

export const getBankingStatus = () => ({
  type: GET_BANKING_STATUS
});

export const getBankingStatusSuccess = payload => ({
  type: GET_BANKING_STATUS_SUCCESS,
  payload
});

export const getBankingStatusFailure = errors => ({
  type: GET_BANKING_STATUS_FAILURE,
  errors
});
