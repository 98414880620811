import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  UPDATE_PHONE_NUMBER,
  UPDATE_PHONE_NUMBER_SUCCESS,
  UPDATE_PHONE_NUMBER_FAILURE,
  UPDATE_PHONE_NUMBER_CLEAR
} from './updatePhoneNumber.actions';

export default createReducer(
  {
    [UPDATE_PHONE_NUMBER]: defaultRequest,
    [UPDATE_PHONE_NUMBER_SUCCESS]: defaultSuccess,
    [UPDATE_PHONE_NUMBER_FAILURE]: defaultError,
    [UPDATE_PHONE_NUMBER_CLEAR]: () => defaultInitialState
  },
  defaultInitialState
);
