import useClaimReports from 'hooks/store/useClaimReports';
import useUserRole from 'hooks/store/useUserRole';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as userRoles from 'utils/constants/userRole.constants';
import { toDisplayDate } from 'utils/date.formatter';
import ClaimReports from './ClaimReports';

const ClaimReportsContainer = () => {
  const history = useHistory();
  const { t } = useTranslation('claimReports');

  const userRole = useUserRole();

  const [filter, setFilter] = useState(t('filter.all'));
  const handleFilterChange = (e) => setFilter(e.target.value);

  const claimReports = useClaimReports((state) => {
    switch (filter) {
      case t('filter.open'):
        return state.claimReports.filter((ticket) => !ticket.closed);
      case t('filter.closed'):
        return state.claimReports.filter((ticket) => !!ticket.closed);
      default:
        return state.claimReports;
    }
  }).map((claimReport) => ({
    ...claimReport,
    state: !claimReport.closed ? t('open') : t('closed'),
  }));

  const [selectedClaimReport, setSelectedClaimReport] = useState(
    claimReports[0]
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const rowEvents = {
    onClick: (_, claimReport) => {
      setSelectedClaimReport(claimReport);
      setIsModalOpen(true);
    },
    onMouseEnter: () => {},
  };

  const defaultSorted = [{ dataField: 'reportedAt', order: 'desc' }];
  const columns = [
    {
      dataField: 'roomSymbol',
      text: t('roomSymbol'),
      sort: true,
    },
    {
      dataField: 'claims[0].description',
      text: t('description'),
      sort: true,
      formatter: (col) => {
        return (
          <span
            style={{
              display: 'block',
              width: 400,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {col}
          </span>
        );
      },
    },
    {
      dataField: 'moveInClaimReport',
      text: t('moveInClaimReport'),
      sort: true,
      formatter: (moveInClaimReport) => (
        <span>{moveInClaimReport ? t('yes') : t('no')}</span>
      ),
    },
    {
      dataField: 'claims[0].reportedBy',
      text: t('reportedBy'),
      sort: true,
    },
    {
      dataField: 'claims[0].reportedAt',
      text: t('reportDate'),
      formatter: (reportedAt) => toDisplayDate(reportedAt),
      sort: true,
    },
    {
      dataField: 'closed',
      text: t('state'),
      sort: true,
      formatter: (closed) => (
        <span style={{ color: !closed ? 'red' : 'green' }}>
          {!closed ? t('open') : t('done')}
        </span>
      ),
    },
  ];

  const handleNewClaimReportClick = () =>
    userRole === userRoles.LANDLORD
      ? history.push('/dashboard/landlord/InvoiceDamage')
      : history.push('/dashboard/report');

  return (
    <ClaimReports
      {...{
        claimReports,
        rowEvents,
        columns,
        t,
        userRole,
        handleNewClaimReportClick,
        isModalOpen,
        selectedClaimReport,
        setIsModalOpen,
        userRoles,
        filter,
        handleFilterChange,
        defaultSorted,
      }}
    />
  );
};

export default ClaimReportsContainer;
