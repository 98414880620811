export const GET_HOUSE_GIVER = 'GET_HOUSE_GIVER';
export const GET_HOUSE_GIVER_SUCCESS = 'GET_HOUSE_GIVER_SUCCESS';
export const GET_HOUSE_GIVER_FAILURE = 'GET_HOUSE_GIVER_FAILURE';
export const GET_HOUSE_GIVER_CLEAR = 'GET_HOUSE_GIVER_CLEAR';

export const getHouseGiver = id => ({
  type: GET_HOUSE_GIVER,
  id
});
export const getHouseGiverSuccess = payload => ({
  type: GET_HOUSE_GIVER_SUCCESS,
  payload
});
export const getHouseGiverFailure = errors => ({
  type: GET_HOUSE_GIVER_FAILURE,
  errors
});
export const getHouseGiverClear = () => ({
  type: GET_HOUSE_GIVER_CLEAR
});
