import { combineReducers } from 'redux';
import activateAccount from './account/activateAccount/activateAccount.reducer';
import confirmEmail from './account/confirmEmail/confirmEmail.reducer';
import confirmEmailByCode from './account/confirmEmailByCode/confirmEmailByCode.reducer';
import forgotPassword from './account/forgotPassword/forgotPassword.reducer';
import user from './account/getUser/user.reducer';
import login from './account/login/login.reducer';
import logout from './account/logout/logout.reducer';
import register from './account/register/register.reducer';
import registerTenant from './account/registerTenant/registerTenant.reducer';
import resendConfirmationEmail from './account/resendConfirmationEmail/resendConfirmationEmail.reducer';
import resetPassword from './account/resetPassword/resetPassword.reducer';
import updateEmail from './account/updateEmail/updateEmail.reducer';
import updateIban from './account/updateIban/updateIban.reducer';
import updatePhoneNumber from './account/updatePhoneNumber/updatePhoneNumber.reducer';
import updateServiceProviderInfo from './account/updateServiceProviderInfo/updateServiceProviderInfo.reducer';
import updateUserInfo from './account/updateUserInfo/updateUserInfo.reducer';
import addAddress from './address/addAddress/addAddress.reducer';
import deleteAddress from './address/deleteAddress/deleteAddress.reducer';
import getAddress from './address/getAddress/getAddress.reducer';
import getAddresses from './address/getAddresses/getAddresses.reducer';
import getBankingStatus from './banking/getBankingStatus/getBankingStatus.reducer';
import synchronizeBanking from './banking/synchronizeBanking/synchronizeBanking.reducer';
import bookAsLandlord from './booking/bookAsLandlord/bookAsLandlord.reducer';
import bookingNextTenant from './booking/bookingNextTenant/bookingNextTenant.reducer';
import bookings from './booking/bookings.reducer';
import canBookRoom from './booking/canBookRoom/canBookRoom.reducer';
import createBooking from './booking/createBooking/createBooking.reducer';
import getBookings from './booking/getBookings/getBookings.reducer';
import updateBooking from './booking/updateBooking/updateBooking.reducer';
import createClaim from './claim/createClaim/createClaim.reducer';
import claimReports from './claimReport/claimReports.reducer';
import getClaimReport from './claimReport/getClaimReport/getClaimReport.reducer';
import getClaimReports from './claimReport/getClaimReports/getClaimReports.reducer';
import invoiceClaimReport from './claimReport/invoiceClaimReport/invoiceClaimReport.reducer';
import sendClaimReport from './claimReport/sendClaimReport/sendClaimReport.reducer';
import getAllCreditNotes from './creditNotes/getAllCreditNotes/getAllCreditNotes.reducer';
import getCurrentAdvertiserPosition from './creditNotes/getCurrentAdvertiserPosition/getCurrentAdvertiserPosition.reducer';
import getOfferedCreditNotes from './creditNotes/getOfferedCreditNotes/getOfferedCreditNotes.reducer';
import debts from './debt/debts.reducer';
import getDebts from './debt/getDebts/getDebts.reducer';
import approveDepositSettlement from './depositSettlement/approveDepositSettlement/approveDepositSettlement.reducer';
import addFlat from './flat/addFlat/addFlat.reducer';
import deleteFlat from './flat/deleteFlat/deleteFlat.reducer';
import flats from './flat/flats.reducer';
import getFlat from './flat/getFlat/getFlat.reducer';
import getFlatHouse from './flat/getFlatHouse/getFlatHouse.reducer';
import getFlatRooms from './flat/getFlatRooms/getFlatRooms.reducer';
import getFlats from './flat/getFlats/getFlats.reducer';
import updateFlat from './flat/updateFlat/updateFlat.reducer';
import addHouseGiver from './houseGiver/addHouseGiver/addHouseGiver.reducer';
import deleteHouseGiver from './houseGiver/deleteHouseGiver/deleteHouseGiver.reducer';
import getHouseGiver from './houseGiver/getHouseGiver/getHouseGiver.reducer';
import getHouseGivers from './houseGiver/getHouseGivers/getHouseGivers.reducer';
import houseGivers from './houseGiver/houseGiver.reducer';
import updateHouseGiver from './houseGiver/updateHouseGiver/updateHouseGiver.reducer';
import addHouseOwner from './houseOwner/addHouseOwner/addHouseOwner.reducer';
import deleteHouseOwner from './houseOwner/deleteHouseOwner/deleteHouseOwner.reducer';
import getHouseOwner from './houseOwner/getHouseOwner/getHouseOwner.reducer';
import getHouseOwners from './houseOwner/getHouseOwners/getHouseOwners.reducer';
import houseOwners from './houseOwner/houseOwner.reducer';
import updateHouseOwner from './houseOwner/updateHouseOwner/updateHouseOwner.reducer';
import addHouse from './houses/addHouse/addHouse.reducer';
import deleteHouse from './houses/deleteHouse/deleteHouse.reducer';
import getHouse from './houses/getHouse/getHouse.reducer';
import getHouseFlats from './houses/getHouseFlats/getHouseFlats.reducer';
import getHouseRooms from './houses/getHouseRooms/getHouseRooms.reducer';
import getHouses from './houses/getHouses/getHouses.reducer';
import houses from './houses/houses.reducer';
import updateHouse from './houses/updateHouse/updateHouse.reducer';
import addLandlord from './landlord/addLandlord/addLandlord.reducer';
import deleteLandlord from './landlord/deleteLandlord/deleteLandlord.reducer';
import getLandlord from './landlord/getLandlord/getLandlord.reducer';
import getLandlords from './landlord/getLandlords/getLandlords.reducer';
import getTenantBalanceForLandlord from './landlord/getTenantBalanceForLandlord/getTenantBalanceForLandlord.reducer';
import tenantBalancesForLandlord from './landlord/getTenantBalanceForLandlord/tenantBalancesForLandlord.reducer';
import getTenantForLandlord from './landlord/getTenantForLandlord/getTenantForLandlord.reducer';
import getTenantsForLandlord from './landlord/getTenantsForLandlord/getTenantsForLandlord.reducer';
import landlords from './landlord/landlord.reducer';
import upadteLandlord from './landlord/updateLandlord/updateLandlord.reducer';
import createLog from './logging/createLog/createLog.reducer';
import addPayments from './payment/addPayments/addPayments.reducer';
import getPayments from './payment/getPayments/getPayments.reducer';
import payments from './payment/payments.reducer';
import savePayment from './payment/savePayment/savePayment.reducer';
import validatePromotionCode from './promotionCode/validatePromotionCode/validatePromotionCode.reducer';
import addRoom from './room/addRoom/addRoom.reducer';
import deleteRoom from './room/deleteRoom/deleteRoom.reducer';
import getAllRooms from './room/getAllRooms/getAllRooms.reducer';
import getRoom from './room/getRoom/getRoom.reducer';
import getRoomFlat from './room/getRoomFlat/getRoomFlat.reducer';
import getRoomHouse from './room/getRoomHouse/getRoomHouse.reducer';
import room from './room/room.reducer';
import rooms from './room/rooms.reducer';
import updateRoom from './room/updateRoom/updateRoom.reducer';
import addServiceProvider from './serviceProvider/addServiceProvider/addServiceProvider.reducer';
import deleteServiceProvider from './serviceProvider/deleteServiceProvider/deleteServiceProvider.reducer';
import getServiceProvider from './serviceProvider/getServiceProvider/getServiceProvider.reducer';
import getServiceProviders from './serviceProvider/getServiceProviders/getServiceProviders.reducer';
import registerServiceProvider from './serviceProvider/registerServiceProvider/registerServiceProvider.reducer';
import serviceProviders from './serviceProvider/serviceProviders.reducer';
import updateServiceProvider from './serviceProvider/updateServiceProvider/updateServiceProvider.reducer';
import creditSettings from './settings/credit/creditSettings.reducer';
import getAdvertisedBonusForRoom from './settings/credit/getAdvertisedBonusForRoom/getAdvertisedBonusForRoom.reducer';
import getCreditSettings from './settings/credit/getCreditSettings/getCreditSettings.reducer';
import saveCreditSettings from './settings/credit/saveCreditSettings/saveCreditSettings.reducer';
import getNewsletterSubscription from './settings/newsletterSubscription/getNewsletterSubscription/getNewsletterSubscription.reducer';
import setNewsletterSubscription from './settings/newsletterSubscription/setNewsletterSubscription/setNewsletterSubscription.reducer';
import rentalEntrance from './statistics/rentalEntrance/rentalEntrance.reducer';
import createNewTenant from './tenants/createNewTenant/createNewTenant.reducer';
import tenants from './tenants/tenants.reducer';
import updateTenantData from './tenants/updateTenantData/updateTenantData.reducer';
import addInvitedNewTenant from './termination/addInvitedNewTenant/addInvitedNewTenant.reducer';
import createTermination from './termination/createTermination/createTermination.reducer';
import deleteTermination from './termination/deleteTermination/deleteTermination.reducer';
import doesTerminationExist from './termination/doesTerminationExist/doesTerminationExist.reducer';
import getTermination from './termination/getTermination/getTermination.reducer';
import termination from './termination/termination.reducer';
import assignTicket from './ticket/assignTicket/assignTicket.reducer';
import closeTicket from './ticket/closeTicket/closeTicket.reducer';
import createTicket from './ticket/createTicket/createTicket.reducer';
import getTickets from './ticket/getTickets/getTickets.reducer';
import tickets from './ticket/tickets.reducer';

export default combineReducers({
  request: combineReducers({
    confirmEmail,
    login,
    logout,
    register,
    resendConfirmationEmail,
    updateEmail,
    updatePhoneNumber,
    updateUserInfo,
    addAddress,
    deleteAddress,
    getAddress,
    getAddresses,
    createBooking,
    getClaimReport,
    getClaimReports,
    sendClaimReport,
    invoiceClaimReport,
    getTenantsForLandlord,
    addFlat,
    deleteFlat,
    getFlat,
    getFlatHouse,
    getFlatRooms,
    getFlats,
    updateFlat,
    addHouseGiver,
    deleteHouseGiver,
    getHouseGiver,
    getHouseGivers,
    updateHouseGiver,
    addHouseOwner,
    deleteHouseOwner,
    getHouseOwner,
    getHouseOwners,
    updateHouseOwner,
    addHouse,
    deleteHouse,
    getHouse,
    getHouseFlats,
    getHouseRooms,
    getHouses,
    updateHouse,
    addLandlord,
    deleteLandlord,
    getLandlord,
    getLandlords,
    upadteLandlord,
    savePayment,
    addRoom,
    deleteRoom,
    getAllRooms,
    getRoom,
    getRoomFlat,
    getRoomHouse,
    updateRoom,
    createTermination,
    deleteTermination,
    getTermination,
    getTickets,
    closeTicket,
    createTicket,
    getServiceProviders,
    addServiceProvider,
    updateServiceProvider,
    getServiceProvider,
    deleteServiceProvider,
    getDebts,
    getPayments,
    canBookRoom,
    bookingNextTenant,
    getBookings,
    registerServiceProvider,
    forgotPassword,
    resetPassword,
    createNewTenant,
    activateAccount,
    getTenantBalanceForLandlord,
    addPayments,
    saveCreditSettings,
    getCreditSettings,
    confirmEmailByCode,
    registerTenant,
    getOfferedCreditNotes,
    getAllCreditNotes,
    getCurrentAdvertiserPosition,
    validatePromotionCode,
    getAdvertisedBonusForRoom,
    getNewsletterSubscription,
    setNewsletterSubscription,
    getBankingStatus,
    synchronizeBanking,
    updateIban,
    updateServiceProviderInfo,
    doesTerminationExist,
    updateTenantData,
    updateBooking,
    bookAsLandlord,
    addInvitedNewTenant,
    assignTicket,
    createLog,
    getTenantForLandlord,
    createClaim,
    approveDepositSettlement,
  }),
  user,
  tenantBalancesForLandlord,
  room,
  rooms,
  houses,
  flats,
  termination,
  claimReports,
  houseGivers,
  houseOwners,
  landlords,
  tickets,
  tenants,
  serviceProviders,
  debts,
  payments,
  bookings,
  rentalEntrance,
  settings: combineReducers({
    credit: creditSettings,
  }),
});
