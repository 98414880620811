import React from 'react';
import PropTypes from 'prop-types';
import Content from 'components/Content';
import { HouseGiverForm } from 'containers';

const AddHouseGiver = ({ onSubmit, t, isLoading }) => {
  return (
    <Content>
      <h2>{t('houseGiver.houseGiver')}</h2>
      <HouseGiverForm onSubmit={onSubmit} isLoading={isLoading} />
    </Content>
  );
};

AddHouseGiver.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default AddHouseGiver;
