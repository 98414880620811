import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { bookingApi } from 'utils/api/';
import {
  UPDATE_BOOKING,
  updateBookingSuccess,
  updateBookingFailure
} from './updateBooking.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';

export function* updateBooking({
  id,
  startDate,
  durationInMonths,
  rentalFee,
  deposit
}) {
  try {
    const { data, errors } = yield call(
      bookingApi.updateBooking,
      id,
      startDate,
      parseInt(durationInMonths),
      parseFloat(rentalFee),
      parseFloat(deposit)
    );

    if (!errors) {
      yield put(updateBookingSuccess(data));
      successToast('updateBooking');
    } else {
      yield put(updateBookingFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(updateBookingFailure([i18n.t('base:generalError')]));
  }
}

export default function*() {
  yield takeLatest(UPDATE_BOOKING, updateBooking);
}
