import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { houseGiverApi } from 'utils/api';
import {
  GET_HOUSE_GIVERS,
  getHouseGiversSuccess,
  getHouseGiversFailure
} from './getHouseGivers.actions';

function* getHouseGiversSaga() {
  try {
    const { data, errors } = yield call(houseGiverApi.getHouseGivers);

    if (!errors) {
      yield put(getHouseGiversSuccess(data));
    } else {
      yield put(getHouseGiversFailure(errors));
    }
  } catch (error) {
    yield put(getHouseGiversFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(GET_HOUSE_GIVERS, getHouseGiversSaga);
}
