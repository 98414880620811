import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { serviceProviderApi } from 'utils/api';
import {
  GET_SERVICE_PROVIDER,
  getServiceProviderSuccess,
  getServiceProviderFailure
} from './getServiceProvider.actions';

function* getHouseOwnerSaga({ id }) {
  try {
    const { data, errors } = yield call(
      serviceProviderApi.getServiceProvider,
      id
    );

    if (!errors) {
      yield put(getServiceProviderSuccess(data));
    } else {
      yield put(getServiceProviderFailure(errors));
    }
  } catch (error) {
    yield put(getServiceProviderFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(GET_SERVICE_PROVIDER, getHouseOwnerSaga);
}
