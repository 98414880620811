import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TenantProfile from './TenantProfile';
import { Spinner } from 'react-bootstrap';
import useUser from 'hooks/store/useUser';
import { getAccessToken } from 'utils/localStorage.adapter';
import { useDispatch, useSelector } from 'react-redux';
import { handleLoadUser } from 'store/account/getUser/user.actions';

const TenantProfileContainer = () => {
  const { t } = useTranslation('tenantProfile');
  const { data: user, success } = useUser(state => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    const jwt = getAccessToken();
    dispatch(handleLoadUser(jwt));
  }, []);

  return success ? (
    <TenantProfile userInfo={user} t={t} />
  ) : (
    <div className="center-at-page">
      <Spinner animation="border" variant="primary" />
    </div>
  );
};

export default TenantProfileContainer;
