export const UPDATE_TENANT_DATA = 'UPDATE_TENANT_DATA';
export const UPDATE_TENANT_DATA_SUCCESS = 'UPDATE_TENANT_DATA_SUCCESS';
export const UPDATE_TENANT_DATA_FAILURE = 'UPDATE_TENANT_DATA_FAILURE';

export const updateTenantData = (
  id,
  phoneNumber,
  nationality,
  dateOfBirth,
  gender,
  job,
  firstName,
  lastName,
  email
) => ({
  type: UPDATE_TENANT_DATA,
  id,
  phoneNumber,
  nationality,
  dateOfBirth,
  gender,
  job,
  firstName,
  lastName,
  email
});
export const updateTenantDataSuccess = payload => ({
  type: UPDATE_TENANT_DATA_SUCCESS,
  payload
});
export const updateTenantDataFailure = errors => ({
  type: UPDATE_TENANT_DATA_FAILURE,
  errors
});
