import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { accountApi } from 'utils/api';
import {
  RESEND_CONFIRMATION_EMAIL,
  resendConfirmationEmailSuccess,
  resendConfirmationEmailFailure
} from './resendConfirmationEmail.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';

function* resendConfirmationEmail({ email }) {
  try {
    const { data, errors } = yield call(
      accountApi.resendConfirmationEmail,
      email
    );

    if (!errors) {
      yield put(resendConfirmationEmailSuccess(data));
      successToast('resendConfirmationEmail');
    } else {
      yield put(resendConfirmationEmailFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(resendConfirmationEmailFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(RESEND_CONFIRMATION_EMAIL, resendConfirmationEmail);
}
