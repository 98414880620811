export const CREATE_BOOKING = 'CREATE_BOOKING';
export const CREATE_BOOKING_SUCCESS = 'CREATE_BOOKING_SUCCESS';
export const CREATE_BOOKING_FAILURE = 'CREATE_BOOKING_FAILURE';

export const createBooking = (
  startDate,
  durationInMonths,
  roomId,
  orderId,
  promotionCode
) => ({
  type: CREATE_BOOKING,
  startDate,
  durationInMonths,
  roomId,
  orderId,
  promotionCode
});

export const createBookingSuccess = payload => ({
  type: CREATE_BOOKING_SUCCESS,
  payload
});

export const createBookingFailure = errors => ({
  type: CREATE_BOOKING_FAILURE,
  errors
});
