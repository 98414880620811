import useDebts from 'hooks/store/useDebts';
import usePayments from 'hooks/store/usePayments';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toDisplayDate } from 'utils/date.formatter';
import { v4 as uuidv4 } from 'uuid';
import Balance from './Balance';

const BalanceContainer = () => {
  const { t } = useTranslation('balance');
  const payments = usePayments();
  const debts = useDebts();
  const [tableData, setTableData] = useState([]);

  const getCorrectDescriptionForDebt = debt => {
    if (debt.description === 'rent') {
      return `${t(debt.description)} ${moment(debt.created).format('MMMM')}`;
    }

    return t(debt.description);
  };

  const mapToPaymentDescription = paymentMehtod => {
    if (paymentMehtod === 4) {
      return t('creditNote');
    } else {
      return t('inpayment');
    }
  };

  useEffect(() => {
    const currentTableData = [];
    debts.map(x =>
      currentTableData.push({
        id: x.id ? x.id : uuidv4(),
        created: x.created,
        remittanceInfo: x.id ? x.description : getCorrectDescriptionForDebt(x),
        amount: -x.amount
      })
    );

    payments.map(x =>
      currentTableData.push({
        id: x.id,
        created: x.bookingDate,
        remittanceInfo: x.info
          ? x.info
          : mapToPaymentDescription(x.paymentMethod),
        amount: x.amount
      })
    );

    currentTableData.sort(
      (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()
    );
    setTableData(currentTableData);
  }, [payments, debts]);

  const columns = [
    {
      dataField: 'remittanceInfo',
      text: t('usage'),
      sort: true,
      classes: 'ellipsis',

      headerStyle: { width: '30%' },
      formatter: col => {
        return (
          <span
            style={{
              display: 'block',

              width: 260,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }}
          >
            {col}
          </span>
        );
      }
    },
    {
      dataField: 'created',
      text: t('date'),
      sort: true,
      formatter: toDisplayDate,
      classes: 'ellipsis',
      headerStyle: { width: '35%' }
    },
    {
      dataField: 'amount',
      text: t('amount'),
      sort: true,
      formatter: x => `${x}€`,
      style: x => (x >= 0 ? { color: '#28A745' } : { color: '#DC3545' }),
      classes: 'ellipsis',
      headerStyle: { width: '25%' }
    }
  ];
  return <Balance {...{ columns, data: tableData, t }} />;
};

export default BalanceContainer;
