import 'bootstrap/dist/css/bootstrap.min.css';
import ScrollToTop from 'hooks/scrollToTop';
import React from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import './shared.css';
import { storeInstance } from './store/configureStore';
import './utils/icons';
import './utils/localization';
import './utils/prevent-google-maps-font'

ReactDOM.render(
  <Provider store={storeInstance}>
    <BrowserRouter>
      <ScrollToTop />
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
