export const ADD_ADDRESS = 'ADD_ADDRESS';
export const ADD_ADDRESS_SUCCESS = 'ADD_ADDRESS_SUCCESS';
export const ADD_ADDRESS_FAILURE = 'ADD_ADDRESS_FAILURE';

export const addAddress = (postalCode, street, houseNumber, location) => ({
  type: ADD_ADDRESS,
  postalCode,
  street,
  houseNumber,
  location
});
export const addAddressSuccess = payload => ({
  type: ADD_ADDRESS_SUCCESS,
  payload
});
export const addAddressFailure = errors => ({
  type: ADD_ADDRESS_FAILURE,
  errors
});
