import i18n from 'i18next';
import { call, put, takeLatest } from 'redux-saga/effects';
import { loggingApi } from 'utils/api';
import {
  createLogFailure,
  createLogSuccess,
  CREATE_LOG,
} from './createLog.actions';

function* createLog({ logLevel, message, args }) {
  try {
    const { data, errors } = yield call(
      loggingApi.createLog,
      logLevel,
      message,
      args
    );

    if (!errors) {
      yield put(createLogSuccess(data));
    } else {
      yield put(createLogFailure(errors));
    }
  } catch (error) {
    yield put(createLogFailure(i18n.t('base:generalError')));
  }
}

export default function* () {
  yield takeLatest(CREATE_LOG, createLog);
}
