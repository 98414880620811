export const VALIDATE_PROMOTIONCODE = 'VALIDATE_PROMOTIONCODE';
export const VALIDATE_PROMOTIONCODE_SUCCESS = 'VALIDATE_PROMOTIONCODE_SUCCESS';
export const VALIDATE_PROMOTIONCODE_FAILURE = 'VALIDATE_PROMOTIONCODE_FAILURE';
export const VALIDATE_PROMOTIONCODE_RESET = 'VALIDATE_PROMOTIONCODE_RESET';

export const validatePromotionCode = promotionCode => ({
  type: VALIDATE_PROMOTIONCODE,
  promotionCode
});
export const validatePromotionCodeSuccess = payload => ({
  type: VALIDATE_PROMOTIONCODE_SUCCESS,
  payload
});
export const validatePromotionCodeFailure = errors => ({
  type: VALIDATE_PROMOTIONCODE_FAILURE,
  errors
});

export const validatePromotionCodeReset = () => ({
  type: VALIDATE_PROMOTIONCODE_RESET
});
