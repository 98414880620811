import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { addressApi } from 'utils/api';
import {
  DELETE_ADDRESS,
  deleteAddressSuccess,
  deleteAddressFailure
} from './deleteAddress.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';

function* deleteAddress({ id }) {
  try {
    const { data, errors } = yield call(addressApi.deleteAddress, id);

    if (!errors) {
      yield put(deleteAddressSuccess(data));
    } else {
      yield put(deleteAddressFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(deleteAddressFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(DELETE_ADDRESS, deleteAddress);
}
