import API from './API';

const prefix = 'account';

export const getUser = () => API.get(`${prefix}`);

export const updateEmail = email => API.put(`${prefix}/`, { email });

export const updatePhoneNumber = phoneNumber =>
  API.put(`${prefix}/`, { phoneNumber });

export const updateIban = iban => API.put(`${prefix}/`, { iban });

export const registerTenant = (email, password) =>
  API.post(`${prefix}/register`, {
    email,
    password
  });

export const confirmEmailByCode = (email, code) =>
  API.post(`${prefix}/confirmEmail?code=${code}&email=${email}`);

export const registerUser = (email, password, firstName, lastName) =>
  API.post(`${prefix}/manualRegister`, {
    email,
    password,
    firstName,
    lastName
  });

export const loginUser = (email, password) =>
  API.post(`${prefix}/login`, { email, password });

export const confirmEmail = (token, email) =>
  API.post(`${prefix}/confirmEmailLink?token=${token}&email=${email}`);

export const confirmEmailForServiceProvider = (token, email, password) =>
  API.post(
    `${prefix}/serviceProvider/confirmEmailLink?token=${token}&email=${email}&password=${password}`
  );

export const resendConfirmationEmail = email =>
  API.post(`${prefix}/resendConfirmationEmail?email=${email}`);

export const forgotPassword = email =>
  API.post(`${prefix}/forgotPassword?email=${email}`);

export const resetPassword = (email, token, password) =>
  API.post(`${prefix}/resetPassword`, { email, token, password });

export const activateAccount = (secret, email, password) =>
  API.post(`${prefix}/activateAccount`, { secret, email, password });

export const updateServiceProviderInfo = (
  firstName,
  lastName,
  email,
  street,
  houseNumber,
  location,
  postalCode
) =>
  API.put(`${prefix}/serviceProvider`, {
    firstName,
    lastName,
    email,
    street,
    houseNumber,
    location,
    postalCode
  });

export default {
  getUser,
  registerUser,
  loginUser,
  resendConfirmationEmail,
  confirmEmail,
  confirmEmailForServiceProvider,
  updateEmail,
  updatePhoneNumber,
  forgotPassword,
  resetPassword,
  activateAccount,
  registerTenant,
  confirmEmailByCode,
  updateIban,
  updateServiceProviderInfo
};
