import { put, takeLatest, call, select } from 'redux-saga/effects';
import i18n from 'i18next';
import { claimReportApi } from 'utils/api';
import * as UserRole from 'utils/constants/userRole.constants';
import {
  GET_CLAIM_REPORTS,
  getClaimReportsSuccess,
  getClaimReportsFailure
} from './getClaimReports.actions';

export function* getClaimReports() {
  try {
    const userRole = yield select(state => state.user.data.role);

    const request =
      userRole === UserRole.LANDLORD
        ? claimReportApi.getAllClaimreportsForLandlord
        : claimReportApi.getClaimReports;

    const { data, errors } = yield call(request);

    if (!errors) {
      yield put(getClaimReportsSuccess(data));
    } else {
      yield put(getClaimReportsFailure(errors));
    }
  } catch (error) {
    yield put(getClaimReportsFailure([i18n.t('base:generalError')]));
  }
}

export default function*() {
  yield takeLatest(GET_CLAIM_REPORTS, getClaimReports);
}
