import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { houseOwnerApi } from 'utils/api';
import {
  DELETE_HOUSE_OWNER,
  deleteHouseOwnerSuccess,
  deleteHouseOwnerFailure
} from './deleteHouseOwner.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';

function* deleteHouseOwnerSaga({ id }) {
  try {
    const { data, errors } = yield call(houseOwnerApi.deleteHouseOwner, id);

    if (!errors) {
      yield put(deleteHouseOwnerSuccess(data));
      successToast('deleteHouseOwner');
    } else {
      yield put(deleteHouseOwnerFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(deleteHouseOwnerFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(DELETE_HOUSE_OWNER, deleteHouseOwnerSaga);
}
