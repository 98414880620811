import i18n from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {
  Search
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import PaymentModal from '../../../payment/PaymentModal';

const Balance = ({ data, columns, t }) => {
  const { SearchBar } = Search;
  let sum = 0;
  data.map(x => (sum += x.amount));
  return (
    <Container className="mt-5">
      <ToolkitProvider
        keyField="id"
        data={data}
        columns={columns}
        search={{ searchFormatted: true }}
      >
        {props => (
          <div>
            <Row>
              <Col
                className="col-md-8 col-12"
                style={{
                  alignItems: 'center',
                  fontSize: '20px'
                }}
              >
                <b
                  style={sum > 0 ? { color: '#28A745' } : { color: '#DC3545' }}
                >{`${t('balance')}: ${sum.toFixed(2)}€`}</b>
              </Col>
              <Col className="col-md-2 col-12 mt-3 mt-md-0">
                <PaymentModal>
                  <Button className="w-100">{t('addMoney')}</Button>
                </PaymentModal>
              </Col>
              <Col className="col-md-2 col-12 mt-3 mt-md-0">
                <SearchBar
                  {...props.searchProps}
                  srText={null}
                  placeholder={i18n.t('base:search')}
                  style={{ float: 'right' }}
                />
              </Col>
            </Row>
            <hr />
            <div className="noselect" style={{ cursor: 'pointer' }}>
              <BootstrapTable
                {...props.baseProps}
                hover
                wrapperClasses="table-responsive"
              />
            </div>
          </div>
        )}
      </ToolkitProvider>
    </Container>
  );
};

Balance.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  baseProps: PropTypes.object.isRequired,
  searchProps: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

export default Balance;
