export const GET_SERVICE_PROVIDER = 'GET_SERVICE_PROVIDER';
export const GET_SERVICE_PROVIDER_SUCCESS = 'GET_SERVICE_PROVIDER_SUCCESS';
export const GET_SERVICE_PROVIDER_FAILURE = 'GET_SERVICE_PROVIDER_FAILURE';
export const GET_SERVICE_PROVIDER_CLEAR = 'GET_SERVICE_PROVIDER_CLEAR';

export const getServiceProvider = id => ({
  type: GET_SERVICE_PROVIDER,
  id
});
export const getServiceProviderSuccess = payload => ({
  type: GET_SERVICE_PROVIDER_SUCCESS,
  payload
});
export const getServiceProviderFailure = errors => ({
  type: GET_SERVICE_PROVIDER_FAILURE,
  errors
});
export const getServiceProviderClear = () => ({
  type: GET_SERVICE_PROVIDER_CLEAR
});
