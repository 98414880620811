import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import HouseForm from './HouseForm';

const HouseFormContainer = ({
  address: _address,
  levels: _levels,
  giverId: _giverId,
  ownerId: _ownerId,
  minutesToTrainStation: _minutesToTrainStation,
  onSubmit,
  isLoading
}) => {
  const houseGivers = useSelector(state => state.houseGivers);

  const [address, setAddress] = useState(_address);
  const [giver, setGiver] = useState(_giverId);
  const [owner, setOwner] = useState(_ownerId);
  const [levels, setLevels] = useState(_levels);
  const [minutesToTrainStation, setMinutesToTrainStation] = useState(
    _minutesToTrainStation
  );

  const handleLevelsChange = e => setLevels(e.target.value);
  const handleGiverChange = houseGiverId => setGiver(houseGiverId);
  const handleOwnerChange = houseOwnerId => setOwner(houseOwnerId);
  const handleMinutesToTrainStationChange = e =>
    setMinutesToTrainStation(e.target.value);

  const handleSubmit = e => {
    e.preventDefault();
    onSubmit({
      address,
      giverId: giver,
      ownerId: owner,
      minutesToTrainStation,
      levels
    });
  };

  return (
    <HouseForm
      {...{
        address,
        levels,
        giver,
        owner,
        minutesToTrainStation,
        handleAddressChange: setAddress,
        handleLevelsChange,
        handleGiverChange,
        handleOwnerChange,
        handleMinutesToTrainStationChange,
        handleSubmit,
        houseGivers,
        isLoading
      }}
    />
  );
};

HouseFormContainer.defaultProps = {
  address: {},
  minutesToTrainStation: 0,
  onSubmit: () => {}
};

HouseFormContainer.propTypes = {
  address: PropTypes.object,
  levels: PropTypes.number,
  giverId: PropTypes.number,
  serviceProviderIds: PropTypes.array,
  ownerId: PropTypes.number,
  minutesToTrainStation: PropTypes.number,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool
};

export default HouseFormContainer;
