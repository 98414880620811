import jobs from './jobs';
import i18n from 'i18next';

export const getAllJobs = () =>
  jobs.map(job => ({
    ...job,
    value: i18n.t(`jobs:${job.value}`)
  }));

export const getJobByKey = key => getAllJobs().find(job => job.key === key);

export const getJobByValue = value =>
  getAllJobs().find(job => job.value === value);
