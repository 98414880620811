import API from './API';

const prefix = 'room';

export const getRoom = id => API.get(`${prefix}/${id}`);
export const deleteRoom = id => API.delete(`${prefix}/${id}`);
export const getRooms = () => API.get(`${prefix}`);
export const addRoom = formData => API.post(`${prefix}`, formData);
export const updateRoom = formData => API.put(`${prefix}`, formData);
export const getRoomHouse = id => API.get(`${prefix}/${id}/house`);
export const getRoomFlat = id => API.get(`${prefix}/${id}/flat`);

export default {
  getRoom,
  deleteRoom,
  getRooms,
  addRoom,
  updateRoom,
  getRoomHouse,
  getRoomFlat
};
