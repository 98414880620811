import React from 'react';
import Documents from './Documents';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const DocumentsContainer = () => {
  const { t } = useTranslation('documents');
  const userInfo = useSelector(state => state.user.data);

  return <Documents t={t} userInfo={userInfo} />;
};

export default DocumentsContainer;
