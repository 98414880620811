import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { debtApi } from 'utils/api';
import {
  GET_DEBTS,
  getDebtsSuccess,
  getDebtsFailure
} from './getDebts.actions';

function* getDebts() {
  try {
    const { data, errors } = yield call(debtApi.getDebts);

    if (!errors) {
      yield put(getDebtsSuccess(data));
    } else {
      yield put(getDebtsFailure(errors));
    }
  } catch (error) {
    yield put(getDebtsFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(GET_DEBTS, getDebts);
}
