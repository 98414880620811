import { put, takeLatest, call } from 'redux-saga/effects';
import { tenantApi } from 'utils/api';
import i18n from 'i18next';
import {
  UPDATE_TENANT_DATA,
  updateTenantDataSuccess,
  updateTenantDataFailure
} from './updateTenantData.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';

function* updateTenantData({
  id,
  phoneNumber,
  nationality,
  dateOfBirth,
  gender,
  job,
  firstName,
  lastName,
  email
}) {
  try {
    const { data, errors } = yield call(
      tenantApi.updateTenantData,
      id,
      phoneNumber,
      nationality,
      dateOfBirth,
      gender,
      job,
      firstName,
      lastName,
      email
    );

    if (!errors) {
      yield put(updateTenantDataSuccess(data));
      successToast('updateUserInfo');
    } else {
      yield put(updateTenantDataFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(updateTenantDataFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(UPDATE_TENANT_DATA, updateTenantData);
}
