import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  UPDATE_TENANT_DATA,
  UPDATE_TENANT_DATA_SUCCESS,
  UPDATE_TENANT_DATA_FAILURE
} from './updateTenantData.actions';

export default createReducer(
  {
    [UPDATE_TENANT_DATA]: defaultRequest,
    [UPDATE_TENANT_DATA_SUCCESS]: defaultSuccess,
    [UPDATE_TENANT_DATA_FAILURE]: defaultError
  },
  defaultInitialState
);
