import {
  createReducer,
  updateArray,
  pushToArray,
  updateItemInArray,
  removeItemInArray
} from 'utils/reducer.helper';

import { ADD_HOUSE_OWNER_SUCCESS } from './addHouseOwner/addHouseOwner.actions';
import { DELETE_HOUSE_OWNER_SUCCESS } from './deleteHouseOwner/deleteHouseOwner.actions';
import { GET_HOUSE_OWNER_SUCCESS } from './getHouseOwner/getHouseOwner.actions';
import { GET_HOUSE_OWNERS_SUCCESS } from './getHouseOwners/getHouseOwners.actions';
import { UPDATE_HOUSE_OWNER_SUCCESS } from './updateHouseOwner/updateHouseOwner.actions';

export default createReducer(
  {
    [GET_HOUSE_OWNERS_SUCCESS]: (state, action) =>
      updateArray(state, { ...action.payload }),
    [GET_HOUSE_OWNER_SUCCESS]: (state, action) =>
      updateItemInArray(state, action.payload.id, action.payload),
    [ADD_HOUSE_OWNER_SUCCESS]: (state, action) =>
      pushToArray(state, action.payload),
    [UPDATE_HOUSE_OWNER_SUCCESS]: (state, action) =>
      updateItemInArray(state, action.payload.id, action.payload),
    [DELETE_HOUSE_OWNER_SUCCESS]: (state, action) =>
      removeItemInArray(state, action.payload.id)
  },
  []
);
