import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  DELETE_TERMINATION,
  DELETE_TERMINATION_SUCCESS,
  DELETE_TERMINATION_FAILURE
} from './deleteTermination.actions';

export default createReducer(
  {
    [DELETE_TERMINATION]: defaultRequest,
    [DELETE_TERMINATION_SUCCESS]: defaultSuccess,
    [DELETE_TERMINATION_FAILURE]: defaultError
  },
  defaultInitialState
);
