import { put, takeLatest, call, select } from 'redux-saga/effects';
import i18n from 'i18next';
import { houseOwnerApi, addressApi } from 'utils/api';
import {
  UPDATE_HOUSE_OWNER,
  updateHouseOwnerSuccess,
  updateHouseOwnerFailure
} from './updateHouseOwner.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';

function* updateHouseOwnerSaga({ id, firstName, lastName, address }) {
  try {
    const state = yield select();

    const currentAddress = state.houseOwners.find(x => x.id === id).address;

    yield call(addressApi.updateAddress, currentAddress.id, address);

    const { data, errors } = yield call(
      houseOwnerApi.updateHouseOwner,
      id,
      address.id,
      firstName,
      lastName
    );

    if (!errors) {
      yield put(updateHouseOwnerSuccess(data));
      successToast('updateHouseOwner');
    } else {
      yield put(updateHouseOwnerFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(updateHouseOwnerFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(UPDATE_HOUSE_OWNER, updateHouseOwnerSaga);
}
