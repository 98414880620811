import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const LoadingButton = ({
  variant,
  isLoading,
  type,
  onClick,
  className,
  children,
  style
}) => {
  const { t } = useTranslation('base');
  return (
    <Button
      className={className}
      variant={variant}
      disabled={isLoading}
      type={type}
      style={style}
      onClick={!isLoading ? onClick : null}
    >
      {isLoading ? t('loading') : children}
    </Button>
  );
};

LoadingButton.defaultProps = {
  variant: 'primary',
  isLoading: false,
  type: 'button',
  onClick: () => {},
  className: ''
};

LoadingButton.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  variant: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object
};

export default LoadingButton;
