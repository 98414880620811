export const ASSIGN_TICKET = 'ASSIGN_TICKET';
export const ASSIGN_TICKET_SUCCESS = 'ASSIGN_TICKET_SUCCESS';
export const ASSIGN_TICKET_FAILURE = 'ASSIGN_TICKET_FAILURE';

export const assignTicket = (id, serviceProviderId) => ({
  type: ASSIGN_TICKET,
  id,
  serviceProviderId
});
export const assignTicketSuccess = payload => ({
  type: ASSIGN_TICKET_SUCCESS,
  payload
});
export const assignTicketFailure = errors => ({
  type: ASSIGN_TICKET_FAILURE,
  errors
});
