import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTickets } from 'store/ticket/getTickets/getTickets.actions';

const useTickets = (selector = state => state.tickets) => {
  const dispatch = useDispatch();

  const { success } = useSelector(state => state.request.getTickets);
  const tickets = useSelector(selector);

  useEffect(() => {
    if (!success) dispatch(getTickets());
  }, [success]);

  return tickets;
};

export default useTickets;
