import API from './API';

const prefix = 'landlord';

export const getLandlord = (id) => API.get(`${prefix}/${id}`);
export const getLandlords = () => API.get(`${prefix}`);
export const addLandlord = (addressId, firstName, lastName) =>
  API.post(`${prefix}/`, { addressId, firstName, lastName });
export const updateLandlord = (id, addressId, firstName, lastName) =>
  API.put(`${prefix}/`, { id, addressId, firstName, lastName });
export const deleteLandlord = (id) => API.delete(`${prefix}/${id}`);
export const getTenants = () => API.get(`${prefix}/tenants`);
export const getTenant = (userId) => API.get(`${prefix}/tenant/${userId}`);
export const createNewTenant = (firstName, lastName, email, password) =>
  API.post(`/${prefix}/tenant`, { firstName, lastName, email, password });
export const getTenantBalanceForLandlord = (id) =>
  API.get(`${prefix}/tenant/${id}/balance`);

export default {
  getLandlord,
  getLandlords,
  addLandlord,
  updateLandlord,
  deleteLandlord,
  getTenants,
  getTenant,
  createNewTenant,
  getTenantBalanceForLandlord,
};
