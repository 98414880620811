import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { toDisplayDate } from 'utils/date.formatter';

const PromotionRoomListItem = ({ room, promotionText, t }) => (
  <div
    className="row align-items-md-center justify-content-md-center"
    style={{
      border: 'lightgrey 1px solid',
      borderRadius: '5px',

      margin: '15px 0px',
      padding: '20px',
    }}
  >
    <div className=" col-12 col-md-1 text-center">
      <img
      className=''
        src={room.images[0] ? room.images[0].imageUrl : null}
        style={{ width: '80px', height: '80px' }}
       
      />
    </div>

    <b className="col-12 col-md-2 mt-2 mt-md-0">{room.description}</b>

    <div className="col-12 col-md-2 mt-2 mt-md-0">
      <FontAwesomeIcon icon={faMapMarkerAlt} className="me-2" />
      {`${room.address.street} ${room.address.houseNumber}, `}
      {`${room.address.postalCode} ${room.address.location}`}
    </div>

    <div className="col-6 col-md-1 mt-2 mt-md-0" style={{ margin: '0 0px' }}>
      <b
        style={{ fontSize: 18, fontWeight: 500 }}
      >{`${room.squareMeters} m²`}</b>{' '}
      <br />
      <small style={{ color: '#757575' }}>{t('squareMeters')}</small>
    </div>

    <div className="col-6 col-md-1 mt-2 mt-md-0">
      <b style={{ fontSize: 18, fontWeight: 500 }}>{`${room.price} €`}</b>{' '}
      <br />
      <small style={{ color: '#757575' }}> {t('rentalFee')}</small>
    </div>

    <div className="col-6 col-md-1 mt-2 mt-md-0">
      <b style={{ fontSize: 18, fontWeight: 500 }}>{`${room.deposit} €`}</b>{' '}
      <br />
      <small style={{ color: '#757575' }}> {t('deposit')}</small>
    </div>

    <div className="col-6 col-md-2 mt-2 mt-md-0">
      <b style={{ fontSize: 18, fontWeight: 500 }}>
        {room.bookable ? (
          <b style={{ fontSize: 18, fontWeight: 500 }}> {t('immediately')} </b>
        ) : (
          <span>{toDisplayDate(room.freeAt)}</span>
        )}
      </b>
      <br />
      <small style={{ color: '#757575' }}>{t('freeAt')}</small>
    </div>

    <div className="col-12 col-md-2 mt-2 mt-md-0">
      <OverlayTrigger
        overlay={
          <Popover id="popover-basic">
            <Popover.Header as="h3">{t('copyAdvertisingText')}</Popover.Header>
            <Popover.Body>{promotionText}</Popover.Body>
          </Popover>
        }
        trigger={['hover', 'focus']}
        placement="left"
        delay={{ show: 250, hide: 250 }}
      >
        <Button onClick={() => navigator.clipboard.writeText(promotionText)}>
          {t('copyAdvertisingText')}
        </Button>
      </OverlayTrigger>
    </div>
  </div>
);

PromotionRoomListItem.propTypes = {
  room: PropTypes.object.isRequired,
  promotionText: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default PromotionRoomListItem;
