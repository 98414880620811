export const GET_RENTAL_ENTRANCE = 'GET_RENTAL_ENTRANCE';
export const GET_RENTAL_ENTRANCE_SUCCESS = 'GET_RENTAL_ENTRANCE_SUCCESS';
export const GET_RENTAL_ENTRANCE_FAILURE = 'GET_RENTAL_ENTRANCE_FAILURE';

export const getRentalEntrance = () => ({
  type: GET_RENTAL_ENTRANCE
});
export const getRentalEntranceSuccess = payload => ({
  type: GET_RENTAL_ENTRANCE_SUCCESS,
  payload
});
export const getRentalEntranceFailure = errors => ({
  type: GET_RENTAL_ENTRANCE_FAILURE,
  errors
});
