export default [
  {
    value: 'male',
    key: 0
  },
  {
    value: 'female',
    key: 1
  },
  {
    value: 'divers',
    key: 2
  }
];
