export const BOOK_AS_LANDLORD = 'BOOK_AS_LANDLORD';
export const BOOK_AS_LANDLORD_SUCCESS = 'BOOK_AS_LANDLORD_SUCCESS';
export const BOOK_AS_LANDLORD_FAILURE = 'BOOK_AS_LANDLORD_FAILURE';

export const bookAsLandlord = (
  startDate,
  durationInMonths,
  roomId,
  userId,
  rentalFee,
  deposit
) => ({
  type: BOOK_AS_LANDLORD,
  startDate,
  durationInMonths,
  roomId,
  userId,
  rentalFee,
  deposit
});

export const bookAsLandlordSuccess = payload => ({
  type: BOOK_AS_LANDLORD_SUCCESS,
  payload
});

export const bookAsLandlordFailure = errors => ({
  type: BOOK_AS_LANDLORD_FAILURE,
  errors
});
