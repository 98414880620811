import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  BOOK_AS_LANDLORD,
  BOOK_AS_LANDLORD_SUCCESS,
  BOOK_AS_LANDLORD_FAILURE
} from './bookAsLandlord.actions';

export default createReducer(
  {
    [BOOK_AS_LANDLORD]: defaultRequest,
    [BOOK_AS_LANDLORD_SUCCESS]: defaultSuccess,
    [BOOK_AS_LANDLORD_FAILURE]: defaultError
  },
  defaultInitialState
);
