import { LoadingButton } from 'components';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import React from 'react';
import { Form, ListGroup, Tab, Tabs } from 'react-bootstrap';
import { toDisplayDate } from 'utils/date.formatter';
import WithNextTenant from './WithNextTenant';
import WithoutNextTenant from './WithoutNextTenant';

const TerminationForm = ({
  t,
  user,
  firstPossibleNoticeDate,
  credentialSettings,
  handleSubmit,
  tab,
  handleTabChanged,
  noticeDate,
  nextTenantEmail,
  handleNextTenantEmailChanged,
  isStudent,
  handleIsStudentChanged,
  ageBetween,
  handleAgeBetweenChanged,
  possibleTerminationDates,
  handleOnChange,
  iban,
  handleIbanChanged,
  createTerminationLoading,
}) => {
  return (
    <>
      <ListGroup className="mb-3">
        <ListGroup.Item className="border-0">
          {t('rentalstartdate').replace(
            '{rentalContractStartDate}',
            toDisplayDate(user.booking.startDateContract)
          )}
        </ListGroup.Item>
        <ListGroup.Item className="border-0">
          {parse(
            t('miminumRentalPeriod')
              .replace('{minimumDuration}', user.booking.durationInMonths)
              .replace(
                '{rentalAgreement}',
                `<a href="${
                  user.documents.rentalContract
                }" target="_blank" rel="noopener noreferrer"> ${t(
                  'rentalAgreement'
                )}</a>`
              )
          )}
        </ListGroup.Item>
        <ListGroup.Item className="border-0">
          {parse(
            t('rentalagreement').replace(
              '{rentalAgreement}',
              `<a href="${
                user.documents.rentalContract
              }" target="_blank" rel="noopener noreferrer"> ${t(
                'rentalAgreement'
              )}</a>`
            )
          )}
        </ListGroup.Item>
        <ListGroup.Item className="border-0">
          {t('possibledate').replace(
            '{earliestPossibleTerminationDate}',
            toDisplayDate(firstPossibleNoticeDate)
          )}
        </ListGroup.Item>
        <ListGroup.Item className="border-0">
          {t('note').replace(
            '{creditAdvertiser}',
            credentialSettings.creditAdvertiser
          )}
        </ListGroup.Item>
      </ListGroup>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Tabs
          id="next-tenant-tabs"
          activeKey={tab}
          onSelect={handleTabChanged}
          className="mb-3"
        >
          <Tab eventKey="with" title={t('withTenant')} unmountOnExit>
            <WithNextTenant
              t={t}
              ageBetween={ageBetween}
              credentialSettings={credentialSettings}
              handleAgeBetweenChanged={handleAgeBetweenChanged}
              handleIbanChanged={handleIbanChanged}
              handleIsStudentChanged={handleIsStudentChanged}
              handleNextTenantEmailChanged={handleNextTenantEmailChanged}
              handleOnChange={handleOnChange}
              iban={iban}
              isStudent={isStudent}
              nextTenantEmail={nextTenantEmail}
              noticeDate={noticeDate}
            />
          </Tab>
          <Tab eventKey="without" title={t('withoutTenant')} unmountOnExit>
            <WithoutNextTenant
              handleIbanChanged={handleIbanChanged}
              handleOnChange={handleOnChange}
              noticeDate={noticeDate}
              iban={iban}
              possibleTerminationDates={possibleTerminationDates}
              t={t}
            />
          </Tab>
        </Tabs>
        <LoadingButton
          isLoading={createTerminationLoading}
          type="submit"
          className="mb-2"
        >
          {t('submit')}
        </LoadingButton>
      </Form>
    </>
  );
};

TerminationForm.propTypes = {
  t: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  firstPossibleNoticeDate: PropTypes.string.isRequired,
  credentialSettings: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  tab: PropTypes.string.isRequired,
  handleTabChanged: PropTypes.func.isRequired,
  noticeDate: PropTypes.string.isRequired,
  nextTenantEmail: PropTypes.array.isRequired,
  handleNextTenantEmailChanged: PropTypes.func.isRequired,
  isStudent: PropTypes.bool.isRequired,
  handleIsStudentChanged: PropTypes.func.isRequired,
  ageBetween: PropTypes.bool.isRequired,
  handleAgeBetweenChanged: PropTypes.bool.isRequired,
  possibleTerminationDates: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  iban: PropTypes.string.isRequired,
  handleIbanChanged: PropTypes.func.isRequired,
  createTerminationLoading: PropTypes.bool.isRequired,
};

export default TerminationForm;
