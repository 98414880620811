import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getHouseOwners } from 'store/houseOwner/getHouseOwners/getHouseOwners.actions';
import HouseOwnerList from './HouseOwnerList';

const HouseOwnerListContainer = ({ houseOwnerSelector }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation('data');

  const houseOwners = useSelector(houseOwnerSelector);
  const { success } = useSelector(state => state.request.getHouseOwners);

  useEffect(() => {
    if (!success) dispatch(getHouseOwners());
  }, [success]);

  const columnConfiguration = [
    {
      dataField: 'firstName',
      text: t('houseOwner.firstName'),
      sort: true
    },
    {
      dataField: 'lastName',
      text: t('houseOwner.lastName'),
      sort: true
    }
  ];

  const handleNewHouseOwnerClick = () =>
    history.push('/dashboard/data/houseOwner/add');
  const handleHouseOwnerRowClick = houseOwner =>
    history.push(`/dashboard/data/houseOwner/${houseOwner.id}`);

  return (
    <HouseOwnerList
      {...{
        t,
        houseOwners,
        columnConfiguration,
        handleNewHouseOwnerClick,
        handleHouseOwnerRowClick
      }}
    />
  );
};

HouseOwnerListContainer.defaultProps = {
  houseOwnerSelector: state => state.houseOwners
};

HouseOwnerListContainer.propTypes = {
  houseOwnerSelector: PropTypes.func
};

export default HouseOwnerListContainer;
