export const ACTIVATE_ACCOUNT = 'ACTIVATE_ACCOUNT';
export const ACTIVATE_ACCOUNT_SUCCESS = 'ACTIVATE_ACCOUNT_SUCCESS';
export const ACTIVATE_ACCOUNT_FAILURE = 'ACTIVATE_ACCOUNT_FAILURE';

export const activateAccount = (secret, email, password) => ({
  type: ACTIVATE_ACCOUNT,
  secret,
  email,
  password
});
export const activateAccountSuccess = payload => ({
  type: ACTIVATE_ACCOUNT_SUCCESS,
  payload
});
export const activateAccountFailure = errors => ({
  type: ACTIVATE_ACCOUNT_FAILURE,
  errors
});
