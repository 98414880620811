import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  addHouse,
  addHouseClear,
} from 'store/houses/addHouse/addHouse.actions';
import AddHouse from './AddHouse';

const AddHouseContainer = () => {
  const { t } = useTranslation('data');
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    data: house,
    success,
    loading,
  } = useSelector((state) => state.request.addHouse);

  useEffect(() => {
    if (success) {
      dispatch(addHouseClear());
      history.push(`/dashboard/data/house/${house.id}`);
    }
  }, [success]);

  const handleSubmit = ({
    address,
    giverId,
    ownerId,
    minutesToTrainStation,
    levels,
  }) => {
    dispatch(
      addHouse(levels, minutesToTrainStation, giverId, ownerId, address)
    );
  };

  return (
    <AddHouse
      isLoading={loading}
      {...{
        handleSubmit,
        t,
      }}
    />
  );
};

export default AddHouseContainer;
