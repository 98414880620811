import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import { toDisplayDate } from 'utils/date.formatter';
import ChangeEMailModal from 'views/tenantProfile/components/ChangeEMailModal';
import ChangePhoneNumberModal from 'views/tenantProfile/components/ChangePhoneNumberModal';

const TenantData = ({
  title,
  customerId,
  lastName,
  firstName,
  email,
  phoneNumber,
  nationality,
  dateOfBirth,
  gender,
  job
}) => {
  const { t } = useTranslation('tenantData');
  return (
    <>
      <div className="mb-2">
        <strong>{title || t('title')}</strong>
      </div>
      <Row>
        <Col className="col-6 col-xl-3 col-lg-3">
          <div className="mb-1 ellipsis">{t('lastName')}</div>
          <div className="mb-1 ellipsis">{t('firstName')}</div>
          <div className="mb-1 ellipsis">{t('email')}</div>
          <div className="mb-1 ellipsis">{t('phoneNumber')}</div>
          <div className="mb-1 ellipsis">{t('nationality')}</div>
          <div className="mb-1 ellipsis">{t('dateOfBirth')}</div>
          <div className="mb-1 ellipsis">{t('gender')}</div>
          <div className="mb-1 ellipsis">{t('job')}</div>
        </Col>
        <Col className="col-6 col-xl-9 col-lg-9">
          <div className="mb-1 ellipsis">{lastName}</div>
          <div className="mb-1 ellipsis">{firstName}</div>
          <div className="mb-1 ellipsis">
            {email}{' '}
            <ChangeEMailModal>
              <a
                className="ms-md-1 d-md-inline d-none"
                style={{ cursor: 'pointer' }}
              >
                <FontAwesomeIcon icon={faEdit} />
              </a>
            </ChangeEMailModal>
          </div>
          <div className="mb-1 ellipsis">
            {phoneNumber}{' '}
            <ChangePhoneNumberModal>
              <a
                className="ms-1 d-md-inline d-none"
                style={{ cursor: 'pointer' }}
              >
                <FontAwesomeIcon icon={faEdit} />
              </a>
            </ChangePhoneNumberModal>
          </div>
          <div className="mb-1 ellipsis">{nationality}</div>
          <div className="mb-1 ellipsis">{toDisplayDate(dateOfBirth)}</div>
          <div className="mb-1 ellipsis">{gender}</div>
          <div className="mb-1 ellipsis">{job}</div>
        </Col>
      </Row>
    </>
  );
};

TenantData.propTypes = {
  title: PropTypes.string,
  customerId: PropTypes.number.isRequired,
  lastName: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  nationality: PropTypes.string.isRequired,
  dateOfBirth: PropTypes.string.isRequired,
  gender: PropTypes.string.isRequired,
  job: PropTypes.string.isRequired
};

export default TenantData;
