import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  GET_HOUSE_GIVERS,
  GET_HOUSE_GIVERS_SUCCESS,
  GET_HOUSE_GIVERS_FAILURE,
  GET_HOUSE_GIVERS_CLEAR
} from './getHouseGivers.actions';

export default createReducer(
  {
    [GET_HOUSE_GIVERS]: defaultRequest,
    [GET_HOUSE_GIVERS_SUCCESS]: defaultSuccess,
    [GET_HOUSE_GIVERS_FAILURE]: defaultError,
    [GET_HOUSE_GIVERS_CLEAR]: () => defaultInitialState
  },
  defaultInitialState
);
