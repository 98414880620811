import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState,
  defaultReset
} from 'utils/reducer.helper';

import {
  GET_ALL_CREDITNOTES,
  GET_ALL_CREDITNOTES_SUCCESS,
  GET_ALL_CREDITNOTES_FAILURE,
  GET_ALL_CREDITNOTES_RESET
} from './getAllCreditNotes.actions';

export default createReducer(
  {
    [GET_ALL_CREDITNOTES]: defaultRequest,
    [GET_ALL_CREDITNOTES_SUCCESS]: defaultSuccess,
    [GET_ALL_CREDITNOTES_FAILURE]: defaultError,
    [GET_ALL_CREDITNOTES_RESET]: defaultReset
  },
  defaultInitialState
);
