import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import OverviewTable from 'components/OverviewTable';

const HouseGiverList = ({
  t,
  houseGivers,
  columnConfiguration,
  handleNewHouseGiverClick,
  handleHouseGiverRowClick
}) => (
  <OverviewTable
    rows={houseGivers}
    columns={columnConfiguration}
    onRowClick={handleHouseGiverRowClick}
    actionButton={
      <OverlayTrigger
        placement="top"
        delay={{ show: 300, hide: 400 }}
        overlay={<Tooltip>{t('houseGiver.addHouseGiver')}</Tooltip>}
      >
        <Button className="float-md-right" onClick={handleNewHouseGiverClick}>
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      </OverlayTrigger>
    }
  />
);

HouseGiverList.propTypes = {
  t: PropTypes.func.isRequired,
  houseGivers: PropTypes.array.isRequired,
  columnConfiguration: PropTypes.array.isRequired,
  handleNewHouseGiverClick: PropTypes.func.isRequired,
  handleHouseGiverRowClick: PropTypes.func.isRequired
};

export default HouseGiverList;
