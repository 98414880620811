import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';

const NumericFormControl = ({ onChange: _onChange, value, ...rest }) => {
  const onChange = e =>
    _onChange({
      ...e,
      target: { ...e.target, value: parseInt(e.target.value) }
    });

  return (
    <FormControl
      type="number"
      min={0}
      step={1}
      value={value}
      onChange={onChange}
      {...rest}
    />
  );
};

NumericFormControl.defaultProps = {
  value: 0
};

NumericFormControl.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

export default NumericFormControl;
