import { createReducer, updateObject } from 'utils/reducer.helper';

import { CREATE_TERMINATION_SUCCESS } from './createTermination/createTermination.actions';
import { GET_TERMINATION_SUCCESS } from './getTermination/getTermination.actions';
import { DELETE_TERMINATION_SUCCESS } from './deleteTermination/deleteTermination.actions';
import { ADD_INVITED_NEW_TENANT_SUCCESS } from './addInvitedNewTenant/addInvitedNewTenant.actions';

export default createReducer(
  {
    [CREATE_TERMINATION_SUCCESS]: (state, action) =>
      updateObject(state, { ...action.payload }),
    [GET_TERMINATION_SUCCESS]: (state, action) =>
      updateObject(state, { ...action.payload }),
    [DELETE_TERMINATION_SUCCESS]: (state, _) => updateObject({}),
    [ADD_INVITED_NEW_TENANT_SUCCESS]: (state, action) =>
      updateObject(state, { ...action.payload })
  },
  {}
);
