import i18n from 'i18next';
import { call, put, takeLatest } from 'redux-saga/effects';
import { depositSettlementApi } from 'utils/api';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';
import {
  approveDepositSettlementFailure,
  approveDepositSettlementSuccess,
  APPROVED_DEPOSIT_SETTLEMENT,
} from './approveDepositSettlement.actions';

function* approveDepositSettlementSaga({ id }) {
  try {
    const { data, errors } = yield call(
      depositSettlementApi.approveDepositSettlement,
      id
    );

    if (!errors) {
      yield put(approveDepositSettlementSuccess(data));
      successToast('approveDepositSettlement');
    } else {
      yield put(approveDepositSettlementFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(approveDepositSettlementFailure(i18n.t('base:generalError')));
  }
}

export default function* () {
  yield takeLatest(APPROVED_DEPOSIT_SETTLEMENT, approveDepositSettlementSaga);
}
