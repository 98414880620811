import React from 'react';
import { useTranslation } from 'react-i18next';
import FlatOverview from './Flats';

const FlatOverviewContainer = () => {
  const { t } = useTranslation('data');

  return <FlatOverview {...{ t }} />;
};

export default FlatOverviewContainer;
