import i18n from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';

const FirstNameFormGroup = ({ value, onChange, ...rest }) => (
  <FormGroup className="mb-3" controlId="formFirstName">
    <FormLabel>{i18n.t('forms:user.firstName')}</FormLabel>
    <FormControl
      value={value}
      onChange={onChange}
      name="firstName"
      maxLength="50"
      {...rest}
    />
  </FormGroup>
);

FirstNameFormGroup.defaultProps = {
  value: ''
};

FirstNameFormGroup.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default FirstNameFormGroup;
