import React from 'react';
import PropTypes from 'prop-types';
import AddressFormGroup from './AddressFormGroup';

const AddressFormGroupContainer = ({
  address,
  onChangeAddress,
  showCoordinates
}) => {
  const handleChange = e =>
    onChangeAddress({ ...address, [e.target.name]: e.target.value });

  return (
    <AddressFormGroup
      address={address}
      onChange={handleChange}
      showCoordinates={showCoordinates}
    />
  );
};

AddressFormGroupContainer.defaultProps = {
  address: {},
  onChangeAddress: () => {},
  showCoordinates: true
};

AddressFormGroupContainer.propTypes = {
  address: PropTypes.object,
  onChangeAddress: PropTypes.func,
  showCoordinates: PropTypes.bool.isRequired
};

export default AddressFormGroupContainer;
