import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  ADD_ROOM,
  ADD_ROOM_SUCCESS,
  ADD_ROOM_FAILURE,
  ADD_ROOM_CLEAR
} from './addRoom.actions';

export default createReducer(
  {
    [ADD_ROOM]: defaultRequest,
    [ADD_ROOM_SUCCESS]: defaultSuccess,
    [ADD_ROOM_FAILURE]: defaultError,
    [ADD_ROOM_CLEAR]: () => defaultInitialState
  },
  defaultInitialState
);
