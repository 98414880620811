import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { landlordApi } from 'utils/api';
import {
  GET_TENANT_BALANCE_FOR_LANDLORD,
  getTenantBalanceForLandlordSuccess,
  getTenantBalanceForLandlordFailure
} from './getTenantBalanceForLandlord.actions';

function* getTenantBalanceForLandlordSaga({ id }) {
  try {
    const { data, errors } = yield call(
      landlordApi.getTenantBalanceForLandlord,
      id
    );
    if (!errors) {
      yield put(getTenantBalanceForLandlordSuccess(data));
    } else {
      yield put(getTenantBalanceForLandlordFailure(errors));
    }
  } catch (error) {
    yield put(getTenantBalanceForLandlordFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(
    GET_TENANT_BALANCE_FOR_LANDLORD,
    getTenantBalanceForLandlordSaga
  );
}
