import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import RegisterServiceProvider from './RegisterServiceProvider';
import qs from 'qs';
import PropTypes from 'prop-types';
import { registerServiceProvider } from 'store/serviceProvider/registerServiceProvider/registerServiceProvider.actions';

const RegisterServiceProviderContainer = ({ location }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('register');

  const { search } = location;
  const queryStrings = qs.parse(search, { ignoreQueryPrefix: true });

  const token = queryStrings.token;
  const email = queryStrings.email;

  const [errors, setErrors] = useState([]);
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const { success, loading, errors: apiErrors } = useSelector(
    state => state.request.registerServiceProvider
  );

  const handleSubmit = (password, repeatPassword) => {
    if (password !== repeatPassword) {
      setErrors([t('passwordsDoNotMatch')]);
      setPassword('');
      setRepeatPassword('');
      return;
    }

    dispatch(registerServiceProvider(email, password, token));
  };

  useEffect(() => {
    if (success) {
      history.push({ pathname: '/login' });
    }
  }, [success, apiErrors, history]);

  const handlePasswordChanged = e => setPassword(e.target.value);
  const handleRepeatPasswordChanged = e => setRepeatPassword(e.target.value);

  return (
    <RegisterServiceProvider
      {...{
        t,
        handleSubmit,
        password,
        setPassword,
        repeatPassword,
        setRepeatPassword,
        errors,
        loading,
        handlePasswordChanged,
        handleRepeatPasswordChanged
      }}
    />
  );
};

RegisterServiceProviderContainer.propTypes = {
  location: PropTypes.object.isRequired
};

export default RegisterServiceProviderContainer;
