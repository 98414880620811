import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  DELETE_ADDRESS,
  DELETE_ADDRESS_SUCCESS,
  DELETE_ADDRESS_FAILURE
} from './deleteAddress.actions';

export default createReducer(
  {
    [DELETE_ADDRESS]: defaultRequest,
    [DELETE_ADDRESS_SUCCESS]: defaultSuccess,
    [DELETE_ADDRESS_FAILURE]: defaultError
  },
  defaultInitialState
);
