import { put, takeLatest, call } from 'redux-saga/effects';
import { settingsApi } from 'utils/api';
import i18n from 'i18next';
import {
  GET_ADVERTISED_BONUS_FOR_ROOM,
  getAdvertisedBonusForRoomSuccess,
  getAdvertisedBonusForRoomFailure
} from './getAdvertisedBonusForRoom.actions';

function* getAdvertisedBonusForRoom({ roomId }) {
  try {
    const { data, errors } = yield call(
      settingsApi.getAdvertisedBonusForRoom,
      roomId
    );
    if (!errors && data.errors.length === 0) {
      yield put(getAdvertisedBonusForRoomSuccess(data.resultObject));
    } else {
      yield put(getAdvertisedBonusForRoomFailure(errors || data.errors));
    }
  } catch (error) {
    yield put(getAdvertisedBonusForRoomFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(GET_ADVERTISED_BONUS_FOR_ROOM, getAdvertisedBonusForRoom);
}
