export const APPROVED_DEPOSIT_SETTLEMENT = 'APPROVED_DEPOSIT_SETTLEMENT';
export const APPROVED_DEPOSIT_SETTLEMENT_SUCCESS =
  'APPROVED_DEPOSIT_SETTLEMENT_SUCCESS';
export const APPROVED_DEPOSIT_SETTLEMENT_FAILURE =
  'APPROVED_DEPOSIT_SETTLEMENT_FAILURE';
export const APPROVED_DEPOSIT_SETTLEMENT_CLEAR =
  'APPROVED_DEPOSIT_SETTLEMENT_CLEAR';

export const approveDepositSettlement = (id) => ({
  type: APPROVED_DEPOSIT_SETTLEMENT,
  id,
});
export const approveDepositSettlementSuccess = (payload) => ({
  type: APPROVED_DEPOSIT_SETTLEMENT_SUCCESS,
  payload,
});
export const approveDepositSettlementFailure = (errors) => ({
  type: APPROVED_DEPOSIT_SETTLEMENT_FAILURE,
  errors,
});
export const approveDepositSettlementClear = () => ({
  type: APPROVED_DEPOSIT_SETTLEMENT_CLEAR,
});
