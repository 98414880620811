import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { landlordApi } from 'utils/api';
import {
  GET_LANDLORD,
  getLandlordSuccess,
  getLandlordFailure
} from './getLandlord.actions';

function* getLandlordSaga({ id }) {
  try {
    const { data, errors } = yield call(landlordApi.getLandlord, id);

    if (!errors) {
      yield put(getLandlordSuccess(data));
    } else {
      yield put(getLandlordFailure(errors));
    }
  } catch (error) {
    yield put(getLandlordFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(GET_LANDLORD, getLandlordSaga);
}
