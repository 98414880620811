import PropTypes from 'prop-types';
import React from 'react';

const DetailValue = ({ label, value, ...rest }) => {
  return (
    <div className="me-3" {...rest}>
      <b style={{ fontSize: 18, fontWeight: 500 }}>{value}</b> <br />
      <small style={{ color: '#757575' }}>{label}</small>
    </div>
  );
};

DetailValue.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

export default DetailValue;
