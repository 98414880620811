/* eslint no-console: 0 */
const environment = process.env.NODE_ENV;

const config = {
  ApiUrl: '',
  HangfireUrl: '',
  PayPalClientId: '',
  environment
};

if (environment === 'development') {
  config.ApiUrl = 'https://localhost:44304/api/';
  config.HangfireUrl = 'https://localhost:44304/hangfire/';
  config.PayPalClientId =
    'AVhXQ_XCT8BGZlYV1zNxRVj0ToPHZCqaIllE1Hx4-W2CelPfzkq4AKXsEfNgh4o8shLtRqWldVpfmo9z';
} else if (environment === 'production') {
  config.ApiUrl = 'https://student-housing-duisburg-api.azurewebsites.net/api/';
  config.HangfireUrl =
    'https://student-housing-duisburg-api.azurewebsites.net/hangfire/';
  config.PayPalClientId =
    'AZZsNSxGvbpN7S52SCqgnzmRHKqzpnZIGvhqlCZtGUs5aHjEug9LmfwS41Mz--gtJv3HhiG22V4QmtGB';
} else {
  console.error('NODE_ENV is wrong, It is neither development or production.');
}

export default config;
