import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  GET_FLAT_HOUSE,
  GET_FLAT_HOUSE_SUCCESS,
  GET_FLAT_HOUSE_FAILURE
} from './getFlatHouse.actions';

export default createReducer(
  {
    [GET_FLAT_HOUSE]: defaultRequest,
    [GET_FLAT_HOUSE_SUCCESS]: defaultSuccess,
    [GET_FLAT_HOUSE_FAILURE]: defaultError
  },
  defaultInitialState
);
