export const GET_LANDLORD = 'GET_LANDLORD';
export const GET_LANDLORD_SUCCESS = 'GET_LANDLORD_SUCCESS';
export const GET_LANDLORD_FAILURE = 'GET_LANDLORD_FAILURE';
export const GET_LANDLORD_CLEAR = 'GET_LANDLORD_CLEAR';

export const getLandlord = id => ({
  type: GET_LANDLORD,
  id
});
export const getLandlordSuccess = payload => ({
  type: GET_LANDLORD_SUCCESS,
  payload
});
export const getLandlordFailure = errors => ({
  type: GET_LANDLORD_FAILURE,
  errors
});
export const getLandlordClear = () => ({
  type: GET_LANDLORD_CLEAR
});
