export const UPDATE_PHONE_NUMBER = 'UPDATE_PHONE_NUMBER';
export const UPDATE_PHONE_NUMBER_SUCCESS = 'UPDATE_PHONE_NUMBER_SUCCESS';
export const UPDATE_PHONE_NUMBER_FAILURE = 'UPDATE_PHONE_NUMBER_FAILURE';
export const UPDATE_PHONE_NUMBER_CLEAR = 'UPDATE_PHONE_NUMBER_CLEAR';

export const updatePhoneNumber = phoneNumber => ({
  type: UPDATE_PHONE_NUMBER,
  phoneNumber
});
export const updatePhoneNumberSuccess = payload => ({
  type: UPDATE_PHONE_NUMBER_SUCCESS,
  payload
});
export const updatePhoneNumberFailure = errors => ({
  type: UPDATE_PHONE_NUMBER_FAILURE,
  errors
});
export const updatePhoneNumberClear = () => ({
  type: UPDATE_PHONE_NUMBER_CLEAR
});
