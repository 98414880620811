export const DELETE_HOUSE = 'DELETE_HOUSE';
export const DELETE_HOUSE_SUCCESS = 'DELETE_HOUSE_SUCCESS';
export const DELETE_HOUSE_FAILURE = 'DELETE_HOUSE_FAILURE';

export const deleteHouse = id => ({
  type: DELETE_HOUSE,
  id
});
export const deleteHouseSuccess = payload => ({
  type: DELETE_HOUSE_SUCCESS,
  payload
});
export const deleteHouseFailure = errors => ({
  type: DELETE_HOUSE_FAILURE,
  errors
});
