import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState,
  defaultReset
} from 'utils/reducer.helper';

import {
  SET_NEWSLETTER_SUBSCRIPTION,
  SET_NEWSLETTER_SUBSCRIPTION_SUCCESS,
  SET_NEWSLETTER_SUBSCRIPTION_FAILURE,
  SET_NEWSLETTER_SUBSCRIPTION_RESET
} from './setNewsletterSubscription.actions';

export default createReducer(
  {
    [SET_NEWSLETTER_SUBSCRIPTION]: defaultRequest,
    [SET_NEWSLETTER_SUBSCRIPTION_SUCCESS]: defaultSuccess,
    [SET_NEWSLETTER_SUBSCRIPTION_FAILURE]: defaultError,
    [SET_NEWSLETTER_SUBSCRIPTION_RESET]: defaultReset
  },
  defaultInitialState
);
