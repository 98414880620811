export const ADD_FLAT = 'ADD_FLAT';
export const ADD_FLAT_SUCCESS = 'ADD_FLAT_SUCCESS';
export const ADD_FLAT_FAILURE = 'ADD_FLAT_FAILURE';
export const ADD_FLAT_CLEAR = 'ADD_FLAT_CLEAR';

export const addFlat = (symbol, level, description, squareMeters, houseId) => ({
  type: ADD_FLAT,
  symbol,
  level,
  description,
  squareMeters,
  houseId
});
export const addFlatSuccess = payload => ({
  type: ADD_FLAT_SUCCESS,
  payload
});
export const addFlatFailure = errors => ({
  type: ADD_FLAT_FAILURE,
  errors
});
export const addFlatClear = () => ({
  type: ADD_FLAT_CLEAR
});
