export const DELETE_HOUSE_OWNER = 'DELETE_HOUSE_OWNER';
export const DELETE_HOUSE_OWNER_SUCCESS = 'DELETE_HOUSE_OWNER_SUCCESS';
export const DELETE_HOUSE_OWNER_FAILURE = 'DELETE_HOUSE_OWNER_FAILURE';
export const DELETE_HOUSE_OWNER_CLEAR = 'DELETE_HOUSE_OWNER_CLEAR';

export const deleteHouseOwner = id => ({
  type: DELETE_HOUSE_OWNER,
  id
});
export const deleteHouseOwnerSuccess = payload => ({
  type: DELETE_HOUSE_OWNER_SUCCESS,
  payload
});
export const deleteHouseOwnerFailure = errors => ({
  type: DELETE_HOUSE_OWNER_FAILURE,
  errors
});
export const deleteHouseOwnerClear = () => ({
  type: DELETE_HOUSE_OWNER_CLEAR
});
