import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';

const DataPrivacy = () => {
  return (
    <Container className="my-5">
      <div>
        <h1>
          <span style={{ fontWeight: 400 }}>Datenschutzerklärung</span>
        </h1>
        <p>
          <span style={{ fontWeight: 400 }}>
            In dieser Datenschutzerklärung informieren wir, die GetVentured
            GmbH, Sie über die Verarbeitung personenbezogener Daten bei der
            Nutzung unserer Website.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Personenbezogene Daten sind Informationen, die sich auf eine
            identifizierte oder identifizierbare Person beziehen. Hierunter
            fallen vor allem Angaben, die Rückschlüsse auf Ihre Identität
            ermöglichen, beispielsweise Ihr Name, Ihre Telefonnummer, Ihre
            Anschrift oder E-Mail-Adresse. Statistische Daten, die wir
            beispielsweise bei einem Besuch unserer Website erheben und die
            nicht mit Ihrer Person in Verbindung gebracht werden können, fallen
            nicht unter den Begriff des personenbezogenen Datums.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Sie können diese Datenschutzerklärung ausdrucken oder speichern,
            indem Sie die übliche Funktionalität Ihres Browsers nutzen.
          </span>
        </p>
        <h2>
          <span style={{ fontWeight: 400 }}>1. Inhaltsverzeichnis</span>
        </h2>
        <ol>
          <li>
            <span style={{ fontWeight: 400 }}> Inhaltsverzeichnis</span>
          </li>
          <li>
            <span style={{ fontWeight: 400 }}> Ansprechpartner</span>
          </li>
          <li>
            <span style={{ fontWeight: 400 }}>
              {' '}
              Datenverarbeitung auf unserer Website
            </span>
          </li>
        </ol>
        <p>
          <span style={{ fontWeight: 400 }}>
            3.1 Aufruf unserer Website / Zugriffsdaten
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>3.2 Kontaktaufnahme</span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>3.3 Registrierung</span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>3.4 Buchung eines Zimmers</span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>3.5 Bewerbungen</span>
        </p>
        <ol start={4}>
          <li>
            <span style={{ fontWeight: 400 }}>
              {' '}
              Einsatz von Cookies und vergleichbaren Technologien
            </span>
          </li>
        </ol>
        <p>
          <span style={{ fontWeight: 400 }}>4.1 Übersicht</span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            4.2 Rechtsgrundlage und Widerruf
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>4.3 Notwendige Tools</span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>4.4 Tools zur Bezahlung</span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>4.5 Analyse-Tools</span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>4.6 Marketing-Tools</span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            4.7 Social-Media-Plugins und externe Medien
          </span>
        </p>
        <ol start={5}>
          <li>
            <span style={{ fontWeight: 400 }}>
              {' '}
              Onlinepräsenzen in sozialen Netzwerken
            </span>
          </li>
        </ol>
        <p>
          <span style={{ fontWeight: 400 }}>5.1 Facebook-Fanpage</span>
        </p>
        <ol start={6}>
          <li>
            <span style={{ fontWeight: 400 }}> Weitergabe von Daten</span>
          </li>
        </ol>
        <p>
          <span style={{ fontWeight: 400 }}>
            6.1 Betrieb unserer Server durch Amazon Web Services
          </span>
        </p>
        <ol start={7}>
          <li>
            <span style={{ fontWeight: 400 }}>
              {' '}
              Datenübermittlung in Drittländer
            </span>
          </li>
          <li>
            <span style={{ fontWeight: 400 }}> Speicherdauer</span>
          </li>
          <li>
            <span style={{ fontWeight: 400 }}> Ihre Rechte</span>
          </li>
          <li>
            <span style={{ fontWeight: 400 }}> Datensicherheit</span>
          </li>
          <li>
            <span style={{ fontWeight: 400 }}>
              {' '}
              Bestehen einer automatisierten Entscheidungsfindung
            </span>
          </li>
          <li>
            <span style={{ fontWeight: 400 }}>
              {' '}
              Änderungen der Datenschutzerklärung
            </span>
          </li>
        </ol>
        <h2>
          <span style={{ fontWeight: 400 }}>2. Ansprechpartner</span>
        </h2>
        <p>
          <span style={{ fontWeight: 400 }}>
            Ansprechpartner und sogenannter Verantwortlicher für die
            Verarbeitung Ihrer personenbezogenen Daten bei Besuch dieser Website
            im Sinne der EU-Datenschutzgrundverordnung (DSGVO) ist die
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>GetVentured GmbH</span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>Drosselweg 2a</span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>61130 Nidderau</span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>Deutschland</span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Wenn Sie Fragen oder Anmerkungen zum Thema Datenschutz haben oder
            Sie Ihre Rechte gegenüber uns geltend machen möchten, so stehen wir
            Ihnen gerne unter obiger postalischer Adresse sowie unter{' '}
          </span>
          <span style={{ fontWeight: 400 }}>
            datenschutz@student-housing-duisburg.de
          </span>
          <span style={{ fontWeight: 400 }}>
            {' '}
            zur Verfügung. Unseren Datenschutzbeauftragten erreichen Sie
            postalisch („z.Hd. Datenschutzbeauftragter GetVentured“) unter
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>GetVentured GmbH</span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>Drosselweg 2a</span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>61130 Nidderau</span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>Deutschland</span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            datenschutz@student-housing-duisburg.de
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Wir weisen ausdrücklich darauf hin, dass bei Nutzung dieser
            E-Mail-Adresse die Inhalte nicht ausschließlich von unserem
            Datenschutzbeauftragten zur Kenntnis genommen werden. Wenn Sie
            vertrauliche Informationen austauschen möchten, bitten Sie daher
            zunächst über diese E-Mail-Adresse um direkte Kontaktaufnahme.
          </span>
        </p>
        <h2>
          <span style={{ fontWeight: 400 }}>
            3. Datenverarbeitung auf unserer Website
          </span>
        </h2>
        <h3>
          <span style={{ fontWeight: 400 }}>
            3.1 AUFRUF UNSERER WEBSITE / ZUGRIFFSDATEN
          </span>
        </h3>
        <p>
          <span style={{ fontWeight: 400 }}>
            Bei jeder Nutzung unserer Website erheben wir die Zugriffsdaten, die
            Ihr Browser automatisch übermittelt, um Ihnen den Besuch der Website
            zu ermöglichen. Die Zugriffsdaten umfassen insbesondere:
          </span>
        </p>
        <ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              IP-Adresse des anfragenden Geräts,
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Datum und Uhrzeit der Anfrage,
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Adresse der aufgerufenen Website und der anfragenden Website,
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Angaben über den verwendeten Browser und das Betriebssystem,
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Der Internet-Service-Provider des zugreifenden Systems,
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Online-Kennungen (z. B. Gerätekennungen, Session-IDs).
            </span>
          </li>
        </ul>
        <p>
          <span style={{ fontWeight: 400 }}>
            Die Datenverarbeitung dieser Zugriffsdaten ist erforderlich, um den
            Besuch der Website zu ermöglichen und um die dauerhafte
            Funktionsfähigkeit und Sicherheit unserer Systeme zu gewährleisten.
            Die Zugriffsdaten werden zu den zuvor beschriebenen Zwecken zudem
            zeitweise in internen Logfiles gespeichert, um statistische Angaben
            über die Nutzung unserer Website zu erstellen, um unsere Website im
            Hinblick auf die Nutzungsgewohnheiten unserer Besucher
            weiterzuentwickeln (z. B., wenn der Anteil mobiler Geräte steigt,
            mit denen die Seiten abgerufen werden) und um unsere Website
            allgemein administrativ zu pflegen.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. b DSGVO, sofern der
            Seitenaufruf im Zuge der Anbahnung oder der Durchführung eines
            Vertrags geschieht, und im Übrigen Art. 6 Abs. 1 S. 1 lit. f DSGVO
            aufgrund unseres berechtigten Interesses an der dauerhaften
            Funktionsfähigkeit und Sicherheit unserer Systeme.
          </span>
        </p>
        <h3>
          <span style={{ fontWeight: 400 }}>3.2 KONTAKTAUFNAHME</span>
        </h3>
        <p>
          <span style={{ fontWeight: 400 }}>
            Sie haben die Möglichkeiten, bei Fragen mit uns in Kontakt zu
            treten. Hierzu bieten wir die Möglichkeit, den Support per E-Mail zu
            kontaktieren. Die in diesem Zusammenhang von Ihnen eingegebenen
            Daten verarbeiten wir ausschließlich zum Zwecke der Kommunikation
            mit Ihnen.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Die Rechtsgrundlage ist Art. 6 Abs. 1 lit. b DSGVO, soweit Sie uns
            als registrierter Kunde kontaktieren und Ihre Angaben zur
            Beantwortung Ihrer Anfrage oder zur Anbahnung bzw. Durchführung
            eines Vertrags benötigt werden, und im Übrigen Art. 6 Abs. 1 lit. f
            DSGVO aufgrund unseres berechtigten Interesses, dass Sie mit uns
            Kontakt aufnehmen und wir Ihre Anfrage beantworten können.
          </span>
        </p>
        <h3>
          <span style={{ fontWeight: 400 }}>3.3 REGISTRIERUNG UND PROFIL</span>
        </h3>
        <p>
          <span style={{ fontWeight: 400 }}>
            Sie haben die Möglichkeit, sich für unseren Login-Bereich zu
            registrieren, um den vollen Funktionsumfang unserer Website und
            insbesondere das WG-Angebot nutzen zu können. Bei Registrierung
            werden folgende Daten über die Eingabemaske erhoben: Vorname,
            Nachname, Emailadresse, Passwort. Ohne diese Daten ist eine
            Registrierung nicht möglich. Nach Registrierung und Login können Sie
            in den Einstellungen freiwillig weitere Angaben zu Ihrem Profil
            vornehmen. Wenn sie nach Registrierung Ihr Profil vervollständigen,
            können Sie folgende Daten eingeben: Adresse, Telefon, Beruf,
            Geburtsdatum, Geschlecht, Nationalität.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Alle diese Angaben werden aber bei Buchung eines Zimmers als
            Pflichtangaben angefordert (siehe hierzu nachfolgender Punkt 3.4
            „Buchung eines Zimmers“.) Rechtsgrundlage der Verarbeitung ist Art.
            6 Abs. 1 lit. b DSGVO. Durch die Registrierung erstellen wir eine
            individuelle Kundennummer, einsehbar in Ihrem Profil.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Auch werden die von Ihrem Internet-Service-Provider (ISP) vergebene
            IP-Adresse, das Datum und die Uhrzeit Ihrer Registrierung
            gespeichert. Die Speicherung dieser Daten erfolgt vor dem
            Hintergrund, dass nur so der Missbrauch unserer Dienste verhindert
            werden kann, und diese Daten im Bedarfsfall ermöglichen, begangene
            Straftaten aufzuklären. Rechtsgrundlage der Verarbeitung ist
            insofern Art. 6 Abs. 1 lit. f DSGVO.
          </span>
        </p>
        <h3>
          <span style={{ fontWeight: 400 }}>3.4 BUCHUNG EINES ZIMMERS</span>
        </h3>
        <p>
          <span style={{ fontWeight: 400 }}>
            Damit Sie ein Zimmer buchen können, erheben wir für die
            Vertragsabwicklung notwendige Pflichtangaben:
          </span>
        </p>
        <ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Vor- und Nachname,</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>E-Mailadresse,</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Telefonnummer,</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Adressdaten,</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Nationalität,</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Geschlecht,</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Beruf,</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Geburtsdatum,</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Ausweisnummer,</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Ggf. Zahlungsdaten (hierzu genauer im Abschnitt „Tools zur
              Bezahlung“)
            </span>
          </li>
        </ul>
        <p>
          <span style={{ fontWeight: 400 }}>
            Rechtsgrundlage der Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. b
            DSGVO.
          </span>
        </p>
        <p>&nbsp;</p>
        <h2>
          <span style={{ fontWeight: 400 }}>
            4. Einsatz von Cookies und vergleichbaren Technologien
          </span>
        </h2>
        <p>
          <span style={{ fontWeight: 400 }}>
            Diese Website verwendet Cookies und vergleichbare Technologien
            (zusammengefasst „Tools“), die entweder von uns selbst oder von
            Dritten angeboten werden.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Ein Cookie ist eine kleine Textdatei, die durch den Browser auf
            ihrem Gerät gespeichert wird. Cookies werden nicht dazu eingesetzt,
            um Programme auszuführen oder Viren auf Ihren Computer zu laden. Die
            meisten Browser sind standardmäßig so eingestellt, dass sie Cookies
            akzeptieren. Sie können jedoch Ihre Browsereinstellungen so
            anpassen, dass Cookies abgelehnt oder nur nach vorheriger
            Einwilligung gespeichert werden. Wenn Sie Cookies ablehnen, können
            nicht alle unsere Angebote für Sie störungsfrei funktionieren.
            Ähnliche Technologien sind insbesondere Fingerprints, Web-Beacons,
            Tags oder Pixels.
          </span>
        </p>
        <h3>
          <span style={{ fontWeight: 400 }}>4.1 ÜBERSICHT</span>
        </h3>
        <p>
          <span style={{ fontWeight: 400 }}>
            Im Folgenden werden die von uns eingesetzten Tools nach Kategorien
            geordnet aufgeführt, wobei wir Sie insbesondere über die Anbieter
            der Tools, die Speicherdauer der Cookies und die Weitergabe der
            Daten an Dritte informieren. Außerdem wird erläutert, in welchen
            Fällen wir Ihre freiwillige Einwilligung zur Nutzung der Tools
            einholen und wie Sie diese widerrufen können.
          </span>
        </p>
        <h3>
          <span style={{ fontWeight: 400 }}>
            4.2 RECHTSGRUNDLAGE UND WIDERRUF
          </span>
        </h3>
        <h4>
          <span style={{ fontWeight: 400 }}>4.2.1 Rechtsgrundlage</span>
        </h4>
        <p>
          <span style={{ fontWeight: 400 }}>
            Für den Websitebetrieb notwendige Tools sowie weitere, bei denen es
            explizit genannt ist, verwenden wir aufgrund unseres berechtigten
            Interesses gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO, um Ihnen eine
            komfortablere und individuellere Nutzung unserer Website zu
            ermöglichen und die Nutzung so zeitsparend wie möglich zu gestalten.
            In bestimmten Fällen können diese Tools auch für die Erfüllung eines
            Vertrags oder zur Durchführung vorvertraglicher Maßnahmen
            erforderlich sein, dann erfolgt die Verarbeitung gemäß Art. 6 Abs. 1
            S. 1 lit. b DSGVO.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Alle anderen Tools, insbesondere solche für Marketingzwecke,
            verwenden wir aufgrund Ihrer Einwilligung gemäß Art. 6 Abs. 1 S. 1
            lit. a DSGVO sowie gemäß § 15 Abs. 3 S. 1 TMG, sofern
            Nutzungsprofile zu Zwecken der Werbung oder Marktforschung erstellt
            werden. Eine Datenverarbeitung mithilfe dieser Tools findet nur
            statt, wenn wir Ihre Einwilligung hierfür erhalten haben.
          </span>
        </p>
        <h4>
          <span style={{ fontWeight: 400 }}>
            4.2.2 Einholung Ihrer Einwilligung
          </span>
        </h4>
        <p>
          <span style={{ fontWeight: 400 }}>
            Für die Einholung und das Management Ihrer Einwilligungen nutzen wir
            das Tool „Cookiebot“, ein Produkt der Cybot A/S, Havnegade 39, 1058
            Kopenhagen, Dänemark (nachfolgend „Cookiebot“).
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Dieses erzeugt ein Banner, welches Sie über die Datenverarbeitung
            auf unserer Website informiert und Ihnen die Möglichkeit gibt,
            allen, einzelnen oder keinen Datenverarbeitungen durch optionale
            Tools zuzustimmen. Dieses Banner erscheint beim ersten Besuch
            unserer Website und wenn Sie die Auswahl Ihrer Einstellungen erneut
            aufrufen, um Sie zu ändern oder Einwilligungen zu widerrufen. Das
            Banner erscheint außerdem bei weiteren Besuchen unserer Website,
            sofern Sie die Speicherung von Cookies deaktiviert haben oder das
            Cookie von Cookiebot gelöscht wurde oder abgelaufen ist. An
            Cookiebot werden im Rahmen Ihres Websitebesuches übermittelt:
          </span>
        </p>
        <ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Die IP-Nummer des Endnutzers in anonymisierter Form (die letzten
              drei Ziffern werden auf ‚0’ gesetzt).
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Datum und Uhrzeit der Zustimmung.
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Benutzeragent des Browsers des Endnutzers.
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Die URL, von der die Zustimmung gesendet wurde.
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Ein anonymer, zufälliger und verschlüsselter Key.
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Der Einwilligungsstatus des Endnutzers, der als Nachweis der
              Zustimmung dient.
            </span>
          </li>
        </ul>
        <p>
          <span style={{ fontWeight: 400 }}>
            Cookiebot setzt hierfür ein notwendiges Cookie namens
            „CookieConsent“ ein, um Ihre erteilten Einwilligungen und Widerrufe
            zu speichern. Sofern Sie Ihre Cookies löschen, werden wir Sie bei
            einem späteren Seitenaufruf erneut um Ihre Einwilligung bitten.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Der Key wird für den Nachweis der Einwilligung und für eine Option
            verwendet, mit der überprüft wird, ob der im Browser des Endnutzers
            gespeicherte Zustimmungsstatus im Vergleich zur ursprünglichen
            Zustimmung, die an Cookiebot übermittelt wurde, unverändert ist.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Die Datenverarbeitung durch Cookiebot ist notwendig, um Ihnen das
            rechtlich geforderte Einwilligungs-Management bereitzustellen und
            unseren Dokumentationspflichten nachzukommen. Rechtsgrundlage zur
            Nutzung von Cookiebot ist Art. 6 Abs. 1 S. 1 lit. f DSGVO, begründet
            durch unser Interesse, die gesetzlichen Anforderungen an ein
            Cookie-Consent-Management zu erfüllen. Weitergehende Informationen
            bietet Cookiebot unter den nachfolgenden Links an:{' '}
          </span>
          <a href="https://www.cookiebot.com/de/privacy-policy/">
            <span style={{ fontWeight: 400 }}>
              https://www.cookiebot.com/de/privacy-policy/
            </span>
          </a>
        </p>
        <h4>
          <span style={{ fontWeight: 400 }}>
            4.2.3 Widerruf Ihrer Einwilligung oder Änderung Ihrer Auswahl
          </span>
        </h4>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Sie können Ihre Einwilligung für bestimmte Tools jederzeit
            widerrufen. Mehr dazu unter Abschnitt 9. „Ihre Rechte“. Sie können
            auch die Auswahl der Tools ändern, in deren Nutzung sie einwilligen
            möchten.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Alternativ können Sie Ihren Widerruf bei bestimmten Tools direkt
            beim Anbieter geltend machen.
          </span>
        </p>
        <h3>
          <span style={{ fontWeight: 400 }}>4.3 NOTWENDIGE TOOLS</span>
        </h3>
        <p>
          <span style={{ fontWeight: 400 }}>
            Wir verwenden bestimmte Tools, um die grundlegenden Funktionen
            unserer Website zu ermöglichen („notwendige Tools“). Ohne diese
            Tools könnten wir unseren Dienst nicht zur Verfügung stellen.
            Deshalb werden notwendige Tools ohne Einwilligung aufgrund unserer
            berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO oder
            zur Erfüllung eines Vertrags oder zur Durchführung vorvertraglicher
            Maßnahmen gemäß Art. 6 Abs. 1 S. 1 lit. b DSGVO verwendet.
          </span>
        </p>
        <h4>
          <span style={{ fontWeight: 400 }}>4.3.1 Eigene Cookies</span>
        </h4>
        <p>
          <span style={{ fontWeight: 400 }}>
            Wir verwenden eigene notwendige Cookies insbesondere
          </span>
        </p>
        <ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              zur Login-Authentifizierung,
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>zur Lastverteilung,</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              um Ihre Spracheinstellungen zu speichern,
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              um zu vermerken, dass Ihnen eine auf unserer Website platzierte
              Informationen angezeigt wurde ­– sodass diese beim nächsten Besuch
              der Website nicht erneut angezeigt wird.
            </span>
          </li>
        </ul>
        <h3>
          <span style={{ fontWeight: 400 }}>4.4 TOOLS ZUR BEZAHLUNG</span>
        </h3>
        <h4>
          <span style={{ fontWeight: 400 }}>
            4.4.1 Paypal als verwendete Zahlungsart
          </span>
        </h4>
        <p>
          <span style={{ fontWeight: 400 }}>
            Sie können bei uns mit dem Zahlungsservice von PayPal bezahlen.
            Anbieter für den europäischen Betrieb ist PayPal (Europe) S.à.r.l.
            &amp; Cie. S.C.A., 22-24 Boulevard Royal, 2449 Luxembourg.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Bei Ihrer Auswahl von Paypal als Zahlungsmethode werden Ihre für den
            Bezahlvorgang erforderlichen Daten an PayPal übermittelt.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Folgende Daten werden regelmäßig erhoben:
          </span>
        </p>
        <ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Name</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Adresse</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Firma</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>E-Mail-Adresse</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Telefon- und Mobilnummer</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>IP-Adresse</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Paypal-Zahler-ID</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Konto-Verifzierungsstatus und Land des Paypal-Accounts
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Informationen über die Buchung bzw. Bezahlung (Betrag, Betreff,
              Zahlungsempfänger, Datum)
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Technische Informationen (Browser, Gerätetyp)
            </span>
          </li>
        </ul>
        <p>
          <span style={{ fontWeight: 400 }}>
            Folgende Daten werden zur Abwicklung der Zahlung durch GetVentured
            verarbeitet:
          </span>
        </p>
        <ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>E-Mail-Adresse</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Telefon- und Mobilnummer</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>IP-Adresse</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Informationen über die Buchung bzw. Bezahlung (Betrag, Betreff,
              Zahlungsempfänger, Datum)
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Technische Informationen (Browser, Gerätetyp)
            </span>
          </li>
        </ul>
        <p>
          <span style={{ fontWeight: 400 }}>
            Die an PayPal übermittelten Daten werden von PayPal unter Umständen
            an Wirtschaftsauskunfteien übermittelt. Diese Übermittlung bezweckt
            die Identitäts- und Bonitätsprüfung.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            PayPal gibt Ihre Daten möglicherweise auch an Dritte weiter, soweit
            dies zur Erfüllung der vertraglichen Verpflichtungen erforderlich
            ist oder die Daten im Auftrag verarbeitet werden sollen. Die
            Datenschutzbestimmungen von PayPal können Sie unter{' '}
          </span>
          <a href="https://www.paypal.com/de/webapps/mpp/ua/privacy-full/">
            <span style={{ fontWeight: 400 }}>
              https://www.paypal.com/de/webapps/mpp/ua/privacy-full/
            </span>
          </a>
          <span style={{ fontWeight: 400 }}> .</span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 b)
            DSGVO, da die Verarbeitung für die Zahlung und damit die
            Durchführung des Vertrages erforderlich ist.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Die in diesem Zusammenhang anfallenden Daten können von Paypal zur
            Auswertung an einen Server in den USA übertragen und dort
            gespeichert werden. Für den Fall, dass personenbezogene Daten in die
            USA übertragen werden, stützen wir uns auf die Rechtsgrundlage des
            Art. 49 Abs. 1 S. 1 lit. b DSGVO, da es mit der Zahlung um die
            Erfüllung eines Vertrags geht. Die damit einhergehenden Risiken
            entnehmen Sie bitte Ziffer 7 („Datenübermittlung in Drittländer“).
          </span>
        </p>
        <h4>
          <span style={{ fontWeight: 400 }}>
            4.4.2 Sofortüberweisung als verwendete Zahlungsart
          </span>
        </h4>
        <p>
          <span style={{ fontWeight: 400 }}>
            Wir bieten ebenfalls die Zahlung per Sofortüberweisung an. Die Daten
            werden in diesem Fall von der Sofort GmbH, Theresienhöhe 12, 80339
            München, erhoben.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>Dies betrifft folgende Daten:</span>
        </p>
        <ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Name des Kunden</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Bankdaten (Name der Bank, IBAN, BIC)
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Betreff der Zahlung</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>IP-Adresse</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Informationen über die Buchung bzw. Bezahlung (Betrag, Betreff,
              Zahlungsempfänger, Datum)
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Technische Informationen (Browser, Gerätetyp)
            </span>
          </li>
        </ul>
        <p>
          <span style={{ fontWeight: 400 }}>
            Zudem verarbeiten wir die Daten über die Dienste von Adyen N.V.,
            Simon Carmiggeltstraat 6-50 1011 DJ, Amsterdam, Niederlande, um die
            Zahlung zu verwalten.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Die Zahlungsdaten werden vom Dienstleister erhoben und von der
            GetVentured zur Zahlungsabwicklung verarbeitet.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Zusätzlich hierzu verarbeitet die Sofort GmbH die IBAN, PIN und ggf.
            TAN Ihres Online-Banking-Accounts, damit Sie sich in Ihr
            Online-Konto einloggen können.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Bei Auswahl dieser Zahlungsmethode erfolgt eine Weiterleitung auf
            die Buchungsseite sofort.de, um die Zahlung abzuschließen.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Nähere Hinweise zu den gespeicherten Daten erhalten Sie unter{' '}
          </span>
          <a href="https://www.sofort.de/datenschutz.html">
            <span style={{ fontWeight: 400 }}>
              https://www.sofort.de/datenschutz.html
            </span>
          </a>
          <span style={{ fontWeight: 400 }}> bzw. unter </span>
          <a href="https://www.adyen.com/de_DE/richtlinien-und-haftungsausschluss/privacy-policy">
            <span style={{ fontWeight: 400 }}>
              https://www.adyen.com/de_DE/richtlinien-und-haftungsausschluss/privacy-policy
            </span>
          </a>
          <span style={{ fontWeight: 400 }}>.</span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 b)
            DSGVO, da die Verarbeitung für die Zahlung und damit die
            Durchführung des Vertrages erforderlich ist.
          </span>
        </p>
        <h4>
          <span style={{ fontWeight: 400 }}>
            4.4.3 Kreditkarte als verwendete Zahlungsart
          </span>
        </h4>
        <p>
          <span style={{ fontWeight: 400 }}>
            Sofern Sie mittels Kreditkarte zahlen, erhebt Adyen N.V., Simon
            Carmiggeltstraat 6-50 1011 DJ, Amsterdam, Niederlande folgende
            Daten:
          </span>
        </p>
        <ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Name des Kreditkarteninhabers
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Kreditkartennummer</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Gültigkeitsdauer der Kreditkarte
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Kartentyp</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Authorisierungscode</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>CVC/CVV-Code</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Informationen über die Buchung bzw. Bezahlung (Betrag, Betreff,
              Zahlungsempfänger, Datum)
            </span>
          </li>
        </ul>
        <p>
          <span style={{ fontWeight: 400 }}>
            Die Zahlungsdaten werden vom Dienstleister erhoben und von der
            GetVentured zur Zahlungsabwicklung verarbeitet.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            GetVentured wahrt bei der Zahlungsabwicklung über Adyen höchste
            Sicherheits- und Datenschutzvorgaben.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Mehr Informationen erhalten Sie in der Datenschutzerklärung des
            Dienstleisters unter:{' '}
          </span>
          <a href="https://www.adyen.com/de_DE/richtlinien-und-haftungsausschluss/privacy-policy">
            <span style={{ fontWeight: 400 }}>
              https://www.adyen.com/de_DE/richtlinien-und-haftungsausschluss/privacy-policy
            </span>
          </a>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 b)
            DSGVO, da die Verarbeitung für die Zahlung und damit die
            Durchführung des Vertrages erforderlich ist.
          </span>
        </p>
        <h4>
          <span style={{ fontWeight: 400 }}>
            4.4.4 Lastschrift als verwendete Zahlungsart
          </span>
        </h4>
        <p>
          <span style={{ fontWeight: 400 }}>
            Sofern Sie sich für das SEPA-Lastschriftverfahren entscheiden,
            werden die folgenden Zahlungsdaten direkt bei Adyen erhoben:
          </span>
        </p>
        <ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Name des Kontoinhabers</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>IBAN</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Informationen über die Buchung bzw. Bezahlung (Betrag, Betreff,
              Zahlungsempfänger, Datum)
            </span>
          </li>
        </ul>
        <p>
          <span style={{ fontWeight: 400 }}>
            Die Zahlungsdaten werden vom Dienstleister erhoben und von der
            GetVentured zur Zahlungsabwicklung verarbeitet.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            GetVentured wahrt bei der Zahlungsabwicklung über Adyen höchste
            Sicherheits- und Datenschutzvorgaben.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Mehr Informationen erhalten Sie in der Datenschutzerklärung des
            Dienstleisters unter:{' '}
          </span>
          <a href="https://www.adyen.com/de_DE/richtlinien-und-haftungsausschluss/privacy-policy">
            <span style={{ fontWeight: 400 }}>
              https://www.adyen.com/de_DE/richtlinien-und-haftungsausschluss/privacy-policy
            </span>
          </a>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 b)
            DSGVO, da die Verarbeitung für die Zahlung und damit die
            Durchführung des Vertrages erforderlich ist.
          </span>
        </p>
        <h3>
          <span style={{ fontWeight: 400 }}>4.5 ANALYSE-TOOLS</span>
        </h3>
        <h4>
          <span style={{ fontWeight: 400 }}>4.5.1 Hotjar</span>
        </h4>
        <p>
          <span style={{ fontWeight: 400 }}>
            Unsere Website verwendet Hotjar, einen Webanalysedienst der Hotjar
            Ltd., Elia Zammit Street 3, St Julians STJ 1000, Malta („Hotjar“).
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Mit Hotjar werden sogenannte Heat Maps erstellt. Heat Maps stellen
            Statistiken über Mausbewegungen und Klicks auf unserer Seite
            grafisch dar, um unsere Website hinsichtlich Ihres Nutzerverhaltens
            zu analysieren. Hiermit können wir oft genutzte Funktionen unserer
            Website erkennen und die Seite weiter verbessern. Ihre IP-Adresse
            wird jedoch vor der Auswertung der Nutzungsstatistiken gekürzt, so
            dass keine Rückschlüsse auf Ihre Identität erfolgen können. Neben
            Mausbewegungen und Klicks werden Informationen zum Betriebssystem,
            Browser, eingehende und ausgehende Verweise (Links), geografischer
            Herkunft sowie Auflösung und Art des Geräts zu statistischen Zwecken
            ausgewertet. Diese Informationen sind pseudonym und werden von uns
            oder von Hotjar nicht an Dritte weitergegeben. Daten, die Sie auf
            Formularfeldern in unserer Website eingegeben, werden ausgeblendet
            und nicht mittels Hotjar erfasst.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Rechtsgrundlage ist Ihre Einwilligung nach Art. 6 Abs. 1 lit. a
            DSGVO. Für den Widerruf Ihrer Einwilligung siehe 4.2.3: „Widerruf
            Ihrer Einwilligung oder Änderung Ihrer Auswahl“
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Nähere Informationen hierzu finden Sie auch in den{' '}
          </span>
          <a href="https://www.hotjar.com/privacy">
            <span style={{ fontWeight: 400 }}>
              Datenschutzbestimmungen von Hotjar
            </span>
          </a>
          <span style={{ fontWeight: 400 }}>.</span>
        </p>
        <h3>
          <span style={{ fontWeight: 400 }}>4.6 MARKETING-TOOLS</span>
        </h3>
        <h4>
          <span style={{ fontWeight: 400 }}>
            4.6.1 Google Ads Conversion-Tracking und Remarketing
          </span>
        </h4>
        <p>
          <span style={{ fontWeight: 400 }}>
            Unsere Websites nutzen die Dienste „Ads Conversion-Tracking“ und
            „Ads Remarketing“, die für Nutzer aus dem Europäischen
            Wirtschaftsraum und der Schweiz von der Google Ireland Limited,
            Gordon House, Barrow Street, Dublin 4, Irland und für alle übrigen
            Nutzer von Google LLC 1600 Amphitheatre Parkway Mountain View, CA
            94043, USA (zusammen „Google“) angeboten werden.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Mittels „Ads Conversion-Tracking“ werden von uns definierte
            Kundenaktionen erfasst (wie beispielweise das Klicken auf eine
            Anzeige, Seitenaufrufe, Downloads) und analysiert. „Ads Remarketing“
            nutzen wir, um Ihnen individualisierte Werbebotschaften für unsere
            Produkte auf Partnerwebsites von Google anzuzeigen. Beide Dienste
            setzen dafür Cookies und ähnliche Technologien ein.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Die in diesem Zusammenhang anfallenden Daten können von Google zur
            Auswertung an einen Server in den USA übertragen und dort
            gespeichert werden. Für den Fall, dass personenbezogene Daten in die
            USA übertragen werden, haben wir mit Google Standardvertragsklauseln
            abgeschlossen und holen über das Cookie-Banner für diese
            Datenübermittlung Ihre ausdrückliche Einwilligung gemäß Art. 49 Abs.
            1 S. 1 lit. a DSGVO ein. Die damit einhergehenden Risiken entnehmen
            Sie bitte Ziffer 3. („Datenübermittlung in Drittländer“).
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Falls Sie ein Google-Konto verwenden, kann Google je nach den im
            Google-Konto hinterlegten Einstellungen Ihren Web- und
            App-Browserverlauf mit Ihrem Google-Konto verknüpfen und
            Informationen aus Ihrem Google-Konto zum Personalisieren von
            Anzeigen verwenden. Wenn Sie diese Zuordnung zu Ihrem Google-Konto
            nicht wünschen, ist es erforderlich, dass Sie sich vor dem Aufruf
            unserer Website bei Google ausloggen.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Wenn Sie der Verwendung von Google Ads nicht zugestimmt haben, wird
            Google nur noch generelle Werbung anzeigen, die nicht anhand der
            über Sie erfassten Informationen auf dieser Website ausgewählt
            wurde. Neben dem Widerruf Ihrer Einwilligung haben Sie zudem die
            Möglichkeit, personalisierte Werbung in den{' '}
          </span>
          <a href="https://adssettings.google.com/authenticated">
            <span style={{ fontWeight: 400 }}>Einstellungen für Werbung</span>
          </a>
          <span style={{ fontWeight: 400 }}> bei Google zu deaktivieren.</span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Folgende Cookies werden durch Google gesetzt:
          </span>
        </p>
        <ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>„_gcl_au“ für 90 Tage</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              „ads/ga-audiences“ bis zum Ende der Sitzung
            </span>
          </li>
        </ul>
        <p>
          <span style={{ fontWeight: 400 }}>
            Nähere Informationen hierzu finden Sie in den{' '}
          </span>
          <a href="https://www.google.com/policies/technologies/ads">
            <span style={{ fontWeight: 400 }}>Hinweisen zur Datennutzung</span>
          </a>
          <span style={{ fontWeight: 400 }}> und der </span>
          <a href="https://www.google.de/privacy.html">
            <span style={{ fontWeight: 400 }}>Datenschutzerklärung</span>
          </a>
          <span style={{ fontWeight: 400 }}> von Google.</span>
        </p>
        <h4>
          <span style={{ fontWeight: 400 }}>
            4.6.2 Google Ad Manager (ehemals DoubleClick)
          </span>
        </h4>
        <p>
          <span style={{ fontWeight: 400 }}>
            Unsere Website verwendet den Google Ad Manager, durch den Dienste
            für Nutzer aus dem Europäischen Wirtschaftsraum und der Schweiz von
            der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
            Irland und für alle übrigen Nutzer von der Google LLC, 1600
            Amphitheatre Parkway, Mountain View, CA 94043, USA (zusammen
            „Google“) angeboten werden.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Diese Dienste verwenden Cookies und ähnliche Technologien, um Ihnen
            für Sie relevante Werbeanzeigen zu präsentieren. Die Verwendung der
            Dienste ermöglicht Google und seinen Partner-Websites die Schaltung
            von Anzeigen auf Basis vorheriger Besuche auf unserer oder anderen
            Websites im Internet.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Die in diesem Zusammenhang anfallenden Daten können von Google zur
            Auswertung an einen Server in den USA übertragen und dort
            gespeichert werden. Für den Fall, dass personenbezogene Daten in die
            USA übertragen werden, haben wir mit Google Standardvertragsklauseln
            abgeschlossen und holen über das Cookie-Banner für diese
            Datenübermittlung Ihre ausdrückliche Einwilligung gemäß Art. 49 Abs.
            1 S. 1 lit. a DSGVO ein. Die damit einhergehenden Risiken entnehmen
            Sie bitte Ziffer 3. („Datenübermittlung in Drittländer“).
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Wenn Sie der Verwendung von Ad Manager nicht zugestimmt haben, wird
            Google nur noch generelle Werbung anzeigen, die nicht anhand der
            über Sie erfassten Informationen auf dieser Website ausgewählt
            wurde. Neben dem Widerruf Ihrer Einwilligung haben Sie zudem die
            Möglichkeit, personalisierte Werbung in den{' '}
          </span>
          <a href="https://adssettings.google.com/authenticated">
            <span style={{ fontWeight: 400 }}>Einstellungen für Werbung</span>
          </a>
          <span style={{ fontWeight: 400 }}> bei Google zu deaktivieren.</span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Nähere Informationen hierzu finden Sie in der{' '}
          </span>
          <a href="https://policies.google.com/?hl=de">
            <span style={{ fontWeight: 400 }}>Datenschutzerklärung</span>
          </a>
          <span style={{ fontWeight: 400 }}> von Google.</span>
        </p>
        <h4>
          <span style={{ fontWeight: 400 }}>4.6.3 Hubspot</span>
        </h4>
        <p>
          <span style={{ fontWeight: 400 }}>
            Wir verwenden auf unserer Website die Dienste der
            Marketing-Plattform „HubSpot“, eines US-Anbieters mit der
            europäischen Niederlassung HubSpot Ireland Limited in 2nd Floor 30
            North Wall Quay, Dublin 1, Ireland.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Die Dienste nutzen wir zu Zwecken des Marketings, insbesondere zur
            Gewinnung neuer Kunden („Lead-Generierung“), bzw. damit wir Reports
            über den Besuch und die besuchten Seiten generieren können.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>Hierzu zählen unter anderem:</span>
        </p>
        <ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              das E-Mail-Marketing, welches den Versand von Newsletter sowie
              automatisierte Mailings regelt,
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Social Media Publishing und Reporting,
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Kontaktmanagement wie die Nutzersegmentierung und
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              CRM, Landing Pages und Kontaktformulare
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Ticketsystem</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Chatbots und Live-Chat</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Feedback-Umfragen</span>
          </li>
        </ul>
        <p>
          <span style={{ fontWeight: 400 }}>
            HubSpot verwendet Cookies, kleine Textdateien, die lokal im
            Zwischenspeicher Ihres Webbrowsers auf Ihrem Endgerät gespeichert
            werden und eine Analyse Ihrer Benutzung der Website ermöglichen.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Folgende Informationen wertet HubSpot im Auftrag von uns aus:
          </span>
        </p>
        <ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>IP-Adresse</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>der geographische Standort</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Art des Browsers</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Dauer des Besuchs</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>aufgerufene Seiten</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Durchschnittliche Seitenaufrufe
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Dauer Zeit der ersten Sitzung
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Dauer der letzten Sitzung</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>IP Land und Region</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>IP Zeitzone</span>
          </li>
        </ul>
        <p>
          <span style={{ fontWeight: 400 }}>
            Mittels HubSpot erfasste Informationen sowie die Inhalte unserer
            Website werden auf Servern der Service-Providern von HubSpot
            gespeichert.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Soweit Sie hierzu Ihre Einwilligung nach Art. 6 Abs. 1 S. 1 lit. a
            DSGVO erteilt haben, erfolgt die Verarbeitung auf dieser Website zum
            Zweck der Webseitenanalyse.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Die in diesem Zusammenhang anfallenden Daten können von Hubspot zur
            Auswertung an einen Server in den USA übertragen und dort
            gespeichert werden. Für den Fall, dass personenbezogene Daten in die
            USA übertragen werden, holen wir über das Cookie-Banner für diese
            Datenübermittlung Ihre ausdrückliche Einwilligung gemäß Art. 49 Abs.
            1 S. 1 lit. a DSGVO ein. Die damit einhergehenden Risiken entnehmen
            Sie bitte Ziffer 7 („Datenübermittlung in Drittländer“).
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Sie können der Erfassung der Daten durch HubSpot und der Setzung von
            Cookies dauerhaft widersprechen, indem Sie die Speicherung von
            Cookies durch Ihre Browsereinstellungen entsprechend verhindern. Sie
            können gegen die Verarbeitung Ihrer personenbezogenen Daten auch mit
            Wirkung für die Zukunft Widerspruch einlegen, indem Sie eine E-Mail
            an{' '}
          </span>
          <span style={{ fontWeight: 400 }}>
            datenschutz@student-housing-duisburg.de
          </span>
          <span style={{ fontWeight: 400 }}> schicken.</span>
        </p>
        <h3>
          <span style={{ fontWeight: 400 }}>
            4.7 SOCIAL-MEDIA-PLUGINS UND EXTERNE MEDIEN
          </span>
        </h3>
        <p>
          <span style={{ fontWeight: 400 }}>
            Wir verwenden darüber hinaus Tools der sozialen Netzwerke, die dazu
            dienen, sich mit den vorhandenen Benutzerkonten auf der Website
            anzumelden oder Beiträge und Inhalte über diese Netzwerke zu teilen
            („Social-Media-Plugins“), sowie andere externe Medien, wie
            eingebundene Videos. Rechtsgrundlage hierfür ist Ihre Einwilligung
            gemäß Art. 6 Abs. 1 S. 1 lit. a DSGVO, die Sie über das
            Cookie-Banner oder bei dem jeweiligen Tool selbst abgeben, indem Sie
            dessen Nutzung über ein darüber gelegtes Banner (Overlay) einzeln
            erlauben. Für den Widerruf Ihrer Einwilligung siehe 4.2.3: „Widerruf
            Ihrer Einwilligung oder Änderung Ihrer Auswahl“
          </span>
        </p>
        <h4>
          <span style={{ fontWeight: 400 }}>
            4.7.1 Anmelden mit Benutzerkonten aus sozialen Netzwerken
          </span>
        </h4>
        <p>
          <span style={{ fontWeight: 400 }}>
            Unsere Website bietet Ihnen die Möglichkeit, sich mit einem
            bestehenden Benutzerkonto der nachfolgend aufgeführten sozialen
            Netzwerke anzumelden:
          </span>
        </p>
        <ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Facebook Login: Facebook Ireland Limited, 4 Grand Canal Square,
              Grand Canal Harbour, Dublin 2, Irland (für Nutzer außerhalb der
              USA und Kanada) bzw. Facebook Inc., 1601 Willow Road, Menlo Park,
              California 94025, USA (für alle übrigen Nutzer) –{' '}
            </span>
            <a href="https://www.facebook.com/about/privacy/">
              <span style={{ fontWeight: 400 }}>Datenschutzhinweise</span>
            </a>
          </li>
        </ul>
        <p>
          <span style={{ fontWeight: 400 }}>
            Sobald Sie sich mit einem Ihrer bestehenden Benutzerkonten
            angemeldet haben, ist eine zusätzliche Registrierung nicht mehr
            erforderlich. Sofern Sie die Funktion nutzen möchten, werden Sie
            zunächst zu dem betreffenden sozialen Netzwerk weitergeleitet. Dort
            werden Sie aufgefordert, sich mit Ihrem Benutzernamen und Ihrem
            Passwort anzumelden. Wir nehmen von diesen Anmeldedaten
            selbstverständlich keine Kenntnis.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Mit Bestätigung des entsprechenden Anmelde-Buttons auf unserer
            Website erfährt das entsprechende soziale Netzwerk, dass Sie sich
            auf unserer Seite mit Ihrem Benutzerkonto angemeldet haben, und
            verknüpft Ihr Benutzerkonto mit Ihrem Kundenkonto auf unserer
            Website. Außerdem werden folgende Daten an uns übermittelt:
          </span>
        </p>
        <ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Facebook Login: E-Mail-Adresse, öffentliche Profilinformationen
              (insbesondere Facebook-ID, Name, Profilbild), ggf. weitere
              Profilinformationen wie Alter, Geburtsdatum, Facebook-Freunde,
              Geschlecht, Wohnort, Beruf, Gefällt-mir-Angaben, Profil-URL,
              Standorte, Beiträge, Fotos, Videos; verwendete Cookies
              insbesondere: „_fbp“
            </span>
          </li>
        </ul>
        <p>
          <span style={{ fontWeight: 400 }}>
            Der Server, zu dem eine Verbindung aufgebaut wird, kann sich in den
            USA befinden. Für den Fall, dass personenbezogene Daten in die USA
            übertragen werden, holen wir über das Cookie-Banner für diese
            Datenübermittlung Ihre ausdrückliche Einwilligung gemäß Art. 49 Abs.
            1 S. 1 lit. a DSGVO ein. Die damit einhergehenden Risiken entnehmen
            Sie bitte Ziffer 7. („Datenübermittlung in Drittländer“).
          </span>
        </p>
        <h2>
          <span style={{ fontWeight: 400 }}>
            5. Onlinepräsenzen in sozialen Netzwerken
          </span>
        </h2>
        <p>
          <span style={{ fontWeight: 400 }}>
            Wir unterhalten Onlinepräsenzen in sozialen Netzwerken, um dort
            unter anderem mit Kunden und Interessenten zu kommunizieren und über
            unsere Produkte und Dienstleistungen zu informieren.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Die Daten der Nutzer werden von den betreffenden sozialen Netzwerken
            in der Regel für Marktforschungs- und Werbezwecke verarbeitet. So
            können Nutzungsprofile anhand der Interessen der Nutzer erstellt
            werden. Zu diesem Zweck werden Cookies und andere Identifier auf den
            Rechnern der Nutzer gespeichert werden. Auf Basis dieser
            Nutzungsprofile werden dann z.B. Werbeanzeigen innerhalb der
            sozialen Netzwerke aber auch auf Websites Dritter geschaltet.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Die Rechtsgrundlage der durch die sozialen Netzwerke in eigener
            Verantwortung erfolgten Datenverarbeitung entnehmen Sie bitte den
            Datenschutzhinweisen des jeweiligen sozialen Netzwerks. Unter den
            nachstehenden Links erhalten Sie zudem weitere Informationen zu den
            jeweiligen Datenverarbeitungen sowie der Widerspruchsmöglichkeiten.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Wir weisen darauf hin, dass Datenschutzanfragen am effizientesten
            bei dem jeweiligen Anbieter des sozialen Netzwerks geltend gemacht
            werden können, da nur diese Anbieter Zugriff auf die Daten haben und
            direkt entsprechende Maßnahmen ergreifen können:
          </span>
        </p>
        <ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Facebook (Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal
              Harbour, Dublin 2, Irland)
            </span>
          </li>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Facebook-Fanpages auf Grundlage einer{' '}
              </span>
              <a href="https://www.facebook.com/legal/terms/page_controller_addendum">
                <span style={{ fontWeight: 400 }}>
                  Vereinbarung über gemeinsame Verarbeitung personenbezogener
                  Daten
                </span>
              </a>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>Datenschutzerklärung: </span>
              <a href="https://www.facebook.com/about/privacy/">
                <span style={{ fontWeight: 400 }}>
                  https://www.facebook.com/about/privacy/
                </span>
              </a>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>Opt-Out: </span>
              <a href="https://www.facebook.com/settings?tab=ads">
                <span style={{ fontWeight: 400 }}>
                  https://www.facebook.com/settings?tab=ads
                </span>
              </a>
              <span style={{ fontWeight: 400 }}> und </span>
              <a href="http://www.youronlinechoices.com/">
                <span style={{ fontWeight: 400 }}>
                  http://www.youronlinechoices.com
                </span>
              </a>
            </li>
          </ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              LinkedIn (LinkedIn Ireland Unlimited Company Wilton Place, Dublin
              2, Irland)
            </span>
          </li>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>Datenschutzerklärung: </span>
              <a href="https://www.linkedin.com/legal/privacy-policy">
                <span style={{ fontWeight: 400 }}>
                  https://www.linkedin.com/legal/privacy-policy
                </span>
              </a>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>Opt-Out: </span>
              <a href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out">
                <span style={{ fontWeight: 400 }}>
                  https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out
                </span>
              </a>
              <span style={{ fontWeight: 400 }}>.</span>
            </li>
          </ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Instagram (Facebook Ireland Ltd., 4 Grand Canal Square, Grand
              Canal Harbour, Dublin 2, Irland)
            </span>
          </li>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>Datenschutzerklärung: </span>
              <a href="https://help.instagram.com/519522125107875">
                <span style={{ fontWeight: 400 }}>
                  https://help.instagram.com/519522125107875
                </span>
              </a>
            </li>
          </ul>
        </ul>
        <h3>
          <span style={{ fontWeight: 400 }}>5.1 FACEBOOK-FANPAGE</span>
        </h3>
        <p>
          <span style={{ fontWeight: 400 }}>
            Wir betreiben eine Seite (Fanpage) auf dem sozialen Netzwerk
            Facebook, das für Nutzer außerhalb der USA und Kanada von der
            Facebook Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour,
            Dublin 2, Irland und für alle übrigen Nutzer von der Facebook Inc.,
            1601 Willow Road, Menlo Park, California 94025, USA (gemeinsam
            „Facebook“) angeboten wird, in gemeinsamer Verantwortung mit
            Facebook, um dort mit Followern (wie z.B. unseren Kunden und
            Interessenten) zu kommunizieren und um über unser Angebot zu
            informieren.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Dabei können wir von Facebook Statistiken zur Nutzung unserer
            Fanpage erhalten (z. B. Angaben über Anzahl, Namen, Interaktionen
            wie Like und Kommentare sowie zusammengefasste demografische und
            andere Informationen oder Statistiken). Nähere Informationen zu Art
            und Umfang dieser Statistiken finden Sie in den{' '}
          </span>
          <a href="https://www.facebook.com/business/pages/manage#page_insights">
            <span style={{ fontWeight: 400 }}>Hinweisen von Facebook</span>
          </a>
          <span style={{ fontWeight: 400 }}> und entsprechenden </span>
          <a href="https://de-de.facebook.com/help/pages/insights">
            <span style={{ fontWeight: 400 }}>Hilfe-Seiten</span>
          </a>
          <span style={{ fontWeight: 400 }}>
            . Weitere Informationen zu den jeweiligen Verantwortlichkeiten
            erhalten Sie in den{' '}
          </span>
          <a href="https://www.facebook.com/legal/terms/page_controller_addendum">
            <span style={{ fontWeight: 400 }}>
              Informationen zu Seiten-Insights
            </span>
          </a>
          <span style={{ fontWeight: 400 }}>.</span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Rechtsgrundlage für diese Datenverarbeitung ist Art. 6 Abs. 1 S. 1
            lit. f DSGVO, beruhend auf unserem vorgenannten berechtigten
            Interesse, bzw. Art. 6 Abs. 1 S. 1 lit. b DSGVO, um mit unseren
            Kunden in Kontakt zu bleiben und sie zu informieren sowie zur
            Durchführung vorvertraglicher Maßnahmen mit künftigen Kunden und
            Interessenten.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Auf Daten, die durch Facebook dabei in eigener Verantwortung nach
            den Nutzungsbedingungen von Facebook verarbeitet werden, haben wir
            keinen Einfluss. Wir weisen aber darauf hin, dass beim Besuch der
            Fanpage Daten von Ihrem Nutzungsverhalten von Facebook und der
            Fanpage zu Facebook übertragen werden. Facebook selbst verarbeitet
            die vorgenannten Informationen, um detailliertere Statistiken zu
            erstellen und für eigene Markforschungs- und Werbezwecke, auf die
            wir keinen Einfluss haben. Nähere Informationen hierzu finden Sie in
            der{' '}
          </span>
          <a href="https://www.facebook.com/about/privacy/">
            <span style={{ fontWeight: 400 }}>Datenrichtlinie</span>
          </a>
          <span style={{ fontWeight: 400 }}> von Facebook.</span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Soweit wir beim Betrieb der Fanpage Ihre personenbezogenen Daten
            erhalten, stehen Ihnen dazu die in dieser Datenschutzerklärung
            genannten Rechte zu. Wenn Sie darüber hinaus Ihre Rechte gegenüber
            Facebook geltend machen wollen, können Sie sich hierfür am
            einfachsten direkt an Facebook wenden. Facebook kennt sowohl die
            Details zum technischen Betrieb der Plattform und die damit
            verbundene Datenverarbeitung als auch die konkreten Zwecke der
            Datenverarbeitung und kann auf Anfrage hin entsprechende Maßnahmen
            umsetzen, wenn Sie von Ihren Rechten Gebrauch machen. Gerne
            unterstützen wir Sie bei der Geltendmachung ihrer Rechte, soweit es
            uns möglich ist und leiten Ihre Anfragen an Facebook weiter.
          </span>
        </p>
        <h2>
          <span style={{ fontWeight: 400 }}>6. Weitergabe von Daten</span>
        </h2>
        <p>
          <span style={{ fontWeight: 400 }}>
            Eine Weitergabe der von uns erhobenen Daten erfolgt grundsätzlich
            nur, wenn:
          </span>
        </p>
        <ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Sie nach Art. 6 Abs. 1 S. 1 lit. a DSGVO Ihre ausdrückliche
              Einwilligung dazu erteilt haben,
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur
              Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
              erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein
              überwiegendes schutzwürdiges Interesse am Unterbleiben der
              Weitergabe Ihrer Daten haben,
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              wir nach Art. 6 Abs. 1 S. 1 lit. c DSGVO zur Weitergabe gesetzlich
              verpflichtet sind oder
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO
              für die Abwicklung von Vertragsverhältnissen mit Ihnen oder für
              die Durchführung vorvertraglicher Maßnahmen erforderlich ist, die
              auf Ihre Anfrage hin erfolgen.
            </span>
          </li>
        </ul>
        <p>
          <span style={{ fontWeight: 400 }}>
            Ein Teil der Datenverarbeitung kann durch unsere Dienstleister
            erfolgen. Neben den in dieser Datenschutzerklärung erwähnten
            Dienstleistern können hierzu insbesondere Rechenzentren, die unsere
            Website und Datenbanken speichern, IT-Dienstleister, die unsere
            Systeme warten, sowie Beratungsunternehmen gehören. Sofern wir Daten
            an unsere Dienstleister weitergeben, dürfen diese die Daten
            ausschließlich zur Erfüllung ihrer Aufgaben verwenden. Die
            Dienstleister wurden von uns sorgfältig ausgewählt und beauftragt.
            Sie sind vertraglich an unsere Weisungen gebunden, verfügen über
            geeignete technische und organisatorische Maßnahmen zum Schutz der
            Rechte der betroffenen Personen und werden von uns regelmäßig
            kontrolliert.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Darüber hinaus kann eine Weitergabe in Zusammenhang mit behördlichen
            Anfragen, Gerichtsbeschlüssen und Rechtsverfahren erfolgen, wenn es
            für die Rechtsverfolgung oder -durchsetzung erforderlich ist.
          </span>
        </p>
        <h3>
          <span style={{ fontWeight: 400 }}>
            6.1 BETRIEB UNSERER SERVER DURCH AMAZON WEB SERVICES
          </span>
        </h3>
        <p>
          <span style={{ fontWeight: 400 }}>
            Ihre Daten werden teilweise auf Servern verarbeitet, die von Amazon
            Web Services zur Verfügung gestellt werden, einem Dienst der Amazon
            Web Services Inc., 410 Terry Avenue North, Seattle, Washington
            98109, USA („AWS“). Über die Server von AWS wird die Verbindung
            ihres Geräts mit den Inhalten in unserer Website hergestellt. Die
            Server, die wir verwenden, befinden sich in der Regel innerhalb des
            Europäischen Wirtschaftsraums (EWR).
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Allerdings können Teile ihrer Daten aus technischen Gründen auch in
            Ländern außerhalb des EWR verarbeitet werden, insbesondere in den
            USA. Um den Schutz Ihrer Daten auch in diesem Fall zu gewährleisten,
            haben wir mit AWS einen speziellen Vertrag abgeschlossen, der den
            Erfordernissen in Hinblick auf die Standardvertragsklauseln der
            Europäischen Kommission entspricht.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DSGVO, beruhend auf
            unserem berechtigten Interesse, Inhalte unserer Website sicher und
            zuverlässig durch externe Dienstleister zu speichern und zugleich
            den eigenen Aufwand für die Bereitstellung der EDV-Infrastruktur
            unserer Website zu reduzieren.
          </span>
        </p>
        <h2>
          <span style={{ fontWeight: 400 }}>
            7. Datenübermittlung in Drittländer
          </span>
        </h2>
        <p>
          <span style={{ fontWeight: 400 }}>
            Wie in dieser Datenschutzerklärung erläutert setzen wir Dienste ein,
            deren Anbieter teilweise in sogenannten Drittländern (wie den USA)
            sitzen, also Ländern, deren Datenschutzniveau nicht dem der
            Europäischen Union entspricht. Soweit dies der Fall ist und die
            Europäische Kommission für diese Länder keinen
            Angemessenheitsbeschluss (Art. 45 DSGVO) erlassen hat, haben wir
            entsprechende Vorkehrungen getroffen, um ein angemessenes
            Datenschutzniveau für etwaige Datenübertragungen zu gewährleisten.
            Hierzu zählen u.a. die Standardvertragsklauseln der Europäischen
            Union oder verbindliche interne Datenschutzvorschriften.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Wo dies nicht möglich ist, stützen wir die Datenübermittlung auf
            Ausnahmen des Art. 49 DSGVO, insbesondere Ihre ausdrückliche
            Einwilligung oder die Erforderlichkeit der Übermittlung zur
            Vertragserfüllung.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Sofern eine Drittstaatenübermittlung vorgesehen ist und kein
            Angemessenheitsbeschluss oder geeignete Garantien vorliegen, ist es
            möglich und besteht das Risiko, dass Behörden im jeweiligen
            Drittland (z.B. Geheimdienste) Zugriff auf die übermittelten Daten
            erlangen können, um diese zu erfassen und zu analysieren, und dass
            eine Durchsetzbarkeit Ihrer Betroffenenrechte nicht gewährleistet
            werden kann. Bei Einholung Ihrer Einwilligung über das Cookie-Banner
            werden Sie hierüber ebenfalls informiert.
          </span>
        </p>
        <h2>
          <span style={{ fontWeight: 400 }}>8. Speicherdauer</span>
        </h2>
        <p>
          <span style={{ fontWeight: 400 }}>
            Grundsätzlich speichern wir personenbezogene Daten nur solange, wie
            zur Erfüllung vertraglicher oder gesetzlicher Pflichten
            erforderlich, zu denen wir die Daten erhoben haben. Danach löschen
            wir die Daten unverzüglich, es sei denn, wir benötigen die Daten
            noch bis zum Ablauf der gesetzlichen Verjährungsfrist zu
            Beweiszwecken für zivilrechtliche Ansprüche oder wegen gesetzlicher
            Aufbewahrungspflichten.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Zu Beweiszwecken müssen wir Vertragsdaten noch drei Jahre ab Ende
            des Jahres, in dem die Geschäftsbeziehungen mit Ihnen enden,
            aufbewahren. Etwaige Ansprüche verjähren nach der gesetzlichen
            Regelverjährungsfrist frühestens zu diesem Zeitpunkt.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Auch danach müssen wir Ihre Daten teilweise noch aus
            buchhalterischen Gründen speichern. Wir sind dazu wegen gesetzlicher
            Dokumentationspflichten verpflichtet, die sich aus dem
            Handelsgesetzbuch, der Abgabenordnung, dem Kreditwesengesetz, dem
            Geldwäschegesetz und dem Wertpapierhandelsgesetz ergeben können. Die
            dort vorgegebenen Fristen zur Aufbewahrung von Unterlagen betragen
            zwei bis zehn Jahre.
          </span>
        </p>
        <h2>
          <span style={{ fontWeight: 400 }}>9. Ihre Rechte</span>
        </h2>
        <p>
          <span style={{ fontWeight: 400 }}>
            Ihnen steht jederzeit das Recht auf Auskunft über die Verarbeitung
            Ihrer personenbezogenen Daten durch uns zu. Wir werden Ihnen in
            diesem Zusammenhang die Datenverarbeitung erläutern und eine
            Übersicht der über Ihre Person gespeicherten Daten zur Verfügung
            stellen.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Falls bei uns gespeicherte Daten falsch oder nicht mehr aktuell sein
            sollten, haben Sie das Recht, diese Daten berichtigen zu lassen. Sie
            können außerdem die Löschung Ihrer Daten verlangen. Eine Löschung
            Ihrer Daten ist grundsätzlich nur möglich, wenn bestimmte
            Voraussetzungen gegeben sind, insbesondere, wenn Daten nicht mehr
            benötigt werden, die Verarbeitung nicht rechtmäßig ist oder sonstige
            Fälle von Art. 17 DSGVO vorliegen. Sollte die Löschung aufgrund
            anderer Rechtsvorschriften ausnahmsweise nicht möglich sein, werden
            die Daten – sofern die erforderlichen Voraussetzungen vorliegen -
            gesperrt, so dass sie nur noch für diesen gesetzlichen Zweck
            verfügbar sind. Sie können die Verarbeitung Ihrer personenbezogenen
            Daten außerdem einschränken lassen, wenn z.B. die Richtigkeit der
            Daten von Ihrer Seite angezweifelt wird.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Ihnen steht unter gewissen Voraussetzungen auch das Recht auf
            Datenübertragbarkeit zu, d.h. dass wir Ihnen auf Wunsch eine
            digitale Kopie der von Ihnen bereitgestellten personenbezogenen
            Daten zukommen lassen.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Um Ihre hier beschriebenen Rechte geltend zu machen, können Sie sich
            jederzeit an die oben genannten Kontaktdaten wenden. Dies gilt auch,
            sofern Sie Kopien von Garantien zum Nachweis eines angemessenen
            Datenschutzniveaus erhalten möchten.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Ihre Anfragen zur Geltendmachung von Datenschutzrechten und unsere
            Antworten darauf werden zu Dokumentationszwecken für die Dauer von
            drei Jahren und im Einzelfall zur Geltendmachung, Ausübung oder
            Verteidigung von Rechtsansprüchen auch darüber hinaus aufbewahrt.
            Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DSGVO, beruhend auf unserem
            Interesse an der Verteidigung gegen etwaige zivilrechtliche
            Ansprüche nach Art. 82 DSGVO, der Vermeidung von Bußgeldern nach
            Art. 83 DSGVO sowie der Erfüllung unserer Rechenschaftspflicht aus
            Art. 5 DSGVO.
          </span>
        </p>
        <p>
          <strong>
            Sie haben das Recht, eine einmal erteilte Einwilligung jederzeit uns
            gegenüber zu widerrufen. Dies hat zur Folge, dass wir die
            Datenverarbeitung, die auf dieser Einwilligung beruhte, für die
            Zukunft nicht mehr fortführen. Durch den Widerruf der Einwilligung
            wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum
            Widerruf erfolgten Verarbeitung nicht berührt.
          </strong>
        </p>
        <p>
          <strong>
            Soweit wir Ihre Daten auf Grundlage von berechtigten Interessen
            verarbeiten, haben Sie das Recht, jederzeit Widerspruch gegen die
            Verarbeitung Ihrer Daten einzulegen aus Gründen, die sich aus Ihrer
            besonderen Situation ergeben. Geht es um einen Widerspruch gegen die
            Datenverarbeitung zu Zwecken der Direktwerbung haben Sie ein
            generelles Widerspruchsrecht, das auch ohne die Angabe von Gründen
            von uns umgesetzt wird.
          </strong>
        </p>
        <p>
          <strong>
            Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch
            machen, genügt eine formlose Mitteilung an die oben genannten
            Kontaktdaten.
          </strong>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Sie haben schließlich das Recht sich bei der für uns zuständigen
            Datenschutzaufsichtsbehörde zu beschweren. Sie können dieses Recht
            bei einer Aufsichtsbehörde in dem Mitgliedstaat Ihres
            Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des mutmaßlichen
            Verstoßes geltend machen.&nbsp;
          </span>
        </p>
        <h2>
          <span style={{ fontWeight: 400 }}>10. Datensicherheit</span>
        </h2>
        <p>
          <span style={{ fontWeight: 400 }}>
            Wir unterhalten aktuelle technische Maßnahmen zur Gewährleistung der
            Datensicherheit, insbesondere zum Schutz Ihrer personenbezogenen
            Daten vor Gefahren bei Datenübertragungen sowie vor
            Kenntniserlangung durch Dritte. Diese werden dem aktuellen Stand der
            Technik entsprechend jeweils angepasst. Zur Sicherung der von Ihnen
            auf unserer Website angegebenen persönlichen Daten verwenden wir den
            Transport Layer Security (TLS), der die von Ihnen eingegebenen
            Informationen verschlüsselt.
          </span>
        </p>
        <h2>
          <span style={{ fontWeight: 400 }}>
            11. Bestehen einer automatisierten Entscheidungsfindung
          </span>
        </h2>
        <p>
          <span style={{ fontWeight: 400 }}>
            Als verantwortungsbewusstes Unternehmen verzichten wir auf eine
            automatische Entscheidungsfindung oder jede Art von Profiling.
          </span>
        </p>
        <h2>
          <span style={{ fontWeight: 400 }}>
            12. Änderungen der Datenschutzerklärung
          </span>
        </h2>
        <p>
          <span style={{ fontWeight: 400 }}>
            Gelegentlich aktualisieren wir diese Datenschutzerklärung,
            beispielsweise wenn wir unsere Website anpassen oder sich die
            gesetzlichen oder behördlichen Vorgaben ändern.
          </span>
        </p>
        <p>&nbsp;</p>
      </div>
    </Container>
  );
};

export default DataPrivacy;
