import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Loader from 'components/Loader';
import Dashboard from './Dashboard';
import * as UserRole from 'utils/constants/userRole.constants';

const DashboardContainer = () => {
  const { t } = useTranslation('dashboard');
  const history = useHistory();

  const { data: user, success } = useSelector(state => state.user);

  useEffect(() => {
    if (!success) {
      return;
    }

    switch (user.role) {
      case UserRole.LANDLORD:
        history.push('/dashboard/data');
        break;
      case UserRole.SERVICEPROVIDER:
        history.push('/dashboard/tickets');
        break;
    }
  }, []);

  const canDoEntryClaimReport = success
    ? user.booking
      ? moment().isBetween(
          moment(user.booking.startDateContract).add(-1, 'days'),
          moment(user.booking.startDateContract).add(5, 'days')
        )
      : false
    : false;

  return (
    <Loader prop={success}>
      <Dashboard
        {...{ t, canDoEntryClaimReport, userRole: user ? user.role : null }}
      />
    </Loader>
  );
};

export default DashboardContainer;
