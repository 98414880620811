export const CREATE_TERMINATION = 'CREATE_TERMINATION';
export const CREATE_TERMINATION_SUCCESS = 'CREATE_TERMINATION_SUCCESS';
export const CREATE_TERMINATION_FAILURE = 'CREATE_TERMINATION_FAILURE';
export const CREATE_TERMINATION_RESET = 'CREATE_TERMINATION_RESET';

export const createTermination = (
  dateToTerminate,
  nextTenantEmail,
  bookingId = null
) => ({
  type: CREATE_TERMINATION,
  dateToTerminate,
  nextTenantEmail,
  bookingId
});
export const createTerminationSuccess = payload => ({
  type: CREATE_TERMINATION_SUCCESS,
  payload
});
export const createTerminationFailure = errors => ({
  type: CREATE_TERMINATION_FAILURE,
  errors
});
export const createTerminationReset = () => ({
  type: CREATE_TERMINATION_RESET
});
