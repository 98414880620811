import React from 'react';
import { AddressFormGroup, FormGroupControl, LoadingButton } from 'components';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ServiceProviderProfile = ({
  address,
  setAddress,
  t,
  email,
  handleEmailChange,
  firstName,
  handleFirstNameChange,
  lastName,
  handleLastNameChange,
  handleSave,
  loading
}) => {
  return (
    <Container>
      <Row>
        <Col style={{ marginTop: '20px', marginBottom: '20px' }}>
          <h1>{t('serviceProviderProfile')}</h1>
        </Col>
      </Row>
      <FormGroupControl
        label={t('email')}
        value={email}
        onChange={handleEmailChange}
        required
        type="email"
      />
      <FormGroupControl
        label={t('firstName')}
        value={firstName}
        onChange={handleFirstNameChange}
        required
      />
      <FormGroupControl
        label={t('lastName')}
        value={lastName}
        onChange={handleLastNameChange}
        required
      />
      <AddressFormGroup
        address={address}
        onChangeAddress={setAddress}
        showCoordinates={false}
      />
      <LoadingButton isLoading={loading} onClick={handleSave}>
        {t('update')}
      </LoadingButton>
    </Container>
  );
};

ServiceProviderProfile.propTypes = {
  address: PropTypes.object.isRequired,
  setAddress: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  handleEmailChange: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
  handleFirstNameChange: PropTypes.func.isRequired,
  lastName: PropTypes.string.isRequired,
  handleLastNameChange: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default ServiceProviderProfile;
