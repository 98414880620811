import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Flat from './Flat';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getFlat } from 'store/flat/getFlat/getFlat.actions';
import { Loader } from 'components';
import { useTranslation } from 'react-i18next';
import { getAllRooms } from 'store/room/getAllRooms/getAllRooms.actions';
import { deleteFlat } from 'store/flat/deleteFlat/deleteFlat.actions';

const FlatContainer = ({ match }) => {
  const { t } = useTranslation('data');
  const dispatch = useDispatch();
  const history = useHistory();
  const flatId = parseInt(match.params.flatId);

  const flats = useSelector(state => state.flats);
  const flat = flats.find(x => x.id === flatId);

  useEffect(() => {
    dispatch(getFlat(flatId));
  }, [flatId]);

  useEffect(() => {
    dispatch(getAllRooms());
  }, []);

  const handleEditFlatClick = () =>
    history.push(`/dashboard/data/flat/${flatId}/edit`);

  const flatSelector = state =>
    state.rooms.filter(room => room.flat.id === flat.id);

  const handleDeleteFlatClick = () => {
    dispatch(deleteFlat(flat.id));
    history.push(`/dashboard/data/flats`);
  };

  return flat !== undefined ? (
    <Loader prop={flat}>
      <Flat
        {...{
          flat,
          handleEditFlatClick,
          handleDeleteFlatClick,
          t,
          flatSelector
        }}
      />
    </Loader>
  ) : (
    <div />
  );
};

FlatContainer.propTypes = { match: PropTypes.object.isRequired };

export default FlatContainer;
