import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { accountApi } from 'utils/api';
import {
  FORGOT_PASSWORD,
  forgotPasswordSuccess,
  forgotPasswordFailure
} from './forgotPassword.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';

function* forgotPassword({ email }) {
  try {
    const { data, errors } = yield call(accountApi.forgotPassword, email);

    if (!errors) {
      successToast('emailSent');
      yield put(forgotPasswordSuccess(data));
    } else {
      yield put(forgotPasswordFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(forgotPasswordFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(FORGOT_PASSWORD, forgotPassword);
}
