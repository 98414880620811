export const ADD_LANDLORD = 'ADD_LANDLORD';
export const ADD_LANDLORD_SUCCESS = 'ADD_LANDLORD_SUCCESS';
export const ADD_LANDLORD_FAILURE = 'ADD_LANDLORD_FAILURE';
export const ADD_LANDLORD_CLEAR = 'ADD_LANDLORD_CLEAR';

export const addLandlord = (houseId, addressId, firstName, lastName) => ({
  type: ADD_LANDLORD,
  houseId,
  addressId,
  firstName,
  lastName
});
export const addLandlordSuccess = payload => ({
  type: ADD_LANDLORD_SUCCESS,
  payload
});
export const addLandlordFailure = errors => ({
  type: ADD_LANDLORD_FAILURE,
  errors
});
export const addLandlordClear = () => ({
  type: ADD_LANDLORD_CLEAR
});
