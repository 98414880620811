export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const resetPassword = (email, token, password) => ({
  type: RESET_PASSWORD,
  email,
  token,
  password
});
export const resetPasswordSuccess = payload => ({
  type: RESET_PASSWORD_SUCCESS,
  payload
});
export const resetPasswordFailure = errors => ({
  type: RESET_PASSWORD_FAILURE,
  errors
});
