import React from 'react';
import TenantRegisterContainer from '../registrationFlow/components/tenantRegister/TenantRegister.container';
import CreateBaseDataContainer from '../registrationFlow/components/createBaseData/CreateBaseData.container';
import CreatePaymentContainer from '../registrationFlow/components/createPayment/CreatePayment.container';
import ConfirmEmailByCode from './components/confirmEmailByCode/ConfirmEmailByCode.container';
import RoomSideView from './components/RoomSideView';
import { Row, Col, Container, Card } from 'react-bootstrap';
import CreateBookingContainer from './components/createBooking/CreateBooking.Container';
import PropTypes from 'prop-types';

const RegistrationFlow = ({
  flowState,
  t,
  room,
  rentalDuration,
  deposit,
  startOfRental,
  onCreateBooking,
  registrationStep,
  bookingStep,
  promotionCode,
  newTenant
}) => (
  <Container style={{ marginTop: '50px', marginBottom: '25px' }}>
    <Row>
      <Col md={8}>
        <div style={{ marginBottom: '3em' }}>
          <div
            className={`${
              registrationStep
                ? 'registrationFlowStepsActive'
                : 'registrationFlowSteps'
            }`}
          >
            <div
              className={`${
                registrationStep
                  ? 'registrationFlowNumberActive'
                  : 'registrationFlowNumber'
              }`}
            >
              1
            </div>
            {t('EmailAndPassword')}
          </div>

          <div
            className={`${
              flowState === 'createBaseData'
                ? 'registrationFlowStepsActive'
                : 'registrationFlowSteps'
            }`}
          >
            <div
              className={`${
                flowState === 'createBaseData'
                  ? 'registrationFlowNumberActive'
                  : 'registrationFlowNumber'
              }`}
            >
              2
            </div>
            {t('baseData')}
          </div>
          <div
            className={`${
              bookingStep
                ? 'registrationFlowStepsActive'
                : 'registrationFlowSteps'
            }`}
          >
            <div
              className={`${
                bookingStep
                  ? 'registrationFlowNumberActive'
                  : 'registrationFlowNumber'
              }`}
            >
              3
            </div>
            {t('booking')}
          </div>
        </div>

        {flowState === 'registerTenant' && <TenantRegisterContainer />}
        {flowState === 'confirmEmail' && <ConfirmEmailByCode />}
        {flowState === 'createBaseData' && <CreateBaseDataContainer />}
        {flowState === 'createPayment' && (
          <CreatePaymentContainer
            roomId={room.id}
            onCreateBooking={onCreateBooking}
            newTenant={newTenant}
          />
        )}
        {flowState === 'createBooking' && (
          <CreateBookingContainer
            {...{ startOfRental, rentalDuration, deposit, promotionCode, newTenant }}
          />
        )}
      </Col>
      <Col md={4} className="mt-5 mt-md-0">
        <Card>
          <Card.Body>
            <RoomSideView room={room} />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container>
);
RegistrationFlow.propTypes = {
  flowState: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  onCreateBooking: PropTypes.func.isRequired,
  startOfRental: PropTypes.string.isRequired,
  rentalDuration: PropTypes.string.isRequired,
  deposit: PropTypes.string.isRequired,
  room: PropTypes.object.isRequired,
  registrationStep: PropTypes.object.isRequired,
  bookingStep: PropTypes.object.isRequired,
  newTenant: PropTypes.object.isRequired
};

export default RegistrationFlow;
