export const CREATE_LOG = 'CREATE_LOG';
export const CREATE_LOG_SUCCESS = 'CREATE_LOG_SUCCESS';
export const CREATE_LOG_FAILURE = 'CREATE_LOG_FAILURE';
export const CREATE_LOG_CLEAR = 'CREATE_LOG_CLEAR';

export const createLog = (logLevel, message, args) => ({
  type: CREATE_LOG,
  logLevel,
  message,
  args,
});
export const createLogSuccess = (payload) => ({
  type: CREATE_LOG_SUCCESS,
  payload,
});
export const createLogFailure = (errors) => ({
  type: CREATE_LOG_FAILURE,
  errors,
});
export const createLogClear = () => ({
  type: CREATE_LOG_CLEAR,
});
