import { Loader } from 'components';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getServiceProvider } from 'store/serviceProvider/getServiceProvider/getServiceProvider.actions';
import {
  updateServiceProvider,
  updateServiceProviderClear
} from 'store/serviceProvider/updateServiceProvider/updateServiceProvider.actions';
import EditServiceProvider from './EditServiceProvider';

const EditServiceProviderContainer = ({ match }) => {
  const { t } = useTranslation('data');
  const serviceProviderId = parseInt(match.params.serviceProviderId);
  const history = useHistory();
  const dispatch = useDispatch();

  const serviceProviders = useSelector(state => state.serviceProviders);
  const serviceProvider = serviceProviders.find(
    x => x.id === serviceProviderId
  );

  useEffect(() => {
    dispatch(getServiceProvider(serviceProviderId));
  }, []);

  const { success, loading: isLoading } = useSelector(
    state => state.request.updateServiceProvider
  );

  useEffect(() => {
    if (success) {
      dispatch(updateServiceProviderClear());
      history.push(`/dashboard/data/serviceProvider/${serviceProvider.id}`);
    }
  }, [success]);

  const onSubmit = ({
    firstName,
    lastName,
    email,
    serviceProviderTypes,
    houseIds
  }) =>
    dispatch(
      updateServiceProvider(
        serviceProviderId,
        firstName,
        lastName,
        email,
        serviceProviderTypes,
        houseIds
      )
    );

  return serviceProvider !== undefined ? (
    <Loader prop={serviceProvider}>
      <EditServiceProvider {...{ serviceProvider, onSubmit, t, isLoading }} />{' '}
    </Loader>
  ) : (
    <div />
  );
};

EditServiceProviderContainer.propTypes = { match: PropTypes.object.isRequired };

export default EditServiceProviderContainer;
