import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  addServiceProvider,
  addServiceProviderClear
} from 'store/serviceProvider/addServiceProvider/addServiceProvider.actions';
import AddServiceProvider from './AddServiceProvider';

const AddServiceProviderContainer = () => {
  const { t } = useTranslation('data');
  const history = useHistory();
  const dispatch = useDispatch();

  const { data: serviceProvider, success, loading } = useSelector(
    state => state.request.addServiceProvider
  );

  useEffect(() => {
    if (success) {
      history.push(`/dashboard/data/serviceProvider/${serviceProvider.id}`);
      dispatch(addServiceProviderClear());
    }
  }, [success]);

  const onSubmit = ({ firstName, lastName, email, serviceProviderTypes }) => {
    dispatch(
      addServiceProvider(firstName, lastName, email, serviceProviderTypes)
    );
  };

  return <AddServiceProvider isLoading={loading} {...{ onSubmit, t }} />;
};

export default AddServiceProviderContainer;
