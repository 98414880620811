import i18n from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';

const MinutesToTrainStationFormGroup = ({
  value,
  onChange,
  min,
  max,
  step,
  ...rest
}) => (
  <FormGroup className="mb-3" controlId="formMinutesToTrainStation">
    <FormLabel>{i18n.t('forms:minutesToTrainStation')}</FormLabel>
    <FormControl
      value={value}
      onChange={onChange}
      name="minutesToTrainstation"
      type="number"
      min={min}
      step={step}
      max={max}
      {...rest}
    />
  </FormGroup>
);

MinutesToTrainStationFormGroup.defaultProps = {
  value: '',
  min: '0',
  max: '1440',
  step: '1'
};

MinutesToTrainStationFormGroup.propTypes = {
  value: PropTypes.string,
  min: PropTypes.any,
  max: PropTypes.any,
  step: PropTypes.any,
  onChange: PropTypes.func.isRequired
};

export default MinutesToTrainStationFormGroup;
