import { ServiceProviderSelect } from 'containers';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { FormGroup, FormLabel } from 'react-bootstrap';

const ServiceProviderFormGroup = ({ value, onChange, ...rest }) => (
  <FormGroup controlId="formServiceProvider">
    <FormLabel>{i18n.t('forms:serviceProvider')}</FormLabel>
    <ServiceProviderSelect
      onChange={onChange}
      value={value}
      name="serviceProvider"
      {...rest}
    />
  </FormGroup>
);

ServiceProviderFormGroup.defaultProps = {
  value: ''
};

ServiceProviderFormGroup.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default ServiceProviderFormGroup;
