export const GET_CLAIM_REPORTS = 'GET_CLAIM_REPORTS';
export const GET_CLAIM_REPORTS_SUCCESS = 'GET_CLAIM_REPORTS_SUCCESS';
export const GET_CLAIM_REPORTS_FAILURE = 'GET_CLAIM_REPORTS_FAILURE';

export const getClaimReports = () => ({
  type: GET_CLAIM_REPORTS
});

export const getClaimReportsSuccess = payload => ({
  type: GET_CLAIM_REPORTS_SUCCESS,
  payload
});

export const getClaimReportsFailure = errors => ({
  type: GET_CLAIM_REPORTS_FAILURE,
  errors
});
