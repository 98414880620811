export const UPDATE_SERVICE_PROVIDER = 'UPDATE_SERVICE_PROVIDER';
export const UPDATE_SERVICE_PROVIDER_SUCCESS =
  'UPDATE_SERVICE_PROVIDER_SUCCESS';
export const UPDATE_SERVICE_PROVIDER_FAILURE =
  'UPDATE_SERVICE_PROVIDER_FAILURE';
export const UPDATE_SERVICE_PROVIDER_CLEAR = 'UPDATE_SERVICE_PROVIDER_CLEAR';

export const updateServiceProvider = (
  id,
  firstName,
  lastName,
  email,
  serviceProviderTypes,
  houseIds
) => ({
  type: UPDATE_SERVICE_PROVIDER,
  id,
  firstName,
  lastName,
  email,
  serviceProviderTypes,
  houseIds
});
export const updateServiceProviderSuccess = payload => ({
  type: UPDATE_SERVICE_PROVIDER_SUCCESS,
  payload
});
export const updateServiceProviderFailure = errors => ({
  type: UPDATE_SERVICE_PROVIDER_FAILURE,
  errors
});
export const updateServiceProviderClear = () => ({
  type: UPDATE_SERVICE_PROVIDER_CLEAR
});
