import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import {
  ErrorAlerts,
  AddressFormGroup,
  FirstNameFormGroup,
  LastNameFormGroup
} from 'components';

const AddOwnerModal = ({
  isOpen,
  onHide,
  firstName,
  lastName,
  handleFirstNameChange,
  handleLastNameChange,
  handleSubmit,
  errors,
  address,
  handleAddressChange,
  t
}) => (
  <Modal
    size="lg"
    show={isOpen}
    onHide={onHide}
    aria-labelledby="example-modal-sizes-title-lg"
  >
    <Modal.Header closeButton>
      <Modal.Title id="example-modal-sizes-title-lg">
        {t('addOwnerModal.newOwner')}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <ErrorAlerts errors={errors} />
      <FirstNameFormGroup
        value={firstName}
        onChange={handleFirstNameChange}
        required
      />
      <LastNameFormGroup
        value={lastName}
        onChange={handleLastNameChange}
        required
      />

      <AddressFormGroup
        address={address}
        onChangeAddress={handleAddressChange}
      />

      <Button onClick={handleSubmit} style={{ marginTop: 10 }}>
        {t('addOwnerModal.submit')}
      </Button>
    </Modal.Body>
  </Modal>
);

AddOwnerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  handleFirstNameChange: PropTypes.func.isRequired,
  handleLastNameChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
  address: PropTypes.object,
  handleAddressChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default AddOwnerModal;
