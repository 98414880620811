import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import EditHouseOwner from './EditHouseOwner';
import { Loader } from 'components';
import { getHouseOwner } from 'store/houseOwner/getHouseOwner/getHouseOwner.actions';
import {
  updateHouseOwner,
  updateHouseOwnerClear
} from 'store/houseOwner/updateHouseOwner/updateHouseOwner.actions';

const EditHouseOwnerContainer = ({ match }) => {
  const { t } = useTranslation('data');
  const houseOwnerId = parseInt(match.params.houseOwnerId);
  const history = useHistory();
  const dispatch = useDispatch();

  const houseOwners = useSelector(state => state.houseOwners);
  const houseOwner = houseOwners.find(x => x.id === houseOwnerId);

  useEffect(() => {
    dispatch(getHouseOwner(houseOwnerId));
  }, []);

  const { success, loading: isLoading } = useSelector(
    state => state.request.updateHouseOwner
  );

  useEffect(() => {
    if (success) {
      dispatch(updateHouseOwnerClear());
      history.push(`/dashboard/data/houseOwner/${houseOwner.id}`);
    }
  }, [success]);

  const onSubmit = ({ firstName, lastName, address }) =>
    dispatch(updateHouseOwner(houseOwnerId, firstName, lastName, address));

  return houseOwner !== undefined ? (
    <Loader prop={houseOwner}>
      <EditHouseOwner {...{ houseOwner, onSubmit, t, isLoading }} />{' '}
    </Loader>
  ) : (
    <div />
  );
};

EditHouseOwnerContainer.propTypes = { match: PropTypes.object.isRequired };

export default EditHouseOwnerContainer;
