import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'qs';

import ResetPassword from './ResetPassword';
import useInputState from 'hooks/useInputState';
import { resetPassword } from 'store/account/resetPassword/resetPassword.actions';

const ResetPasswordContainer = ({ location }) => {
  const { t } = useTranslation('resetPassword');
  const dispatch = useDispatch();

  const [errors, setErrors] = useState([]);
  const [password, setPassword] = useInputState('');
  const [repeatPassword, setRepeatPassword] = useInputState('');

  const { search } = location;
  const queryStrings = qs.parse(search, { ignoreQueryPrefix: true });

  const token = queryStrings.token;
  const email = queryStrings.email;

  const handleSubmit = () => {
    if (password !== repeatPassword) {
      setErrors([t('passwordsDoNotMatch')]);
      setPassword({ target: { value: '' } });
      setRepeatPassword({ target: { value: '' } });
      return;
    }

    dispatch(resetPassword(email, token, password));
  };

  const { success, loading, errors: apiErrors } = useSelector(
    state => state.request.resetPassword
  );

  useEffect(() => {
    if (apiErrors.length > 0) {
      setErrors(apiErrors);
    }
  }, [apiErrors]);

  useEffect(() => {
    if (success) {
      setPassword({ target: { value: '' } });
      setRepeatPassword({ target: { value: '' } });
      setErrors([]);
    }
  }, [success]);

  return (
    <ResetPassword
      {...{
        t,
        handleSubmit,
        password,
        setPassword,
        repeatPassword,
        setRepeatPassword,
        errors,
        loading
      }}
    />
  );
};

export default ResetPasswordContainer;
