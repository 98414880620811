import * as moment from 'moment';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import RoomForm from './RoomForm';

const RoomFormContainer = ({
  squareMeters: _squareMeters,
  price: _price,
  deposit: _deposit,
  freeAt: _freeAt,
  houseId: _houseId,
  flatId: _flatId,
  equipment: _equipment,
  description: _description,
  images: _images,
  symbol: _symbol,
  locationOfRoom: _locationOfRoom,
  onSubmit,
  isLoading,
}) => {
  const { t } = useTranslation('data');

  const sliderRef = useRef(null);

  const [equipment, setEquipment] = useState(_equipment);
  const handleEquipmentChange = (e) => setEquipment(e.target.value);

  const [squareMeters, setSquareMeters] = useState(_squareMeters);
  const [price, setPrice] = useState(_price);
  const [deposit, setDeposit] = useState(_deposit);
  const [freeAt, setFreeAt] = useState(moment(_freeAt));
  const [houseId, setHouseId] = useState(_houseId);
  const [flatId, setFlatId] = useState(_flatId);
  const [description, setDescription] = useState(_description);
  const [locationOfRoom, setLocationOfRoom] = useState(_locationOfRoom);
  const [images, setImages] = useState(_images);
  const [imagePreviews, setImagePreviews] = useState(
    _images.map((file) => file.imageUrl)
  );
  const [symbol, setSymbol] = useState(_symbol);

  const handleSquareMetersChange = (e) => setSquareMeters(e.target.value);
  const handlePriceChange = (e) => setPrice(e.target.value);
  const handleDepositChange = (e) => setDeposit(e.target.value);
  const handleFreeAtChange = (e) => setFreeAt(e);
  const handleDescriptionChange = (e) => setDescription(e.target.value);
  const handleLocationOfRoomChange = (e) => setLocationOfRoom(e.target.value);
  const handleHouseIdChange = (houseId) => {
    setHouseId(houseId);
    setFlatId(null);
  };
  const handleFlatIdChange = (flatId) => setFlatId(flatId);
  const handleSymbolChange = (e) => setSymbol(e.target.value);

  const flatSelector = (state) =>
    state.flats.filter((x) => x.houseId === parseInt(houseId));

  const handleSubmit = (e) => {
    e.preventDefault();

    const existingImages = images
      .filter((image) => image.imageUrl)
      .map((x) => x.id);

    const newImages = images.filter((image) => !image.imageUrl);

    onSubmit({
      symbol,
      squareMeters,
      price,
      deposit,
      freeAt: freeAt.startOf('day').add(12, 'hours').toISOString(),
      houseId,
      flatId,
      equipment,
      description,
      existingImages,
      newImages,
      locationOfRoom,
    });
  };

  const isSubmitEnabled = houseId !== undefined && flatId !== undefined;

  const handleDeleteImageClick = () => {
    const index = sliderRef.current.getCurrentIndex();
    if (images.length === 1) {
      toast.error(t('keepImage'));
      return;
    }
    const test2 = images.filter((_, i) => i !== index);
    const test = imagePreviews.filter((_, i) => i !== index);

    setImages(test2);
    setImagePreviews(test);
  };

  const handleSelectImages = (e) => {
    setImages([...images, ...e.target.files]);

    const previews = images.map((image) => {
      if (image.imageUrl) return image.imageUrl;
      else return URL.createObjectURL(image);
    });

    setImagePreviews([
      ...previews,
      ...[...e.target.files].map((file) => URL.createObjectURL(file)),
    ]);
  };

  return (
    <RoomForm
      {...{
        imagePreviews,
        handleSubmit,
        squareMeters,
        handleSquareMetersChange,
        price,
        handlePriceChange,
        deposit,
        handleDepositChange,
        freeAt,
        handleFreeAtChange,
        houseId,
        setHouseId: handleHouseIdChange,
        flatId,
        setFlatId: handleFlatIdChange,
        description,
        handleDescriptionChange,
        t,
        isSubmitEnabled,
        handleSelectImages,
        images,
        sliderRef,
        handleDeleteImageClick,
        equipment,
        handleEquipmentChange,
        flatSelector,
        symbol,
        handleSymbolChange,
        isLoading,
        locationOfRoom,
        handleLocationOfRoomChange,
      }}
    />
  );
};

RoomFormContainer.defaultProps = {
  equipment: {},
  onSubmit: () => {},
  houseId: undefined,
  flatId: undefined,
  description: '',
  images: [],
  symbol: undefined,
};

RoomFormContainer.propTypes = {
  equipment: PropTypes.object,
  onSubmit: PropTypes.func,
  squareMeters: PropTypes.number,
  price: PropTypes.number,
  deposit: PropTypes.number,
  freeAt: PropTypes.string,
  houseId: PropTypes.string,
  flatId: PropTypes.number,
  description: PropTypes.string,
  images: PropTypes.array,
  symbol: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default RoomFormContainer;
