import useHouseOwners from 'hooks/store/useHouseOwners';
import PropTypes from 'prop-types';
import React from 'react';
import Select from '../../shared/select';

const HouseOwnerSelectContainer = ({ onChange, value, selector, ...rest }) => {
  const houseOwners = useHouseOwners(selector);

  return (
    <Select
      className="mb-3"
      value={value}
      onChange={onChange}
      options={houseOwners}
      selectProp="id"
      output={houseOwner => `${houseOwner.firstName} ${houseOwner.lastName}`}
      {...rest}
    />
  );
};

HouseOwnerSelectContainer.defaultProps = {
  onChange: () => {},
  selector: state => state.houseOwners
};

HouseOwnerSelectContainer.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  selector: PropTypes.func
};

export default HouseOwnerSelectContainer;
