export const DELETE_FLAT = 'DELETE_FLAT';
export const DELETE_FLAT_SUCCESS = 'DELETE_FLAT_SUCCESS';
export const DELETE_FLAT_FAILURE = 'DELETE_FLAT_FAILURE';

export const deleteFlat = id => ({
  type: DELETE_FLAT,
  id
});
export const deleteFlatSuccess = payload => ({
  type: DELETE_FLAT_SUCCESS,
  payload
});
export const deleteFlatFailure = errors => ({
  type: DELETE_FLAT_FAILURE,
  errors
});
