import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  GET_FLATS,
  GET_FLATS_SUCCESS,
  GET_FLATS_FAILURE
} from './getFlats.actions';

export default createReducer(
  {
    [GET_FLATS]: defaultRequest,
    [GET_FLATS_SUCCESS]: defaultSuccess,
    [GET_FLATS_FAILURE]: defaultError
  },
  defaultInitialState
);
