import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ServiceProviderTypesFormGroup from './ServiceProviderTypesFormGroup';

const ServiceProviderTypesFormGroupContainer = ({
  serviceProviderTypes,
  setServiceProviderTypes
}) => {
  const { t } = useTranslation('data', { keyPrefix: 'serviceProvider' });

  const handleChange = type => {
    const existingType = serviceProviderTypes.indexOf(type);

    if (existingType > -1) {
      serviceProviderTypes.splice(existingType, 1);
      setServiceProviderTypes([...serviceProviderTypes]);
      return;
    }

    setServiceProviderTypes([...serviceProviderTypes, type]);
  };

  return (
    <ServiceProviderTypesFormGroup
      {...{
        serviceProviderTypes,
        handleChange,
        t
      }}
    />
  );
};

ServiceProviderTypesFormGroupContainer.defaultProps = {
  equipment: {},
  onChange: () => {}
};

ServiceProviderTypesFormGroupContainer.propTypes = {
  equipment: PropTypes.object,
  onChange: PropTypes.func
};

export default ServiceProviderTypesFormGroupContainer;
