export const GET_HOUSE_FLATS = 'GET_HOUSE_FLATS';
export const GET_HOUSE_FLATS_SUCCESS = 'GET_HOUSE_FLATS_SUCCESS';
export const GET_HOUSE_FLATS_FAILURE = 'GET_HOUSE_FLATS_FAILURE';

export const getHouseFlats = id => ({
  type: GET_HOUSE_FLATS,
  id
});
export const getHouseFlatsSuccess = payload => ({
  type: GET_HOUSE_FLATS_SUCCESS,
  payload
});
export const getHouseFlatsFailure = errors => ({
  type: GET_HOUSE_FLATS_FAILURE,
  errors
});
