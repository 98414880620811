import React from 'react';
import PropTypes from 'prop-types';
import Equipment from './Equipment';
import { useTranslation } from 'react-i18next';

const EquipmentContainer = ({ equipment }) => {
  const { t } = useTranslation();

  return <Equipment t={t} equipment={equipment} />;
};

EquipmentContainer.propTypes = { equipment: PropTypes.array.isRequired };

export default EquipmentContainer;
