import { put, takeLatest, call, select } from 'redux-saga/effects';
import i18n from 'i18next';
import { houseGiverApi, addressApi } from 'utils/api';
import {
  UPDATE_HOUSE_GIVER,
  updateHouseGiverSuccess,
  updateHouseGiverFailure
} from './updateHouseGiver.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';

function* getHouseGiversSaga({ id, address, firstName, lastName }) {
  try {
    const state = yield select();
    const currentAddress = state.houseGivers.find(x => x.id === id).address;

    yield call(addressApi.updateAddress, currentAddress.id, address);

    const { data, errors } = yield call(
      houseGiverApi.updateHouseGiver,
      id,
      address.id,
      firstName,
      lastName
    );

    if (!errors) {
      yield put(updateHouseGiverSuccess(data));
      successToast('updateHouseGiver');
    } else {
      yield put(updateHouseGiverFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(updateHouseGiverFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(UPDATE_HOUSE_GIVER, getHouseGiversSaga);
}
