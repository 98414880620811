import React from 'react';
import BaseData from './BaseData';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const BaseDataContainer = () => {
  const { t } = useTranslation('data');
  const history = useHistory();

  const onHandleHousesClick = () => history.push('/dashboard/data/houses');
  const onHandleFlatsClick = () => history.push('/dashboard/data/flats');
  const onHandleRoomsClick = () => history.push('/dashboard/data/rooms');
  const onHandleServiceProvidersClick = () =>
    history.push('/dashboard/data/serviceProviders');
  const onHandleHouseOwnersClick = () =>
    history.push('/dashboard/data/houseOwners');
  const onHandleHouseGiversClick = () =>
    history.push('/dashboard/data/houseGivers');

  return (
    <BaseData
      {...{
        onHandleHousesClick,
        onHandleFlatsClick,
        onHandleRoomsClick,
        onHandleServiceProvidersClick,
        onHandleHouseOwnersClick,
        onHandleHouseGiversClick,
        t
      }}
    />
  );
};

export default BaseDataContainer;
