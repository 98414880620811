import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { synchronizeBanking } from 'store/banking/synchronizeBanking/synchronizeBanking.actions';

const BankingSettings = ({ t }) => {
  const dispatch = useDispatch();

  const onSynchronize = () => {
    dispatch(synchronizeBanking());
  };

  const {
    loading: synchronizeBankingLoading,
    success: synchronizeBankingSuccess
  } = useSelector(state => state.request.synchronizeBanking);
  const { loading: bankingStatusLoading, data: bankingStatus } = useSelector(
    state => state.request.getBankingStatus
  );

  return (
    <>
      {bankingStatusLoading && (
        <div className="d-flex align-items-center flex-direction-row">
          <Spinner animation="border" variant="primary" size="sm" />
          <div style={{ marginLeft: 10 }}>{t('landlord.banking.loading')}</div>
        </div>
      )}
      {bankingStatus && (
        <>
          <div className="d-flex flex-direction-row align-items-center mb-3">
            <div className="me-2">{`${t('landlord.banking.status')}:`}</div>
            <div
              style={{
                width: 40,
                height: 40,
                backgroundColor:
                  bankingStatus.status || synchronizeBankingSuccess
                    ? 'green'
                    : 'red',
                borderRadius: '100%'
              }}
            ></div>
          </div>
          <div className="mb-3">
            {`${t('landlord.banking.lastSuccessfulSynchronization')}:`}{' '}
            {bankingStatus.lastSuccessfulSynchronization
              ? moment(bankingStatus.lastSuccessfulSynchronization).format(
                  'DD.MM.YYYY HH:MM'
                )
              : synchronizeBankingSuccess
              ? t('landlord.banking.recently')
              : t('landlord.banking.unknown')}
          </div>
          <div className="mb-5">
            {`${t('landlord.banking.lastFailedSynchronization')}:`}{' '}
            {bankingStatus.lastFailedSynchronization
              ? moment(bankingStatus.lastFailedSynchronization).format(
                  'DD.MM.YYYY HH:MM'
                )
              : t('landlord.banking.unknown')}
          </div>

          <Button
            onClick={onSynchronize}
            variant={synchronizeBankingSuccess ? 'success' : 'primary'}
            className={'d-flex flex-direction-row align-items-center'}
            disabled={synchronizeBankingLoading}
          >
            {synchronizeBankingLoading
              ? t('landlord.banking.activateTan')
              : t('landlord.banking.synchronise')}
            {synchronizeBankingSuccess && (
              <FontAwesomeIcon style={{ marginLeft: 5 }} icon={faCheck} />
            )}
          </Button>
        </>
      )}
    </>
  );
};

export default BankingSettings;

BankingSettings.propTypes = {
  creditAdvertiser: PropTypes.number.isRequired,
  onCreditAdvertiserChange: PropTypes.func.isRequired,
  creditAdvertised: PropTypes.number.isRequired,
  onCreditAdvertisedChange: PropTypes.func.isRequired,
  bonus: PropTypes.number.isRequired,
  onBonusChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  submitDisabled: PropTypes.bool.isRequired
};
