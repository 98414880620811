import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { accountApi } from 'utils/api';
import {
  CONFIRM_EMAIL_BY_CODE,
  confirmEmailByCodeSuccess,
  confirmEmailByCodeFailure
} from './confirmEmailByCode.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';

function* confirmEmailByCode({ code, email }) {
  try {
    const { data, errors } = yield call(
      accountApi.confirmEmailByCode,
      email,
      code
    );

    if (!errors) {
      yield put(confirmEmailByCodeSuccess(data));
      localStorage.setItem('access_token', data.token);
    } else {
      yield put(confirmEmailByCodeFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(confirmEmailByCodeFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(CONFIRM_EMAIL_BY_CODE, confirmEmailByCode);
}
