import { put, takeLatest, call } from 'redux-saga/effects';
import { landlordApi } from 'utils/api';
import i18n from 'i18next';
import {
  CREATE_NEW_TENANT,
  createNewTenantSuccess,
  createNewTenantFailure
} from './createNewTenant.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';

function* createNewTenant({ firstName, lastName, email, password }) {
  try {
    const { data, errors } = yield call(
      landlordApi.createNewTenant,
      firstName,
      lastName,
      email,
      password
    );

    if (!errors) {
      yield put(createNewTenantSuccess(data));
      successToast('createNewTenant');
    } else {
      yield put(createNewTenantFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(createNewTenantFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(CREATE_NEW_TENANT, createNewTenant);
}
