import React from 'react';
import PropTypes from 'prop-types';
import Content from 'components/Content/Content';
import { RoomForm } from 'containers';

const AddRoom = ({ t, handleCreateRoom, loading }) => (
  <Content>
    <h2>{t('addRoom.addRoom')}</h2>
    <RoomForm onSubmit={handleCreateRoom} isLoading={loading} />
  </Content>
);

AddRoom.propTypes = {
  t: PropTypes.func.isRequired,
  handleCreateRoom: PropTypes.func.isRequired
};

export default AddRoom;
