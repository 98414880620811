import { put, takeLatest, call } from 'redux-saga/effects';
import { tenantApi } from 'utils/api';
import i18n from 'i18next';
import {
  UPDATE_USERINFO,
  updateUserInfoSuccess,
  updateUserInfoFailure
} from './updateUserInfo.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';

function* updateUserInfo({
  mobileNumber,
  nationality,
  birthDate,
  gender,
  job,
  firstName,
  lastName
}) {
  try {
    const { data, errors } = yield call(
      tenantApi.updateUserInfo,
      mobileNumber,
      nationality,
      birthDate,
      gender,
      job,
      firstName,
      lastName
    );

    if (!errors) {
      yield put(updateUserInfoSuccess(data));
      successToast('updateUserInfo');
    } else {
      yield put(updateUserInfoFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(updateUserInfoFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(UPDATE_USERINFO, updateUserInfo);
}
