import API from './API';

const prefix = 'house';

export const getHouses = () => API.get(`${prefix}`);
export const getHouse = (id) => API.get(`${prefix}/${id}`);
export const addHouse = (
  addressId,
  minutesToTrainStation,
  levels,
  giverId,
  ownerId
) =>
  API.post(`${prefix}/`, {
    addressId,
    minutesToTrainStation,
    levels,
    giverId,
    ownerId,
  });

export const updateHouse = (
  id,
  addressId,
  minutesToTrainStation,
  levels,
  giverId,
  ownerId
) =>
  API.put(`${prefix}`, {
    id,
    addressId,
    minutesToTrainStation,
    levels,
    giverId,
    ownerId,
  });
export const deleteHouse = (id) => API.delete(`${prefix}/${id}`);
export const getHouseRooms = (id) => API.get(`${prefix}/${id}/rooms`);
export const getHouseFlats = (id) => API.get(`${prefix}/${id}/flats`);

export default {
  getHouses,
  getHouse,
  addHouse,
  updateHouse,
  deleteHouse,
  getHouseRooms,
  getHouseFlats,
};
