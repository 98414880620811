import Content from 'components/Content';
import { ServiceProviderForm } from 'containers';
import PropTypes from 'prop-types';
import React from 'react';

const EditServiceProvider = ({ serviceProvider, onSubmit, t, isLoading }) => {
  return (
    <Content>
      <h2>{t('serviceProvider.serviceProvider')}</h2>
      <ServiceProviderForm
        firstName={serviceProvider.firstName}
        lastName={serviceProvider.lastName}
        email={serviceProvider.email}
        serviceProviderTypes={serviceProvider.serviceProviderTypes}
        houseIds={serviceProvider.houseIds}
        onSubmit={onSubmit}
        isLoading={isLoading}
      />
    </Content>
  );
};

EditServiceProvider.propTypes = {
  t: PropTypes.func.isRequired,
  serviceProvider: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default EditServiceProvider;
