import useRooms from 'hooks/store/useRooms';
import useTenants from 'hooks/store/useTenants';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toDisplayDate } from 'utils/date.formatter';
import TenantsDetailModal from 'views/dashboard/views/tenants/TenantsModal';
import Rooms from './Rooms';

const RoomsContainer = () => {
  const history = useHistory();

  const sortTypes = React.useMemo(
    () => ({
      alphanumericFalsyLast(rowA, rowB, columnId) {
        
        const a = Number(rowA.values[columnId]);
        const b = Number(rowB.values[columnId]);

        return Number(a) - Number(b);
      },
    }),
    []
  );

  const { t } = useTranslation('rooms');
  const { t: tModal } = useTranslation('tenants');

  const [filter, setFilter] = useState(t('allRooms'));

  const handleFilterChange = (e) => setFilter(e.target.value);

  const rooms = useRooms((state) => {
    for (var room of state.rooms) {
      room.relevantBooking = room.bookings
        ?.sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
        .find(
          (x) =>
            x.termination === null ||
            moment(x.termination.effectiveFrom).isAfter(moment())
        );

      room.nextBooking =
        room.bookings?.length > 1
          ? room.bookings
              .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
              .find(
                (x) =>
                  x.id !== room.relevantBooking?.id &&
                  moment(x.startDate).isAfter(moment()) &&
                  (x.termination === null ||
                    moment(x.termination.effectiveFrom).isAfter(moment()))
              )
          : null;

      if (room.relevantBooking) {
        room.resident = room.relevantBooking.tenant;
        room.resident.fullName = `${room.resident.firstName} ${room.resident.lastName}`;
      }

      room.freeAt = getFreeAt();
    }

    switch (filter) {
      case t('freeRooms'):
        return  state.rooms.filter((room) => !room.resident);

      case t('bookedRooms'):
        return state.rooms.filter((room) => room.resident);

      default:
        return state.rooms;
        
        
         
    }

    function getFreeAt() {
      if (room.nextBooking) {
        return null;
      }

      if (room.relevantBooking && !room.relevantBooking.termination) {
        return null;
      }

      if (room.relevantBooking && room.relevantBooking.termination) {
        return room.relevantBooking.termination.effectiveFrom;
      }

      return room.freeAt;
    }
  });

  useTenants((state) => {
    rooms.forEach((room) => {
      room.resident = state.tenants.find(
        (tenant) => tenant.userId === room.resident?.userId
      );
    });
  });

  const freeAtFormatter = (freeAt) => {
    if (!freeAt) {
      return '-';
    }

    if (moment(freeAt).isSameOrBefore(moment())) {
      return t('fromNow');
    }

    return toDisplayDate(freeAt);
  };

  function historyToRoom() {
    return {
      onClick: (e, column, columnIndex, row, rowIndex) => {
        history.push(`/dashboard/data/room/${row.id}`);
      },
    };
  }

  function historyToResident() {
    return {
      onClick: (e, column, columnIndex, row, rowIndex) => {
        e.stopPropagation();
        if (row.resident) {
          setSelectedTenant({
            ...row.resident,
          });
          setIsTenantModalOpen(true);
        }
      },
    };
  }

  function historyToNextTenant() {
    return {
      onClick: (e, column, columnIndex, row, rowIndex) => {
        e.stopPropagation();
        if (row.nextBooking && row.nextBooking.tenant) {
          setSelectedTenant({
            ...row.nextBooking.tenant,
            booking: row.nextBooking,
          });
          setIsTenantModalOpen(true);
        }
      },
    };
  }

  const columns = [
    {
      dataField: 'symbol',
      text: t('symbol'),
      sort: true,
      formatter: (symbol) =>
        symbol.length > 8 ? `${symbol.substring(0, 8)}...` : symbol,
      events: historyToRoom(),
    },
    {
      dataField: 'address',
      text: t('address'),
      sort: true,
      formatter: ({ street, houseNumber, postalCode, location }) =>
        `${street} ${houseNumber}, ${postalCode} ${location}`,
      events: historyToRoom(),
    },
    {
      dataField: 'squareMeters',
      text: t('size'),
      sort: true,
      align: 'center',
      formatter: (sm) => `${sm}m²`,
      events: historyToRoom(),
    },
    {
      dataField: 'relevantBooking.rentalFee',
      text: t('rent'),
      sort: true,
      align: 'center',
      formatter: (_, room) => {
        if (!room.relevantBooking) {
          return `${room.price}€`;
        }

        return `${room.relevantBooking.rentalFee}€`;
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation();
          if (!row.relevantBooking) {
            history.push(`/dashboard/data/room/${row.id}`);
          }

          if (row.resident) {
            setSelectedTenant({
              ...row.resident,
            });
            setIsTenantModalOpen(true);
          }
        },
      },
    },
    {
      dataField: 'relevantBooking.durationInMonths',
      text: t('durationInMonths'),
      sort: true,
      align: 'center',
      events: historyToResident(),
    },
    {
      dataField: 'resident.customerId',
      text: t('customerId'),
      sort: true,
      align: 'center',
      headerStyle: { whiteSpace: 'nowrap' },
      events: historyToResident(),
    },
    {
      dataField: 'resident',
      text: t('tenant'),
      sort: true,
      align: 'center',
      formatter: (tenant) =>
        tenant ? `${tenant.firstName} ${tenant.lastName}` : '',
      events: historyToResident(),
    },
    {
      dataField: 'resident.accountBalance',
      text: t('balance'),
      sort: true,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (order === 'asc') {
          return b - a;
        }
        return a - b; // desc
      },
      align: 'center',
      formatter: (b) => (b || b === 0 ? `${b}€` : '-'),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation();
          if (row.resident && typeof row.resident.accountBalance === 'number') {
            history.push(`/dashboard/balance/${row.resident.userId}`, {
              firstName: row.resident.firstName,
              lastName: row.resident.lastName,
              customerId: row.resident.customerId,
              tenant: row.resident,
            });
          }
        },
      },
    },
    {
      dataField: 'relevantBooking.startDate',
      text: t('startDate'),
      sort: true,
      align: 'center',
      headerStyle: { whiteSpace: 'nowrap' },
      formatter: (startDate) => `${startDate ? toDisplayDate(startDate) : ''}`,
      events: historyToResident(),
    },
    {
      dataField: 'nextBooking.startDate',
      text: t('nextTenantFrom'),
      sort: true,
      align: 'center',
      headerStyle: { whiteSpace: 'nowrap' },
      formatter: (startDate) => `${startDate ? toDisplayDate(startDate) : ''}`,
      events: historyToNextTenant(),
    },
    {
      dataField: 'nextBooking.tenant',
      text: t('nextTenant'),
      sort: true,
      align: 'center',
      formatter: (tenant) =>
        tenant ? `${tenant.firstName} ${tenant.lastName}` : '',
      events: historyToNextTenant(),
    },
    {
      dataField: 'freeAt',
      text: t('freeAt'),
      sort: true,
      align: 'center',
      formatter: freeAtFormatter,
      events: historyToRoom(),
    },
  ];

  const [selectedTenant, setSelectedTenant] = useState('');
  const [isModalOpen, setIsTenantModalOpen] = useState(false);

  const handleOpenRoomClick = (roomId) => history.push(`data/room/${roomId}`);

  return (
    <>
      {selectedTenant && (
        <TenantsDetailModal
          tenant={selectedTenant}
          isOpen={isModalOpen}
          onHide={() => setIsTenantModalOpen(false)}
          handleOpenRoomClick={handleOpenRoomClick}
          t={tModal}
        />
      )}

      <Rooms {...{ rooms, columns, filter, handleFilterChange, t }} />
    </>
  );
};

export default RoomsContainer;
