import { put, takeLatest, call } from 'redux-saga/effects';
import { accountApi } from 'utils/api';
import i18n from 'i18next';
import {
  UPDATE_SERVICE_PROVIDER_INFO,
  updateServiceProviderInfoSuccess,
  updateServiceProviderInfoFailure
} from './updateServiceProviderInfo.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';

function* updateServiceProviderInfo({
  firstName,
  lastName,
  email,
  street,
  houseNumber,
  location,
  postalCode
}) {
  try {
    const { data, errors } = yield call(
      accountApi.updateServiceProviderInfo,
      firstName,
      lastName,
      email,
      street,
      houseNumber,
      location,
      postalCode
    );

    if (!errors) {
      yield put(updateServiceProviderInfoSuccess(data));
      successToast('updateUserInfo');
    } else {
      yield put(updateServiceProviderInfoFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(updateServiceProviderInfoFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(UPDATE_SERVICE_PROVIDER_INFO, updateServiceProviderInfo);
}
