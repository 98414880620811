import { put, takeLatest, call } from 'redux-saga/effects';
import { ticketApi } from 'utils/api';
import i18n from 'i18next';
import {
  GET_TICKETS,
  getTicketsSuccess,
  getTicketsFailure
} from './getTickets.actions';

function* getTickets() {
  try {
    const { data, errors } = yield call(ticketApi.getTickets);

    if (!errors) {
      yield put(getTicketsSuccess(data));
    } else {
      yield put(getTicketsFailure(errors));
    }
  } catch (error) {
    yield put(getTicketsFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(GET_TICKETS, getTickets);
}
