import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button, FormControl } from 'react-bootstrap';
import { ErrorAlerts } from 'components';

const ResendConfirmationEmail = ({
  t,
  email,
  setEmail,
  errors,
  handleSubmit
}) => (
  <div className="center-at-page col-12 col-md-6 col-lg-3">
    <h4 className="text-left mt-5 mb-4">{t('enterEmail')}</h4>

    <ErrorAlerts errors={errors} />
    <Form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <FormControl
        placeholder={t('base:emailAddress')}
        value={email}
        onChange={setEmail}
        required
        type="email"
      ></FormControl>
      <Button className="w-100 mt-3" type="submit">
        {t('submit')}
      </Button>
    </Form>
  </div>
);

ResendConfirmationEmail.propTypes = {
  t: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default ResendConfirmationEmail;
