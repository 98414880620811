import React from 'react';
import RoomOverview from './Rooms';
import { useTranslation } from 'react-i18next';

const RoomOverviewContainer = () => {
  const { t } = useTranslation('data');

  return <RoomOverview {...{ t }} />;
};

RoomOverviewContainer.propTypes = {};

export default RoomOverviewContainer;
