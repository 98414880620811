import {
  createReducer,
  updateArray,
  pushToArray,
  updateItemInArray,
  removeItemInArray
} from 'utils/reducer.helper';

import { ADD_HOUSE_GIVER_SUCCESS } from './addHouseGiver/addHouseGiver.actions';
import { DELETE_HOUSE_GIVER_SUCCESS } from './deleteHouseGiver/deleteHouseGiver.actions';
import { GET_HOUSE_GIVER_SUCCESS } from './getHouseGiver/getHouseGiver.actions';
import { GET_HOUSE_GIVERS_SUCCESS } from './getHouseGivers/getHouseGivers.actions';
import { UPDATE_HOUSE_GIVER_SUCCESS } from './updateHouseGiver/updateHouseGiver.actions';

export default createReducer(
  {
    [GET_HOUSE_GIVERS_SUCCESS]: (state, action) =>
      updateArray(state, { ...action.payload }),
    [GET_HOUSE_GIVER_SUCCESS]: (state, action) =>
      updateItemInArray(state, action.payload.id, action.payload),
    [ADD_HOUSE_GIVER_SUCCESS]: (state, action) =>
      pushToArray(state, action.payload),
    [UPDATE_HOUSE_GIVER_SUCCESS]: (state, action) =>
      updateItemInArray(state, action.payload.id, action.payload),
    [DELETE_HOUSE_GIVER_SUCCESS]: (state, action) =>
      removeItemInArray(state, action.payload.id)
  },
  []
);
