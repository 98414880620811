export const SET_NEWSLETTER_SUBSCRIPTION = 'SET_NEWSLETTER_SUBSCRIPTION';
export const SET_NEWSLETTER_SUBSCRIPTION_SUCCESS =
  'SET_NEWSLETTER_SUBSCRIPTION_SUCCESS';
export const SET_NEWSLETTER_SUBSCRIPTION_FAILURE =
  'SET_NEWSLETTER_SUBSCRIPTION_FAILURE';
export const SET_NEWSLETTER_SUBSCRIPTION_RESET =
  'SET_NEWSLETTER_SUBSCRIPTION_RESET';

export const setNewsletterSubscription = (value) => ({
  type: SET_NEWSLETTER_SUBSCRIPTION,
  value
});
export const setNewsletterSubscriptionSuccess = payload => ({
  type: SET_NEWSLETTER_SUBSCRIPTION_SUCCESS,
  payload
});
export const setNewsletterSubscriptionFailure = errors => ({
  type: SET_NEWSLETTER_SUBSCRIPTION_FAILURE,
  errors
});

export const setNewsletterSubscriptionReset = () => ({
  type: SET_NEWSLETTER_SUBSCRIPTION_RESET
});
