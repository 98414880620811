export const UPDATE_BOOKING = 'UPDATE_BOOKING';
export const UPDATE_BOOKING_SUCCESS = 'UPDATE_BOOKING_SUCCESS';
export const UPDATE_BOOKING_FAILURE = 'UPDATE_BOOKING_FAILURE';

export const updateBooking = (
  id,
  startDate,
  durationInMonths,
  rentalFee,
  deposit
) => ({
  type: UPDATE_BOOKING,
  id,
  startDate,
  durationInMonths,
  rentalFee,
  deposit
});

export const updateBookingSuccess = payload => ({
  type: UPDATE_BOOKING_SUCCESS,
  payload
});

export const updateBookingFailure = errors => ({
  type: UPDATE_BOOKING_FAILURE,
  errors
});
