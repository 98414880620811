import { useState } from 'react';

const useInputState = (
  initialState,
  settings = { debug: false, debugMessage: 'VALUE' }
) => {
  const { debug, debugMessage } = settings;
  const [value, setValue] = useState(initialState);

  const handleValueChange = e => {
    setValue(e.target.value);
    if (debug) console.log(debugMessage || 'VALUE', e.target.value);
  };

  return [value, handleValueChange];
};

export default useInputState;
