import { put, takeLatest, call } from 'redux-saga/effects';
import { terminationApi } from 'utils/api';
import i18n from 'i18next';
import {
  DOES_TERMINATION_EXIST,
  doesTerminationExistSuccess,
  doesTerminationExistFailure
} from './doesTerminationExist.actions';

function* doesTerminationExist({ id }) {
  try {
    const { data, errors } = yield call(
      terminationApi.doesTerminationExist,
      id
    );

    if (!errors) {
      yield put(doesTerminationExistSuccess(data));
    } else {
      yield put(doesTerminationExistFailure(errors));
    }
  } catch (error) {
    yield put(doesTerminationExistFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(DOES_TERMINATION_EXIST, doesTerminationExist);
}
