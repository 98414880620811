import useTickets from 'hooks/store/useTickets';
import useUserRole from 'hooks/store/useUserRole';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { assignTicket } from 'store/ticket/assignTicket/assignTicket.actions';
import { closeTicket } from 'store/ticket/closeTicket/closeTicket.actions';
import { LANDLORD } from 'utils/constants/userRole.constants';
import { toDisplayDate } from 'utils/date.formatter';
import Tickets from './Tickets';

const TicketOverviewContainer = () => {
  const { t } = useTranslation('tickets');
  const history = useHistory();
  const dispatch = useDispatch();

  const [filter, setFilter] = useState(t('filter.open'));
  const [images, setImages] = useState([]);

  const handleSelectImages = (e) => {
    setImages([...images, ...e.target.files]);

    const previews = images.map((image) => {
      if (image.imageUrl) return image.imageUrl;
      else return URL.createObjectURL(image);
    });

    // setImagePreviews([
    //   ...previews,
    //   ...[...e.target.files].map((file) => URL.createObjectURL(file)),
    // ]);
  };

  const handleFilterChange = (e) => setFilter(e.target.value);

  const userRole = useUserRole();
  const tickets = useTickets((state) => {
    state.tickets.forEach((ticket) => {
      if (ticket.room) {
        ticket.referenceObject = {
          symbol: ticket.room.symbol,
          address: ticket.room.address,
          object: t('room'),
        };
      }
      if (ticket.flat) {
        ticket.referenceObject = {
          symbol: ticket.flat.symbol,
          address: ticket.flat.address,
          object: t('flat'),
        };
      }
      if (ticket.house) {
        ticket.referenceObject = {
          address: ticket.house.address,
          object: t('house'),
        };
      }
    });

    switch (filter) {
      case t('filter.open'):
        return state.tickets.filter((ticket) => !ticket.closed);
      case t('filter.closed'):
        return state.tickets.filter((ticket) => !!ticket.closed);
      default:
        return state.tickets;
    }
  }).map((ticket) => ({
    ...ticket,
    state: !ticket.closed ? t('open') : t('closed'),
  }));

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModal2Open, setIsModal2Open] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(tickets[0]);

  const rowEvents = {
    onClick: (_, ticket) => {
      setSelectedTicket(ticket);
      setIsModalOpen(true);
    },
    onMouseEnter: () => {},
  };
  const [selectedTenant, setSelectedTenant] = useState();
  const [isTenantModalOpen, setIsTenantModalOpen] = useState(false);
  const defaultSorted = [{ dataField: 'created', order: 'desc' }];

  const getTicketType = (row) => {
    if (row.isCleanUpTicket) {
      return t('cleanUp');
    }

    if (row.claimReport) {
      return t('claimReport');
    }

    return t('default');
  };

  const columns = [
    {
      dataField: 'referenceObject.symbol',
      text: t('roomSymbol'),
      sort: true,
    },
    {
      dataField: 'created',
      text: t('date'),
      sort: true,
      formatter: (date) => toDisplayDate(date),
    },
    {
      dataField: 'referenceObject.address',
      text: t('address'),
      sort: true,
      formatter: (referenceObject) => {
        if (referenceObject) {
          return `${referenceObject.street} ${referenceObject.houseNumber} ${referenceObject.postalCode} ${referenceObject.location}`;
        }
      },
    },
    {
      dataField: 'referenceObject.object',
      text: t('object'),
      sort: true,
    },
    {
      dataField: 'ticket.tenant',
      text: t('name'),
      sort: true,
      formatter: (_, row) =>
        row.tenant ? `${row.tenant.firstName} ${row.tenant.lastName}` : '',
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.tenant !== null && row.tenant && userRole === LANDLORD) {
            e.stopPropagation();
            setSelectedTenant(row.tenant);
            setIsTenantModalOpen(true);
          }
        },
      },
    },
    {
      dataField: 'serviceProvider',
      text: t('serviceProvider'),
      sort: true,
      formatter: (_, row) =>
        row.serviceProvider
          ? `${row.serviceProvider.firstName} ${row.serviceProvider.lastName}`
          : '',
    },
    {
      dataField: 'isCleanUpTicket',
      isDummyField: true,
      text: t('type'),
      sort: true,
      formatter: (_, row) => getTicketType(row),
      sortValue: (_, row) => getTicketType(row),
    },
    {
      dataField: 'state',
      text: t('state'),
      sort: true,
      formatter: (state) => (
        <span style={{ color: state === t('open') ? 'red' : 'green' }}>
          {state}
        </span>
      ),
    },
  ];

  const handleNewTicketClick = () => history.push('/dashboard/tickets/add');

  const handleCloseTicket = (ticketId, amount) =>
    dispatch(closeTicket(ticketId, amount));

  const handleAssignTicket = (ticketId, serviceProviderId) =>
    dispatch(assignTicket(ticketId, serviceProviderId));

  return (
    <Tickets
      {...{
        tickets,
        rowEvents,
        columns,
        filter,
        handleFilterChange,
        t,
        handleNewTicketClick,
        userRole,
        isModalOpen,
        selectedTicket,
        setIsModalOpen,
        handleCloseTicket,
        isModal2Open,
        setIsModal2Open,
        isTenantModalOpen,
        selectedTenant,
        setIsTenantModalOpen,
        defaultSorted,
        handleAssignTicket,
        handleSelectImages,
      }}
    />
  );
};

export default TicketOverviewContainer;
