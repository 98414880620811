import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Button, Spinner } from 'react-bootstrap';
import RoomPreview from './components/RoomPreview';
import Filter from './filter/Filter';

const LandingPage = ({
  rooms,
  t,
  history,
  handleOnSearch,
  roomRef,
  handleScrollToRooms,
  cities,
  roomsLoading
}) => (
  <div>
    <div
      style={{
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        textAlign: 'center'
      }}
      className="py-5 picture"
    >
      <h1 className="ribbon" id="content-desktop">
        <div
          className="ribbon-content-wrapper"
          href="https://www.wg-gesucht.de/"
          id="content-desktop"
        >
          <a
            href="https://www.wg-gesucht.de/"
            style={{ color: 'white' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong className="ribbon-content" style={{ fontSize: 'x-large' }}>
              {t('verified')} WG-GESUCHT.de
            </strong>
          </a>
        </div>
      </h1>
      <div
        className="sideRibbon sideRibbon-top-left"
        style={{ left: 0 }}
        id="content-mobile"
      >
        <a href="https://www.wg-gesucht.de/">
          <span>
            {t('room:verified')}
            <br /> WG-GESUCHT.de
          </span>
        </a>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: 150,
          borderRadius: '100%',
          alignItems: 'center'
        }}
      >
        <div
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            padding: 20,
            borderRadius: 5
          }}
        >
          <h1
            className="text-center"
            style={{
              color: 'white',
              fontWeight: 'bold'
            }}
          >
            Student Housing Duisburg
          </h1>
          <div style={{ color: 'white', fontSize: 24 }}>{t('welcome')}</div>
        </div>
      </div>

      <Filter onSearch={handleOnSearch} cities={cities} className="mt-md-5" />
      <div className="mt-md-5 mt-5">
        <Button onClick={handleScrollToRooms}>{t('toRooms')}</Button>
      </div>
    </div>
    <Container ref={roomRef}>
      <Row className="mx-0 mx-lg-3 py-4">
        {roomsLoading && (
          <div className="d-flex flex-direction-row align-items-center">
            <Spinner animation="border" variant="primary" />
            <div style={{ marginLeft: 10 }}>{t('roomsLoading')}</div>
          </div>
        )}

        {rooms.length > 0 &&
          !roomsLoading &&
          rooms.map(room => (
            <RoomPreview key={room.id} {...room} history={history} t={t} />
          ))}
        {rooms.length <= 0 && !roomsLoading && <div>{t('noRooms')}</div>}
      </Row>
    </Container>
  </div>
);

LandingPage.propTypes = {
  handleLogoutClick: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  rooms: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  handleOnSearch: PropTypes.func.isRequired,
  handleScrollToRooms: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  roomRef: PropTypes.object.isRequired,
  roomsLoading: PropTypes.bool.isRequired,
  cities: PropTypes.array.isRequired
};

export default LandingPage;
