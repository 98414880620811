import Content from 'components/Content';
import { HouseOwnerList } from 'containers';
import PropTypes from 'prop-types';
import React from 'react';

const HouseOwners = ({ t }) => (
  <Content>
    <h2>{t('houseOwner.houseOwner')}</h2>
    <HouseOwnerList />
  </Content>
);

HouseOwners.propTypes = {
  t: PropTypes.func.isRequired
};

export default HouseOwners;
