import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { landlordApi } from 'utils/api';
import {
  GET_TENANTS_FOR_LANDLORD,
  getTenantsForLandlordSuccess,
  getTenantsForLandlordFailure
} from './getTenantsForLandlord.actions';

export function* getTenantsForLandlord() {
  try {
    const { data, errors } = yield call(landlordApi.getTenants);

    if (!errors) {
      yield put(getTenantsForLandlordSuccess(data));
    } else {
      yield put(getTenantsForLandlordFailure(errors));
    }
  } catch (error) {
    yield put(getTenantsForLandlordFailure([i18n.t('base:generalError')]));
  }
}

export default function*() {
  yield takeLatest(GET_TENANTS_FOR_LANDLORD, getTenantsForLandlord);
}
