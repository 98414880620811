import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  UPDATE_LANDLORD,
  UPDATE_LANDLORD_SUCCESS,
  UPDATE_LANDLORD_FAILURE,
  UPDATE_LANDLORD_CLEAR
} from './updateLandlord.actions';

export default createReducer(
  {
    [UPDATE_LANDLORD]: defaultRequest,
    [UPDATE_LANDLORD_SUCCESS]: defaultSuccess,
    [UPDATE_LANDLORD_FAILURE]: defaultError,
    [UPDATE_LANDLORD_CLEAR]: () => defaultInitialState
  },
  defaultInitialState
);
