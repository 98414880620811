export const GET_SERVICE_PROVIDERS = 'GET_SERVICE_PROVIDERS';
export const GET_SERVICE_PROVIDERS_SUCCESS = 'GET_SERVICE_PROVIDERS_SUCCESS';
export const GET_SERVICE_PROVIDERS_FAILURE = 'GET_SERVICE_PROVIDERS_FAILURE';

export const getServiceProviders = () => ({
  type: GET_SERVICE_PROVIDERS
});

export const getServiceProvidersSuccess = payload => ({
  type: GET_SERVICE_PROVIDERS_SUCCESS,
  payload
});

export const getServiceProvidersFailure = errors => ({
  type: GET_SERVICE_PROVIDERS_FAILURE,
  errors
});
