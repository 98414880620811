export const HANDLE_LOAD_USER = 'HANDLE_LOAD_USER';
export const HANDLE_LOAD_USER_SUCCESS = 'HANDLE_LOAD_USER_SUCCESS';
export const HANDLE_LOAD_USER_FAILURE = 'HANDLE_LOAD_USER_FAILURE';

export const handleLoadUser = jwt => ({
  type: HANDLE_LOAD_USER,
  jwt
});
export const handleLoadUserSuccess = payload => ({
  type: HANDLE_LOAD_USER_SUCCESS,
  payload
});
export const handleLoadUserFailure = errors => ({
  type: HANDLE_LOAD_USER_FAILURE,
  errors
});
