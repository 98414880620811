import PropTypes from 'prop-types';
import React from 'react';
import { FormControl } from 'react-bootstrap';

const PhoneNumberFormControl = ({
  value,
  handlePhoneNumberChange,
  handlePhoneNumberKeyDown,
  ...rest
}) => (
  <FormControl
    type="tel"
    placeholder="+49 1234 5648999"
    onChange={handlePhoneNumberChange}
    onKeyDown={handlePhoneNumberKeyDown}
    value={value}
    {...rest}
  />
);

PhoneNumberFormControl.propTypes = {
  value: PropTypes.string.isRequired,
  handlePhoneNumberChange: PropTypes.func.isRequired,
  handlePhoneNumberKeyDown: PropTypes.func.isRequired
};

export default PhoneNumberFormControl;
