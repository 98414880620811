import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';

const LoaderContainer = ({ prop, children, spinner }) => {
  const type = typeof prop;

  let successLoading = false;
  switch (type) {
    case 'boolean':
      if (prop) successLoading = true;
      break;
    case 'object':
      if (Object.keys(prop).length) successLoading = true;
      break;
    case 'array':
      if (prop.length > 0) successLoading = true;
      break;
    case 'undefined':
      successLoading = false;
      break;
    default:
      break;
  }

  return successLoading ? (
    children
  ) : spinner ? (
    <div className="center-at-page">
      <Spinner animation="border" variant="primary" />
    </div>
  ) : (
    <div />
  );
};

LoaderContainer.propTypes = {
  children: PropTypes.node.isRequired,
  prop: PropTypes.any.isRequired,
  spinner: PropTypes.bool.isRequired
};

LoaderContainer.defaultProps = {
  spinner: false
};

export default LoaderContainer;
