import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  DELETE_SERVICE_PROVIDER,
  DELETE_SERVICE_PROVIDER_SUCCESS,
  DELETE_SERVICE_PROVIDER_FAILURE,
  DELETE_SERVICE_PROVIDER_CLEAR
} from './deleteServiceProvider.actions';

export default createReducer(
  {
    [DELETE_SERVICE_PROVIDER]: defaultRequest,
    [DELETE_SERVICE_PROVIDER_SUCCESS]: defaultSuccess,
    [DELETE_SERVICE_PROVIDER_FAILURE]: defaultError,
    [DELETE_SERVICE_PROVIDER_CLEAR]: () => defaultInitialState
  },
  defaultInitialState
);
