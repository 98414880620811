import i18n from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';

const StreetFormGroup = ({ value, onChange, ...rest }) => (
  <FormGroup className="mb-3" controlId="formAddressStreet">
    <FormLabel>{i18n.t('forms:address.street')}</FormLabel>
    <FormControl value={value} onChange={onChange} name="street" {...rest} />
  </FormGroup>
);

StreetFormGroup.defaultProps = {
  value: ''
};

StreetFormGroup.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default StreetFormGroup;
