import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Content from 'components/Content/Content';
import { FlatList } from 'containers';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const House = ({
  house,
  handleEditHouseClick,
  handleDeleteHouseClick,
  t,
  flatSelector
}) => (
  <Content>
    <OverlayTrigger
      placement="top"
      delay={{ show: 300, hide: 400 }}
      overlay={<Tooltip>{t('house.editHouse')}</Tooltip>}
    >
      <Button
        style={{ float: 'right' }}
        onClick={handleEditHouseClick}
        variant="secondary"
      >
        <FontAwesomeIcon icon={faPen} />
      </Button>
    </OverlayTrigger>
    <OverlayTrigger
      placement="top"
      delay={{ show: 300, hide: 400 }}
      overlay={<Tooltip>{t('house.deleteHouse')}</Tooltip>}
    >
      <Button
        style={{ float: 'right', marginRight: 20 }}
        onClick={handleDeleteHouseClick}
        variant="danger"
      >
        <FontAwesomeIcon icon={faTrash} />
      </Button>
    </OverlayTrigger>

    <h2>
      {`${t('house.house')} - ${house.address.street} ${
        house.address.houseNumber
      }`}
    </h2>
    <h5>{t('house.address')}</h5>
    <span>
      {`${house.address.postalCode} ${house.address.location} ${house.address.street} ${house.address.houseNumber}`}
    </span>
    <h5>{t('house.levels')}</h5>
    <span>{house.levels}</span>
    <h5>{t('house.minutesToTrainStation')}</h5>
    <span>{house.minutesToTrainStation}</span>
    <h5 style={{ marginTop: '20px' }}>{t('house.flats')}</h5>
    <FlatList flatSelector={flatSelector} />
  </Content>
);

House.propTypes = {
  house: PropTypes.object.isRequired,
  handleEditHouseClick: PropTypes.func.isRequired,
  handleDeleteHouseClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  flatSelector: PropTypes.func.isRequired
};

export default House;
