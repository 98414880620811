import { createReducer, updateArray } from 'utils/reducer.helper';

import { GET_DEBTS_SUCCESS } from './getDebts/getDebts.actions';

export default createReducer(
  {
    [GET_DEBTS_SUCCESS]: (state, action) =>
      updateArray(state, { ...action.payload })
  },
  []
);
