import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { serviceProviderApi } from 'utils/api';
import {
  DELETE_SERVICE_PROVIDER,
  deleteServiceProviderSuccess,
  deleteServiceProviderFailure
} from './deleteServiceProvider.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';

function* deleteServiceProviderSaga({ id }) {
  try {
    const { data, errors } = yield call(
      serviceProviderApi.deleteServiceProvider,
      id
    );

    if (!errors) {
      yield put(deleteServiceProviderSuccess(data));
      successToast('deleteServiceProvider');
    } else {
      yield put(deleteServiceProviderFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(deleteServiceProviderFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(DELETE_SERVICE_PROVIDER, deleteServiceProviderSaga);
}
