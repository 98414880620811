import { put, takeLatest, call } from 'redux-saga/effects';
import { terminationApi } from 'utils/api';
import i18n from 'i18next';
import {
  GET_TERMINATION,
  getTerminationSuccess,
  getTerminationFailure
} from './getTermination.actions';

function* getTermination() {
  try {
    const { data, errors } = yield call(terminationApi.getTermination);

    if (!errors) {
      yield put(getTerminationSuccess(data));
    } else {
      yield put(getTerminationFailure(errors));
    }
  } catch (error) {
    yield put(getTerminationFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(GET_TERMINATION, getTermination);
}
