import { put, takeLatest, call } from 'redux-saga/effects';
import { creditNotesApi } from 'utils/api';
import i18n from 'i18next';
import {
  GET_ALL_CREDITNOTES,
  getAllCreditNotesSuccess,
  getAllCreditNotesFailure
} from './getAllCreditNotes.actions';

function* getAllCreditNotes() {
  try {
    const { data, errors } = yield call(creditNotesApi.getAllCreditNotes);
    if (!errors && data.errors.length === 0) {
      yield put(getAllCreditNotesSuccess(data.resultObject));
    } else {
      yield put(getAllCreditNotesFailure(errors || data.errors));
    }
  } catch (error) {
    yield put(getAllCreditNotesFailure(i18n.t('base:generalError')));
  }
}

export default function* () {
  yield takeLatest(GET_ALL_CREDITNOTES, getAllCreditNotes);
}
