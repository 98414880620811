import PropTypes from 'prop-types';
import React from 'react';
import { Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const ServiceProviderTypeBadges = ({ types }) => {
  const { t } = useTranslation('data');

  const getBackgroundColor = type => {
    switch (type) {
      case 'caretaker':
        return '#ef476f';
      case 'cleaning':
        return '#ffd166';
      case 'sanitary':
        return '#06d6a0';
      case 'electrician':
        return '#118ab2';
      case 'lockSmith':
        return '#073b4c';
      default:
        return '#ef476f';
    }
  };

  return types.map(x => {
    return (
      <Badge
        style={{
          fontWeight: 'inherit'
        }}
        ref={el => {
          if (el) {
            el.style.setProperty(
              'background-color',
              getBackgroundColor(x),
              'important'
            );
          }
        }}
        key={x}
        pill
        className="me-1"
      >
        {t(`serviceProvider.${x}`)}
      </Badge>
    );
  });
};

ServiceProviderTypeBadges.propTypes = {
  types: PropTypes
};

export default ServiceProviderTypeBadges;
