import {
  createReducer,
  updateArray,
  updateItemInArray,
  removeItemInArray,
  pushToArray
} from 'utils/reducer.helper';

import { GET_SERVICE_PROVIDERS_SUCCESS } from './getServiceProviders/getServiceProviders.actions';
import { ADD_SERVICE_PROVIDER_SUCCESS } from './addServiceProvider/addServiceProvider.actions';
import { DELETE_SERVICE_PROVIDER_SUCCESS } from './deleteServiceProvider/deleteServiceProvider.actions';
import { GET_SERVICE_PROVIDER_SUCCESS } from './getServiceProvider/getServiceProvider.actions';
import { UPDATE_SERVICE_PROVIDER_SUCCESS } from './updateServiceProvider/updateServiceProvider.actions';

export default createReducer(
  {
    [GET_SERVICE_PROVIDERS_SUCCESS]: (state, action) =>
      updateArray(state, { ...action.payload }),
    [GET_SERVICE_PROVIDER_SUCCESS]: (state, action) =>
      updateItemInArray(state, action.payload.id, action.payload),
    [ADD_SERVICE_PROVIDER_SUCCESS]: (state, action) =>
      pushToArray(state, action.payload),
    [UPDATE_SERVICE_PROVIDER_SUCCESS]: (state, action) =>
      updateItemInArray(state, action.payload.id, action.payload),
    [DELETE_SERVICE_PROVIDER_SUCCESS]: (state, action) =>
      removeItemInArray(state, action.payload.id)
  },
  []
);
