import Content from 'components/Content/Content';
import { RoomForm } from 'containers';
import PropTypes from 'prop-types';
import React from 'react';

const EditRoom = ({ t, room, handleSubmit, isLoading }) => (
  <Content>
    <h2>{t('editRoom.editRoom')}</h2>
    <RoomForm
      locationOfRoom={room.locationOfRoom}
      symbol={room.symbol}
      deposit={room.deposit}
      description={room.description}
      flatId={room.flat.id}
      houseId={room.house.id}
      images={room.images}
      price={room.price}
      freeAt={room.freeAt}
      squareMeters={room.squareMeters}
      equipment={room.equipment}
      onSubmit={handleSubmit}
      isLoading={isLoading}
    />
  </Content>
);

EditRoom.propTypes = {
  t: PropTypes.func.isRequired,
  room: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default EditRoom;
