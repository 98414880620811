import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ForgotPassword from './ForgotPassword';
import useInputState from 'hooks/useInputState';
import { forgotPassword } from 'store/account/forgotPassword/forgotPassword.actions';

const ForgotPasswordContainer = () => {
  const { t } = useTranslation('forgotPassword');
  const dispatch = useDispatch();

  const [email, setEmail] = useInputState('');

  const handleSubmit = () => {
    dispatch(forgotPassword(email));
  };

  const { success, loading, errors: apiErrors } = useSelector(
    state => state.request.forgotPassword
  );

  return (
    <ForgotPassword
      {...{
        t,
        handleSubmit,
        handleEmailChange: setEmail,
        loading,
        email
      }}
    />
  );
};

export default ForgotPasswordContainer;
