import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  UPDATE_EMAIL,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_EMAIL_FAILURE,
  UPDATE_EMAIL_CLEAR
} from './updateEmail.actions';

export default createReducer(
  {
    [UPDATE_EMAIL]: defaultRequest,
    [UPDATE_EMAIL_SUCCESS]: defaultSuccess,
    [UPDATE_EMAIL_FAILURE]: defaultError,
    [UPDATE_EMAIL_CLEAR]: () => defaultInitialState
  },
  defaultInitialState
);
