export const GET_CREDIT_SETTINGS = 'GET_CREDIT_SETTINGS';
export const GET_CREDIT_SETTINGS_SUCCESS = 'GET_CREDIT_SETTINGS_SUCCESS';
export const GET_CREDIT_SETTINGS_FAILURE = 'GET_CREDIT_SETTINGS_FAILURE';
export const GET_CREDIT_SETTINGS_RESET = 'GET_CREDIT_SETTINGS_RESET';

export const getCreditSettings = () => ({
  type: GET_CREDIT_SETTINGS
});
export const getCreditSettingsSuccess = payload => ({
  type: GET_CREDIT_SETTINGS_SUCCESS,
  payload
});
export const getCreditSettingsFailure = errors => ({
  type: GET_CREDIT_SETTINGS_FAILURE,
  errors
});

export const getCreditSettingsReset = () => ({
  type: GET_CREDIT_SETTINGS_RESET
});
