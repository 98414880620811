export const UPDATE_SERVICE_PROVIDER_INFO = 'UPDATE_SERVICE_PROVIDER_INFO';
export const UPDATE_SERVICE_PROVIDER_INFO_SUCCESS =
  'UPDATE_SERVICE_PROVIDER_INFO_SUCCESS';
export const UPDATE_SERVICE_PROVIDER_INFO_FAILURE =
  'UPDATE_SERVICE_PROVIDER_INFO_FAILURE';

export const updateServiceProviderInfo = (
  firstName,
  lastName,
  email,
  street,
  houseNumber,
  location,
  postalCode
) => ({
  type: UPDATE_SERVICE_PROVIDER_INFO,
  firstName,
  lastName,
  email,
  street,
  houseNumber,
  location,
  postalCode
});
export const updateServiceProviderInfoSuccess = payload => ({
  type: UPDATE_SERVICE_PROVIDER_INFO_SUCCESS,
  payload
});
export const updateServiceProviderInfoFailure = errors => ({
  type: UPDATE_SERVICE_PROVIDER_INFO_FAILURE,
  errors
});
