import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  UPDATE_SERVICE_PROVIDER,
  UPDATE_SERVICE_PROVIDER_SUCCESS,
  UPDATE_SERVICE_PROVIDER_FAILURE,
  UPDATE_SERVICE_PROVIDER_CLEAR
} from './updateServiceProvider.actions';

export default createReducer(
  {
    [UPDATE_SERVICE_PROVIDER]: defaultRequest,
    [UPDATE_SERVICE_PROVIDER_SUCCESS]: defaultSuccess,
    [UPDATE_SERVICE_PROVIDER_FAILURE]: defaultError,
    [UPDATE_SERVICE_PROVIDER_CLEAR]: () => defaultInitialState
  },
  defaultInitialState
);
