import PropTypes from 'prop-types';
import React from 'react';
import { FormCheck } from 'react-bootstrap';

const RoomEquipmentFormGroup = ({
  allInclusive,
  furnished,
  internet,
  kitchen,
  tenancyAgreementInclusive,
  washer,
  onAllInclusiveChange,
  onFurnishedChange,
  onInternetChange,
  onKitchenChange,
  onTenancyAgreementInclusiveChange,
  onWasherChange,
  t
}) => (
  <>
    <FormCheck
      type="checkbox"
      label={t('roomForm.furnished')}
      checked={furnished}
      onChange={onFurnishedChange}
    />
    <FormCheck
      type="checkbox"
      label={t('roomForm.washer')}
      checked={washer}
      onChange={onWasherChange}
    />
    <FormCheck
      type="checkbox"
      label={t('roomForm.internet')}
      checked={internet}
      onChange={onInternetChange}
    />
    <FormCheck
      type="checkbox"
      label={t('roomForm.kitchen')}
      checked={kitchen}
      onChange={onKitchenChange}
    />
    <FormCheck
      type="checkbox"
      label={t('roomForm.allInclusive')}
      checked={allInclusive}
      onChange={onAllInclusiveChange}
    />
    <FormCheck
      type="checkbox"
      label={t('roomForm.tenancyAgreementInclusive')}
      checked={tenancyAgreementInclusive}
      onChange={onTenancyAgreementInclusiveChange}
    />
  </>
);

RoomEquipmentFormGroup.propTypes = {
  allInclusive: PropTypes.bool,
  furnished: PropTypes.bool,
  internet: PropTypes.bool,
  kitchen: PropTypes.bool,
  tenancyAgreementInclusive: PropTypes.bool,
  washer: PropTypes.bool,
  onAllInclusiveChange: PropTypes.func.isRequired,
  onFurnishedChange: PropTypes.func.isRequired,
  onInternetChange: PropTypes.func.isRequired,
  onKitchenChange: PropTypes.func.isRequired,
  onTenancyAgreementInclusiveChange: PropTypes.func.isRequired,
  onWasherChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default RoomEquipmentFormGroup;
