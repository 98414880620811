import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  UPDATE_FLAT,
  UPDATE_FLAT_SUCCESS,
  UPDATE_FLAT_FAILURE,
  UPDATE_FLAT_CLEAR
} from './updateFlat.actions';

export default createReducer(
  {
    [UPDATE_FLAT]: defaultRequest,
    [UPDATE_FLAT_SUCCESS]: defaultSuccess,
    [UPDATE_FLAT_FAILURE]: defaultError,
    [UPDATE_FLAT_CLEAR]: () => defaultInitialState
  },
  defaultInitialState
);
