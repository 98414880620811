import React from 'react';
import PropTypes from 'prop-types';
import {
  StreetFormGroup,
  HouseNumberFormGroup,
  PostalCodeFormGroup,
  LocationFormGroup,
  LatitudeFormGroup,
  LongitudeFormGroup
} from 'components';

const AddressFormGroup = ({ address, onChange, showCoordinates }) => (
  <>
    <StreetFormGroup value={address.street} onChange={onChange} required />

    <HouseNumberFormGroup
      value={address.houseNumber}
      onChange={onChange}
      required
    />

    <PostalCodeFormGroup
      value={address.postalCode}
      onChange={onChange}
      required
    />

    <LocationFormGroup value={address.location} onChange={onChange} required />

    {showCoordinates && (
      <>
        <LatitudeFormGroup
          value={address.latitude}
          onChange={onChange}
          required
        />
        <LongitudeFormGroup
          value={address.longitude}
          onChange={onChange}
          required
        />
      </>
    )}
  </>
);

AddressFormGroup.propTypes = {
  address: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  showCoordinates: PropTypes.bool.isRequired
};

export default AddressFormGroup;
