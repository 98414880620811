import API from './API';

const prefix = 'depositSettlement';

export const approveDepositSettlement = (id) =>
  API.put(`${prefix}/approve/${id}`);

export default {
  approveDepositSettlement,
};
