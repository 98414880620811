import register from './register_en.json';
import login from './login_en.json';
import setBaseData from './setBaseData_en.json';
import genders from './genders_en.json';
import jobs from './jobs_en.json';
import room from './room_en.json';
import bookingOverview from './bookingOverview_en.json';
import payment from './payment_en.json';
import tenantProfile from './tenantProfile_en.json';
import tenantData from './tenantData_en.json';
import termination from './termination_en.json';
import confirmEmail from './confirmEmail_en.json';
import requestConfirmationMail from './requestConfirmationMail_en.json';
import landingPage from './landingPage_en.json';
import claimReportTypes from './claimReportTypes_en.json';
import claimReport from './claimReport_en.json';
import dashboard from './dashboard_en.json';
import claimReportOverview from './claimReportOverview_en.json';
import debtOverview from './debtOverview_en.json';
import base from './base_en.json';
import data from './data_en.json';
import ticketOverview from './ticketOverview_en.json';
import ticket from './ticket_en.json';
import header from './header_en.json';
import filter from './filter_en.json';
import documents from './documents_en.json';
import forms from './forms_en.json';
import rooms from './rooms_en.json';
import tenants from './tenants_en.json';
import claimReports from './claimReports_en.json';
import balance from './balance_en.json';
import tickets from './tickets_en.json';
import bookings from './bookings_en.json';
import statistics from './statistics_en.json';
import saga from './saga_en.json';
import faq from './faq_en.json';
import forgotPassword from './forgotPassword_en.json';
import resetPassword from './resetPassword_en.json';
import createNewTenant from './createNewTenant_en.json';
import activateAccount from './activateAccount_en.json';
import settings from './settings_en.json';
import registrationFlow from './registrationFlow_en.json';
import creditNotes from './creditNotes_en.json';
import earnMoney from './earnMoney_de.json';
import promotionRoomListItem from './promotionRoomListItem_en.json';
import serviceProviderProfile from './serviceProviderProfile_en.json';

export default {
  base,
  register,
  login,
  setBaseData,
  genders,
  jobs,
  room,
  bookingOverview,
  payment,
  tenantProfile,
  tenantData,
  termination,
  confirmEmail,
  requestConfirmationMail,
  landingPage,
  claimReportTypes,
  claimReport,
  dashboard,
  claimReportOverview,
  debtOverview,
  data,
  ticketOverview,
  ticket,
  header,
  filter,
  documents,
  forms,
  rooms,
  tenants,
  claimReports,
  balance,
  tickets,
  bookings,
  statistics,
  saga,
  faq,
  forgotPassword,
  resetPassword,
  createNewTenant,
  activateAccount,
  settings,
  registrationFlow,
  creditNotes,
  earnMoney,
  promotionRoomListItem,
  serviceProviderProfile
};
