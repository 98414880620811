import {
  createReducer,
  defaultError,
  defaultInitialState,
  defaultRequest,
  defaultSuccess,
} from 'utils/reducer.helper';
import {
  GET_TENANT_FOR_LANDLORD,
  GET_TENANT_FOR_LANDLORD_FAILURE,
  GET_TENANT_FOR_LANDLORD_SUCCESS,
} from './getTenantForLandlord.actions';

export default createReducer(
  {
    [GET_TENANT_FOR_LANDLORD]: defaultRequest,
    [GET_TENANT_FOR_LANDLORD_SUCCESS]: defaultSuccess,
    [GET_TENANT_FOR_LANDLORD_FAILURE]: defaultError,
  },
  defaultInitialState
);
