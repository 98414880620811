import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import LoginForm from './LoginForm';
import { ErrorAlerts } from 'components';
import { Link } from 'react-router-dom';

const Login = ({
  t,
  errors,
  emailConfimationError,
  isFromRegister,
  ...rest
}) => (
  <div className="d-flex justify-content-center">
    <div className="col-12 col-md-6 col-lg-4 my-5">
      <h1 className="text-center mb-5 mt-3 mt-md-0">
        {t('drweberimmobilien')}
      </h1>

      <Alert variant="primary" show={isFromRegister}>
        {t('fromRegister')}
      </Alert>

      <Alert variant="warning" show={emailConfimationError}>
        {t('emailNotConfirmed')}
        <Link to="/resendConfirmationEmail">{` ${t('requestNew')}`}</Link>
      </Alert>

      <ErrorAlerts errors={errors} />
      <LoginForm t={t} {...rest} />
    </div>
  </div>
);

Login.propTypes = {
  t: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
  emailConfimationError: PropTypes.string.isRequired,
  isFromRegister: PropTypes.bool.isRequired
};

export default Login;
