import { put, takeLatest, call, take } from 'redux-saga/effects';
import i18n from 'i18next';
import { houseApi } from 'utils/api';
import {
  GET_HOUSE_FLATS,
  getHouseFlatsSuccess,
  getHouseFlatsFailure
} from './getHouseFlats.actions';

import { getHouseSuccess } from '../getHouse/getHouse.actions';

function* getHouseFlats({ id }) {
  try {
    const { data: houseData, errors: houseError } = yield call(
      houseApi.getHouse,
      id
    );
    const { data, errors } = yield call(houseApi.getHouseFlats, id);

    if (!errors && !houseError) {
      yield put(getHouseSuccess(houseData));
      yield put(getHouseFlatsSuccess(data));
    } else {
      yield put(getHouseFlatsFailure(errors));
    }
  } catch (error) {
    yield put(getHouseFlatsFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(GET_HOUSE_FLATS, getHouseFlats);
}
