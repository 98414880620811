export const UPDATE_USERINFO = 'UPDATE_USERINFO';
export const UPDATE_USERINFO_SUCCESS = 'UPDATE_USERINFO_SUCCESS';
export const UPDATE_USERINFO_FAILURE = 'UPDATE_USERINFO_FAILURE';

export const updateUserInfo = (
  mobileNumber,
  nationality,
  birthDate,
  gender,
  job,
  firstName,
  lastName
) => ({
  type: UPDATE_USERINFO,
  mobileNumber,
  nationality,
  birthDate,
  gender,
  job,
  firstName,
  lastName
});
export const updateUserInfoSuccess = payload => ({
  type: UPDATE_USERINFO_SUCCESS,
  payload
});
export const updateUserInfoFailure = errors => ({
  type: UPDATE_USERINFO_FAILURE,
  errors
});
