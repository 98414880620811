import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Image from 'react-bootstrap/Image';
import { Equipment } from 'components';
import { InfoBoard } from '../../../../roomOverview/components';

const RoomSideView = ({ room }) => {
  const { t } = useTranslation();

  return (
    <>
      <Image
        src={room.images[0] ? room.images[0].imageUrl : null}
        className="roomOverviewImage"
        fluid
        style={{}}
      />
      <div className="mt-2">
        <InfoBoard
          {...{
            t: t,
            tenants: room.flat.totalTenants,
            floor: room.flat.level,
            distance: room.house.minutesToTrainStation,
            address: room.house.address,
            price: room.price,
            deposit: room.deposit,
            squareMeters: room.squareMeters,
            flatSquareMeters: room.flat.squareMeters,
            freeAt: room.freeAt
          }}
        />
      </div>
      <Equipment equipment={room.equipment} t={t} />
    </>
  );
};

RoomSideView.propTypes = {
  room: PropTypes.object.isRequired
};

export default RoomSideView;
