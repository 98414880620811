import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  GET_FLAT,
  GET_FLAT_SUCCESS,
  GET_FLAT_FAILURE
} from './getFlat.actions';

export default createReducer(
  {
    [GET_FLAT]: defaultRequest,
    [GET_FLAT_SUCCESS]: defaultSuccess,
    [GET_FLAT_FAILURE]: defaultError
  },
  defaultInitialState
);
