import i18n from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { FormControl, FormGroup, FormLabel, InputGroup } from 'react-bootstrap';

const PriceFormGroup = ({ value, onChange, min, step, ...rest }) => (
  <FormGroup className="mb-3" controlId="formPrice">
    <FormLabel>{i18n.t('forms:price')}</FormLabel>
    <InputGroup>
      <FormControl
        value={value}
        onChange={onChange}
        name="price"
        type="number"
        min={min}
        step={step}
        {...rest}
      />
      <InputGroup.Text>{i18n.t('base:currencySymbol')}</InputGroup.Text>
    </InputGroup>
  </FormGroup>
);

PriceFormGroup.defaultProps = {
  value: '0',
  min: '0',
  step: '1'
};

PriceFormGroup.propTypes = {
  value: PropTypes.string,
  min: PropTypes.string,
  step: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default PriceFormGroup;
