import useInputState from 'hooks/useInputState';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ServiceProviderForm from './ServiceProviderForm';

const ServiceProviderFormContainer = ({
  onSubmit,
  firstName: _firstName,
  lastName: _lastName,
  email: _email,
  serviceProviderTypes: _serviceProviderTypes,
  houseIds: _houseIds,
  isLoading
}) => {
  const [firstName, setFirstName] = useInputState(_firstName);
  const [lastName, setLastName] = useInputState(_lastName);
  const [email, setEmail] = useInputState(_email);
  const [serviceProviderTypes, setServiceProviderTypes] = useState(
    _serviceProviderTypes
  );
  const [houseIds, setHouseIds] = useState(_houseIds);

  const handleSubmit = e => {
    e.preventDefault();
    onSubmit({
      firstName,
      lastName,
      email,
      serviceProviderTypes,
      houseIds
    });
  };

  return (
    <ServiceProviderForm
      {...{
        firstName,
        setFirstName,
        lastName,
        setLastName,
        email,
        setEmail,
        handleSubmit,
        serviceProviderTypes,
        setServiceProviderTypes,
        isLoading,
        houseIds,
        setHouseIds
      }}
    />
  );
};

ServiceProviderFormContainer.defaultProps = {
  onSubmit: () => {},
  firstName: '',
  lastName: '',
  email: '',
  serviceProviderTypes: []
};

ServiceProviderFormContainer.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  serviceProviderTypes: PropTypes.array,
  houseIds: PropTypes.array
};

export default ServiceProviderFormContainer;
