import PropTypes from 'prop-types';
import React from 'react';
import { Alert, Col, FormGroup, FormLabel, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import FormControl from 'react-bootstrap/FormControl';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {
  CheckBoxes,
  RentalDuration,
  StartOfRental
} from '../../../../../bookingOverview/components';

const CreatePayment = ({
  t,
  room,
  startOfRental,
  handleStartOfRentalChange,
  rentalDuration,
  handleRentalDuration,
  rental,
  handleSubmit,
  isSubmitEnabled,
  firstCheckBox,
  handleFirstCheckBoxChecked,
  secondCheckBox,
  handleSecondCheckBoxChecked,
  firstPossibleCheckinDate,
  promotionCode,
  handlePromotionCodeChange,
  handleValidatePromotionCode,
  loading,
  isPromotionCodeValid,
  promotionBonus,
  newTenant
}) => (
  <Container>
    <StartOfRental
      {...{
        t,
        room,
        handleStartOfRentalChange,
        firstPossibleCheckinDate,
        startOfRental,
        disabled: newTenant
      }}
    />
    <RentalDuration {...{ t, rentalDuration, handleRentalDuration }} />
    <div className="my-3 mt-4">
      <strong>{t('rental')}</strong>
      <div>{`${rental}€`}</div>
    </div>
    <div className="my-3">
      <strong>{t('hint')}</strong>
      <div>{t('hintText')}</div>
    </div>

    <div style={{ marginTop: 40 }}>
      <CheckBoxes
        {...{
          t,
          firstCheckBox,
          handleFirstCheckBoxChecked,
          secondCheckBox,
          handleSecondCheckBoxChecked
        }}
      />
    </div>

    <FormGroup className="mb-3" style={{ marginTop: '40px' }}>
      <FormLabel>{t('promotionCode')}</FormLabel>
      <Row>
        <Col lg={10} md={16}>
          <FormControl
            value={promotionCode}
            onChange={handlePromotionCodeChange}
          />
        </Col>
        <Col lg={2} md={16}>
          <Button
            onClick={handleValidatePromotionCode}
            style={{ width: '100%' }}
            disabled={loading}
            variant={
              isPromotionCodeValid === null
                ? 'primary'
                : isPromotionCodeValid === true
                ? 'success'
                : 'danger'
            }
          >
            Check
          </Button>
        </Col>
      </Row>
      <Alert variant={'info'} className="mt-3">
        {t('promotionCodeHint')
          .replace('{promotionBonus}', promotionBonus)
          .replace('{promotionBonus}', promotionBonus)}
      </Alert>
    </FormGroup>
    {isSubmitEnabled ? (
      <Button
        style={{ marginTop: '40px' }}
        onClick={handleSubmit}
        disabled={!isSubmitEnabled}
      >
        {t('submit')}
      </Button>
    ) : (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip>{t('submitTooltip')}</Tooltip>}
      >
        <div
          style={{
            display: 'inline-block',
            cursor: 'not-allowed',
            marginTop: '40px'
          }}
        >
          <Button
            style={{ pointerEvents: 'none' }}
            onClick={handleSubmit}
            disabled={!isSubmitEnabled}
          >
            {t('submit')}
          </Button>
        </div>
      </OverlayTrigger>
    )}
  </Container>
);

CreatePayment.propTypes = {
  t: PropTypes.func.isRequired,
  room: PropTypes.object.isRequired,
  startOfRental: PropTypes.string.isRequired,
  handleStartOfRentalChange: PropTypes.func.isRequired,
  rentalDuration: PropTypes.string.isRequired,
  handleRentalDuration: PropTypes.func.isRequired,
  rental: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitEnabled: PropTypes.bool.isRequired,
  firstCheckBox: PropTypes.bool.isRequired,
  handleFirstCheckBoxChecked: PropTypes.func.isRequired,
  secondCheckBox: PropTypes.bool.isRequired,
  handleSecondCheckBoxChecked: PropTypes.func.isRequired,
  firstPossibleCheckinDate: PropTypes.string.isRequired,
  promotionCode: PropTypes.string.isRequired,
  handlePromotionCodeChange: PropTypes.func.isRequired,
  handleValidatePromotionCode: PropTypes.func.isRequired,
  newTenant: PropTypes.bool.isRequired
};

export default CreatePayment;
