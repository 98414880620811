import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import RoomsList from './RoomList';
import useRooms from 'hooks/store/useRooms';

const RoomListContainer = ({ roomSelector }) => {
  const { t } = useTranslation('data');
  const history = useHistory();
  const rooms = useRooms(roomSelector);

  const columnConfiguration = [
    {
      dataField: 'symbol',
      text: t('rooms.symbol'),
      sort: true
    },
    {
      dataField: 'flat.symbol',
      text: t('rooms.flatSymbol'),
      sort: true
    },
    {
      dataField: 'squareMeters',
      text: t('rooms.size'),
      sort: true
    },
    {
      dataField: 'price',
      text: t('rooms.price'),
      sort: true
    }
  ];

  const handleNewRoomClick = () => history.push('/dashboard/data/room/add');
  const handleRoomRowClick = room =>
    history.push(`/dashboard/data/room/${room.id}`);

  return (
    <RoomsList
      {...{
        t,
        rooms,
        columnConfiguration,
        handleNewRoomClick,
        handleRoomRowClick
      }}
    />
  );
};

RoomListContainer.defaultProps = {
  roomSelector: state => state.rooms
};

RoomListContainer.propTypes = {
  roomSelector: PropTypes.func
};

export default RoomListContainer;
