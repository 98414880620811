import React from 'react';
import PropTypes from 'prop-types';
import OverviewTable from './OverviewTable';

const OverviewTableContainer = ({
  actionButton,
  searchable,
  keyField,
  rows,
  columns,
  onRowClick
}) => {
  const rowEvents = {
    onClick: (_, row) => onRowClick(row),
    onMouseEnter: () => {}
  };

  return (
    <OverviewTable
      {...{ columns, rows, rowEvents, keyField, searchable, actionButton }}
    />
  );
};

OverviewTableContainer.defaultProps = {
  actionButton: <div />,
  searchable: true,
  keyField: 'id'
};

OverviewTableContainer.propTypes = {
  actionButton: PropTypes.node,
  searchable: PropTypes.bool,
  keyField: PropTypes.string,
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  onRowClick: PropTypes.func.isRequired
};

export default OverviewTableContainer;
