export const GET_ALL_CREDITNOTES = 'GET_ALL_CREDITNOTES';
export const GET_ALL_CREDITNOTES_SUCCESS = 'GET_ALL_CREDITNOTES_SUCCESS';
export const GET_ALL_CREDITNOTES_FAILURE = 'GET_ALL_CREDITNOTES_FAILURE';
export const GET_ALL_CREDITNOTES_RESET = 'GET_ALL_CREDITNOTES_RESET';

export const getAllCreditNotes = () => ({
  type: GET_ALL_CREDITNOTES
});
export const getAllCreditNotesSuccess = payload => ({
  type: GET_ALL_CREDITNOTES_SUCCESS,
  payload
});
export const getAllCreditNotesFailure = errors => ({
  type: GET_ALL_CREDITNOTES_FAILURE,
  errors
});

export const getAllCreditNotesReset = () => ({
  type: GET_ALL_CREDITNOTES_RESET
});
