import { put, takeLatest, call } from 'redux-saga/effects';
import { promotionCodeApi } from 'utils/api';
import i18n from 'i18next';
import {
  VALIDATE_PROMOTIONCODE,
  validatePromotionCodeSuccess,
  validatePromotionCodeFailure
} from './validatePromotionCode.actions';

function* getCreditSettings({ promotionCode }) {
  try {
    const { data, errors } = yield call(
      promotionCodeApi.validate,
      promotionCode
    );
    if (!errors && data.errors.length === 0) {
      yield put(validatePromotionCodeSuccess(data.resultObject));
    } else {
      yield put(validatePromotionCodeFailure(errors || data.errors));
    }
  } catch (error) {
    yield put(validatePromotionCodeFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(VALIDATE_PROMOTIONCODE, getCreditSettings);
}
