import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RegistrationFlow from './RegistrationFlow';
import TenantRegisterContainer from '../registrationFlow/components/tenantRegister/TenantRegister.container';
import { getRoom } from 'store/room/getRoom/getRoom.actions';
import qs from 'qs';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const RegistrationFlowContainer = ({ match, location }) => {
  const [flowState, setFlowState] = useState('');
  const { roomId } = match.params;
  const { search } = location;

  const newTenant = location.state ? location.state.newTenant : {};

  const queryStrings = qs.parse(search, { ignoreQueryPrefix: true });
  const startOfRental = queryStrings.startofrental;
  const rentalDuration = queryStrings.rentalduration;
  const promotionCode = queryStrings.promotionCode;
  const deposit = queryStrings.deposit;
  const { t } = useTranslation('registrationFlow');

  const dispatch = useDispatch();
  const { success: tenantRegisterSuccess } = useSelector(
    state => state.request.registerTenant
  );
  const { success: confirmEmailByCodeSuccess } = useSelector(
    state => state.request.confirmEmailByCode
  );
  const { success: updateUserInfoSuccess } = useSelector(
    state => state.request.updateUserInfo
  );

  const registrationStep =
    flowState === 'registerTenant' || flowState === 'confirmEmail';

  const bookingStep =
    flowState === 'createPayment' || flowState === 'createBooking';

  const onCreateBooking = () => setFlowState('createBooking');
  const { data: room } = useSelector(state => state.request.getRoom);

  const savedInitialFlowState = localStorage.getItem('flowState', flowState);
  useEffect(() => {
    dispatch(getRoom(roomId));
    if (localStorage.getItem('email')) {
      setFlowState('confirmEmail');
    } else setFlowState('registerTenant');
    if (savedInitialFlowState) setFlowState(savedInitialFlowState);
  }, []);

  useEffect(() => {
    if (tenantRegisterSuccess) {
      setFlowState('confirmEmail');
      localStorage.setItem('flowState', 'confirmEmail');
      window.scrollTo(0,0)
    }
  }, [tenantRegisterSuccess]);

  useEffect(() => {
    if (confirmEmailByCodeSuccess) {
      setFlowState('createBaseData');
      localStorage.setItem('flowState', 'createBaseData');
      window.scrollTo(0,0)
    }
  }, [confirmEmailByCodeSuccess]);

  useEffect(() => {
    if (updateUserInfoSuccess) {
      setFlowState('createPayment');
      localStorage.setItem('flowState', 'createPayment');
      window.scrollTo(0,0)
    }
  }, [updateUserInfoSuccess]);

  return (
    <>
      {room && (
        <RegistrationFlow
          {...{
            flowState,
            TenantRegisterContainer,
            room,
            startOfRental,
            rentalDuration,
            deposit,
            onCreateBooking,
            registrationStep,
            bookingStep,
            promotionCode,
            t,
            newTenant
          }}
        />
      )}
    </>
  );
};
RegistrationFlowContainer.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default RegistrationFlowContainer;
