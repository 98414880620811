import i18n from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';

const LastNameFormGroup = ({ value, onChange, ...rest }) => (
  <FormGroup className="mb-3" controlId="formLastName">
    <FormLabel>{i18n.t('forms:user.lastName')}</FormLabel>
    <FormControl
      value={value}
      onChange={onChange}
      name="lastName"
      maxLength="50"
      {...rest}
    />
  </FormGroup>
);

LastNameFormGroup.defaultProps = {
  value: ''
};

LastNameFormGroup.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default LastNameFormGroup;
