import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { houseApi, addressApi } from 'utils/api';
import {
  UPDATE_HOUSE,
  updateHouseSuccess,
  updateHouseFailure
} from './updateHouse.actions';
import {
  updateAddressFailure,
  updateAddressSuccess
} from 'store/address/updateAddress/updateAddress.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';

function* updateHouse({
  houseId,
  levels,
  minutesToTrainStation,
  giverId,
  ownerId,
  serviceProviderIds,
  addressId,
  address: adressToChange
}) {
  try {
    const { data: address, errors: addressErrors } = yield call(
      addressApi.updateAddress,
      addressId,
      adressToChange
    );

    if (!addressErrors) {
      yield put(updateAddressSuccess(address));

      const { data: house, errors: houseErrors } = yield call(
        houseApi.updateHouse,
        houseId,
        addressId,
        parseInt(minutesToTrainStation),
        parseInt(levels),
        parseInt(giverId),
        parseInt(ownerId),
        serviceProviderIds
      );

      const houseWithAddress = {
        ...house,
        address
      };

      if (!houseErrors) {
        yield put(updateHouseSuccess(houseWithAddress));
        successToast('updateHouse');
      } else {
        yield put(updateHouseFailure(houseErrors));
      }
    } else {
      yield put(updateAddressFailure(addressErrors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(updateHouseFailure(i18n.t('base:generalError')));
    yield put(updateAddressFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(UPDATE_HOUSE, updateHouse);
}
