import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import OverviewTable from 'components/OverviewTable';

const HouseOwnerList = ({
  t,
  houseOwners,
  columnConfiguration,
  handleNewHouseOwnerClick,
  handleHouseOwnerRowClick
}) => (
  <OverviewTable
    rows={houseOwners}
    columns={columnConfiguration}
    onRowClick={handleHouseOwnerRowClick}
    actionButton={
      <OverlayTrigger
        placement="top"
        delay={{ show: 300, hide: 400 }}
        overlay={<Tooltip>{t('houseOwner.addHouseOwner')}</Tooltip>}
      >
        <Button className="float-md-right" onClick={handleNewHouseOwnerClick}>
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      </OverlayTrigger>
    }
  />
);

HouseOwnerList.propTypes = {
  t: PropTypes.func.isRequired,
  houseOwners: PropTypes.array.isRequired,
  columnConfiguration: PropTypes.array.isRequired,
  handleNewHouseOwnerClick: PropTypes.func.isRequired,
  handleHouseOwnerRowClick: PropTypes.func.isRequired
};

export default HouseOwnerList;
