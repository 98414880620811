import { LoadingButton } from 'components';
import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { toDisplayDate } from 'utils/date.formatter';
import PropTypes from 'prop-types';

const TerminatedInfo = ({
  termination,
  t,
  user,
  credentialSettings,
  handleCancelTermination
}) => (
  <>
    <ListGroup className="mb-3">
      <ListGroup.Item className="border-0">
        <b>
          {t('endrentagreement').replace(
            '{terminationDate}',
            toDisplayDate(termination.effectiveFrom)
          )}
        </b>
      </ListGroup.Item>
      <ListGroup.Item className="border-0">{t('deposit')}</ListGroup.Item>
      <ListGroup.Item className="border-0">{t('receive')}</ListGroup.Item>
      <ListGroup.Item className="border-0">{t('roomLeave')}</ListGroup.Item>
      <ListGroup.Item className="border-0">
        {t('keyService')
          .replace(
            '{terminationDate}',
            toDisplayDate(termination.effectiveFrom)
          )
          .replace('{roomSymbol}', user.booking.roomSymbol)}
      </ListGroup.Item>
      <ListGroup.Item className="border-0">
        {t('ageRequirement').replace(
          '{creditAdvertiser}',
          credentialSettings.creditAdvertiser
        )}
      </ListGroup.Item>
    </ListGroup>
    <LoadingButton onClick={handleCancelTermination} className="mb-2">
      {t('cancelTermination')}
    </LoadingButton>
  </>
);

TerminatedInfo.propTypes = {
  termination: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  credentialSettings: PropTypes.object.isRequired,
  handleCancelTermination: PropTypes.func.isRequired
};

export default TerminatedInfo;
