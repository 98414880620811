import React from 'react';
import PropTypes from 'prop-types';
import PromotionRoomListItem from './PromotionRoomListItem';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const PromotionRoomListItemContainer = ({ room }) => {
  const promotionCode = useSelector(state => state.user.data.promotionCode);
  const  {t}  = useTranslation('promotionRoomListItem');
  const moneyForAdvertised = useSelector(
    state => state.settings.credit.creditAdvertised + '€'
  );

  const promotionText =
    `Ich empfehle für Duisburg die voll möblierten Zimmer von student-housing-duisburg.de, wie z.B. dieses Zimmer hier: https://www.student-housing-duisburg.de/room/${room.id}. \n` +
    `Die sind sehr vertrauenswürdig und zuverlässig. Die Buchung der Zimmer erfolgt online. Verwende den Gutschein-Code ${promotionCode} und bezahle im 1. Monat ${moneyForAdvertised} weniger Miete.`;

  return <PromotionRoomListItem room={room} promotionText={promotionText} t={t} />;
};

PromotionRoomListItemContainer.propTypes = {
  room: PropTypes.object.isRequired
};

export default PromotionRoomListItemContainer;
