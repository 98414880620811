import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation('claimReportTypes');
  return [
    {
      value: t('electricity.electricity'),
      subtypes: [
        {
          value: t('electricity.lightBulb.lightBulb'),
          subtypes: [
            { value: t('electricity.lightBulb.myRoom') },
            { value: t('electricity.lightBulb.kitchen') },
            { value: t('electricity.lightBulb.bathRoom') },
            { value: t('electricity.lightBulb.corridor') },
            { value: t('electricity.lightBulb.stairwell') }
          ]
        },
        {
          value: t('electricity.noElectricity.noElectricity'),
          subtypes: [
            { value: t('electricity.noElectricity.myRoom') },
            { value: t('electricity.noElectricity.kitchen') },
            { value: t('electricity.noElectricity.bathRoom') },
            { value: t('electricity.noElectricity.corridor') }
          ]
        }
      ]
    },
    {
      value: t('heater.heater'),
      subtypes: [
        {
          value: t('heater.defekt'),
          subtypes: [
            { value: t('heater.myRoom') },
            { value: t('heater.kitchen') },
            { value: t('heater.bathRoom') }
          ]
        }
      ]
    },
    {
      value: t('internet.internet'),
      subtypes: [
        { value: t('internet.defekt') },
        { value: t('internet.slow') },
        { value: t('internet.noWifi') }
      ]
    },
    {
      value: t('washingMachine.washingMachine'),
      subtypes: [
        { value: t('washingMachine.noElectricity') },
        { value: t('washingMachine.other') }
      ]
    },
    {
      value: t('cleanliness.cleanliness'),
      subtypes: [{ value: t('cleanliness.roommates') }]
    },
    {
      value: t('kitchen.kitchen'),
      subtypes: [
        { value: t('kitchen.oven') },
        { value: t('kitchen.strove') },
        { value: t('kitchen.fridge') },
        { value: t('kitchen.sink') }
      ]
    },
    {
      value: t('bathroom.bathroom'),
      subtypes: [
        { value: t('bathroom.clogged.noWarmWater') },
        { value: t('bathroom.clogged.shower') },
        { value: t('bathroom.clogged.sink') },
        { value: t('bathroom.clogged.wc') },
        { value: t('bathroom.leaky.sink') },
        { value: t('bathroom.leaky.shower') },
        { value: t('bathroom.leaky.wc') },
        { value: t('bathroom.leaky.tap') }
      ]
    },
    {
      value: t('window.window'),
      subtypes: [
        { value: t('window.myRoom') },
        { value: t('window.kitchen') },
        { value: t('window.bathroom') }
      ]
    },
    {
      value: t('key.key'),
      subtypes: [
        {
          value: t('key.lost'),
          subtypes: [
            { value: t('key.allKeys') },
            { value: t('key.roomKey') },
            { value: t('key.flatKey') },
            { value: t('key.houseKey') }
          ]
        }
      ]
    },
    {
      value: t('roommate.roommate'),
      subtypes: [
        { value: t('roommate.loud') },
        { value: t('roommate.manyVisits') },
        { value: t('roommate.cleaning') },
        { value: t('roommate.aggressive') },
        { value: t('roommate.drugs') }
      ]
    },
    {
      value: t('mould.mould'),
      subtypes: [
        { value: t('mould.bath') },
        { value: t('mould.kitchen') },
        { value: t('mould.myRoom') }
      ]
    },
    {
      value: t('inventory.inventory'),
      subtypes: [
        { value: t('inventory.bed') },
        { value: t('inventory.closet') },
        { value: t('inventory.chair') },
        { value: t('inventory.desk') },
        { value: t('inventory.curtain') },
        { value: t('inventory.floor') },
        { value: t('inventory.door') },
        { value: t('inventory.flatDoor') },
        { value: t('inventory.houseDoor') }
      ]
    },
    {
      value: t('other')
    }
  ];
};
