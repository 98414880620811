import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createNewTenant } from 'store/tenants/createNewTenant/createNewTenant.actions';
import CreateNewTenant from './CreateNewTenant';

const CreateNewTenantContainer = () => {
  const { t } = useTranslation('createNewTenant');
  const dispatch = useDispatch();

  const { loading, success } = useSelector(
    state => state.request.createNewTenant
  );

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = () => {
    dispatch(createNewTenant(firstName, lastName, email, password));
  };

  const handleFirstNameChange = e => setFirstName(e.target.value);
  const handleLastNameChange = e => setLastName(e.target.value);
  const handleEmailChange = e => setEmail(e.target.value);
  const handlePasswordChange = e => setPassword(e.target.value);

  useEffect(() => {
    if (success) {
      setFirstName('');
      setLastName('');
      setEmail('');
      setPassword('');
    }
  }, [success]);

  return (
    <CreateNewTenant
      {...{
        t,
        loading,
        handleSubmit,
        firstName,
        handleFirstNameChange,
        lastName,
        handleLastNameChange,
        email,
        handleEmailChange,
        password,
        handlePasswordChange
      }}
    ></CreateNewTenant>
  );
};

export default CreateNewTenantContainer;
