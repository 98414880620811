import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Content from 'components/Content';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const HouseOwner = ({
  houseOwner,
  handleEdithouseOwnerClick,
  handleDeleteHouseOwnerClick,
  t
}) => (
  <Content>
    <OverlayTrigger
      placement="top"
      delay={{ show: 300, hide: 400 }}
      overlay={<Tooltip>{t('houseOwner.editHouseOwner')}</Tooltip>}
    >
      <Button
        style={{ float: 'right' }}
        onClick={handleEdithouseOwnerClick}
        variant="secondary"
      >
        <FontAwesomeIcon icon={faPen} />
      </Button>
    </OverlayTrigger>
    <OverlayTrigger
      placement="top"
      delay={{ show: 300, hide: 400 }}
      overlay={<Tooltip>{t('houseOwner.deleteHouseOwner')}</Tooltip>}
    >
      <Button
        style={{ float: 'right', marginRight: 20 }}
        onClick={handleDeleteHouseOwnerClick}
        variant="danger"
      >
        <FontAwesomeIcon icon={faTrash} />
      </Button>
    </OverlayTrigger>
    <h5>{t('houseOwner.firstName')}</h5>
    <span>{houseOwner.firstName}</span>
    <h5>{t('houseOwner.lastName')}</h5>
    <span>{houseOwner.lastName}</span>
    <h5>{t('houseOwner.address')}</h5>
    <span>{`${houseOwner.address.street} ${houseOwner.address.houseNumber} ${houseOwner.address.postalCode} ${houseOwner.address.location}`}</span>
  </Content>
);

HouseOwner.propTypes = {
  houseOwner: PropTypes.object.isRequired,
  handleDeleteHouseOwnerClick: PropTypes.func.isRequired,
  handleEdithouseOwnerClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default HouseOwner;
