import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col,
  FormControl,
  FormLabel,
  FormGroup,
  Badge,
  Image
} from 'react-bootstrap';

const ClaimReport = ({
  t,
  description,
  category1,
  category2,
  category3,
  images,
  state,
  date
}) => (
  <Container>
    <Row>
      <Col
        className="col-10"
        style={{
          margin: '50px auto',
          padding: '1em',
          border: '.2rem solid #ececec',
          borderWidth: '.2rem',
          borderRadius: '8px'
        }}
      >
        <div style={{ float: 'right' }}>
          {
            {
              0: <Badge variant="secondary">{t('unprocessed')}</Badge>,
              1: <Badge variant="primary">{t('inProcessing')}</Badge>,
              2: <Badge variant="success">{t('done')}</Badge>
            }[state]
          }
        </div>
        <h2>{t('claimReport')}</h2>
        <FormGroup className="mb-3">
          <FormLabel>{t('category1')}</FormLabel>
          <FormControl value={category1} disabled />
        </FormGroup>

        {category2 && (
          <FormGroup className="mb-3">
            <FormLabel>{t('category2')}</FormLabel>
            <FormControl value={category2} disabled />
          </FormGroup>
        )}

        {category3 && (
          <FormGroup className="mb-3">
            <FormLabel>{t('category3')}</FormLabel>
            <FormControl value={category3} disabled />
          </FormGroup>
        )}

        <FormGroup className="mb-3">
          <FormLabel>{t('description')}</FormLabel>
          <FormControl as="textarea" rows="6" value={description} disabled />
        </FormGroup>

        <FormGroup className="mb-3">
          <FormLabel>{t('date')}</FormLabel>
          <FormControl value={date} disabled />
        </FormGroup>

        <FormGroup className="mb-3">
          <FormLabel>{t('images')}</FormLabel>
          {images.map(image => (
            <Image
              key={image}
              src={image}
              style={{ maxWidth: '100%', marginBottom: '20px' }}
            />
          ))}
        </FormGroup>
      </Col>
    </Row>
  </Container>
);

ClaimReport.propTypes = {
  t: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  images: PropTypes.array.isRequired,
  state: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired
};

export default ClaimReport;
