export const GET_CLAIM_REPORT = 'GET_CLAIM_REPORT';
export const GET_CLAIM_REPORT_SUCCESS = 'GET_CLAIM_REPORT_SUCCESS';
export const GET_CLAIM_REPORT_FAILURE = 'GET_CLAIM_REPORT_FAILURE';

export const getClaimReport = id => ({
  type: GET_CLAIM_REPORT,
  id
});

export const getClaimReportSuccess = payload => ({
  type: GET_CLAIM_REPORT_SUCCESS,
  payload
});

export const getClaimReportFailure = errors => ({
  type: GET_CLAIM_REPORT_FAILURE,
  errors
});
