import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { useSelector } from 'react-redux';
import { LoadingButton } from 'components';

const SubmitButtonFormGroup = ({
  text,
  isLoadingSelector,
  isLoading,
  ...rest
}) => {
  const selector = isLoadingSelector || (state => state);
  const selected = useSelector(selector);

  const selectIsLoading = isLoadingSelector ? selected : false;

  const finalIsLoading = isLoading || selectIsLoading;
  return (
    <LoadingButton type="submit" {...rest} isLoading={finalIsLoading}>
      {i18n.t('forms:submit')}
    </LoadingButton>
  );
};

SubmitButtonFormGroup.defaultProps = {
  text: i18n.t('forms:submit'),
  isLoadingSelector: undefined,
  isLoading: undefined
};

SubmitButtonFormGroup.propTypes = {
  text: PropTypes.string,
  isLoadingSelector: PropTypes.func,
  isLoading: PropTypes.bool
};

export default SubmitButtonFormGroup;
