export const DELETE_SERVICE_PROVIDER = 'DELETE_SERVICE_PROVIDER';
export const DELETE_SERVICE_PROVIDER_SUCCESS =
  'DELETE_SERVICE_PROVIDER_SUCCESS';
export const DELETE_SERVICE_PROVIDER_FAILURE =
  'DELETE_SERVICE_PROVIDER_FAILURE';
export const DELETE_SERVICE_PROVIDER_CLEAR = 'DELETE_SERVICE_PROVIDER_CLEAR';

export const deleteServiceProvider = id => ({
  type: DELETE_SERVICE_PROVIDER,
  id
});
export const deleteServiceProviderSuccess = payload => ({
  type: DELETE_SERVICE_PROVIDER_SUCCESS,
  payload
});
export const deleteServiceProviderFailure = errors => ({
  type: DELETE_SERVICE_PROVIDER_FAILURE,
  errors
});
export const deleteServiceProviderClear = () => ({
  type: DELETE_SERVICE_PROVIDER_CLEAR
});
