import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import FormControl from 'react-bootstrap/FormControl';
import { toInputValue } from 'utils/date.formatter';
import { DateFormControl } from 'components';

const StartOfRental = ({
  t,
  startOfRental,
  handleStartOfRentalChange,
  firstPossibleCheckinDate,
  disabled
}) => (
  <div className="mb-3">
    <div>{t('selectStartOfRental')}</div>
    <div>
      <DateFormControl
        value={startOfRental}
        min={toInputValue(firstPossibleCheckinDate)}
        max={toInputValue(moment(firstPossibleCheckinDate).add(13, 'days'))}
        onChange={handleStartOfRentalChange}
        onKeyDown={() => false}
        isValid={moment(startOfRental).isBetween(
          moment(firstPossibleCheckinDate),
          moment(firstPossibleCheckinDate).add(13, 'days'),
          'date',
          '[]'
        )}
        disabled={disabled}
      />
    </div>
  </div>
);

StartOfRental.propTypes = {
  t: PropTypes.func.isRequired,
  startOfRental: PropTypes.string.isRequired,
  handleStartOfRentalChange: PropTypes.func.isRequired,
  firstPossibleCheckinDate: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default StartOfRental;
