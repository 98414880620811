import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import EditHouseGiver from './EditHouseGiver';
import { getHouseGiver } from 'store/houseGiver/getHouseGiver/getHouseGiver.actions';
import {
  updateHouseGiverClear,
  updateHouseGiver
} from 'store/houseGiver/updateHouseGiver/updateHouseGiver.actions';
import { Loader } from 'components';

const AddHouseGiverContainer = ({ match }) => {
  const { t } = useTranslation('data');
  const houseGiverId = parseInt(match.params.houseGiverId);
  const history = useHistory();
  const dispatch = useDispatch();

  const houseGivers = useSelector(state => state.houseGivers);
  const houseGiver = houseGivers.find(x => x.id === houseGiverId);

  useEffect(() => {
    dispatch(getHouseGiver(houseGiverId));
  }, []);

  const { success, loading: isLoading } = useSelector(
    state => state.request.updateHouseGiver
  );
  useEffect(() => {
    if (success) {
      dispatch(updateHouseGiverClear());
      history.push(`/dashboard/data/houseGiver/${houseGiver.id}`);
    }
  }, [success]);

  const onSubmit = ({ firstName, lastName, address }) =>
    dispatch(updateHouseGiver(houseGiverId, firstName, lastName, address));

  return houseGiver !== undefined ? (
    <Loader prop={houseGiver}>
      <EditHouseGiver {...{ houseGiver, onSubmit, t, isLoading }} />{' '}
    </Loader>
  ) : (
    <div />
  );
};

AddHouseGiverContainer.propTypes = { match: PropTypes.object.isRequired };

export default AddHouseGiverContainer;
