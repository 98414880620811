export const CREATE_TICKET = 'CREATE_TICKET';
export const CREATE_TICKET_SUCCESS = 'CREATE_TICKET_SUCCESS';
export const CREATE_TICKET_FAILURE = 'CREATE_TICKET_FAILURE';
export const CREATE_TICKET_CLEAR = 'CREATE_TICKET_CLEAR';

export const createTicket = (
  serviceProviderId,
  description,
  entityType,
  entityTypeId,
  intervalInDays,
  images
) => ({
  type: CREATE_TICKET,
  serviceProviderId,
  description,
  entityType,
  entityTypeId,
  intervalInDays,
  images
});
export const createTicketSuccess = payload => ({
  type: CREATE_TICKET_SUCCESS,
  payload
});
export const createTicketFailure = errors => ({
  type: CREATE_TICKET_FAILURE,
  errors
});
export const createTicketClear = () => ({
  type: CREATE_TICKET_CLEAR
});
