import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { accountApi } from 'utils/api';
import { setAccessToken } from 'utils/localStorage.adapter';
import {
  ACTIVATE_ACCOUNT,
  activateAccountSuccess,
  activateAccountFailure
} from './activateAccount.actions';
import { handleLoadUser } from '../getUser/user.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';

export function* activateAccount({ secret, email, password }) {
  try {
    const { data, errors } = yield call(
      accountApi.activateAccount,
      secret,
      email,
      password
    );

    if (!errors) {
      yield call(setAccessToken, data.token);
      yield put(handleLoadUser(data.token));
      yield put(activateAccountSuccess(data));
      successToast('activateAccount');
    } else {
      yield put(activateAccountFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(activateAccountFailure([i18n.t('base:generalError')]));
  }
}

export default function*() {
  yield takeLatest(ACTIVATE_ACCOUNT, activateAccount);
}
