import {
  AddressFormGroup,
  HouseGiverFormGroup,
  HouseOwnerFormGroup,
  LevelsFormGroup,
  MinutesToTrainStationFormGroup,
  SubmitButtonFormGroup
} from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';

const HouseForm = ({
  address,
  levels,
  giver,
  owner,
  minutesToTrainStation,
  handleAddressChange,
  handleLevelsChange,
  handleGiverChange,
  handleOwnerChange,
  handleMinutesToTrainStationChange,
  handleSubmit,
  isLoading
}) => (
  <>
    <Form onSubmit={handleSubmit}>
      <AddressFormGroup
        address={address}
        onChangeAddress={handleAddressChange}
      />

      <MinutesToTrainStationFormGroup
        value={minutesToTrainStation}
        onChange={handleMinutesToTrainStationChange}
        required
      />

      <LevelsFormGroup value={levels} onChange={handleLevelsChange} required />

      <HouseOwnerFormGroup
        onChange={handleOwnerChange}
        value={owner}
        required
      />

      <HouseGiverFormGroup
        onChange={handleGiverChange}
        value={giver}
        required
      />

      <SubmitButtonFormGroup isLoading={isLoading} />
    </Form>
  </>
);

HouseForm.propTypes = {
  address: PropTypes.object,
  levels: PropTypes.number,
  giver: PropTypes.number,
  serviceProvider: PropTypes.number,
  owner: PropTypes.number,
  minutesToTrainStation: PropTypes.number,
  handleAddressChange: PropTypes.func.isRequired,
  handleLevelsChange: PropTypes.func.isRequired,
  handleGiverChange: PropTypes.func.isRequired,
  handleOwnerChange: PropTypes.func.isRequired,
  handleMinutesToTrainStationChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  latitude: PropTypes.string.isRequired,
  longitude: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default HouseForm;
