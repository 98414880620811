import { put, takeLatest, call } from 'redux-saga/effects';
import { settingsApi } from 'utils/api';
import i18n from 'i18next';
import successToast from 'utils/successToast';
import errorToast from 'utils/errorToast';
import {
  SET_NEWSLETTER_SUBSCRIPTION,
  setNewsletterSubscriptionSuccess,
  setNewsletterSubscriptionFailure
} from './setNewsletterSubscription.actions';

function* setNewsletterSubscription({ value }) {
  try {
    const { data, errors } = yield call(
      settingsApi.setNewsletterSubscription,
      value
    );

    if (!errors) {
      yield put(setNewsletterSubscriptionSuccess());
      successToast('savedSettingsSuccess');
    } else {
      yield put(setNewsletterSubscriptionFailure(errors || data.errors));
      errorToast('savedSettingsError');
    }
  } catch (error) {
    yield put(setNewsletterSubscriptionFailure(i18n.t('base:generalError')));
    errorToast('savedSettingsError');
  }
}

export default function*() {
  yield takeLatest(SET_NEWSLETTER_SUBSCRIPTION, setNewsletterSubscription);
}
