import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FlatList from './FlatList';
import { getFlats } from 'store/flat/getFlats/getFlats.actions';

const FlatListContainer = ({ flatSelector }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation('data');

  const flats = useSelector(flatSelector);
  const { success } = useSelector(state => state.request.getFlats);

  useEffect(() => {
    if (!success) dispatch(getFlats());
  }, [success]);

  const columnConfiguration = [
    {
      dataField: 'symbol',
      text: t('flats.symbol'),
      sort: true
    },
    {
      dataField: 'level',
      text: t('flats.level'),
      sort: true
    },
    {
      dataField: 'squareMeters',
      text: t('flats.squareMeters'),
      sort: true
    }
  ];

  const handleNewFlatClick = () => history.push('/dashboard/data/flat/add');
  const handleFlatRowClick = flat =>
    history.push(`/dashboard/data/flat/${flat.id}`);

  return (
    <FlatList
      {...{
        t,
        flats,
        columnConfiguration,
        handleNewFlatClick,
        handleFlatRowClick
      }}
    />
  );
};

FlatListContainer.defaultProps = {
  flatSelector: state => state.flats
};

FlatListContainer.propTypes = {
  flatSelector: PropTypes.func
};

export default FlatListContainer;
