import useTenants from 'hooks/store/useTenants';
import PropTypes from 'prop-types';
import React from 'react';
import Select from '../shared/select';

const TenantsSelectContainer = ({ onChange, value, selector, ...rest }) => {
  const tenants = useTenants(selector).sort((a, b) => {
    if (!a.lastName && !b.lastName) return 0;
    if (!a.lastName) return 1;
    if (!b.lastName) return -1;
    return a.lastName.localeCompare(b.lastName);
  });

  return (
    <Select
      className="mb-3"
      onChange={onChange}
      options={tenants}
      value={value}
      output={(user) =>
        `${user.booking ? user.booking.roomSymbol : 'no room'}, ${
          user.customerId
        }, ${user.lastName}, ${user.firstName}`
      }
      selectProp="userId"
      {...rest}
    />
  );
};

TenantsSelectContainer.defaultProps = {
  onChange: () => {},
  selector: (state) => state.tenants,
};

TenantsSelectContainer.propTypes = {
  onChange: PropTypes.func,
  selector: PropTypes.func,
};

export default TenantsSelectContainer;
