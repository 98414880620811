import i18n from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';

const ImagesFormGroup = ({ onChange, multiple, ...rest }) => (
  <FormGroup className="mb-3" controlId="formImages">
    <FormLabel>
      {multiple ? i18n.t('forms:images') : i18n.t('forms:image')}
    </FormLabel>
    <FormControl
      type="file"
      accept="image/*"
      name="images"
      multiple={multiple}
      onChange={onChange}
      {...rest}
    />
  </FormGroup>
);

ImagesFormGroup.defaultProps = {
  multiple: true,
};

ImagesFormGroup.propTypes = {
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
};

export default ImagesFormGroup;
