import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  UPDATE_HOUSE,
  UPDATE_HOUSE_SUCCESS,
  UPDATE_HOUSE_FAILURE,
  UPDATE_HOUSE_CLEAR
} from './updateHouse.actions';

export default createReducer(
  {
    [UPDATE_HOUSE]: defaultRequest,
    [UPDATE_HOUSE_SUCCESS]: defaultSuccess,
    [UPDATE_HOUSE_FAILURE]: defaultError,
    [UPDATE_HOUSE_CLEAR]: () => defaultInitialState
  },
  defaultInitialState
);
