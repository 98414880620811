import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { toDisplayDate } from 'utils/date.formatter';
import PropTypes from 'prop-types';

const NextTenantBookedInfo = ({ termination, user, t, credentialSettings }) => (
  <>
    <ListGroup className="mb-3">
      <ListGroup.Item className="border-0">
        {t('newTenantHasBooked')}
      </ListGroup.Item>
      <ListGroup.Item className="border-0">
        {t('endrentagreement').replace(
          '{terminationDate}',
          toDisplayDate(termination.effectiveFrom)
        )}
      </ListGroup.Item>
      <ListGroup.Item className="border-0">{t('deposit')}</ListGroup.Item>
      <ListGroup.Item className="border-0">{t('receive')}</ListGroup.Item>
      <ListGroup.Item className="border-0">{t('roomLeave')}</ListGroup.Item>
      <ListGroup.Item className="border-0">
        {t('keyService')
          .replace(
            '{terminationDate}',
            toDisplayDate(termination.effectiveFrom)
          )
          .replace('{roomSymbol}', user.booking.roomSymbol)}
      </ListGroup.Item>
      <ListGroup.Item className="border-0">
        {t('newTenantBonus').replace(
          '{creditAdvertiser}',
          credentialSettings.creditAdvertiser
        )}
      </ListGroup.Item>
    </ListGroup>
  </>
);

NextTenantBookedInfo.propTypes = {
  termination: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  credentialSettings: PropTypes.object.isRequired
};

export default NextTenantBookedInfo;
