import { Loader } from 'components';
import useRooms from 'hooks/store/useRooms';
import moment from 'moment';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { doesTerminationExist } from 'store/termination/doesTerminationExist/doesTerminationExist.actions';
import { getRoom } from '../../store/room/getRoom/getRoom.actions';
import Room from './Room';

const RoomContainer = ({ match, location }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const { search } = location;
  const queryStrings = qs.parse(search);

  const tenantId = queryStrings['?tenantId'];
  const terminationDate = queryStrings.terminationDate;
  const moveInDate = queryStrings.moveInDate;
  const secret = queryStrings.secret;
  const terminationId = queryStrings.terminationId;
  const isNextTenantBooking = !!tenantId;

  const room = useSelector(state => state.room);
  const rooms = useRooms(state =>
    state.rooms.filter(
      x =>
        x.bookable &&
        moment(x.freeAt)
          .startOf('day')
          .isSameOrBefore(moment().startOf('day'))
    )
  );

  const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  };

  const randomStart = getRandomInt(0, rooms.length - 5);
  const moreRooms = rooms.slice(randomStart, randomStart + 5);
  const { data: user } = useSelector(state => state.user);

  const { loading: isLoading, data } = useSelector(
    state => state.request.doesTerminationExist
  );

  useEffect(() => {
    dispatch(getRoom(match.params.id));
  }, [match.params.id]);

  useEffect(() => {
    if (isNextTenantBooking) {
      dispatch(doesTerminationExist(terminationId));
    }
  }, [isNextTenantBooking]);

  const handleToBookingClick = () => {
    history.push(`/tenantRegister/${match.params.id}`, {
      redirect: history.location,
      newTenant: {
        terminationDate,
        moveInDate,
        secret,
        tenantId
      }
    });
  };

  return data === false ? (
    <Alert variant="warning">
      {t('room:terminationForNextTenantWithdrawn')}
      <Link className="ms-1" to="/">
        {t('room:toRooms')}
      </Link>
    </Alert>
  ) : (
    <Loader prop={room} spinner>
      {isNextTenantBooking && (
        <Alert variant="info">{t('room:nextTenantBooking')}</Alert>
      )}
      <Room {...{ t, handleToBookingClick, room, moreRooms, location }} />
    </Loader>
  );
};

RoomContainer.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default RoomContainer;
