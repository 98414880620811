import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  GET_PAYMENTS,
  GET_PAYMENTS_SUCCESS,
  GET_PAYMENTS_FAILURE
} from './getPayments.actions';

export default createReducer(
  {
    [GET_PAYMENTS]: defaultRequest,
    [GET_PAYMENTS_SUCCESS]: defaultSuccess,
    [GET_PAYMENTS_FAILURE]: defaultError
  },
  defaultInitialState
);
