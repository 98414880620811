import API from './API';

const prefix = 'creditNotes';

export const getOfferedCreditNotes = () => API.get(`${prefix}/offered`);
export const getReceivedCreditNotes = () => API.get(`${prefix}/received`);
export const getAllCreditNotes = () => API.get(`${prefix}`);
export const getCurrentAdvertiserPosition = () => API.get(`${prefix}/position`);

export default {
  getOfferedCreditNotes,
  getReceivedCreditNotes,
  getAllCreditNotes,
  getCurrentAdvertiserPosition
};
