export const ADD_ROOM = 'ADD_ROOM';
export const ADD_ROOM_SUCCESS = 'ADD_ROOM_SUCCESS';
export const ADD_ROOM_FAILURE = 'ADD_ROOM_FAILURE';
export const ADD_ROOM_CLEAR = 'ADD_ROOM_CLEAR';

export const addRoom = (
  symbol,
  flatId,
  squareMeters,
  price,
  deposit,
  description,
  freeAt,
  images,
  equipment,
  locationOfRoom
) => ({
  symbol,
  type: ADD_ROOM,
  flatId,
  squareMeters,
  price,
  deposit,
  description,
  freeAt,
  images,
  equipment,
  locationOfRoom,
});
export const addRoomSuccess = (payload) => ({
  type: ADD_ROOM_SUCCESS,
  payload,
});
export const addRoomFailure = (errors) => ({
  type: ADD_ROOM_FAILURE,
  errors,
});
export const addRoomClear = () => ({
  type: ADD_ROOM_CLEAR,
});
