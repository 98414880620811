import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ClaimReport from './ClaimReport';
import useClaimReportStore from 'hooks/store/useClaimReportStore';

const ClaimReportContainer = ({ match }) => {
  let parts,
    categorys,
    categoryParts,
    category1,
    category2,
    category3,
    description;
  const { reportId } = match.params;
  const { t } = useTranslation('claimReport');

  const {
    getClaimReport,
    getClaimReportData,
    getClaimReportSuccess
  } = useClaimReportStore();
  const claimReport = getClaimReportData();
  const success = getClaimReportSuccess();

  useEffect(() => {
    getClaimReport(reportId);
  }, []);

  if (success) {
    parts = claimReport ? claimReport.description.split('\n') : [];
    categorys = parts[0];
    categoryParts = categorys.split(' - ');
    category1 = categoryParts[0];
    category2 = categoryParts[1];
    category3 = categoryParts[2];
    description = parts.slice(1, parts.length).join('\n') || '';
  }

  return success ? (
    <ClaimReport
      {...{
        t,
        id: claimReport.id,
        description,
        category1,
        category2,
        category3,
        images: claimReport.images,
        state: claimReport.state,
        date: claimReport.date
      }}
    />
  ) : (
    <div />
  );
};

ClaimReportContainer.propTypes = {
  match: PropTypes.object.isRequired
};

export default ClaimReportContainer;
