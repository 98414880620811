import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  GET_ALL_ROOMS,
  GET_ALL_ROOMS_FAILURE,
  GET_ALL_ROOMS_SUCCESS
} from './getAllRooms.actions';

export default createReducer(
  {
    [GET_ALL_ROOMS]: defaultRequest,
    [GET_ALL_ROOMS_SUCCESS]: defaultSuccess,
    [GET_ALL_ROOMS_FAILURE]: defaultError
  },
  defaultInitialState
);
