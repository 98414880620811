import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { logout } from 'store/account/logout/logout.actions';
import useRooms from 'hooks/store/useRooms';
import LandingPage from './LandingPage';
import * as moment from 'moment';

const LandingPageContainer = () => {
  const { t } = useTranslation('landingPage');
  const dispatch = useDispatch();
  const history = useHistory();
  const roomsStore = useRooms();
  const [rooms, setRooms] = useState([]);
  const isLoggedIn = useSelector(state => state.user.success);
  const [cities, setCities] = useState([]);

  const { loading: roomsLoading } = useSelector(
    state => state.request.getAllRooms
  );

  const handleButtonRegister = () => history.push('/register');
  const handleButtonLogin = () => history.push('/login');
  const handleDashboardClick = () => history.push('/dashboard');
  const handleLogoutClick = () => dispatch(logout());

  const bookableRooms = roomsStore.filter(x => x.bookable);

  useEffect(() => {
    setRooms(bookableRooms);
    setCities([...new Set(bookableRooms.map(x => x.address.location))]);
    handleOnSearch({
      priceTo: null,
      fromSize: null,
      city: null,
      freeAt: moment()
    });
  }, [roomsStore]);

  const roomRef = React.createRef();

  const handleOnSearch = search => {
    if (!search.priceTo && !search.fromSize && !search.city && !search.freeAt) {
      setRooms(bookableRooms);
      return;
    }

    let searchedRooms = [...bookableRooms];
    if (Number.isInteger(search.priceTo)) {
      searchedRooms = searchedRooms.filter(x => x.price < search.priceTo);
    }

    if (Number.isInteger(search.fromSize)) {
      searchedRooms = searchedRooms.filter(
        x => x.squareMeters > search.fromSize
      );
    }

    if (search.city) {
      searchedRooms = searchedRooms.filter(x =>
        x.address.location.toLowerCase().includes(search.city.toLowerCase())
      );
    }

    if (search.freeAt) {
      searchedRooms = searchedRooms.filter(x =>
        moment(x.freeAt)
          .startOf('day')
          .isSameOrBefore(search.freeAt.startOf('day'))
      );
    }

    setRooms(searchedRooms);
  };

  const handleScrollToRooms = () => {
    roomRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  };

  return (
    <LandingPage
      {...{
        handleButtonRegister,
        handleButtonLogin,
        handleDashboardClick,
        handleLogoutClick,
        isLoggedIn,
        rooms,
        t,
        history,
        handleOnSearch,
        roomRef,
        handleScrollToRooms,
        cities,
        roomsLoading
      }}
    />
  );
};

export default LandingPageContainer;
