import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import {
  GET_BANKING_STATUS,
  getBankingStatusSuccess,
  getBankingStatusFailure
} from './getBankingStatus.actions';
import { bankingApi } from 'utils/api';

export function* getBankingStatus() {
  try {
    const { data, errors } = yield call(bankingApi.getBankingStatus);

    if (!errors) {
      yield put(getBankingStatusSuccess(data));
    } else {
      yield put(getBankingStatusFailure(errors));
    }
  } catch (error) {
    yield put(getBankingStatusFailure([i18n.t('base:generalError')]));
  }
}

export default function*() {
  yield takeLatest(GET_BANKING_STATUS, getBankingStatus);
}
