export const GET_FLATS = 'GET_FLATS';
export const GET_FLATS_SUCCESS = 'GET_FLATS_SUCCESS';
export const GET_FLATS_FAILURE = 'GET_FLATS_FAILURE';

export const getFlats = () => ({
  type: GET_FLATS
});
export const getFlatsSuccess = payload => ({
  type: GET_FLATS_SUCCESS,
  payload
});
export const getFlatsFailure = errors => ({
  type: GET_FLATS_FAILURE,
  errors
});
