import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import DetailValue from '../DetailValue';

const SimpleRoomPreview = ({
  image,
  price,
  squareMeters,
  description,
  id,
  className
}) => {
  const { t } = useTranslation('room');
  const history = useHistory();
  return (
    <div
      className={className + ' d-flex'}
      style={{ cursor: 'pointer' }}
      onClick={() => history.push(`/room/${id}`)}
    >
      <img className="me-3" src={image} width="100" />
      <div className="ellipsis">
        <div>{description}</div>
        <div className="d-flex">
          <DetailValue
            className="me-3"
            label={t('rentalFee')}
            value={`${price}€`}
          />
          <DetailValue label={t('floorSpace')} value={`${squareMeters}m²`} />
        </div>
      </div>
    </div>
  );
};

SimpleRoomPreview.propTypes = {
  image: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  squareMeters: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  className: PropTypes.string
};

export default SimpleRoomPreview;
