import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from '../../../utils/reducer.helper';

import {
  CONFIRM_EMAIL,
  CONFIRM_EMAIL_SUCCESS,
  CONFIRM_EMAIL_FAILURE
} from './confirmEmail.actions';

export default createReducer(
  {
    [CONFIRM_EMAIL]: defaultRequest,
    [CONFIRM_EMAIL_SUCCESS]: defaultSuccess,
    [CONFIRM_EMAIL_FAILURE]: defaultError
  },
  defaultInitialState
);
