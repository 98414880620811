import { put, takeLatest, call } from 'redux-saga/effects';
import { accountApi } from 'utils/api';
import i18n from 'i18next';
import {
  HANDLE_LOAD_USER,
  handleLoadUserSuccess,
  handleLoadUserFailure
} from './user.actions';
import { parseRole } from 'utils/jwtDecoder';
import * as UserRole from 'utils/constants/userRole.constants';

function* handleLoadUser({ jwt }) {
  try {
    const role = parseRole(jwt).toUpperCase();

    let { data, errors } = yield call(accountApi.getUser);

    data = {
      ...data,
      role: !role ? UserRole.USER : role,
      isLoaded: true
    };

    if (!errors) {
      yield put(handleLoadUserSuccess(data));
    } else {
      yield put(handleLoadUserFailure(errors));
    }
  } catch (error) {
    yield put(handleLoadUserFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(HANDLE_LOAD_USER, handleLoadUser);
}
