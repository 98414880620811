import {
  FirstNameFormGroup,
  HouseFormGroup,
  LastNameFormGroup,
  ServiceProviderTypesFormGroup,
  SubmitButtonFormGroup
} from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import EmailFormGroup from '../../../components/FormGroups/EmailFormGroup';

const ServiceProviderForm = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  handleSubmit,
  serviceProviderTypes,
  setServiceProviderTypes,
  houseIds,
  setHouseIds,
  isLoading
}) => (
  <Form onSubmit={handleSubmit}>
    <FirstNameFormGroup value={firstName} onChange={setFirstName} required />
    <LastNameFormGroup value={lastName} onChange={setLastName} required />
    <EmailFormGroup value={email} onChange={setEmail} required />
    <ServiceProviderTypesFormGroup
      serviceProviderTypes={serviceProviderTypes}
      setServiceProviderTypes={setServiceProviderTypes}
    />
    <HouseFormGroup multiple value={houseIds} onChange={setHouseIds} />
    <SubmitButtonFormGroup isLoading={isLoading} />
  </Form>
);

ServiceProviderForm.propTypes = {
  firstName: PropTypes.string.isRequired,
  setFirstName: PropTypes.func.isRequired,
  lastName: PropTypes.string.isRequired,
  setLastName: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  email: PropTypes.func.isRequired,
  setEmail: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default ServiceProviderForm;
