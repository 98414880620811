import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddGiverModal from 'components/addGiverModal';
import { HouseGiverSelect } from 'containers';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormGroup, FormLabel } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

const HouseGiverFormGroup = ({ value, onChange, allowAddValue, ...rest }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <FormGroup className="mb-3" controlId="formHouseGiver">
        <FormLabel>{i18n.t('forms:houseGiver')}</FormLabel>
        <div style={{ display: 'flex' }}>
          <HouseGiverSelect
            onChange={onChange}
            value={value}
            name="houseGiver"
            {...rest}
          />
          {allowAddValue && (
            <Button
              className="mb-3"
              style={{ marginLeft: 10 }}
              onClick={() => setIsModalOpen(true)}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          )}
        </div>
      </FormGroup>
      <AddGiverModal
        isOpen={isModalOpen}
        onHide={() => setIsModalOpen(false)}
      />
    </>
  );
};

HouseGiverFormGroup.defaultProps = {
  value: '',
  allowAddValue: true
};

HouseGiverFormGroup.propTypes = {
  value: PropTypes.string,
  allowAddValue: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

export default HouseGiverFormGroup;
