import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { claimReportApi } from 'utils/api';
import {
  SEND_CLAIM_REPORT,
  sendClaimReportSuccess,
  sendClaimReportFailure
} from './sendClaimReport.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';

export function* sendClaimReport({
  claim,
  description,
  images,
  isMoveInClaimReport,
  damageAmount,
  userId,
  roomId
}) {
  try {
    const formData = new FormData();
    formData.append('claim', claim);
    formData.append('description', description);
    if (damageAmount) {
      formData.append('damageAmount', damageAmount);
    }
    if (userId) {
      formData.append('userId', userId);
    }
    if (roomId) {
      formData.append('roomId', roomId);
    }
    images.forEach(file => {
      formData.append('images', file);
    });
    formData.append('moveInClaimReport', isMoveInClaimReport);

    const { data, errors } = yield call(
      claimReportApi.sendClaimReport,
      formData
    );

    if (!errors) {
      yield put(sendClaimReportSuccess(data));
      successToast('sendClaimReport');
    } else {
      yield put(sendClaimReportFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(sendClaimReportFailure([i18n.t('base:generalError')]));
  }
}

export default function*() {
  yield takeLatest(SEND_CLAIM_REPORT, sendClaimReport);
}
