import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { accountApi } from 'utils/api';
import {
  REGISTER_TENANT,
  registerTenantSuccess,
  registerTenantFailure
} from './registerTenant.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';

function* registerTenant({ email, password }) {
  try {
    const { data, errors } = yield call(
      accountApi.registerTenant,
      email,
      password
    );

    if (!errors) {
      yield put(registerTenantSuccess(data));
    } else {
      yield put(registerTenantFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(registerTenantFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(REGISTER_TENANT, registerTenant);
}
