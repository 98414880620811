import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  ADD_LANDLORD,
  ADD_LANDLORD_SUCCESS,
  ADD_LANDLORD_FAILURE,
  ADD_LANDLORD_CLEAR
} from './addLandlord.actions';

export default createReducer(
  {
    [ADD_LANDLORD]: defaultRequest,
    [ADD_LANDLORD_SUCCESS]: defaultSuccess,
    [ADD_LANDLORD_FAILURE]: defaultError,
    [ADD_LANDLORD_CLEAR]: () => defaultInitialState
  },
  defaultInitialState
);
