import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState,
  defaultReset
} from 'utils/reducer.helper';

import {
  CREATE_TERMINATION,
  CREATE_TERMINATION_SUCCESS,
  CREATE_TERMINATION_FAILURE,
  CREATE_TERMINATION_RESET
} from './createTermination.actions';

export default createReducer(
  {
    [CREATE_TERMINATION]: defaultRequest,
    [CREATE_TERMINATION_SUCCESS]: defaultSuccess,
    [CREATE_TERMINATION_FAILURE]: defaultError,
    [CREATE_TERMINATION_RESET]: defaultReset
  },
  defaultInitialState
);
