import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  REGISTER_SERVICE_PROVIDER,
  REGISTER_SERVICE_PROVIDER_SUCCESS,
  REGISTER_SERVICE_PROVIDER_FAILURE
} from './registerServiceProvider.actions';

export default createReducer(
  {
    [REGISTER_SERVICE_PROVIDER]: defaultRequest,
    [REGISTER_SERVICE_PROVIDER_SUCCESS]: defaultSuccess,
    [REGISTER_SERVICE_PROVIDER_FAILURE]: defaultError
  },
  defaultInitialState
);
