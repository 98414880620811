import { put, takeLatest, call } from 'redux-saga/effects';
import { terminationApi } from 'utils/api';
import i18n from 'i18next';
import {
  CREATE_TERMINATION,
  createTerminationSuccess,
  createTerminationFailure
} from './createTermination.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';

function* createTermination({ dateToTerminate, nextTenantEmail, bookingId }) {
  try {
    const { data, errors } = yield call(
      terminationApi.createTermination,
      dateToTerminate,
      nextTenantEmail,
      bookingId
    );

    if (!errors) {
      yield put(createTerminationSuccess(data));
      successToast('createTermination');
    } else {
      yield put(createTerminationFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(createTerminationFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(CREATE_TERMINATION, createTermination);
}
