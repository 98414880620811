export const REGISTER_TENANT = 'REGISTER_TENANT';
export const REGISTER_TENANT_SUCCESS = 'REGISTER_TENANT_SUCCESS';
export const REGISTER_TENANT_FAILURE = 'REGISTER_TENANT_FAILURE';
export const REGISTER_TENANT_CLEAR = 'REGISTER_TENANT_CLEAR';

export const registerTenant = (email, password) => ({
  type: REGISTER_TENANT,
  email,
  password
});
export const registerTenantSuccess = payload => ({
  type: REGISTER_TENANT_SUCCESS,
  payload
});
export const registerTenantFailure = errors => ({
  type: REGISTER_TENANT_FAILURE,
  errors
});
export const registerTenantClear = errors => ({
  type: REGISTER_TENANT_CLEAR,
  errors
});
