export const UPDATE_HOUSE_OWNER = 'UPDATE_HOUSE_OWNER';
export const UPDATE_HOUSE_OWNER_SUCCESS = 'UPDATE_HOUSE_OWNER_SUCCESS';
export const UPDATE_HOUSE_OWNER_FAILURE = 'UPDATE_HOUSE_OWNER_FAILURE';
export const UPDATE_HOUSE_OWNER_CLEAR = 'UPDATE_HOUSE_OWNER_CLEAR';

export const updateHouseOwner = (id, firstName, lastName, address) => ({
  type: UPDATE_HOUSE_OWNER,
  id,
  address,
  firstName,
  lastName
});
export const updateHouseOwnerSuccess = payload => ({
  type: UPDATE_HOUSE_OWNER_SUCCESS,
  payload
});
export const updateHouseOwnerFailure = errors => ({
  type: UPDATE_HOUSE_OWNER_FAILURE,
  errors
});
export const updateHouseOwnerClear = () => ({
  type: UPDATE_HOUSE_OWNER_CLEAR
});
