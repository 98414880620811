import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getHouses } from 'store/houses/getHouses/getHouses.actions';
import HouseList from './HouseList';

const HouseListContainer = ({ houseSelector }) => {
  const { t } = useTranslation('data');
  const history = useHistory();
  const dispatch = useDispatch();

  const houses = useSelector(houseSelector);
  const { success } = useSelector(state => state.request.getHouses);

  useEffect(() => {
    if (!success) dispatch(getHouses());
  }, [success]);

  const columnConfiguration = [
    {
      dataField: 'address',
      text: t('houses.address'),
      sort: true,
      formatter: addressFormatter
    }
  ];

  const handleNewHouseClick = () => history.push('/dashboard/data/house/add');
  const handleHouseRowClick = flat =>
    history.push(`/dashboard/data/house/${flat.id}`);

  return (
    <HouseList
      {...{
        t,
        houses,
        columnConfiguration,
        handleNewHouseClick,
        handleHouseRowClick
      }}
    />
  );
};

HouseListContainer.defaultProps = {
  houseSelector: state => state.houses
};

HouseListContainer.propTypes = {
  houseSelector: PropTypes.func
};

export default HouseListContainer;

function addressFormatter({ street, houseNumber, postalCode, location }, row) {
  return `${street} ${houseNumber}, ${postalCode} ${location}`;
}
